import React, { useEffect, useState } from 'react';
import ApiConfig from '../api/ApiConfig';
import { getWithAuthCallWithtext } from '../api/ApiServices';

function TermsAndCondtion(props) {
  const [termsAndContion, setTermsAndCondition] = useState("")
  function getTermsAndContion() {
    getWithAuthCallWithtext(ApiConfig.TERMS_AND_CONDITION)
      .then((data) => {
        setTermsAndCondition(data.text);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    getTermsAndContion();
  }, []);
  return (
    <div dangerouslySetInnerHTML={{ __html: termsAndContion }}>

    </div>
  );
}

export default TermsAndCondtion;