export default {
  FULLNAME: "Full Name",
  EMAIL: "Email",
  MOBILE_NUMBER: "Mobile Number",
  COUNTRY: "Country",
  CITY: "City",
  DATE_OF_BIRTH: "Date of Birth",
  LINKEDIN_PROFILE: "Linkedin Profile",
  SHORT_DESCRIPTION: "Short description about yourself",
  UPDATE_BASIC_DETAILS: "Update Basic Details",
  UPDATE: "Update",
  CONFIRMATION_FOR_DELETE: "Are you sure want to delete your profile?",
  MATCHED_JOBS: "Matched Jobs",
  SAVED_JOBS: "Saved Jobs",
  SET_STATUS: "Set Status",
  ADD_INTRO_VIDEO: "Add Intro Video",
  BASIC_PROFILE: "Basic Profile",
  EDUCATION: "Education",
  EXPERIANCE: "Experience",
  PREFERNCES: "Preferences",
  LANGUAGE: "Language",
  INDUSTRY: "Industry",
  MATCHING_PERCENTAGE_PREFERENCE: "Matching percentage preference",
  JOB_TITLE: "Job Title",
  MONTHLY_SALARY: "Monthly Salary",
  DESIRED_LOCATION: "Desired Location",
  WORK_LOCATION: "Work Location",
  SKILLS: "Skills",
  TOOLS: "Tools",
  CERTIFICATES: "Certificates",
  CURRENT_EXPERIANCE_LEVEL: "Current experiance level",
  CURRENT_ROLE: "Current role",
  TUTORIAL: "Tutorial",
  CHANGE_LANGAUAGE: "Change Langauage",
  SUBSCRIPTION_PLANS: "Subscription Plans",
  FAQ: "FAQ",
  FEEDBACK_SUGGESTION: "Feedback & Suggestion",
  CONTACT_US: "Contact Us",
  SETTINGS: "Settings",
  LEGAL: "Legal",
  LOGOUT: "Logout",
  SELECT_LANGUAGE: "Select Language",
  SELECT_YOUR_PREFERRED_LANGUAGE_TO_CONTINUE:
    "Select your preferred language to continue",
  CONTINUE: "CONTINUE",
  UPGRADE: "Upgrade",
  GIVE_US_FEEDBACK_ABOUT_A_FEATURE_LET_US_KNOW_HOW_WE_CAN_IMPROVE_YOUR_EXPERIANCE:
    "Give Us Feedback about a Feature. Let us know how we can improve your experience.",
  SELECT_FEATURE: "Select Feature",
  ENTER_YOUR_FEEDBACK_BELOW: "Enter your Feedback below",
  GET_IN_TOUCH_WITH_US: "Get In Touch With Us",
  MESSAGE_US: "MESSAGE US",
  SUBJECT: "Subject",
  PLEASE_SELECT_SUBJECT: "Please select subject",
  MESSAGE: "Message",
  ACCOUNT_SETTINGS: "ACCOUNT SETTINGS",
  HIDE_STATUS_FOR_EMPLOYERS: "Hide status for Recruiter",
  ENABLE_READ_RECEIPT: "Enable read receipt",
  BLOCKING: "Blocking",
  CHANGE_PASSWORD: "Change Password",
  DELETE_PROFILE: "Delete Profile",
  NOTIFICATIONS_SETTINGS: "NOTIFICATIONS SETTINGS",
  ENABLE_NOTIFICATIONS_FOR_MATCHES: "Enable notifications for matches",
  WANT_TO_TRY_OUR_PLATFORM: "Want to try our platform?",
  CLICK_HERE_FOR_3_DAYS_FREE_TRIAL: "Click here for 3 days free trial!",
  MAXIMUM_JOBS_TO_APPLY: "Maximum Jobs To Apply",
  NUMBER_OF_JOBS_SAVES: "Number of Jobs Saved",
  VALIDITY: "Validity",
  PRICE: "Price",
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_CONDITIONS: "Terms & Conditions",
  PRIVACY_PREFERENCES: "Privacy Preferences",
  NO_NEW_NOTIFICATION: "No new notification!",
  ADD_EXPERIENCE: "Add Experience",
  COMPANY_NAME: "Company Name",
  JOB_TYPE: "Job Type",
  PLEASE_SELECT_JOB_TYPE: "Please select job type",
  PLEASE_SELECT_JOB_TITLE: "Please select job title",
  PLEASE_SELECT_JOB_EXPERIENCE: "Please select job experience",
  START_YEAR: "Start Year",
  SELECT_DATE: "Select date",
  END_YEAR: "End Year",
  I_AM_CURRENTLY_WORK_HERE: "I am currently work here",
  SAVE: "SAVE",
  EDUCATION_TYPE: "Education Type",
  PLEASE_SELECT_EDUCATION_TYPE: "Please select education type",
  INSTITUTE_NAME: "Institute Name",
  AREA_OF_STUDY: "Area Of Study",
  YEAR_OF_GRADUATION: "Year of Graduation",
  PLEASE_ENTER_EDUCATION_TYPE: "Please enter education type",
  PLEASE_ENTER_INSTITUTE_NAME: "Please enter institute name",
  PLEASE_ENTER_AREA_OF_STUDY: "Please enter area of study",
  PLEASE_ENTER_GRADUATION_YEAR: "Please enter graduation year",
  PLEASE_ENTER_VALID_GRADUATION_YEAR: "Please enter valid graduation year",
  PLEASE_ENTER_COMPANY_NAME: "Please enter company name!",
  PLEASE_CHOOSE_ROLE: "Please choose role!",
  PLEASE_CHOOSE_JOB_TYPE: "Please choose job type!",
  PLEASE_ADD_EXPERIENCE: "Please add experience!",
  PLEASE_ADD_CITY: "Please add city!",
  PLEASE_ADD_COUNTRY: "Please add country!",
  PLEASE_CHOOSE_START_YEAR: "Please choose start year!",
  BEGINNER: "Beginner",
  COMPETENT: "Competent",
  PROFICIENT: "Proficient",
  EXPERT: "Expert",
  ADD_CERTIFICATE: "Add certificate",
  ADD_LINKEDIN_PROFILE: "Add LinkedIn Profile",
  PLEASE_SELECT_LANGUAGE: "Please select language",
  PLEASE_SELECT_INDUSTRIES: "Please select industries",
  DESIRED_COUNTRY: "Desired Country",
  DESIRED_CITY: "Desired City",
  PLEASE_SELECT_YOUR_SALARY_RANGE: "Please select your salary range",
  MATCH_PRECENTAGE: "Match Precentage",
  PLEASE_SELECT_MATCH_PRECENTAG: "Please select match precentage",
  ONSITE: "Onsite",
  HYBRID: "Hybrid",
  REMOTE: "Remote",
  UPGRADE_TO_FULL_ACCESS_MSG:
    "You need to either upgrade to full access to save/apply more jobs or wait till your free access reloads.",
  CANCEL: "CANCEL",
  RECENT_SEARCHES: "Recent Searches",
  PLEASE_ENTER_YOUR_EMAIL_PHONE_NUMBER: "Please enter your Email/Phone number!",
  PLEASE_ENTER_YOUR_PASSWORD: "Please enter your password!",
  PLEASE_SELECT_USER_TYPE: "Please select user type",
  JOB_SEEKER: "Job Seeker",
  RECRUITER: "Recruiter",
  LOGIN: "Login",
  LOGIN_INSTRUCTION_LABEL_MSG:
    "Enter your verified email/phone number to login to your account",
  FORGOT_PASSWORD: "Forgot Password?",
  LOGIN_AGREE_LABEL_MSG: "By clicking “Login”, you agree with our ",
  TERMS: "Terms..",
  LOGIN_PRIVACY_COOKIES_POLICY_LABEL_MSG:
    " Learn how we process your data in our",
  PRIVACY_POLICY: "Privacy Policy.",
  LOGIN_WITH: "Login With",
  OR_LOGIN_WITH: "Or Login With",
  DONT_HAVE_AN_ACCOUNT: "Don’t have an account?",
  REGISTER: "Register",
  PLEASE_ENTER_CORRECT_OTP: "Please enter correct OTP!",
  DIDNT_RECEIVE_CODE: "Didn't receive code?",
  RESEND: "RESEND",
  PLEASE_ENTER_YOUR_NAME: "Please enter your name.",
  PLEASE_ENTER_YOUR_EMAIL: "Please enter your email.",
  PLEASE_ENTER_VALID_EMAIL_ADDRESS: "Please enter valid email address!",
  PLEASE_ENTER_YOUR_MOBILE_NUMBER: "Please enter your Mobile Number.",
  PLEASE_ENTER_YOUR_CITY: "Please enter your city.",
  PLEASE_ENTER_YOUR_COUNTRY: "Please enter your country.",
  PLEASE_SELECT_YOUR_DATE_OF_BIRTH: "Please select your date of birth!.",
  PLEASE_DESCRIBE_YOUR_SELF: "Please describe your self!.",
  PLEASE_ENTER_YOUR_PASSWORD: "Please enter your password.",
  VALID_PASSWORD_MSG:
    "Password should be a mixture of at least one uppercase, lowercase, numeric & special character.",
  PLEASE_ENTER_YOUR_CONFIRM_PASSWORD: "Please enter your confirm password.",
  PASSWORD_DO_NOT_MATCH: "Password do not match!",
  UPLOAD_PROFILE_PICTURE: "Upload Profile Picture",
  UPLOAD_FROM_STORAGE: "Upload from Storage",
  CAMERA: "Camera",
  CONFIRM: "Confirm",
  PLEASE_ENTER_YOUR_DEPARTMENT: "Please enter your department!",
  PLEASE_SELECT_YOUR_ROLE: "Please select your role!",
  PLEASE_ENTER_YOUR_COMPANY_NAME: "Please enter your company name!",
  PLEASE_SELECT_YOUR_COMPANY_SIZE: "Please select your company size!",
  PLEASE_ENTER_YOUR_VAT_COMPANY_REGISTRATION_NUMBER:
    "Please enter your VAT/Company Registration Number!",
  PLEASE_ADD_COMPANY_LINKEDIN_PROFILE: "Please Add Company Linkedin Profile!",
  SUCCESSFULLY_REGISTER: "Successfully Registered.",
  PLEASE_SELECT_INDUSTRY: "Please select industry",
  DEPARTMENT: "Department",
  PLEASE_SELECT_SIZE: "Please select size",
  VAT_COMPANY_REGISTRATION_NUMBER: "VAT/Company Registration Number",
  ADD_COMPANY_LINKEDIN_PROFILE: "Add Company LinkedIn Profile",
  YOUR_PASSWORD_HAS_BEEN_CHANGED_SUCCESSFULLY:
    "Password changed successfully!",
  PLEASE_ENTER_CURRENT_PASSWORD: "Please enter current Password.",
  PLEASE_ENTER_NEW_PASSWORD: "Please enter new Password.",
  PLEASE_ENTER_CONFIRM_NEW_PASSWORD: "Please re-enter Password.",
  CURRENT_PASSWORD_AND_NEW_PASSWORD_SAME:
    "Current password and New Password should be same!",
  PASSWORD_DOES_NOT_MATCH: "Password does not match!",
  ENTER_CURRENT_PASSWORD: "Enter Current Password",
  ENTER_NEW_PASSWORD: "Enter New Password",
  CONFIRM_NEW_PASSWORD: "Confirm New Password",
  SUBMIT: "SUBMIT",
  PLEASE_ADD_YOUR__PHOTO: "Please upload your  profile image",
  PLEASE_ENTER_ABOUT_COMPANY: "Please enter about company!",
  PLEASE_ENTER_INDUSTRY_NAME: "Please enter industry name!",
  COPY_JOB_AD: "Copy Job Ad",
  COMPANY_INFO: "Company Info",
  ABOUT_THE_COMPANY: "About the Company",
  VIDEO_LENGTH_SHOULD_NOT_BE_MORE_THAN_45_SEC:
    "Video length should not exceed more than 45 seconds.!",
  PLEASE_ENTER_JOB_DESCRIPTION: "Please enter job description!",
  PLEASE_SELECT_JOB_TYPE: "Please select job type!",
  PLEASE_SELECT_REQUIRE_EXPERIENCE: "Please select experience!",
  PLEASE_SELECT_WORK_LOCATION: "Please select work location!",
  PLEASE_SELECT_LANGUAGE_REQUIRE: "Please select language!",
  PLEASE_SELECT_LANGUAGE_FOR_JOB_POSTING:
    "Please select language!",
  PLEASE_ADD_DURATION_OF_ADV: "Please add duration of ad!",
  JOB_INFO: "Job Info",
  ADD_VIDEO: "Add Video",
  PART_TIME: "Part Time",
  FULL_TIME: "Full Time",
  EXPERIENCE_LEVEL: "Experience Level",
  LIST_OF_LANGUAGES: "List of languages",
  JOB_POSTING_LANGUAGE: "Job Posting Language",
  AD_DURATION: "Ad Duration",
  JOB_COPIED_SUCCESSFULLY: "Job copied successfully.",
  PREREQUISITES: "Prerequisites",
  ADD_SKILLS: "Add skills",
  ADD_TOOLS: "Add tools",
  CREATE_JOB_AD: "Create Job Ad",
  JOB_CREATED_SUCCESSFULLY: "Job created successfully",
  OPPS_NO_JOBS_FOUND: "OPPS! no jobs found!",
  OPPS_NO_PROFILES_FOUND: "OPPS! no Profiles found!",
  LOADING: "Loading...",
  THIS_IS_TEMPORARY_I_WILL_BE_BACK: "This is temporary, I will be back.",
  I_GET_TOO_MANY_NOTIFICATIONS: "I get too many notifications.",
  I_SPEND_TOO_MUCH_TIME_IN_PROW: "I spend too much time in ProW.",
  MY_ACCOUNT_WAS_HACKED: "My account was hacked.",
  I_DIDNT_FIND_PROW_USEFUL: "I didn't find ProW useful.",
  I_HAVE_A_PRIVATE_CONCERN: "I have a privacy concern.",
  DELETE_ACCOUNT: "Delete Account",
  SELECT_A_REASON_FOR_DELETING_THE_ACCOUNT:
    "Select a reason for deleting your account",
  OTHER: "Other",
  DELETE_YOUR_ACCOUNT_DO_YOU_WISH_TO_CONTINUE:
    "Once you delete your account, all your data’s will be lost and you no longer will be able to access your account. Do you wish to continue?",
  NO: "NO",
  YES: "YES",
  YOUR_ACCOUNT_HAS_BEEN_DELETED_SUCCESSFULLY_CLICK_CONTINUE_TO_LOGOUT:
    "Your account has been deleted successfully!",
  EDIT_JOB_AD: "Edit Job Ad",
  JOB_UPDATED_SUCCESSFULLY: "Job Updated successfully",
  SEARCH: "Search...",
  SAVED_PROFILES: "Saved Profiles",
  MATCHED_PROFILES: "Matched Profiles",
  OPEN: "open",
  PAUSED: "Paused",
  PAUSE: "Pause",
  CLOSED: "Closed",
  CLOSE: "Close",
  RESUME: "RESUME",
  JOB_ADS: "Job Ads",
  REQUIRED_LANGUAGE: "Required Language",
  NO_VIDEO_FOUND: "No video found!",
  ARE_YOU_SURE_WANT_TO_MAKE_DUPLICATE_JOB:
    "Are you sure want to make duplicate?,Please note this action will create duplicate of this job.",
  NO_PROFILES_SAVED: "No profiles saved.",
  ABOUT_THE_JOB: "About The Job",
  AD_DETAILS: "AD Details",
  ARE_YOU_SURE_WANT_TO_LOGOUT: "Are you sure want to Logout?",
  SELECT_YEAR: "select year",
  PLEASE_ENTER_INDUSTRY_PREFERENCE: "Please enter industry preference!",
  PLEASE_ENTER_ROLE_PREFERENCE: "Please enter role preference!",
  PLEASE_ENTER_JOB_TYPE_PREFERENCE: "Please enter Job type preference!",
  PLEASE_SELECT_YOUR_PREFERENCE_COUNTRY:
    "Please select your preference country!",
  PLEASE_SELECT_YOUR_PREFERENCE_CITY: "Please select your preference city!",
  PLEASE_SELECT_MONTHLY_SALARY_PREFERENCE:
    "Please select monthly salary preference!",
  INDUSTRIES: "Industries",
  PERCENTAGE: "Percentage",
  PLEASE_SELECT_PERCENTAGE: "Please select percentage",
  PLEASE_ADD_YOUR_CURRENT_EXPERIENCE_LEVEL:
    "Please add your current experience level!",
  PLEASE_ADD_YOUR_CUURENT_DESIGNATION: "Please add your cuurent designation!",
  PLEASE_SELECT_YOUR_SKILLS: "Please select your skills",
  PLEASE_SELECT_YOUR_TOOLS: "Please select your tools",
  DO_YOU_HAVE_DRIVING_LICENSE: "Do you have driving license?",
  CURRENT_EXPERIENCE_LEVEL: "Current experience level",
  PHONE_NUMBER_VERIFIED_SUCESSFULLY: "Phone number verified sucessfully",
  EMAIL_VERIFIED_SUCESSFULLY: "Email verified sucessfully",
  PLEASE_ENTER_PHONE_NUMBER: "Please enter phone number!",
  OTP_VERIFICATION: "Otp Verification",
  PLEASE_ENTER_THE_4_DIGIT_CODE_SENT_TO:
    "Please enter the 4 digit code sent to",
  YOU_NEED_TO_VERIFIY_YOUR_EMAIL_PLEASE_VERIFIY:
    "You need to verify your email, Please verify",
  OK: "OK",
  YOU_ARE_ALREADY_USING_THIS_EMAIL_NO_NEED_OF_VERIFICATION:
    "You are already using this email, no need of verification",
  YOU_NEED_TO_VERIFIY_YOUR_PHONE_NUMBER_PLEASE_VERIFIY:
    "You need to verify your phone number, Please verify",
  YOU_ARE_ALREADY_USING_THIS_PHONE_NUMBER_NO_NEED_OF_VERIFICATION:
    "You are already using this phone number, no need of verification",
  PLEASE_ENTER_INDUSTRY: "Please enter industry!",
  UPDATE_COMPANY_INFO: "Update company info",
  PRESENT: "Present",
  ARE_YOU_ACTIVELY_HIRING: "Are you actively hiring?",
  ACTIVELY_HIRING_FOR: "Actively Hiring for",
  ACTIVELY_LOOKING_FOR: "Actively looking for a job",
  MATCHES: "Matches",
  CREATE_NEW_JOB_AD: "Create New Job Ads",
  SEE_ALL_ADS: "SEE ALL Ads",
  BASIC_INFO: "BASIC INFO",
  ARE_YOU_LOOKING_FOR_A_JOB: "Are you looking for a job?",
  TUTORIALS: "Tutorials",
  SKIP: "SKIP",
  TOOLBAR: "toolbar",
  TYPE_HERE: "Type here",
  GET_STARTED: "Get Started",
  PLEASE_ADD_YOUR_LINKEDIN_PROFILE: "Please add your linkedin profile",
  PLEASE_SELECT_DID_YOU_HAVE_DRIVING_LICENSE:
    "Please select did you have driving license!",
  SOMTHING_WENT_TO_WRONG: "Something went wrong!",
  MATCH_WITH_JOB: "MATCH WITH JOB",
  TOOLS_REQUIRED: "Tools Required",
  DRIVING_LICENSE: "Driving License",
  PROFILE: "Profile",
  ADVANCED_SKILLS: "Advanced Skills",
  UPLOAD_VIDEO: "Upload Video",
  YOUR_PREFERRED_INDUSTRY: "Select Your Preferred Industry",
  LOOKING_FOR_A_JOB: "Looking For A Job?",
  FILTERS: "Filters",
  PLAY_INTRO_VIDEO: "Play intro video",
  WORKPLACE: "Workplace",
  APPLY: "APPLY",
  MENU: "MENU",
  NOTIFICATIONS: "Notifications",
  SEE_ALL: "See All",
  SEND: "Send",
  HIDE_STATUS_FOR_RECRUITERS: "Hide status for recruiters",
  HOME: "Home",
  CHAT: "Chat",
  NOT_INTERESTED: "Not Interested",
  BLOCK_COMPANY: "Block Company",
  EXPLORE: "Explore",
  MATCHING_JOBS: "Matching jobs",
  MATCHING_PERCENTAGE: "Matching percentage",
  MATCH: "Match",
  LOOKING_TO_HIRE_PEOPLE: "Looking to Hire People?",
  SELECT_YOUR_PREFERRED_ROLE: "Select Your Preferred Role.",
  ACTIVELY_HIRING: "Actively Hiring",
  COMPANY: "Company",
  LOCATION: "Location",
  COMPANY_SIZE: "Company Size",
  COMPANY_LINKEDIN_PROFILE: "Company LinkedIn Profile",
  JOBS: "Jobs",
  EDIT_BASIC_INFO: "Edit Basic Info",
  EDIT_COMPANY_INFO: "Edit Company Info",
  BLOCK: "Block",
  NO_OF_JOB_SAVES: "No. of Job Saves",
  OUR_PLANS: "Our Plans",
  FEEDBACK_AND_SUGGESTIONS: "Feedback & Suggestions",
  ACCOUNT: "Account",
  SEARCH_HERE: "Search here",
  TOP_MATCHES: " Top Matches",
  RECENT_CHATS: "Recent Chats",
  BLOCK_COMPANY_LABEL_MSG:
    "Once you block a company, you won’t be able to see the job ad’s posted by them.",
  BLOCKED_COMPANY_LIST: "BLOCKED COMPANY LIST",
  ACTION: "Action",
  UNBLOCK: "Unblock",
  RECRUITER_INFO: "Recruiter Info",
  TYPE_NAME_HERE: "Type name here",
  COMPANY_BLOCKED_SUCESSFULLY: "Company blocked sucessfully",
  PLEASE_ENTER_YOUR_MESSAGE: "please Enter your message!",
  MEASSAGE_SENT_SUCCESSFULLY: "Meassage sent successfully",
  PLEASE_CREATE_JOB_FIRST: "Please create job first!",
  MATCHED_JOBS_NOT_FOUND: "Matched Jobs Not Found!",
  MATCHED_PROFILES_NOT_FOUND: "Matched Profiles Not Found!",
  NO_NEW_RECORDS_FOUND: "No new records found",
  PLEASE_SELECT_MATCH_FOR: "Please select match for",
  PLEASE_ENTER_YOUR_MOBILE_NUMBER_OR_EMAIL:
    "Please enter your Mobile Number or Email",
  ENTER_YOUR_MOBILE_NUMBER_OR_EMAIL_ID_TO_CONTINUE:
    "Enter your mobile number or email id to continue",
  MOBILE_NUMBER_OR_EMAIL_ID: "Mobile number or Email id",
  EMAIL_OR_PHONE: "Email or Phone",
  ENTER_THE_CODE_SENT_YOUR_REGISTERED_MOBILE_NUMBER_EMAIL_ID_TO_CONTINUE:
    "Enter the code sent your registered mobile number/email id to continue",
  AD_ID: "Ad ID",
  VALID_UNTIL: "Valid until",
  COMPANY_INFO: "COMPANY INFO",
  ARE_YOU_SURE_YOU_WANT_TO: "Are you sure you want to",
  THE_JOB: "the job?",
  SELECT_JOB: "Select Job",
  NO_RECORDS_FOUND: "No records found",
  NO_RECENT_SEARCHES: "No Recent Searches",
  REJECT: "Reject",
  ACCEPT: "Accept",
  EDIT_EDUCATION_INFO: "Edit Education Info",
  EDIT_EXPRIENCE_INFO: "Edit Exprience Info",
  EDIT_ADVANCED_SKILLS: "Edit_Advanced_Skills",
  EDIT_PREFERENCES: "Edit Preferences",
  PREV: "Prev",
  NEXT: "Next",
  ADD_JOB_INFO: "Add Job Info",
  WELCOME_LABEL: "Welcome to",
  PRODUCT_INTRO:
    "Designed for Job seekers and Recruiters. Make your hiring decisions faster, easier We tell you exact matching percentage of job and profile.",
  PH_EMAIL_PHONE_NUMBER: "Email/Phone Number",
  PASSWORD: "Password",
  ERROR_MSG_INVALID_CREDENTIALS: "Invalid Credentials",
  REGISTER_AS: "Register As",
  CONFIRM_PASSWORD: "Re-Enter Password",
  REGISTER_INTO: "Enter your details",
  ALERT_OTP_NOT_VERIFIED: "OTP Not verified",
  ALREADY_HAVE_A_ACCOUNT_LABLE: "Already have a account?",
  SETUP_YOUR_PROFILE: "Enter the details to setup your profile",
  ROLE: "Role",
  ADD_YOUR_COMPANY_LINKEDIN_PROFILE: "Add Your Company LinkedIn Profile",
  FORGOT_PASSWORD_HEADING: "Forgot Password",
  DATA_UPDATE_SUCCESSFULLY: "Data Updated Successfully",
  PLEASE_SELECT_JOB_TITLE: "Please Select Job Title",
  SELECT_EXPERIENCE: "Select Experience",
  SELECT_COUNTRY: "Select country",
  SELECT_CITY: "Select city",
  SELECT_START_YEAR: "Select start year",
  SELECT_END_YEAR: "Select end year",
  DELETE: "Delete",
  EDIT: "Edit",
  EDIT_EXPERIENCE: "Edit Experience",
  COMPANY_UNBLOCK_SUCESSFULLY: "Company Unblocked sucessfully",
  ADD_EXPERIENCE: "Add Experience",
  SETUP_YOUR_PROFILE: "Setup Your Profile",
  INTERMEDIATE: "Intermediate",
  DESIGNATION: "Designation",
  ROLES: "Roles",
  ENTER_FULL_NAME: "Enter Full Name",
  ENTER_EMAIL: "Enter Email Address",
  ENTER_DATE_OF_BIRTH: "Enter Date Of Birth",
  ENTER_PHONE_NUMBER: "Enter Phone Number",
  SELECT_COUNTRY_CODE: "Select country code ",
  ENTER_CITY: "Enter City",
  ENTER_ABOUT_YOU: "Enter About You",
  ENTER_COUNTRY: "Enter Country",
  JOB_DESCRIPTION: "Job Description",
  REQUIRED_EXPERIENCE: "Required Experience",
  ADD_PREFERENCES: "Add Preferences",
  LANGAUGES: "Langauges",
  SELECT_SALARY: "Select Salary",
  SELECT: "Select",
  ENTER_COMPANY_COUNTRY: "Enter Company Country",
  ENTER_YOUR_JOB_TITLE: "Enter Your Job Title",
  ENTER_COMPANY_SIZE: "Enter Company Size",
  ENTER_COMPANY_CITY: "Enter Company City",
  ENTER_DEPARTMENT: "Enter Department",
  ENTER_INDUSTRY: "Enter Industry",
  SELECT_JOB_TITLE: "Select Job Title",
  SELECT_EDUCATION_TYPE: "Select Education Type",
  ENTER_INTITUTE_NAME: "Enter Intitute Name",
  ENTER_AREA_OF_STUDY: "Enter Area of study",
  ENTER_YEAR_OF_GRADUATION: "Enter Year of Graduation",
  EDIT_EDUCATION: "EDIT EDUCATION",
  INTERESTED: "Interested",
  SKILLS_REQUIRED: "Skills Required",
  MATCH_PERCENTAGE: "MATCH PERCENTAGE",
  VIDEO_RECORD_TIME_MSG: "Video will Be Recorded For 45 Seconds only",
  START_RECORDING: "Start Recording",
  STOP_RECORDING: "Stop Recording",
  ENTER_SUBJECT: "Enter Subject",
  ENTER_MESSAGE: "Enter Message",
  MESSAGE_SENT_SUCCESSFULLY: "Message sent successfully",
  JOB_SAVED_SUCCESSFULLY: "Job saved Successfully",
  JOB_NOT_SAVED_SUCCESSFULLY: "Job not saved Successfully",
  SELECT_AN_ITEM: "Select an item",
  ERROR_TRY_AFTER_SOMETIME: "Some error occured. Please try after sometime",
  REASON_NEEDED_PROCEED: "Reason is needed to proceed",
  S_No: "S.No",
  PERSONAL_BANKED_TO_LOGIN: "Enter your personal banked to login.",
  VALID_DIGIT_OTP: "Please enter a valid 4 digit OTP",
  GO_BACK: "Go Back",
  SETUP_CAREER_PROFILE: "Set up your career profile by adding your experience",
  ADD_EDUCATION_LEVELS: "Add Education Levels",
  SELECT_EDUCATION_TYPE: "Select Education Type",
  ENTER_EMAIL_IN_CURRECT_FORMAT: "Enter a Valid Email",
  ENTER_PASSWORD: "Enter Password",
  PASSWORDS_DOES_NOT_MATCH: "Password does not match",
  COOKIES_POLICY: "Cookies Policy",
  PERSONAL_ID: "Personal ID",
  UPLOAD_PICTURE: "Upload Picture",
  ADD_EDUCATION: "Add Education",
  CHECKOUT_MORE_PROFILES: "Checkout more profiles on Explore section",
};