import React, { useState } from "react";
import { Link } from "react-router-dom";
import RecruiterHeader from "../../../sharedComponents/RecruiterHeader";
import chevron_right_colored from "../../../assets/img/chevron_right_colored.svg";
import { postWithAuthCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import useTranslation from "../../../customHooks/translations";

function Delete_Profile() {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    reason: [],
    other_reason: "",
    errMsg: "",
  });

  function changeReason() {
    var reason_array = [];

    var checkboxes = document.getElementsByClassName("form-check-input");
    // loop over them all
    for (var i = 0; i < checkboxes.length; i++) {
      // And stick the checked ones onto an array...
      if (checkboxes[i].checked) {
        reason_array.push(checkboxes[i].value);
      }
    }

    setState({ ...state, reason: reason_array, errMsg: "" });
  }

  function otherReason(e) {
    var reason_array = [];
    reason_array.push(e.target.value);
    setState({
      ...state,
      other_reason: e.target.value,
      reason: reason_array,
      errMsg: "",
    });
  }

  function deleteAccount() {
    if (state.reason.length > 0) {
      let requestBody = JSON.stringify({
        reason: state.reason,
      });

      postWithAuthCallWithErrorResponse(
        ApiConfig.DELETE_ACCOUNT_API,
        requestBody
      )
        .then((data) => {
          setState({ ...state, isProcessing: true });
          if (data.error == true) {
            setState({ ...state, isProcessing: false });
          } else {
            window.location.href = "/login";
          }
        })
        .catch((error) => {
          setState({ ...state, isProcessing: false });
        });
    } else {
      setState({ ...state, errMsg: translation.REASON_NEEDED_PROCEED });
    }
  }
  return (
    <div>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/Setting">{translation.SETTINGS}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.DELETE_PROFILE}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-6 col-md-12 offset-lg-3">
              <div
                className="card delete_profile login settings_card"
                style={{ marginTop: "4em" }}
              >
                <div className="card-body">
                  <p className="text-muted mb-4">
                    {translation.SELECT_A_REASON_FOR_DELETING_THE_ACCOUNT}
                  </p>

                  <div className="d-flex justify-content-between">
                    <p>{translation.THIS_IS_TEMPORARY_I_WILL_BE_BACK}</p>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="This is temporary, I will be back."
                        id="flexCheckChecked"
                        onChange={changeReason}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p>{translation.I_GET_TOO_MANY_NOTIFICATIONS}</p>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="I get too many notifications."
                        id="flexCheckChecked"
                        onChange={changeReason}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p>{translation.I_SPEND_TOO_MUCH_TIME_IN_PROW}</p>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="I spend too much time in Linco."
                        id="flexCheckChecked"
                        onChange={changeReason}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p>{translation.MY_ACCOUNT_WAS_HACKED}</p>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="My account was hacked."
                        id="flexCheckChecked"
                        onChange={changeReason}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p>{translation.I_DIDNT_FIND_PROW_USEFUL}</p>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="I didn’t find Linco useful."
                        id="flexCheckChecked"
                        onChange={changeReason}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p>{translation.I_HAVE_A_PRIVATE_CONCERN}</p>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="I have a privacy concern."
                        id="flexCheckChecked"
                        onChange={changeReason}
                      />
                    </div>
                  </div>
                  <hr />

                  <div className="form_block w-100 pt-0">
                    <form className="row g-3 text-start">
                      <div className="col-md-12 regsiterrf">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.OTHER}
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ height: "69px" }}
                          value={state.other_reason}
                          onChange={(e) => otherReason(e)}
                        ></textarea>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="loginwithlinks editprofile_block">
                <div className="d-lg-flex d-md-flex justify-content-end">
                  <div className="loginwithbtn">
                    <p className="fs-22 fw-600 text-primary">
                      {translation.CONTINUE}{" "}
                      <button
                        type="button"
                        className="btn btn-outline-primary rounded-circle ms-2"
                        data-bs-toggle="modal"
                        data-bs-target="#HomeUpgrade"
                      >
                        <img src={chevron_right_colored} alt="" />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Home Upgrade Modal Start --> */}

      <div
        className="modal fade"
        id="HomeUpgrade"
        tabIndex="-1"
        aria-labelledby="HomeUpgradeLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body text-center">
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg ? state.errMsg : ""}
              </span>
              <p>{translation.DELETE_YOUR_ACCOUNT_DO_YOU_WISH_TO_CONTINUE}</p>

              <button
                type="button"
                className="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                {translation.NO}
              </button>
              {/*<Link to="/recruiter-setting"></Link>*/}
              <button
                type="button"
                className="btn btn-primary"
                onClick={deleteAccount}
              >
                {translation.YES}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Home Upgrade Modal End --> */}
    </div>
  );
}

export default Delete_Profile;
