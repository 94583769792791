import { createContext, useEffect, useState } from "react";
import ApiConfig from "../api/ApiConfig";
import { getWithAuthCallWithErrorResponse } from "../api/ApiServices";

export const NotificationContext = createContext();

export default function NOtificationContextProvider({ children }) {
    const [notificationList, setNotificationList] = useState([]);
    useEffect(() => {
        getWithAuthCallWithErrorResponse(ApiConfig.NOTIFICATION_LIST)
            .then((data) => {
                if (!data.error)
                    setNotificationList(data.json)
            })
            .catch((error) => {
            });
    }, [])
    return (
        <NotificationContext.Provider value={{ notificationList, setNotificationList }}>
            {children}
        </NotificationContext.Provider>
    );
}
