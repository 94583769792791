import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../../sharedComponents/Header";
import {
  postWithAuthCallWithErrorResponse,
  getWithAuthCallWithErrorResponse,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTranslation from "../../../customHooks/translations";
toast.configure();

function Setting_BlockUser() {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    company_name: "",
    item: [],
    errMsg: {},
  });

  const selectStyles = {
    control: (provided) => ({ ...provided, backgroundColor: 'transparent', borderRadius: "8px", border: "1px solid #b3c2c9", fontSize: "13px" }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)", backgroundColor: 'transparent' }),
  };
  const notify = () => {
    // Calling toast method by passing string
    toast.success(translation.COMPANY_BLOCKED_SUCESSFULLY, { autoClose: 1000 });
  };
  const unblockNotify = () => {
    toast.success(translation.COMPANY_UNBLOCK_SUCESSFULLY, { autoClose: 1000 });
  };

  const blockCompany = async (e) => {
    const { name } = e.target;

    if (name) {

      state.company_name = name;
    }

    if (state.company_name == "" || state.company_name == null) {
      setState({
        ...state,
        errMsg: { company_name: translation.PLEASE_ENTER_COMPANY_NAME },
      });
      return;
    } else {
      let requestBody = JSON.stringify({
        company_name: state.company_name,
      });
      postWithAuthCallWithErrorResponse(
        ApiConfig.BLOCK_COMPANY_API,
        requestBody
      )
        .then((data) => {
          setState({ ...state, isProcessing: true });
          if (data.error == true) {
            setState({ ...state, isProcessing: false });
          } else {
            if (data.json.message === 'Company unblocked successfully') {
              GetBlockedCompany();
              unblockNotify()
            }
            else {
              GetBlockedCompany();
              notify()
            }

          }
        })
        .catch((error) => {
          setState({ ...state, isProcessing: false });
        });
    }
  };

  const GetBlockedCompany = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.BLOCK_COMPANY_API)
      .then((data) => {
        if (!data.error) {
          let item = data.json;
          setState({
            ...state,
            item: item,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };
  useEffect(() => {
    GetBlockedCompany();
  }, []);

  return (
    <div>
      <Header />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item">
                    <Link to="/Setting">{translation.SETTINGS}</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {translation.BLOCKING}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" class="settings">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-12 offset-lg-3">
              <div class="text-center mt-5">
                <p>{translation.BLOCK_COMPANY_LABEL_MSG}</p>
                <form action="#">
                  <div class="settings_userblock input-group">
                    <input
                      type="text"
                      class="form-control"
                      value={state.company_name}
                      required
                      onChange={(e) => {
                        setState({ ...state, company_name: e.target.value });
                      }}
                      placeholder={translation.TYPE_NAME_HERE}
                      aria-label="Type name here"
                      aria-describedby="button-addon2"
                    />

                    <button
                      class="btn btn-primary"
                      type="button"
                      onClick={blockCompany}
                      id="button-addon2"
                    >
                      {translation.BLOCK}
                    </button>
                  </div>
                </form>
                <span className="fs-20 text-center" style={{ color: "red" }}>
                  {state.errMsg.company_name ? state.errMsg.company_name : ""}
                </span>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="blocked_list mt-5">
                <h2 class="fs-16">{translation.BLOCKED_COMPANY_LIST}</h2>

                <table
                  class="table table-borderless"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 0.5em",
                  }}
                >
                  <thead>
                    <tr>
                      <th scope="col">{translation.COMPANY_NAME}</th>
                      <th scope="col" class="text-end">
                        {translation.ACTION}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.item.map((index) => (
                      <tr>
                        <td>{index.company}</td>
                        <td class="text-end">
                          <button
                            class="btn text-primary"
                            id="button-addon2"
                            type="button"
                            name={index.company}
                            onClick={(e) => {
                              blockCompany(e);

                            }}
                          >
                            {translation.UNBLOCK}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Setting_BlockUser;
