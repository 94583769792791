import React, { useState } from "react";
import mail_filled from "../../assets/img/mail_filled.svg";
import logo_color from "../../assets/img/logo_color.png";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Select from "react-select";
import Header from "../../sharedComponents/Header";
import useTranslation from "../../customHooks/translations";
import { toast } from "react-toastify";

function Contact_Us() {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    subject: "",
    message: "",
    errMsg: {},
    successMsg: "",
    selected_Subject: {},
  });

  const [subjectsState, setsubjectsState] = useState([
    { label: "Regarding Account", value: "Regarding Account" },
    { label: "Regarding Jobs", value: "Regarding Jobs" },
  ]);

  const selectStyles = {
    control: provided => ({ ...provided, backgroundColor: 'transparent', borderRadius: "8px", border: "1px solid #b3c2c9", fontSize: "13px" }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)', backgroundColor: 'transparent' }),
  };

  function onSubjectChange(value) {
    setState({ ...state, selected_Subject: value, subject: value.label });
  }

  function sendMessage() {
    if (
      state.subject == "" ||
      state.subject == null ||
      state.message == "" ||
      state.message == null
    ) {
      if (state.subject == "" || state.subject == null) {
        setState({ ...state, errMsg: { subject: translation.ENTER_SUBJECT } });
      } else if (state.message == "" || state.message == null) {
        setState({ ...state, errMsg: { message: translation.ENTER_MESSAGE } });
      }
    } else {
      let requestBody = JSON.stringify({
        subject: state.subject,
        message: state.message,
      });

      postWithAuthCallWithErrorResponse(ApiConfig.CONTACT_US_API, requestBody)
        .then((data) => {
          setState({ ...state, isProcessing: true });
          if (data.error == true) {
            setState({ ...state, isProcessing: false });
          } else {
            setState({ ...state, successMsg: translation.MESSAGE_SENT_SUCCESSFULLY });
            toast.success(translation.MESSAGE_SENT_SUCCESSFULLY, { autoClose: 1000 })
            setTimeout(function () {
              window.location.href = "/Seeker_Profile";
            }, 1000);
          }
        })
        .catch((error) => {
          setState({ ...state, isProcessing: false });
        });
    }
  }
  return (
    <div>
      <Header />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item active" aria-current="page">
                    {translation.CONTACT_US}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div
                className="card contact_us login"
                style={{ marginTop: "1em" }}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="contact_lf text-center">
                        <img src={logo_color} alt="" />
                        {/* <p className="py-3 fs-14">
                          Lorem ipsum dolor sit amet, consetetur sadipscing
                          elitr, sed diam nonumy eirmod tempor invidunt ut
                          labore et dolore magna aliquyam.
                        </p> */}
                        <a
                          href="mailto:workr@gmail.com"
                          className="fs-14"
                          style={{ color: "#152C37" }}
                        >
                          <img src={mail_filled} className="me-1" alt="" />
                          prow@gmail.com
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form_block w-100 pt-0">
                        <form className="row g-3 text-start">
                          <h2 className="fs-20">
                            {translation.GET_IN_TOUCH_WITH_US}
                          </h2>
                          <span
                            className="fs-14"
                            style={{ color: "#13bd13", textAlign: "center" }}
                          >
                            {state.successMsg}
                          </span>
                          {/*<div className="col-md-12 regsiterrf">
                            <label className="form-label fs-14">Email</label>
                            <input type="email" className="form-control" />
                          </div>*/}
                          <div className="col-md-12 regsiterrf">
                            <label className="form-label fs-14">
                              {translation.SUBJECT}
                            </label>
                            {/*<input type="text" className="form-control" value={state.subject}
                              onChange={(e) => {
                                setState({ ...state, subject: e.target.value });
                              }} />*/}
                            <Select
                              isClearable={false}
                              onChange={onSubjectChange}
                              options={subjectsState}
                              placeholder={translation.SEARCH}
                              styles={selectStyles}
                              //tabSelectsValue={false}
                              value={state.selected_Subject}
                            />
                            <span className="fs-14" style={{ color: "red" }}>
                              {state.errMsg.subject ? state.errMsg.subject : ""}
                            </span>
                          </div>
                          <div className="col-md-12 regsiterrf">
                            <label className="form-label fs-14">
                              {translation.MESSAGE}
                            </label>
                            <textarea
                              className="form-control"
                              id="exampleFormControlTextarea1"
                              rows="5"
                              style={{ height: "69px" }}
                              value={state.message}
                              onChange={(e) => {
                                setState({ ...state, message: e.target.value });
                              }}
                            ></textarea>
                            <span className="fs-14" style={{ color: "red" }}>
                              {state.errMsg.message ? state.errMsg.message : ""}
                            </span>
                          </div>
                        </form>
                        <div className="col-md-12 mt-4">
                          {/* <Link to="/Recuiter_Home"></Link> */}
                          <button
                            className="btn gradient_btn rounded-pill w-50"
                            onClick={sendMessage}
                          >
                            {translation.SEND}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact_Us;
