import React from 'react';
import { Link } from 'react-router-dom';
import useTranslation from '../../../customHooks/translations';
import Header from '../../../sharedComponents/Header';

function Subscription_Premium() {
  const translation = useTranslation();
  return (
   <div>
       <Header/>
       <div class="breadcrumbs_block">
        <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                      <li class="breadcrumb-item"><Link to="/Setting">{translation.ACCOUNT}</Link></li>
                      <li class="breadcrumb-item active" aria-current="page">{translation.SUBSCRIPTION_PLANS}</li>
                    </ol>
                </nav>
              </div>
            </div>
          </div>
    </div>

    <section id="settings" class="settings">
      <div class="container">
          <div class="row position-relative">
              <div class="col-lg-12 col-md-12">
                <div class="row pricing_block subscribe_price_block">
                  <div class="col-lg-3">
                      <div class="card pricing_lf settings_card mt-5 ">
                          <div class="card-body text-center">
                            <div class="plan_title">
                              <h4 class="fs-18 m-0 fw-600">{translation.OUR_PLANS}</h4>
                            </div>
                            <hr />
                            <div class="plan_feature mt-4">
                              <p class="m-0 fw-600">{translation.MAXIMUM_JOBS_TO_APPLY}</p>
                            </div>
                            <div class="plan_feature">
                              <p class="m-0 fw-600">{translation.NO_OF_JOB_SAVES}</p>
                            </div>
                            <div class="plan_feature">
                              <p class="m-0 fw-600">{translation.VALIDITY}</p>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-9">
                      <div class="card pricing_header mt-5">
                        <div class="card-body text-center">
                          <h4 style={{color:"white"}} class="light">{translation.WANT_TO_TRY_OUR_PLATFORM}</h4>
                          <p class="text-white m-0">{translation.CLICK_HERE_FOR_3_DAYS_FREE_TRIAL}</p>
                        </div>
                      </div>

                      <div class="subscribe_price pricing_plans d-lg-flex">
                        
                            <div class="card settings_card ">
                                <div class="card-body text-center">
                                  <div class="plan_title">
                                    <h4 class="fs-16 m-0 fw-600">Single Job Posting</h4>
                                  </div>
                                  <div class="plan_feature">
                                    <p class="m-0 fw-600">2000 SEK</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">1</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">30</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">365</p>
                                  </div>
                                  <div class="light_feature text-center">
                                      <button class="btn btn-outline-primary">{translation.UPGRADE}</button>
                                  </div>
                                </div>
                            </div>

                            <div class="card settings_card ">
                                <div class="card-body text-center">
                                  <div class="popular_plan">
                                    <p class="m-0">POPULAR</p>
                                  </div>
                                  <div class="plan_title">
                                    <h4 class="fs-16 m-0 fw-600">3 Pack Job Posting</h4>
                                  </div>
                                  <div class="plan_feature">
                                    <p class="m-0 fw-600">5000 SEK</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">3</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">60</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">365</p>
                                  </div>
                                  <div class="light_feature text-center">
                                      <button class="btn btn-outline-primary">{translation.UPGRADE}</button>
                                  </div>
                                </div>
                            </div>

                            <div class="card settings_card">
                                <div class="card-body text-center">
                                  <div class="plan_title">
                                    <h4 class="fs-16 m-0 fw-600">5 Pack Job Posting</h4>
                                  </div>
                                  <div class="plan_feature">
                                    <p class="m-0 fw-600">10,000 SEK</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">3</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">60</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">365</p>
                                  </div>
                                  <div class="light_feature text-center">
                                      <button class="btn btn-outline-primary">{translation.UPGRADE}</button>
                                  </div>
                                </div>
                            </div>

                            <div class="card settings_card ">
                                <div class="card-body text-center">
                                  <div class="plan_title">
                                    <h4 class="fs-16 m-0 fw-600">10 Pack Job Posting</h4>
                                  </div>
                                  <div class="plan_feature">
                                    <p class="m-0 fw-600">19,000 SEK</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">3</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">60</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">365</p>
                                  </div>
                                  <div class="light_feature text-center">
                                      <button class="btn btn-outline-primary">{translation.UPGRADE}</button>
                                  </div>
                                </div>
                            </div>

                            <div class="card settings_card me-0">
                                <div class="card-body text-center">
                                  <div class="plan_title">
                                    <h4 class="fs-16 m-0 fw-600">15 Pack Job Posting</h4>
                                  </div>
                                  <div class="plan_feature">
                                    <p class="m-0 fw-600">27,000 SEK</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">3</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">60</p>
                                  </div>
                                  <div class="light_feature">
                                    <p class="m-0 fw-600">365</p>
                                  </div>
                                  <div class="light_feature text-center">
                                      <button class="btn btn-outline-primary">{translation.UPGRADE}</button>
                                  </div>
                                </div>
                            </div>
                      
                      </div>
                  </div>
                </div>
                </div>
                
          </div>
      </div>
    </section>

   </div>
   )
}

export default Subscription_Premium;
