import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/img/logo.png";
import chevron_right_colored from "../assets/img/chevron_right_colored.svg";
import { simplePostCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import useTranslation from '../customHooks/translations';

const ForgotPasswordOTP = (props) => {
    const translation = useTranslation();
    const [state, setState] = useState({
        isProcessing: false,
        newOTP: localStorage.getItem("forgetPword_OTP"),
        otpDigit1: "",
        otpDigit2: "",
        otpDigit3: "",
        otpDigit4: "",
        errorMsg: "",
        OTPerrorMSG: ""
    });

    const resendOTP = async () => {
        let requestBody = JSON.stringify({
            user_id: localStorage.getItem("forgetPword_UserID"),
        });

        simplePostCallWithErrorResponse(ApiConfig.FORGOT_PASSWORD_API, requestBody)
            .then((data) => {
                setState({ ...state, isProcessing: true });
                if (data.error == true) {
                    setState({ ...state, isProcessing: false, errorMsg: data.json.message });
                } else {
                    setState({ ...state, isProcessing: false, newOTP: data.json.otp });
                }
            })
            .catch((error) => {
                setState({ ...state, isProcessing: false });
            });
    }

    const continueResetPword = async () => {

        if (state.otpDigit1 == "" || state.otpDigit2 == "" || state.otpDigit3 == "" || state.otpDigit4 == "") {
            setState({ ...state, OTPerrorMSG: translation.VALID_DIGIT_OTP });
        } else {
            var otp = state.otpDigit1 + state.otpDigit2 + state.otpDigit3 + state.otpDigit4;
            localStorage.setItem("forgotPwordOTP", otp);

            window.location.href = "/forgot-password-new";
        }
    }
    const handleEnter = (event) => {
        var validkeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
        if (validkeys.includes(event.key)) {
            event.target.value = event.key;
            const form = event.target.form;
            const index = [...form].indexOf(event.target);
            form.elements[index + 1] && form.elements[index + 1].focus();
            event.preventDefault();
        }
    };
    return (
        <>
            <section id="login" className="login">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 g-0 p-0">
                            {/* <div className="login_ltblock text-center">
                                <img src="img/logo.png" alt="">
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p>
                            </div> */}


                            <div className="doctor_leftimg">
                                <div className="overlay_content text-center">
                                    <img src={logo} className="mb-3" />
                                    <p className="m-t-25 text-white">{translation.PRODUCT_INTRO}</p>
                                </div>
                                {/* <div className="overlay_logo">
                                    <img src="img/logo.png">
                                </div> */}
                            </div>

                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="login_rtblock position-relative pt-4">
                                <div className="d-lg-flex d-md-flex justify-content-end">
                                    <p>{translation.DONT_HAVE_AN_ACCOUNT} &nbsp; <Link to="/register" className="fw-600">{translation.REGISTER}</Link></p>
                                </div>

                                <div className="form_block">
                                    <div className="text-center">
                                        <h2 className="fs-24 fw-600">{translation.FORGOT_PASSWORD}</h2>
                                        <p className="fs-14 mt-3 mb-4">{translation.ENTER_THE_CODE_SENT_YOUR_REGISTERED_MOBILE_NUMBER_EMAIL_ID_TO_CONTINUE} - {state.newOTP}</p>
                                        <img src="img/otp_verification_image.svg" alt="" />
                                        <div style={{ width: "100%", textAlign: "center" }}>
                                            <span className="fs-14" style={{ float: "left", color: "red", width: "100%" }}>{localStorage.getItem("resetPwordErrMsg")}</span>
                                        </div>
                                        <form action="" className="otp_box my-5">
                                            <input className="otp" type="text" value={state.otpDigit1} maxLength='1' required=""

                                                onKeyDown={(e) => {
                                                    if (String(e.key).match("^[0-9]+$")) {
                                                        setState({ ...state, otpDigit1: e.key });
                                                        handleEnter(e)
                                                    }
                                                }} />
                                            <input className="otp" type="text" value={state.otpDigit2} maxLength='1' required=""
                                                onKeyDown={(e) => {
                                                    if (String(e.key).match("^[0-9]+$")) {
                                                        setState({ ...state, otpDigit2: e.key }); handleEnter(e)
                                                    }
                                                }} />
                                            <input className="otp" type="text" value={state.otpDigit3} maxLength='1' required=""
                                                onKeyDown={(e) => {
                                                    if (String(e.key).match("^[0-9]+$")) {
                                                        setState({ ...state, otpDigit3: e.key }); handleEnter(e)
                                                    }
                                                }} />
                                            <input className="otp" type="text" value={state.otpDigit4} maxLength='1' required=""
                                                onKeyDown={(e) => {
                                                    if (String(e.key).match("^[0-9]+$")) {
                                                        setState({ ...state, otpDigit4: e.key }); handleEnter(e)
                                                    }
                                                }} />
                                        </form>
                                        <div style={{ width: "100%", textAlign: "center" }}>
                                            <span className="fs-14" style={{ float: "left", color: "red", width: "100%" }} id="error_msg">{state.OTPerrorMSG}</span>
                                        </div>
                                        <p>{translation.DIDNT_RECEIVE_CODE} &nbsp; <a onClick={resendOTP} className="fw-600" style={{ cursor: "pointer" }}>{translation.RESEND}</a></p>
                                        <div style={{ width: "100%", textAlign: "center" }}>
                                            <span className="fs-14" style={{ float: "left", color: "red", width: "100%" }} id="error_msg">{state.errorMsg}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="loginwithlinks">
                                <div className="d-lg-flex d-md-flex justify-content-end">
                                    <div className="loginwithbtn">
                                        <p className="fs-22 fw-600 text-primary">
                                            {translation.CONTINUE}
                                            {/* to="/forgot-password-new" */}
                                            <a onClick={continueResetPword}>
                                                <button type="button" className="btn btn-outline-primary rounded-circle ms-2">
                                                    <img src={chevron_right_colored} alt="" />
                                                </button>
                                            </a>
                                        </p>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </section>

        </>
    );
}
export default ForgotPasswordOTP
