import React, { Component, useContext, useEffect, useState } from "react";
import Bookmark from "../../assets/img/bookmark.svg";
import close_job from "../../assets/img/close_job.svg";
import check_job from "../../assets/img/check_job.svg";
import profile_image from "../../assets/img/profile_image.png";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import TinderCard from "react-tinder-card";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import useTranslation from "../../customHooks/translations";
import ReactTooltip from "react-tooltip";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";
import { UserContext } from "../../contexts/userContext";
const blurImgStyle = {
  filter: "blur(4px)",
  "-webkit-filter": "blur(4px)",
};

const JobCard = (props) => {
  const translation = useTranslation();
  const [profileData, setProfileData] = useState(props.profileData);
  const { onSwipe } = props;

  return (
    <TinderCard
      className="card-body text-center swipe"

      onSwipe={onSwipe}
    >
      <ReactTooltip />
      <div className="jobmatch_percent">
        <p className="m-0">
          {profileData.percent} {translation.MATCH}
        </p>
      </div>

      <div
        className="job_bookmark"
        style={{ cursor: "pointer" }}
        onClick={() => {
          props.saveJob(profileData.user.id);
        }}
      >
        <img src={Bookmark} alt="" />
      </div>

      <img
        style={{ height: 100, width: 100, borderRadius: "50%" }}
        src={
          profileData.user.user.profile_pic !== null
            ? profileData.user.user.profile_pic
            : profile_image
        }
        alt=""
      />

      <h3 className="fs-16 fw-600 mt-4">{profileData.user.user.designation}</h3>
      <p className="m-0 fs-14">{profileData.user.user.full_name}</p>
      <p className="m-0 fs-14 pb-4">
        {profileData.user.user.user_experience_level}.{" "}
        {profileData.user.user.city}. {profileData.user.user.country}
      </p>

      <p className="fs-14">{profileData.user.user.about}</p>

      <hr />

      <div className="row">
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.SKILLS}</h4>
          <p className="fs-16 ls-4 m-0">
            {profileData.user.skill.map((item, index) => {
              return <> {item.skill},</>;
            })}
          </p>
        </div>
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.TOOLS}</h4>
          <p className="fs-16 ls-4 m-0">
            {profileData.user.tools.map((item, index) => {
              return <> {item.tool},</>;
            })}
          </p>
        </div>
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.CERTIFICATES}</h4>
          <p className="fs-16 ls-4 m-0 text-truncate">
            {profileData.user.certificate.map((item, index) => {
              return <> {item.user_certificates},</>;
            })}
          </p>
        </div>
        {/* <div className="links job_actions text-center">
            <a href=""
              type="button"
              // disabled={
                // count < profileList.length - 1 ? false : true
              // }
              className="btn "
              data-tip="Reject"

              onClick={() => {
                onSwipe("left");
              }}
              style={{marginRight:"5rem"}}
            >
              <img src={close_job} alt="" />
            </a>

            <a href=""
              type="button"
              className="btn "
              data-tip="Accept"

              // data-bs-toggle="modal"
              // data-bs-target="#HomeUpgrade"
              onClick={()=>onSwipe("right")}
            >
              <img src={check_job} alt="" />
            </a>
        </div> */}
      </div>
    </TinderCard>
  );
};

function Recuiter_Home() {
  const translation = useTranslation();
  const [profileList, setProfileList] = useState([]);
  const [count, setCount] = useState(0);
  const filterData = useLocation().state;
  const [state, setState] = useState({
    isProcessing: false,
  });
  const { UserData, getUserData } = useContext(UserContext)
  useEffect(() => {
    if (!filterData) {
      setState({ ...state, isProcessing: true });
      getWithAuthCallWithErrorResponse(ApiConfig.PROFILE_LISTING).then(
        (data) => {
          setState({ ...state, isProcessing: false });
          if (data.error !== true) {
            setProfileList(data.json.list);
          } else {
            alert(data.json.detail);
          }
        }
      );
    }
    getUserData();
  }, []);
  useEffect(() => {
    if (filterData) {
      filterData.competency = "";
      let requestBody = JSON.stringify(filterData);
      let uri;

      if (filterData.isFrom === "recruiter-explore-jobs") {
        uri = ApiConfig.FILTER_JOB_SEEKER;
      }
      else if (filterData.isFrom === "search") {
        uri = ApiConfig.PROFILE_SEARCH_API;
      }
      else {
        uri = ApiConfig.PROFILE_FILTER;
      }
      postWithAuthCallWithErrorResponse(uri, requestBody).then((data) => {
        if (data.error !== true) {
          setProfileList(data.json.list);
        } else {
          alert(data.json.message);
        }
      });
    }
  }, [filterData]);

  const onSwipe = (direction, profileData) => {
    console.log('====================================');
    console.log(direction, profileData);
    console.log('====================================');
    let profileListFiltered = profileList.filter(
      (profile) => profile.user.id !== profileData.user.id
    );
    setProfileList(profileListFiltered);
    if (direction == "right") {
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_RECRUITER_TINDERL,
        JSON.stringify({
          action: "accept",
          job_id: profileData.job_id,
          user_id: profileData.user.id,
        })
      )
        .then((res) => {
          let profileListFiltered = profileList.filter(
            (profile) => profile.job_id !== profileData.job_id
          );
          setProfileList(profileListFiltered);
          toast.success(res.json.message, { autoClose: 1000 });
        })
        .catch((err) => console.log(err));
    } else if (direction == "left")
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_RECRUITER_TINDERL,
        JSON.stringify({
          action: "reject",
          job_id: profileData.job_id,
          user_id: profileData.user.id,
        })
      )
        .then((res) => {
          let profileListFiltered = profileList.filter(
            (profile) => profile.job_id !== profileData.job_id
          );
          toast.success(res.json.message, { autoClose: 1000 });
          setProfileList(profileListFiltered);
        })
        .catch((err) => console.log(err));
  };
  const saveJob = (user_id) => {
    let requestBody = JSON.stringify({
      user_id: user_id,
    });
    postWithAuthCallWithErrorResponse(
      ApiConfig.FAVOURITE_JOB_API_RECRUITER,
      requestBody
    ).then((data) => {
      if (data.error !== true) {
        toast.success("User saved Successfully", { autoClose: 1000 });
        if (count < profileList.length - 1) {
          setCount(count + 1);
        }
      } else {
        alert(data.json.message);
      }
    });
  };

  return (
    <div>
      <RecruiterHeader />
      <section id="home" className="home">
        <div className="container-fluid">
          <div>
            {UserData.actively_hiring && profileList && profileList.length > 0 ? (
              profileList.map((item, index) => {
                return (
                  <div className="row" key={index}>
                    <div className="col-lg-8 col-md-12 offset-lg-2">
                      <div className={"job_card overlap_card card"}>
                        <JobCard
                          profileList={profileList}
                          onSwipe={onSwipe}
                          setProfileList={setProfileList}
                          profileData={item}
                          index={index}
                          saveJob={saveJob}
                        />
                      </div>
                      {/* <!-- Job Card Two --> */}
                      {/* <div className="job_card overlap_card card">
                          <JobCard profileData={item} saveJob={saveJob} />
                        </div> */}
                      {/* <!-- Job Card Three --> */}
                      {/* <div className="job_card overlap_card third_card card">
                          <JobCard profileData={item} saveJob={saveJob} />
                        </div> */}
                    </div>
                  </div>
                );
              })
            ) : (
              state.isProcessing ? <SpinnerCmp loading={state.isProcessing} /> :
                <div className="row">
                  <div className="col-lg-8 col-md-12 offset-lg-2">
                    <div className="card-body text-center no-record">
                      <p className="m-0 fs-50 no-record-style">
                        {" "}
                        {translation.NO_RECORDS_FOUND}
                      </p>
                    </div>
                  </div>
                </div>
            )}
          </div>
          {/* <div className="Card-button "> */}
          {UserData.actively_hiring && profileList && profileList.length > 0 && (
            <div className="job_actions Card-button" style={{ zIndex: "1000" }}>
              <button
                href=""
                type="button"
                // disabled={
                // count < profileList.length - 1 ? false : true
                // }
                className="btn "
                data-tip={translation.REJECT}
                onClick={() => {
                  onSwipe("left", profileList[profileList.length - 1]);
                }}
                style={{ marginRight: "5rem" }}
              >
                <img src={close_job} alt="" />
              </button>

              <button
                href=""
                type="button"
                className="btn "
                data-tip={translation.ACCEPT}
                // data-bs-toggle="modal"
                // data-bs-target="#HomeUpgrade"
                onClick={() =>
                  onSwipe("right", profileList[profileList.length - 1])
                }
              >
                <img src={check_job} alt="" />
              </button>
            </div>
          )}
        </div>

        {/* </div> */}
      </section>
      {/* <!-- Home Upgrade Modal Start --> */}

      <div
        class="modal fade"
        id="HomeUpgrade"
        tabIndex="-1"
        aria-labelledby="HomeUpgradeLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body text-center">
              <p>{translation.UPGRADE_TO_FULL_ACCESS_MSG}</p>

              <button
                type="button"
                class="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                {translation.CANCEL}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                {translation.UPGRADE}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Home Upgrade Modal End --> */}
    </div>
  );
}

export default Recuiter_Home;
