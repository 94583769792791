import React, { useState, useContext } from "react";
import menu from "../../assets/img/menu.svg";
import Header from "../../sharedComponents/Header";
import { Link } from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import { NotificationContext } from "../../contexts/NotificationContext";
import NotificationComponent from "./NotificationComponent";
function Notification() {
  const translation = useTranslation();


  return (
    <>
      <Header />
      <div>
        <section class="breadcrumbs_block">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item">
                      <Link to="/Seeker_Profile">{translation.PROFILE}</Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {translation.NOTIFICATIONS}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section id="settings" class="settings">
          <div class="container">
            <div class="row">
              <NotificationComponent />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Notification;
