import React, { useState, useEffect } from "react";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import { Link, useLocation } from "react-router-dom";
import job_default_icon from "../../assets/img/job_default_icon.svg";
import menu_options from "../../assets/img/menu_options.svg";
import ApiConfig from "../../api/ApiConfig";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";
import { toast } from "react-toastify";
import useTranslation from "../../customHooks/translations";

function RecruiterMatchingProfilesList() {
  const location = useLocation();
  let isFrom = "";
  if (location.state) isFrom = location.state.isFrom;
  const translation = useTranslation();
  const [jobList, setJobList] = useState([])
  const [profileList, setProfilelist] = useState([])
  const [state, setState] = useState({
    isProcessing: false,
    jobList: [],
    status: "open",
    selectedJobID: "",
    profileListsorginal: [],
    profileList: [],
    errMsg: translation.NO_RECORDS_FOUND,
    isCallFrom: isFrom ? isFrom : "",
  });

  const getjobs = async () => {
    setState({ ...state, isProcessing: true, jobList: [] });
    getWithAuthCallWithErrorResponse(
      ApiConfig.RECRUITER_EXPLORE_PAGE_API
    )
      .then((data) => {
        if (!data.error) {
          // var jobList = data.json;
          setJobList(data.json);
          // setState({ ...state, isProcessing: false, jobList: jobList });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };
  useEffect(() => {
    getjobs();
  }, [state.status]);
  useEffect(() => {
    getAllMatchingProfiles();
  }, []);
  function getMatchingProfiles(selectedJobID = "") {
    setState({ ...state, selectedJobID: selectedJobID });
    if (selectedJobID.length) {
      let profileListFiltered = state.profileListsorginal.length && state.profileList.filter(profile => {
        return profile.user.user.designation == selectedJobID
      })
      setProfilelist(profileListFiltered)
    } else {
      setProfilelist(state.profileListsorginal)
    }
  }

  function getAllMatchingProfiles(selectedJobID = "") {
    setState({ ...state, isProcessing: true, selectedJobID: selectedJobID });
    const url =
      state.isCallFrom === "Saved"
        ? ApiConfig.FAVOURITE_JOB_API_RECRUITER
        : ApiConfig.MATCHING_PROFILE_LISTING_API + selectedJobID
    getWithAuthCallWithErrorResponse(
      url
    )
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false, errMsg: translation.NO_RECORDS_FOUND });
        } else {
          var profile_List = data.json.list;
          setProfilelist(profile_List)
          setState({
            ...state,
            isProcessing: false,
            selectedJobID: selectedJobID,
            profileList: profile_List,
            profileListsorginal: profile_List,
            errMsg: "",
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  }

  function blockJobSeeker(user_id) {
    let requestBody = JSON.stringify({
      user_id: user_id,
    });
    postWithAuthCallWithErrorResponse(ApiConfig.BLOCK_JOB_SEEKER, requestBody)
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
          toast.error(translation.ERROR_TRY_AFTER_SOMETIME, { autoClose: 1000 });
        } else {
          setState({ ...state, isProcessing: false });
          toast.success(data.json.message);
          document.getElementById("user_div_" + user_id).style.display = "none";
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        toast.error(translation.ERROR_TRY_AFTER_SOMETIME, { autoClose: 1000 });
      });
  }
  //console.log(state.profileList);
  return (
    <>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/recruiter-profile">{translation.PROFILE}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {state.isCallFrom === "Saved" ? translation.SAVED_PROFILES : translation.MATCHED_PROFILES}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" className="matched_jobs">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 chat_message mt-5">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <select
                    className="form-select bg-transparent"
                    value={state.selectedJobID}
                    defaultValue="AllMatching"
                    onChange={(e) => getMatchingProfiles(e.target.value)}
                  >
                    <option value="">{translation.SELECT_JOB}</option>
                    {jobList.map((jobData, index) => {
                      return (
                        <option value={jobData.name} key={index}>
                          {jobData.name}
                        </option>
                      );
                    })}
                  </select>
                </li>
              </ul>
              <SpinnerCmp loading={state.isProcessing} />
              <div
                className="tab-content"
                id="pills-tabContent"
                style={{ display: state.isProcessing ? "none" : "" }}
              >
                <div
                  className="tab-pane fade show active"
                  id="pills-joblist"
                  role="tabpanel"
                  aria-labelledby="pills-joblist-tab"
                >
                  {profileList.length ? profileList.map((profile_data, index) => {
                    return (
                      <div
                        className="card settings_card mt-4"
                        style={{ display: state.errMsg == "" ? "" : "none" }}
                        key={index}
                        id={"user_div_" + profile_data.user.user.id}
                      >
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <Link to="/Match_Profile" state={profile_data} className="itemside">
                              <div className="aside">
                                <img
                                  src={
                                    profile_data.user.user.profile_pic
                                      ? profile_data.user.user.profile_pic
                                      : job_default_icon
                                  }
                                  className="img-sm"
                                  style={{ width: "55px", height: "55px" }}
                                />
                              </div>
                              <div className="info align-self-center">
                                <p className="title fs-18 mb-1">
                                  {profile_data.user.user.full_name}
                                </p>
                                <div className="chat_role">
                                  <p className="m-0 fs-14">
                                    {profile_data.user.user.designation} &#8226;
                                    {profile_data.user.user.city},{" "}
                                    {profile_data.user.user.country} &#8226;
                                    {
                                      profile_data.user.user.user_experience_level
                                    }{" "}
                                    &#8226;
                                    <span style={{ color: "green" }}>
                                      {profile_data.percent}% Match
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </Link>
                            {/* <div className="linco_dropdown btn-group dropstart">
                              <button
                                type="button"
                                className="btn dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={menu_options} alt="" />
                              </button>
                              <ul className="dropdown-menu">
                                {state.status !== "pause" &&
                                  state.status !== "close" ? (
                                  <li>
                                    <button
                                      name="pause"
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      {translation.CHAT}
                                    </button>
                                  </li>
                                ) : null}
                                {state.status !== "close" ? (
                                  <li>
                                    <button
                                      name="close"
                                      onClick={() =>
                                        blockJobSeeker(profile_data.user.id)
                                      }
                                      className="dropdown-item"
                                      type="button"
                                    >
                                      {translation.BLOCK}
                                    </button>
                                  </li>
                                ) : null}
                              </ul>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    );
                  }) :
                    <div className="card settings_card mt-4">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">{translation.NO_RECORDS_FOUND}</p>
                        </div>
                      </div>
                    </div>
                  }

                  <div
                    className="card settings_card mt-4"
                    style={{ display: state.errMsg == "" ? "none" : "" }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <p className="m-0 fs-14">{state.errMsg}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RecruiterMatchingProfilesList;
