import React, { useState } from "react";
import logoColor from "../assets/img/logo_color.png";
import notification from "../assets/img/notification.svg";
import search from "../assets/img/search.svg";
import sweden from "../assets/img/sweden.png";
import check_job from "../assets/img/check_job.svg";
import menu from "../assets/img/menu.svg";
import swedish_lang from "../assets/img/swedish_lang.png";
import english_lang from "../assets/img/english_lang.png";
import german_lang from "../assets/img/hindi.png";
import Hindi from '../assets/img/hindi.png';
import tutorial from "../assets/img/tutorial.svg";
import right_arrow from "../assets/img/right_arrow.svg";
import change_language from "../assets/img/change_language.svg";
import contact_us from "../assets/img/contact_us.svg";
import settings from "../assets/img/settings.svg";
import legal from "../assets/img/legal.svg";
import s_logout from "../assets/img/s_logout.svg";
import faq from "../assets/img/faq.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import filter from "../assets/img/filter.svg";
import thumbs_up from "../assets/img/thumbs_up.svg";
import ReactTooltip from "react-tooltip";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  postWithAuthCallWithErrorResponse,
  putWithAuthCall,
} from "../api/ApiServices.js";
import ApiConfig from "../api/ApiConfig";
import SelectLanguage from "./SelectLanguage";
import { useLanguageContext } from "../contexts/LanguageContext";
import useTranslation from "../customHooks/translations";
import NotificationComponent from "../pages/Job_Seeker/NotificationComponent";
import Filter_JobseekerHome from "./Filter_JobseekerHome";

function RecruiterHeader() {
  const navigate = useNavigate()
  const translation = useTranslation();
  const [state, setState] = useState({
    LangIcon: english_lang,
  });
  const location = useLocation();
  const [CurrentRoute, setCurrentRoute] = useState(location.pathname);
  const { languageIcon } = useLanguageContext();
  const [searchKeyword, setSearchKeyword] = useState();
  const checkLogin = async () => {
    if (localStorage.getItem("is_logged_in") == "true") {
      if (localStorage.getItem("USER_ROLE") != "Recruiter") {
        Logout();
      }
    } else {
      Logout();
    }
  };
  const Logout = async () => {
    const lang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "IN_en";
    localStorage.clear();
    localStorage.setItem("language", lang);
    navigate("/login")
    // window.location.href = "/login";
  };
  const onLangChange = (value) => {
    let selectedLangIcon = english_lang;
    switch (value) {
      case "English":
        selectedLangIcon = english_lang;
        break;
      case "Hindi":
        selectedLangIcon = Hindi;
        break;
      case "Marathi":
        selectedLangIcon = Hindi;
        break;
    }
    setState({ ...state, language: value, selectedLangIcon: selectedLangIcon });
  };

  checkLogin();

  //SEARCH PROFILE_SEARCH_API

  const SearchProfile = (e) => {

    postWithAuthCallWithErrorResponse(ApiConfig.PROFILE_SEARCH_API)
      .then((data) => {
        setState({ ...state, isProcessing: true });
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
        } else {
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };
  //END SEARCH
  return (
    <>
      <ReactTooltip />
      <ToastContainer />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            style={{ width: "5rem" }}
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
            <a className="navbar-brand" href="#">
              <img src={logoColor} alt="" />
            </a>
            <ul className="lfmenu navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link to="/Recuiter_Home" className="nav-link" href="#">
                  {translation.HOME}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/recruiter-explore" className="nav-link" href="#">
                  {translation.EXPLORE}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/recruiter-profile" className="nav-link" href="#">
                  {translation.PROFILE}
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/recruiter-matching-profiles" className="nav-link" href="#">
                  {translation.MATCHED_PROFILES}
                </Link>
              </li>
            </ul>
            <ul className="rlmenu navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item" data-tip={translation.NOTIFICATIONS}>
                <a
                  className="nav-link"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasNotification"
                  aria-controls="offcanvasNotification"
                >
                  <img src={notification} alt="" />
                </a>
              </li>
              {(CurrentRoute === "/Recuiter_Home" || CurrentRoute === "/recruiter-explore-jobs") &&
                (<>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="offcanvas"
                      href="#offcanvasSearchFilter"
                      aria-controls="offcanvasSearchFilter"
                    >
                      <img src={filter} alt="" />
                    </a>
                  </li>
                  <li className="nav-item" data-tip={translation.SEARCH}>
                    <a
                      className="nav-link search_modal"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#SearchModal"
                      data-bs-backdrop="false"
                    >
                      <img src={search} alt="" />
                    </a>
                  </li>
                </>)}
              <li class="nav-item lang_dropdown">
                <div class="btn-group">
                  <a
                    class="dropdown-toggle nav-link lang_flag"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    href="#"
                  >
                    <img
                      src={languageIcon}
                      alt=""
                      data-tip={translation.SELECT_LANGUAGE}
                    />
                  </a>
                  <SelectLanguage />
                </div>
              </li>
              <li className="nav-item" data-tip={translation.MENU}>
                <a
                  className="nav-link"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasSidebarMenu"
                  aria-controls="offcanvasSidebarMenu"
                >
                  <img src={menu} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Menu Modal */}
      {/* <!-- Mobile Menu Actions --> */}
      <ul className="rlmenu rlmenu_mobile navbar-nav ms-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <a
            className="nav-link"
            data-bs-toggle="offcanvas"
            href="#offcanvasNotification"
            aria-controls="offcanvasNotification"
          >
            <img src={notification} alt="" />
          </a>
        </li>
        {(CurrentRoute === "/Recuiter_Home" || CurrentRoute === "/recruiter-explore-jobs")
          &&
          (<><li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="offcanvas"
              href="#offcanvasSearchFilter"
              aria-controls="offcanvasSearchFilter"
            >
              <img src={filter} alt="" />
            </a>
          </li>
            <li className="nav-item">
              <a
                className="nav-link search_modal"
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#SearchModal"
                data-bs-backdrop="false"
              >
                <img src={search} alt="" />
              </a>
            </li>
          </>)
        }
        <li className="nav-item lang_dropdown">
          <div className="btn-group">
            <a
              className="dropdown-toggle nav-link lang_flag"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              href="#"
            >
              <img
                src={languageIcon}
                alt=""
                data-tip={translation.SELECT_LANGUAGE}
              />
            </a>
            <SelectLanguage />
          </div>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-bs-toggle="offcanvas"
            href="#offcanvasSidebarMenu"
            aria-controls="offcanvasSidebarMenu"
          >
            <img src={menu} alt="" />
          </a>
        </li>
      </ul>
      <div
        className="SidebarMenuCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasSidebarMenu"
        aria-labelledby="offcanvasSidebarMenuLabel"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-t  itle fs-16 fw-600"
            id="offcanvasSidebarMenuLabel"
          >
            {translation.MENU}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body recruiterHeaderSidebar">
          <div className="d-flex justify-content-between">
            <Link to="/tutorial-recruiter" className="menu_title">
              <img src={tutorial} alt="" />
              {translation.TUTORIAL}
            </Link>
            <Link to="/tutorial-recruiter">
              <img src={right_arrow} alt="" />
            </Link>
          </div>
          <hr className="my-4" />
          {/* <div className="d-flex justify-content-between">
            <Link to="/recruiter-subscription" className="menu_title">
              <img src={manage_subscription} alt="" />
              {translation.SUBSCRIPTION_PLANS}
            </Link>
            <Link to="/recruiter-subscription">
              <img src={right_arrow} alt="" />
            </Link>
          </div>
          <hr className="my-4" /> */}
          {/* <div className="d-flex justify-content-between">
            <a href="#" className="menu_title">
              <img src={change_language} alt="" />
              Change Language
            </a>
            <a href="#">
              <img src={right_arrow} alt="" />
            </a>
          </div>
          <hr className="my-4" /> */}
          {/* <div className="d-flex justify-content-between">
            <Link to="/recruiter-faq" className="menu_title">
              <img src={faq} alt="" />
              {translation.FAQ}
            </Link>
            <a href="#">
              <img src={right_arrow} alt="" />
            </a>
          </div>

          <hr className="my-4" /> */}
          {/* <div className="d-flex justify-content-between">
            <Link to="/Recruiter_Feedback" className="menu_title">
              <img src={thumbs_up} alt="" />
              {translation.FEEDBACK_SUGGESTION}
            </Link>
            <Link to="/Recruiter_Feedback">
              <img src={right_arrow} alt="" />
            </Link>
          </div>
          <hr className="my-4" /> */}
          <div className="d-flex justify-content-between">
            <Link to="/recruiter-contact-us" className="menu_title">
              <img src={contact_us} alt="" />
              {translation.CONTACT_US}
            </Link>
            <Link to="/recruiter-contact-us">
              <img src={right_arrow} alt="" />
            </Link>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-between">
            <Link to="/recruiter-setting" className="menu_title">
              <img src={settings} alt="" />
              {translation.SETTINGS}
            </Link>
            <Link to="/recruiter-setting">
              <img src={right_arrow} alt="" />
            </Link>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-between">
            <Link to="/recruiter-legal" className="menu_title">
              <img src={legal} alt="" />
              {translation.LEGAL}
            </Link>
            <Link to="/recruiter-legal">
              <img src={right_arrow} alt="" />
            </Link>
          </div>

          <hr className="my-4" />
          <div className="d-flex justify-content-between">
            <a href="" onClick={Logout} className="menu_title">
              <img src={s_logout} alt="" />
              {translation.LOGOUT}
            </a>
            <a href="" onClick={Logout}>
              <img src={right_arrow} alt="" />
            </a>
          </div>
        </div>
      </div>
      {/* Menu modal */}

      {/* <!-- Search Modal Start --> */}
      <div
        className="modal fade"
        id="SearchModal"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <Link
                    to={
                      window.location.pathname === "/Recuiter_Home"
                        ? "/Recuiter_Home"
                        : window.location.pathname === "/recruiter-explore-jobs"
                          ? "/recruiter-explore-jobs"
                          : "#"
                    }
                    state={{
                      keyword: searchKeyword,
                      industry: "",
                      isFrom: "search",
                    }}
                  >
                    <img src={search} alt="" />
                  </Link>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder={translation.SEARCH_HERE}
                  //onChange={SearchProfile}
                  value={searchKeyword}
                  onChange={(e) => {
                    setSearchKeyword(e.target.value);
                  }}
                />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>{" "}
                <span className="divide_line"></span>
                {/* <a className="btn" data-bs-toggle="offcanvas" href="#offcanvasSearchFilter" aria-controls="offcanvasSearchFilter"><img src={filter} alt="" /></a> */}
              </div>
              <p className="text-center fs-14 m-0">{translation.NO_RECENT_SEARCHES}</p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Search Modal End --> */}
      {/* <!-- Notifications Offcanvas Start --> */}
      <div
        className="NotificationCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasNotification"
        aria-labelledby="offcanvasNotificationLabel"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title fs-16 fw-600"
            id="offcanvasNotificationLabel"
          >
            {translation.NOTIFICATIONS}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="d-flex justify-content-end p-2">
            <Link to="/recruiter-notification">{translation.SEE_ALL}</Link>
          </div>
          <NotificationComponent />
        </div>
      </div>
      {/* <!-- Notifications Offcanvas End --> */}
      {/* <!-- Search Filter Offcanvas Start --> */}
      <Filter_JobseekerHome />
      {/* <!-- Search Filter Offcanvas End --> */}
    </>
  );
}

export default RecruiterHeader;
