import React from "react";
import Header from "./Header";
import TutorialImg from "../assets/img/Tutorial01.jpg";

// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function Tutorial() {
  return (
    <div>
      <Header />

      <section class="tutorial_otrWrapper">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
              >
                <SwiperSlide>
                  <img src={TutorialImg} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={TutorialImg} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={TutorialImg} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src={TutorialImg} alt="" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Tutorial;
