import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import chevron_right from "../../assets/img/chevron_right_colored.svg";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import Select from "react-select";
import Country from "../../assets/CountrtiesData/Country";
import listContryCity from "../../assets/CountrtiesData/CoutrycityList";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";
import useTranslation from "../../customHooks/translations";

function EditCompanyInfo() {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    errMsg: {},
    industry: "",
    department: "",
    city: "",
    country: "",
    company_name: "",
    company_size: "",
    company_reg_no: "",
    company_linkedin: "",
    role: "",
    selected_Country: {},
    selected_City: {},
    selected_CompanySize: {},
  });

  const [countriesState, setCountriesState] = useState();
  const [citiesState, setCitiesState] = useState();
  const [industryState, setIndustryState] = useState();
  const [companySizeState, setcompanySizeState] = useState([
    { label: "1-50", value: "1-50" },
    { label: "51-100", value: "51-100" },
    { label: "101-500", value: "101-500" },
    { label: "501-1000", value: "501-1000" },
    { label: "1001-5000", value: "1001-5000" },
    { label: "5001-10000", value: "5001-10000" },
    { label: "10001-Above", value: "10001-Above" },
  ]);

  const getRecruiterDetails = async () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.RECRUITER_DETAILS_API)
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
        } else {
          var linkedin_profile = data.json.linkedin_profile;
          var industry = data.json.industry;
          var department = data.json.department;
          var company_city = data.json.city;
          var company_country = data.json.country;
          var company_name = data.json.company_name;
          var company_size = data.json.company_size;
          var company_registration_number =
            data.json.company_registration_number;
          var role = data.json.role;

          setState({
            ...state,
            industry: industry,
            selected_industry: { label: industry, value: industry },
            department: department,
            city: company_city,
            country: company_country,
            selected_Country: {
              label: company_country,
              value: company_country,
            },
            selected_City: { label: company_city, value: company_city },
            company_name: company_name,
            company_size: company_size,
            selected_CompanySize: { label: company_size, value: company_size },
            company_reg_no: company_registration_number,
            company_linkedin: linkedin_profile,
            role: role,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };

  const updateCompanyInfo = async () => {
    if (
      state.industry == "" ||
      state.department == "" ||
      state.city == "" ||
      state.country == "" ||
      state.company_name == "" ||
      state.company_size == "" ||
      state.company_reg_no == "" ||
      state.role == "" ||
      state.industry == null ||
      state.department == null ||
      state.city == null ||
      state.country == null ||
      state.company_name == null ||
      state.company_size == null ||
      state.company_reg_no == null ||
      state.role == null
    ) {
      if (state.industry == "" || state.industry == null) {
        setState({ ...state, errMsg: { industry: translation.ENTER_INDUSTRY } });
      } else if (state.department == "" || state.department == null) {
        setState({ ...state, errMsg: { department: translation.ENTER_DEPARTMENT } });
      } else if (state.city == "" || state.city == null) {
        setState({ ...state, errMsg: { city: translation.ENTER_COMPANY_CITY } });
      } else if (state.country == "" || state.country == null) {
        setState({ ...state, errMsg: { country: translation.ENTER_COMPANY_COUNTRY } });
      } else if (state.company_name == "" || state.company_name == null) {
        setState({ ...state, errMsg: { company_name: translation.PLEASE_ENTER_COMPANY_NAME } });
      } else if (state.company_size == "" || state.company_size == null) {
        setState({ ...state, errMsg: { company_size: translation.ENTER_COMPANY_SIZE } });
      } else if (state.role == "" || state.role == null) {
        setState({ ...state, errMsg: { role: translation.ENTER_YOUR_JOB_TITLE } });
      } else if (state.company_reg_no == "" || state.company_reg_no == null) {
        setState({
          ...state,
          errMsg: { company_reg_no: translation.VAT_COMPANY_REGISTRATION_NUMBER },
        });
      }
    } else {
      let requestBody = JSON.stringify({
        industry: state.industry,
        department: state.department,
        city: state.city,
        country: state.country,
        role: state.role,
        company_name: state.company_name,
        company_size: state.company_size,
        company_registration_number: state.company_reg_no,
        linkedin_profile:state.company_linkedin,
      });

      postWithAuthCallWithErrorResponse(
        ApiConfig.RECRUITER_DETAILS_API,
        requestBody
      )
        .then((data) => {
          setState({ ...state, isProcessing: true });
          if (data.error == true) {
            setState({ ...state, isProcessing: false });
          } else {
            window.location.href = "/recruiter-profile";
          }
        })
        .catch((error) => {
          setState({ ...state, isProcessing: false });
        });
    }
  };

  function onCompanySizeChange(value) {
    setState({
      ...state,
      selected_CompanySize: value,
      company_size: value.label,
    });
  }

  /* city section starts */
  function getCitiesList(selected_country) {
    Object.keys(listContryCity.listContryCity).forEach(function (key, index) {
      var newJson = listContryCity.listContryCity[key];
      if (newJson.country == selected_country) {
        updateCity(newJson.city);
      }
    });
  }

  /* country section starts */
  const selectStyles = {
    control: provided => ({ ...provided, backgroundColor: 'transparent', borderRadius: "8px", border: "1px solid #b3c2c9", fontSize: "13px" }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)', backgroundColor: 'transparent' }),
  };
  function updateCity(data) {
    let temp_cities = [];
    data.map((item, index) => {
      temp_cities.push({ label: item, value: item });
    });
    setCitiesState(temp_cities);
  }

  function onCityChange(value) {
    setState({ ...state, selected_City: value, city: value.label });
  }
  /* city section ends */

  /* country section starts */
  function getCountriesList() {
    setCountriesState(Country.Country);
  }

  function onCountryChange(value) {
    setState({ ...state, selected_Country: value, country: value.label });
    getCitiesList(value.label);
  }
  /* country section ends */

  /* industry section starts */
  const getIndustryList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.INDUSTRY_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseIndustryList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseIndustryList = (data) => {
    let temp_industries = [];
    data.map((item, index) => {
      temp_industries.push({ label: item.name, value: item.id });
    });
    setIndustryState(temp_industries);
  };

  function onIndustryChange(value) {
    setState({ ...state, industry: value.label, selected_industry: value });
  }
  /* industry section ends */

  useEffect(() => {
    getCountriesList();
    getIndustryList();
    getRecruiterDetails();
  }, []);

  return (
    <div>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/recruiter-profile">{translation.PROFILE}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.EDIT_COMPANY_INFO}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <SpinnerCmp loading={state.isProcessing} />
      <section
        id="settings"
        className="settings"
        style={{ display: state.isProcessing ? "none" : "" }}
      >
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div
                className="card login settings_card"
                style={{ marginTop: "7em" }}
              >
                <div className="card-body">
                  <div className="form_block w-100 pt-0">
                    <form className="row g-3 text-start">
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.INDUSTRY}
                        </label>

                        <Select
                          isClearable={false}
                          onChange={onIndustryChange}
                          options={industryState}
                          placeholder={translation.SEARCH}
                          styles={selectStyles}
                          //tabSelectsValue={false}
                          value={state.selected_industry}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.industry ? state.errMsg.industry : ""}
                        </span>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.DEPARTMENT}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Department"
                          value={state.department}
                          onChange={(e) => {
                            setState({ ...state, department: e.target.value });
                          }}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.department
                            ? state.errMsg.department
                            : ""}
                        </span>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.COUNTRY}
                        </label>

                        <Select
                          isClearable={false}
                          onChange={onCountryChange}
                          options={countriesState}
                          placeholder={translation.SEARCH}
                          styles={selectStyles}
                          //tabSelectsValue={false}
                          value={state.selected_Country}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.country ? state.errMsg.country : ""}
                        </span>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.CITY}
                        </label>

                        <Select
                          isClearable={false}
                          onChange={onCityChange}
                          options={citiesState}
                          placeholder={translation.SEARCH}
                          styles={selectStyles}
                          //tabSelectsValue={false}
                          value={state.selected_City}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.city ? state.errMsg.city : ""}
                        </span>
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.COMPANY_NAME}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="CompanyName"
                          value={state.company_name}
                          onChange={(e) => {
                            setState({
                              ...state,
                              company_name: e.target.value,
                            });
                          }}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.company_name
                            ? state.errMsg.company_name
                            : ""}
                        </span>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.COMPANY_SIZE}
                        </label>
                        {/*<input type="email" className="form-control" id="CompanySize" value={state.company_size}
                                                    onChange={(e) => {
                                                        setState({ ...state, company_size: e.target.value });
                                                    }} />*/}
                        <Select
                          isClearable={false}
                          onChange={onCompanySizeChange}
                          options={companySizeState}
                          placeholder={translation.SEARCH}
                          styles={selectStyles}
                          //tabSelectsValue={false}
                          value={state.selected_CompanySize}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.company_size
                            ? state.errMsg.company_size
                            : ""}
                        </span>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.VAT_COMPANY_REGISTRATION_NUMBER}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="CompanyRegistrationNumber"
                          value={state.company_reg_no}
                          onChange={(e) => {
                            setState({
                              ...state,
                              company_reg_no: e.target.value,
                            });
                          }}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.company_reg_no
                            ? state.errMsg.company_reg_no
                            : ""}
                        </span>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.JOB_TITLE}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="Role"
                          value={state.role}
                          onChange={(e) => {
                            setState({ ...state, role: e.target.value });
                          }}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.role ? state.errMsg.role : ""}
                        </span>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.COMPANY_LINKEDIN_PROFILE}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="CompanyLinkedInProfile"
                          value={state.company_linkedin}
                          onChange={(e) => {
                            setState({
                              ...state,
                              company_linkedin: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="loginwithlinks editprofile_block">
                <div className="d-lg-flex d-md-flex justify-content-end">
                  <div className="loginwithbtn">
                    <p className="fs-22 fw-600 text-primary">
                      {translation.UPDATE}{" "}
                      <button
                        type="button"
                        className="btn btn-outline-primary rounded-circle ms-2"
                        onClick={updateCompanyInfo}
                      >
                        <img src={chevron_right} alt="" />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EditCompanyInfo;
