import React from "react";
import RecruiterHeader from "../../../sharedComponents/RecruiterHeader";
import arrow_next_grey from "../../../assets/img/arrow_next_grey.svg";
import { Link } from "react-router-dom";
import useTranslation from "../../../customHooks/translations";

function Setting() {
  const translation = useTranslation();
  return (
    <div>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/Recuiter_Home">{translation.HOME}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.SETTINGS}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" className="settings">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 g-0">
              <div className="card settings_card mt-4">
                <div className="card-body">
                  <label htmlFor="" className="fs-14 fw-600 text-accent">
                    {translation.ACCOUNT_SETTINGS}
                  </label>
                  <hr />
                  {/* <div className="d-flex justify-content-between">
                    <p>Hide status for recruiters</p>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>
                  <hr /> */}
                  {/* <div className="d-flex justify-content-between">
                    <p>{translation.ENABLE_READ_RECEIPT}</p>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div> */}
                  {/* <hr />
                  <Link to="/recruiter-block-user">
                    <div className="d-flex justify-content-between">
                      <p>Blocking</p>
                      <img src={arrow_next_grey} alt="" />
                    </div>
                  </Link> */}
                  <hr />
                  <Link to="/recruiter-change-password">
                    <div className="d-flex justify-content-between">
                      <p>{translation.CHANGE_PASSWORD}</p>

                      <img src={arrow_next_grey} alt="" />
                    </div>
                  </Link>
                  <hr />
                  <Link
                    to="/recruiter-delete-profile"
                    className="d-flex justify-content-between"
                  >
                    <p>{translation.DELETE_PROFILE}</p>
                    <img src={arrow_next_grey} alt="" />
                  </Link>
                </div>
              </div>
              {/* <div className="card settings_card mt-4 mb-4">
                <div className="card-body">
                  <label htmlFor="" className="fs-14 fw-600 text-accent">
                    {translation.NOTIFICATIONS}
                  </label>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <p>{translation.ENABLE_NOTIFICATIONS_FOR_MATCHES}</p>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Setting;
