import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import RecruiterHeader from "../../../sharedComponents/RecruiterHeader";
import menu from "../../../assets/img/change_password_image.svg";
import change_password_image from "../../../assets/img/change_password_image.svg";
import eye from "../../../assets/img/eye.svg";
import eye_closed from "../../../assets/img/eye_closed.svg";
import chevron_right_colored from "../../../assets/img/chevron_right_colored.svg";
import useTranslation from "../../../customHooks/translations";
import { postWithAuthCallWithErrorResponse } from "../../../api/ApiServices"
import ApiConfig from "../../../api/ApiConfig";
import PopoverElement from "../../../sharedComponents/Popover";
import { toast } from "react-toastify";
function Setting_ChangePass() {
  const password = useRef(null)
  const currentPassword = useRef(null)
  const confirmPassword = useRef(null)
  const [errorMsg, setErrorMsg] = useState({});
  const translation = useTranslation();
  const [state, setState] = useState({
    currentPassword: "",
    password: "",
    confirm_password: "",
    isShowCurrentPassword: false,
    isShowPassword: false,
    isShowConfirmPassword: false,
  });
  const showPassword = async (field_id) => {
    if (field_id == "currentPassword")
      setState({
        ...state,
        isShowCurrentPassword: !state.isShowCurrentPassword,
      });
    else if (field_id == "password")
      setState({ ...state, isShowPassword: !state.isShowPassword });
    else if (field_id == "ConfirmPassword")
      setState({
        ...state,
        isShowConfirmPassword: !state.isShowConfirmPassword,
      });
  };
  const validatepassword = (password) => {
    console.log('====================================');
    console.log(password);
    console.log('====================================');
    return String(password)
      .toLowerCase()
      .match(
        /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{6,50}$/
      );
  }
  const changePassword = () => {
    if (state.password === "" || state.currentPassword === "" || state.confirm_password === "") {
      if (state.currentPassword === "") {
        setErrorMsg({ currentPassword: "Enter Current password" })
        return
      }
      if (state.password === "") {
        setErrorMsg({ password: "Enter New password" })
        return
      }
      if (state.confirm_password === "") {
        setErrorMsg({ confirm_password: "Enter Confirm password" })
        return
      }


    }
    else {
      if (state.password.length < 6) {
        setErrorMsg({ password: "Password must be of 6 characters" });
        return
      }
      if (!validatepassword(state.password)) {
        setErrorMsg({ password: "Password must contains combination alphabets and numbers" });
        return
      }
      else if (state.password !== state.confirm_password) {
        setErrorMsg({ confirm_password: "New Password and Confirm Password Does Not Match" })
        return
      }
      else {
        let requestBody = JSON.stringify({ old_password: state.currentPassword, new_password: state.password })
        postWithAuthCallWithErrorResponse(ApiConfig.CHANGE_PASS, requestBody)
          .then((data) => {
            toast.success(data.json.message, { autoClose: 1000 })
            if (!data.error) {
              window.location.href = "/Pass_Suceess";
            }
          })
          .catch((error) => {
            console.log('error parse', error);
          });
      }

    }

  }
  return (
    <div>
      <RecruiterHeader />
      <div className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/Setting">{translation.SETTINGS}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.CHANGE_PASSWORD}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div id="settings" className="settings">
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-6 col-md-12 offset-lg-3">
              <div
                className="card login settings_card"
                style={{ marginTop: "7em" }}
              >
                <div className="card-body">
                  <div className="w-100 pt-0">
                    <form className="row g-3 text-start">
                      <div className="col-md-12">
                        <div className="text-center py-3">
                          <img src={change_password_image} alt="" />
                        </div>
                      </div>
                      <div className="col-md-12 regsiterrf">
                        <label className="form-label fs-14">
                          {translation.ENTER_CURRENT_PASSWORD}
                        </label>

                        <div className="input-group">
                          <input
                            type={
                              state.isShowCurrentPassword ? "text" : "password"
                            }
                            id="CurrentPassword_field"
                            ref={currentPassword}
                            className="form-control"
                            value={state.currentPassword}
                            onChange={(e) => {
                              setState({
                                ...state,
                                currentPassword: e.target.value,
                              });
                            }}
                          />
                          <span className="input-group-text">
                            <img
                              src={
                                state.isShowCurrentPassword ? eye_closed : eye
                              }
                              alt=""
                              onClick={() => showPassword("currentPassword")}
                            />
                          </span>
                          {
                            errorMsg.currentPassword &&
                            <PopoverElement references={currentPassword} msg={errorMsg.currentPassword} Id={"CurrentPassword_field"} />
                          }
                        </div>
                      </div>
                      <div className="col-md-12 regsiterrf">
                        <label className="form-label fs-14">
                          {translation.ENTER_NEW_PASSWORD}
                        </label>
                        <div className="input-group">
                          <input
                            type={state.isShowPassword ? "text" : "password"}
                            ref={password}
                            id="password_field"
                            className="form-control"
                            value={state.password}
                            onChange={(e) => {
                              setState({ ...state, password: e.target.value });
                            }}
                          />
                          <span className="input-group-text">
                            <img
                              src={state.isShowPassword ? eye_closed : eye}
                              alt=""
                              onClick={() => showPassword("password")}
                            />
                          </span>
                          {
                            errorMsg.password &&
                            <PopoverElement references={password} msg={errorMsg.password} Id={"CurrentPassword_field"} />
                          }
                        </div>
                      </div>
                      <div className="col-md-12 regsiterrf">
                        <label className="form-label fs-14">
                          {translation.CONFIRM_NEW_PASSWORD}
                        </label>
                        <div className="input-group">
                          <input
                            type={
                              state.isShowConfirmPassword ? "text" : "password"
                            }
                            ref={confirmPassword}
                            id="ConfirmPassword_field"
                            className="form-control"
                            value={state.confirm_password}
                            onChange={(e) => {
                              setState({
                                ...state,
                                confirm_password: e.target.value,
                              });
                            }}
                          />
                          <span className="input-group-text">
                            <img
                              src={
                                state.isShowConfirmPassword ? eye_closed : eye
                              }
                              alt=""
                              onClick={() => showPassword("ConfirmPassword")}
                            />
                          </span>
                          {
                            errorMsg.confirm_password &&
                            <PopoverElement references={confirmPassword} msg={errorMsg.confirm_password} Id={"ConfirmPassword_field"} />
                          }
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="loginwithlinks editprofile_block">
                <div className="d-lg-flex d-md-flex justify-content-end">
                  <div className="loginwithbtn">
                    <p className="fs-22 fw-600 text-primary">
                      {translation.CONTINUE}{" "}
                      {/* <Link to="/recruiter-password-success"> */}
                      <button
                        onClick={changePassword}
                        type="button"
                        className="btn btn-outline-primary rounded-circle ms-2"
                      >
                        <img src={chevron_right_colored} alt="" />
                      </button>
                      {/* </Link> */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting_ChangePass;
