import React, { useEffect, useState } from 'react';
import ApiConfig from '../api/ApiConfig';
import { getWithAuthCallWithtext } from '../api/ApiServices';

function Privacy(props) {

  const [privacy,setPrivacy]=useState("")
  useEffect(() => {
    getPrivacy()
  }, []);


function getPrivacy() {
  getWithAuthCallWithtext(ApiConfig.PRIVACY_POLICY)
      .then((data) => {
        setPrivacy(data.text);
      })
      .catch((error) => {
        console.log(error);
      });
}
    return (
        <div dangerouslySetInnerHTML={{ __html: privacy }}>
            
        </div>
    );
}

export default Privacy;