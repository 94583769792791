export default {
  FULLNAME: "Fullständigt namn",
  EMAIL: "E-post",
  MOBILE_NUMBER: "Mobilnummer",
  COUNTRY: "Land",
  CITY: "Stad",
  DATE_OF_BIRTH: "Födelsedatum",
  LINKEDIN_PROFILE: "Linkedin-profil",
  SHORT_DESCRIPTION: "Kort beskrivning om dig själv",
  UPDATE_BASIC_DETAILS: "Uppdatera grundläggande detaljer",
  UPDATE: "Uppdatera",
  CONFIRMATION_FOR_DELETE: "Är du säker på att du vill ta bort?",
  MATCHED_JOBS: "Matchade jobb",
  SAVED_JOBS: "Sparade jobb",
  SET_STATUS: "Ange status",
  ADD_INTRO_VIDEO: "Lägg till introduktionsvideo",
  BASIC_PROFILE: "Grundläggande profil",
  EDUCATION: "Utbildning",
  EXPERIANCE: "Erfarenhet",
  PREFERNCES: "Inställningar",
  LANGUAGE: "Språk",
  INDUSTRY: "Industry",
  MATCHING_PERCENTAGE_PREFERENCE: "Matchande procentuell preferens",
  JOB_TITLE: "Arbetstitel",
  MONTHLY_SALARY: "Månadslön",
  DESIRED_LOCATION: "Önskad plats",
  WORK_LOCATION: "Arbetsplats",
  SKILLS: "Färdigheter",
  TOOLS: "Verktyg",
  CERTIFICATES: "Certifikat",
  CURRENT_EXPERIANCE_LEVEL: "Nuvarande erfarenhetsnivå",
  CURRENT_ROLE: "Nuvarande roll",
  TUTORIAL: "Handledning",
  CHANGE_LANGAUAGE: "Ändra språk",
  SUBSCRIPTION_PLANS: "Prenumerationsplaner",
  FAQ: "FAQ",
  FEEDBACK_SUGGESTION: "Feedback och förslag",
  CONTACT_US: "Kontakta oss",
  SETTINGS: "Inställningar",
  LEGAL: "Legal",
  LOGOUT: "Logga ut",
  SELECT_LANGUAGE: "Välj språk",
  SELECT_YOUR_PREFERRED_LANGUAGE_TO_CONTINUE:
    "Välj ditt föredragna språk för att fortsätta",
  CONTINUE: "CONTINUE",
  UPGRADE: "Uppgradera",
  GIVE_US_FEEDBACK_ABOUT_A_FEATURE_LET_US_KNOW_HOW_WE_CAN_IMPROVE_YOUR_EXPERIANCE:
    "Ge oss feedback om en funktion. Låt oss veta hur vi kan förbättra din upplevelse.",
  SELECT_FEATURE: "Välj funktion",
  ENTER_YOUR_FEEDBACK_BELOW: "Ange din feedback nedan",
  GET_IN_TOUCH_WITH_US: "Ta kontakt med oss",
  MESSAGE_US: "MESSAGE US",
  SUBJECT: "Ämne",
  PLEASE_SELECT_SUBJECT: "Välj ämne",
  MESSAGE: "Meddelande",
  ACCOUNT_SETTINGS: "KONTOINSTÄLLNINGAR",
  HIDE_STATUS_FOR_EMPLOYERS: "Dölj status för arbetsgivare",
  ENABLE_READ_RECEIPT: "Aktivera läskvitto",
  BLOCKING: "Blockar",
  CHANGE_PASSWORD: "Ändra lösenord",
  DELETE_PROFILE: "Ta bort profil",
  NOTIFICATIONS_SETTINGS: "NOTIFICATIONS SETTINGS",
  ENABLE_NOTIFICATIONS_FOR_MATCHES: "Aktivera aviseringar för matcher",
  WANT_TO_TRY_OUR_PLATFORM: "Vill du prova vår plattform?",
  CLICK_HERE_FOR_3_DAYS_FREE_TRIAL:
    "Klicka här för 3 dagars gratis provperiod!",
  MAXIMUM_JOBS_TO_APPLY: "Maximalt antal jobb att söka",
  NUMBER_OF_JOBS_SAVES: "Antal sparade jobb",
  VALIDITY: "Validity",
  PRICE: "Pris",
  PRIVACY_POLICY: "Sekretesspolicy",
  TERMS_CONDITIONS: "Villkor",
  PRIVACY_PREFERENCES: "Sekretessinställningar",
  NO_NEW_NOTIFICATION: "Ingen ny notifikation!",
  ADD_EXPERIENCE: "Lägg till upplevelse",
  COMPANY_NAME: "Företagets namn",
  JOB_TYPE: "Jobbtyp",
  PLEASE_SELECT_JOB_TYPE: "Välj jobbtyp",
  PLEASE_SELECT_JOB_TITLE: "Välj befattning",
  PLEASE_SELECT_JOB_EXPERIENCE: "Välj arbetserfarenhet",
  START_YEAR: "Startår",
  SELECT_DATE: "välj datum",
  END_YEAR: "Slutår",
  I_AM_CURRENTLY_WORK_HERE: "Jag jobbar för närvarande här",
  SAVE: "SPARA",
  EDUCATION_TYPE: "Utbildningstyp",
  PLEASE_SELECT_EDUCATION_TYPE: "Välj utbildningstyp",
  INSTITUTE_NAME: "Institutets namn",
  AREA_OF_STUDY: "Studieområde",
  YEAR_OF_GRADUATION: "År för examen",
  PLEASE_ENTER_EDUCATION_TYPE: "Ange utbildningstyp",
  PLEASE_ENTER_INSTITUTE_NAME: "Vänligen ange institutets namn",
  PLEASE_ENTER_AREA_OF_STUDY: "Vänligen ange studieområde",
  PLEASE_ENTER_GRADUATION_YEAR: "Vänligen ange examensåret",
  PLEASE_ENTER_VALID_GRADUATION_YEAR: "Vänligen ange ett giltigt examensår",
  PLEASE_ENTER_COMPANY_NAME: "Vänligen ange företagets namn!",
  PLEASE_CHOOSE_ROLE: "Välj roll!",
  PLEASE_CHOOSE_JOB_TYPE: "Välj jobbtyp!",
  PLEASE_ADD_EXPERIENCE: "Snälla lägg till erfarenhet!",
  PLEASE_ADD_CITY: "Vänligen lägg till stad!",
  PLEASE_ADD_COUNTRY: "Vänligen lägg till land!",
  PLEASE_CHOOSE_START_YEAR: "Välj startår!",
  BEGINNER: "Nybörjare",
  COMPETENT: "Kompetent",
  PROFICIENT: "Snäll",
  EXPERT: "Expert",
  ADD_CERTIFICATE: "Lägg till certifikat",
  ADD_LINKEDIN_PROFILE: "Lägg till LinkedIn-profil",
  PLEASE_SELECT_LANGUAGE: "Välj språk",
  PLEASE_SELECT_INDUSTRIES: "Välj branscher",
  DESIRED_COUNTRY: "Önskat land",
  DESIRED_CITY: "Önskad stad",
  PLEASE_SELECT_YOUR_SALARY_RANGE: "Välj ditt löneintervall",
  MATCH_PRECENTAGE: "Matchandel",
  PLEASE_SELECT_MATCH_PRECENTAG: "Välj matchande andel",
  ONSITE: "På plats",
  HYBRID: "Hybrid",
  REMOTE: "Fjärr",
  UPGRADE_TO_FULL_ACCESS_MSG:
    "Du måste antingen uppgradera till full åtkomst för att spara/ansöka fler jobb eller vänta tills din gratis åtkomst laddas om.",
  CANCEL: "ANNULLERA",
  RECENT_SEARCHES: "Senaste sökningar",
  PLEASE_ENTER_YOUR_EMAIL_PHONE_NUMBER: "Ange din e-post/telefonnummer!",
  PLEASE_ENTER_YOUR_PASSWORD: "Vänligen ange ditt lösenord!",
  PLEASE_SELECT_USER_TYPE: "Välj användartyp",
  JOB_SEEKER: "Jobbsökande",
  RECRUITER: "Rekryterare",
  LOGIN: "Logga in",
  LOGIN_INSTRUCTION_LABEL_MSG:
    "Ange din verifierade e-post/telefonnummer för att logga in på ditt konto",
  FORGOT_PASSWORD: "Glömt lösenord?",
  LOGIN_AGREE_LABEL_MSG: 'Genom att klicka på "Logga in" godkänner du vår ',
  TERMS: "Villkor..",
  LOGIN_PRIVACY_COOKIES_POLICY_LABEL_MSG:
    " Lär dig hur vi behandlar dina uppgifter i vår",
  PRIVACY_POLICY: "Sekretesspolicy.",
  LOGIN_WITH: "Logga in med",
  OR_LOGIN_WITH: "Eller logga in med",
  DONT_HAVE_AN_ACCOUNT: "Har du inget konto?",
  REGISTER: "Registrera",
  PLEASE_ENTER_CORRECT_OTP: "Vänligen ange korrekt OTP!",
  DIDNT_RECEIVE_CODE: "Fick du ingen kod?",
  RESEND: "RESEND",
  PLEASE_ENTER_YOUR_NAME: "Vänligen ange ditt namn.",
  PLEASE_ENTER_YOUR_EMAIL: "Ange din e-postadress.",
  PLEASE_ENTER_VALID_EMAIL_ADDRESS: "Vänligen ange en giltig e-postadress!",
  PLEASE_ENTER_YOUR_MOBILE_NUMBER: "Vänligen ange ditt mobilnummer.",
  PLEASE_ENTER_YOUR_CITY: "Vänligen ange din stad.",
  PLEASE_ENTER_YOUR_COUNTRY: "Vänligen ange ditt land.",
  PLEASE_SELECT_YOUR_DATE_OF_BIRTH: "Välj ditt födelsedatum!.",
  PLEASE_DESCRIBE_YOUR_SELF: "Vänligen beskriv dig själv!.",
  PLEASE_ENTER_YOUR_PASSWORD: "Vänligen ange ditt lösenord.",
  VALID_PASSWORD_MSG:
    "Lösenordet bör vara en blandning av minst en versal, gemen, numerisk och specialtecken.",
  PLEASE_ENTER_YOUR_CONFIRM_PASSWORD: "Vänligen ange ditt bekräftelselösenord.",
  PASSWORD_DO_NOT_MATCH: "Lösenordet matchar inte!",
  UPLOAD_PROFILE_PICTURE: "Ladda upp profilbild",
  UPLOAD_FROM_STORAGE: "Ladda upp från lagring",
  CAMERA: "Kamera",
  CONFIRM: "Bekräfta",
  PLEASE_ENTER_YOUR_DEPARTMENT: "Vänligen ange din avdelning!",
  PLEASE_SELECT_YOUR_ROLE: "Välj din roll!",
  PLEASE_ENTER_YOUR_COMPANY_NAME: "Vänligen ange ditt företagsnamn!",
  PLEASE_SELECT_YOUR_COMPANY_SIZE: "Välj din företagsstorlek!",
  PLEASE_ENTER_YOUR_VAT_COMPANY_REGISTRATION_NUMBER:
    "Ange ditt moms-/företagsnummer!",
  PLEASE_ADD_COMPANY_LINKEDIN_PROFILE:
    "Vänligen lägg till företagsprofil för Linkedin!",
  SUCCESSFULLY_REGISTER: "Registrera dig framgångsrikt.",
  PLEASE_SELECT_INDUSTRY: "Välj bransch",
  DEPARTMENT: "Avdelning",
  PLEASE_SELECT_SIZE: "Välj storlek",
  VAT_COMPANY_REGISTRATION_NUMBER: "Moms-/företagsregistreringsnummer",
  ADD_COMPANY_LINKEDIN_PROFILE: "Lägg till företags LinkedIn-profil",
  YOUR_PASSWORD_HAS_BEEN_CHANGED_SUCCESSFULLY:
    "Ditt lösenord har ändrats framgångsrikt!",
  PLEASE_ENTER_CURRENT_PASSWORD: "Vänligen ange nuvarande lösenord.",
  PLEASE_ENTER_NEW_PASSWORD: "Vänligen ange nytt lösenord.",
  PLEASE_ENTER_CONFIRM_NEW_PASSWORD: "Vänligen ange bekräfta nytt lösenord.",
  CURRENT_PASSWORD_AND_NEW_PASSWORD_SAME:
    "Nuvarande lösenord och nytt lösenord samma!",
  PASSWORD_DOES_NOT_MATCH: "Lösenordet matchar inte!",
  ENTER_CURRENT_PASSWORD: "Ange aktuellt lösenord",
  ENTER_NEW_PASSWORD: "Ange nytt lösenord",
  CONFIRM_NEW_PASSWORD: "Bekräfta nytt lösenord",
  SUBMIT: "SUBMIT",
  PLEASE_ADD_YOUR__PHOTO: "Snälla lägg till ditt foto!",
  PLEASE_ENTER_ABOUT_COMPANY: "Vänligen ange om företaget!",
  PLEASE_ENTER_INDUSTRY_NAME: "Ange branschnamn!",
  COPY_JOB_AD: "Kopiera jobbannons",
  COMPANY_INFO: "Företagsinformation",
  ABOUT_THE_COMPANY: "Om företaget",
  VIDEO_LENGTH_SHOULD_NOT_BE_MORE_THAN_45_SEC:
    "Videolängden bör inte vara mer än 45 sekunder.",
  PLEASE_ENTER_JOB_DESCRIPTION: "Ange arbetsbeskrivning!",
  PLEASE_SELECT_JOB_TYPE: "Välj jobbtyp!",
  PLEASE_SELECT_REQUIRE_EXPERIENCE: "Välj kräver erfarenhet!",
  PLEASE_SELECT_WORK_LOCATION: "Välj arbetsplats!",
  PLEASE_SELECT_LANGUAGE_REQUIRE: "Välj språk kräver!",
  PLEASE_SELECT_LANGUAGE_FOR_JOB_POSTING: "Välj språk för jobbannonsering!",
  PLEASE_ADD_DURATION_OF_ADV: "Vänligen lägg till varaktighet för annonsen!",
  JOB_INFO: "Jobbinformation",
  ADD_VIDEO: "Lägg till video",
  PART_TIME: "Deltid",
  FULL_TIME: "Heltid",
  EXPERIENCE_LEVEL: "Erfarenhetsnivå",
  LIST_OF_LANGUAGES: "Lista över språk",
  JOB_POSTING_LANGUAGE: "Språk för jobbannonsering",
  AD_DURATION: "Annonslängd",
  JOB_COPIED_SUCCESSFULLY: "Jobb kopierades framgångsrikt.",
  PREREQUISITES: "Förutsättningar",
  ADD_SKILLS: "Lägg till färdigheter",
  ADD_TOOLS: "Lägg till verktyg",
  CREATE_JOB_AD: "Skapa jobbannons",
  JOB_CREATED_SUCCESSFULLY: "Jobb skapat framgångsrikt",
  OPPS_NO_JOBS_FOUND: "OPPS! inga jobb hittades!",
  OPPS_NO_PROFILES_FOUND: "OPPS! inga profiler hittades!",
  LOADING: "Laddar...",
  THIS_IS_TEMPORARY_I_WILL_BE_BACK:
    "Detta är tillfälligt, jag kommer tillbaka.",
  I_GET_TOO_MANY_NOTIFICATIONS: "Jag får för många aviseringar.",
  I_SPEND_TOO_MUCH_TIME_IN_PROW: "Jag spenderar för mycket tid i ProW.",
  MY_ACCOUNT_WAS_HACKED: "Mitt konto hackades.",
  I_DIDNT_FIND_PROW_USEFUL: "Jag tyckte inte att ProW var användbart.",
  I_HAVE_A_PRIVATE_CONCERN: "Jag har en privat oro.",
  DELETE_ACCOUNT: "Ta bort konto",
  SELECT_A_REASON_FOR_DELETING_THE_ACCOUNT:
    "Välj en anledning till att ta bort kontot",
  _OTHER: "Annan",
  DELETE_YOUR_ACCOUNT_DO_YOU_WISH_TO_CONTINUE:
    "När du raderar ditt konto kommer alla dina data att gå förlorade och du kommer inte längre att kunna komma åt ditt konto. Vill du fortsätta?",
  NO: "NEJ",
  YES: "JA",
  YOUR_ACCOUNT_HAS_BEEN_DELETED_SUCCESSFULLY_CLICK_CONTINUE_TO_LOGOUT:
    "Ditt konto har raderats! Klicka på fortsätt för att logga ut.",
  EDIT_JOB_AD: "Redigera jobbannons",
  JOB_UPDATED_SUCCESSFULLY: "Jobb uppdaterat framgångsrikt",
  SEARCH: "Sök...",
  SAVED_PROFILES: "Sparade profiler",
  MATCHED_PROFILES: "Matchade profiler",
  OPEN: "öppen",
  PAUSED: "Pausad",
  PAUSE: "Paus",
  CLOSED: "Stängt",
  CLOSE: "Stäng",
  RESUME: "ÅTERUPPTA",
  JOB_ADS: "Arbetsannonser",
  REQUIRED_LANGUAGE: "Obligatoriskt språk",
  NO_VIDEO_FOUND: "Ingen video hittades!",
  ARE_YOU_SURE_WANT_TO_MAKE_DUPLICATE_JOB:
    "Är du säker på att du vill göra en dubblett? Observera att denna åtgärd kommer att skapa en dubblett av det här jobbet.",
  NO_PROFILES_SAVED: "Inga profiler sparade.",
  ABOUT_THE_JOB: "OM JOBBET",
  AD_DETAILS: "AD Detaljer",
  ARE_YOU_SURE_WANT_TO_LOGOUT: "Är du säker på att du vill logga ut?",
  SELECT_YEAR: "välj år",
  PLEASE_ENTER_INDUSTRY_PREFERENCE: "Ange branschpreferens!",
  PLEASE_ENTER_ROLE_PREFERENCE: "Ange rollpreferens!",
  PLEASE_ENTER_JOB_TYPE_PREFERENCE: "Vänligen ange inställningar för jobbtyp!",
  PLEASE_SELECT_YOUR_PREFERENCE_COUNTRY: "Välj önskat land!",
  PLEASE_SELECT_YOUR_PREFERENCE_CITY: "Välj din önskade stad!",
  PLEASE_SELECT_MONTHLY_SALARY_PREFERENCE: "Välj önskad månadslön!",
  INDUSTRIES: "Industrier",
  PERCENTAGE: "Procentandel",
  PLEASE_SELECT_PERCENTAGE: "Välj procentandel",
  PLEASE_ADD_YOUR_CURRENT_EXPERIENCE_LEVEL:
    "Vänligen lägg till din nuvarande erfarenhetsnivå!",
  PLEASE_ADD_YOUR_CUURENT_DESIGNATION:
    "Vänligen lägg till din cuurent-beteckning!",
  PLEASE_SELECT_YOUR_SKILLS: "Välj dina färdigheter",
  PLEASE_SELECT_YOUR_TOOLS: "Välj dina verktyg",
  DO_YOU_HAVE_DRIVING_LICENSE: "Har du körkort?",
  CURRENT_EXPERIENCE_LEVEL: "Nuvarande erfarenhetsnivå",
  PHONE_NUMBER_VERIFIED_SUCESSFULLY:
    "Telefonnumret har verifierats framgångsrikt",
  EMAIL_VERIFIED_SUCESSFULLY: "E-post verifierad framgångsrikt",
  PLEASE_ENTER_PHONE_NUMBER: "Ange telefonnummer!",
  OTP_VERIFICATION: "Otp-verifiering",
  PLEASE_ENTER_THE_4_DIGIT_CODE_SENT_TO:
    "Ange den fyrsiffriga koden som skickats till",
  YOU_NEED_TO_VERIFIY_YOUR_EMAIL_PLEASE_VERIFIY:
    "Du måste verifiera din e-post, vänligen verifiera",
  OK: "OK",
  YOU_ARE_ALREADY_USING_THIS_EMAIL_NO_NEED_OF_VERIFICATION:
    "Du använder redan detta e-postmeddelande, inget behov av verifiering",
  YOU_NEED_TO_VERIFIY_YOUR_PHONE_NUMBER_PLEASE_VERIFIY:
    "Du måste verifiera ditt telefonnummer, vänligen verifiera",
  YOU_ARE_ALREADY_USING_THIS_PHONE_NUMBER_NO_NEED_OF_VERIFICATION:
    "Du använder redan detta telefonnummer, inget behov av verifiering",
  PLEASE_ENTER_INDUSTRY: "Snälla gå in i branschen!",
  UPDATE_COMPANY_INFO: "Uppdatera företagsinformation",
  PRESENT: "Presenter",
  ARE_YOU_ACTIVELY_HIRING: "Anställer du aktivt?",
  ACTIVELY_HIRING_FOR: "Anställer aktivt för",
  ACTIVELY_LOOKING_FOR: "Söker aktivt jobb",
  MATCHES: "Matchs",
  CREATE_NEW_JOB_AD: "Skapa ny jobbannons",
  SEE_ALL_ADS: "SE ALLA ANNONSER",
  BASIC_INFO: "GRUNDLÄGGANDE INFORMATION",
  ARE_YOU_LOOKING_FOR_A_JOB: "Söker du efter ett jobb?",
  TUTORIALS: "Självstudier",
  SKIP: "SKIP",
  TOOLBAR: "verktygsfält",
  TYPE_HERE: "Skriv här",
  GET_STARTED: "Kom igång",
  PLEASE_ADD_YOUR_LINKEDIN_PROFILE: "Vänligen lägg till din länkade profil",
  PLEASE_SELECT_DID_YOU_HAVE_DRIVING_LICENSE: "Välj har du körkort!",
  SOMTHING_WENT_TO_WRONG: "Något gick fel!",
  MATCH_WITH_JOB: "MATCH MED JOBB",
  TOOLS_REQUIRED: "Verktyg krävs",
  DRIVING_LICENSE: "Körkort",
  PROFILE: "Profil",
  ADVANCED_SKILLS: "Avancerade färdigheter",
  UPLOAD_VIDEO: "Ladda upp video",
  YOUR_PREFERRED_INDUSTRY: "Välj din föredragna bransch",
  LOOKING_FOR_A_JOB: "Söker du efter ett jobb?",
  FILTERS: "Filter",
  PLAY_INTRO_VIDEO: "Spela introvideo",
  WORKPLACE: "Arbetsplats",
  APPLY: "APPLY",
  MENU: "MENY",
  NOTIFICATIONS: "Meddelanden",
  SEE_ALL: "Se alla",
  SEND: "Skicka",
  HIDE_STATUS_FOR_RECRUITERS: "Dölj status för rekryterare",
  HOME: "Hem",
  CHAT: "Chatta",
  NOT_INTERESTED: "Inte intresserad",
  BLOCK_COMPANY: "Blockera företag",
  EXPLORE: "Utforska",
  MATCHING_JOBS: "Matchande jobb",
  MATCHING_PERCENTAGE: "Matchningsprocent",
  MATCH: "Match",
  LOOKING_TO_HIRE_PEOPLE: "Vill du anställa personer?",
  SELECT_YOUR_PREFERRED_ROLE: "Välj din föredragna roll.",
  ACTIVELY_HIRING: "Anställer aktivt",
  COMPANY: "Företag",
  LOCATION: "Plats",
  COMPANY_SIZE: "Företagsstorlek",
  COMPANY_LINKEDIN_PROFILE: "Företagets LinkedIn-profil",
  JOBS: "Jobb",
  EDIT_BASIC_INFO: "Redigera grundläggande information",
  EDIT_COMPANY_INFO: "Redigera företagsinformation",
  BLOCK: "Blockera",
  NO_OF_JOB_SAVES: "Antal jobbbesparingar",
  OUR_PLANS: "Våra planer",
  FEEDBACK_AND_SUGGESTIONS: "Feedback och förslag",
  ACCOUNT: "Konto",
  SEARCH_HERE: "Sök här",
  TOP_MATCHES: " Toppmatcher",
  RECENT_CHATS: "Senaste chattar",
  BLOCK_COMPANY_LABEL_MSG:
    "När du blockerar ett företag kommer du inte att kunna se jobbannonsen som lagts upp av dem.",
  BLOCKED_COMPANY_LIST: "LISTA FÖR BLOCKERADE FÖRETAG",
  ACTION: "Action",
  UNBLOCK: "Avblockera",
  RECRUITER_INFO: "Rekryterarinformation",
  TYPE_NAME_HERE: "Skriv namn här",
  COMPANY_BLOCKED_SUCESSFULLY: "Företaget har blockerats framgångsrikt",
  PLEASE_ENTER_YOUR_MESSAGE: "Vänligen skriv in ditt meddelande!",
  MEASSAGE_SENT_SUCCESSFULLY: "Meddelandet har skickats framgångsrikt",
  PLEASE_CREATE_JOB_FIRST: "Skapa jobb först!",
  MATCHED_JOBS_NOT_FOUND: "Matchade jobb hittades inte!",
  MATCHED_PROFILES_NOT_FOUND: "Matchade profiler hittades inte!",
  NO_NEW_RECORDS_FOUND: "Inga nya poster hittades",
  PLEASE_SELECT_MATCH_FOR: "Välj matchning för",
  PLEASE_ENTER_YOUR_MOBILE_NUMBER_OR_EMAIL:
    "Ange ditt mobilnummer eller e-post",
  ENTER_YOUR_MOBILE_NUMBER_OR_EMAIL_ID_TO_CONTINUE:
    "Ange ditt mobilnummer eller e-post-ID för att fortsätta",
  MOBILE_NUMBER_OR_EMAIL_ID: "Mobilnummer eller e-post-id",
  EMAIL_OR_PHONE: "E-post eller telefon",
  ENTER_THE_CODE_SENT_YOUR_REGISTERED_MOBILE_NUMBER_EMAIL_ID_TO_CONTINUE:
    "Ange koden skickade ditt registrerade mobilnummer/e-post-id för att fortsätta",
  AD_ID: "Annons-ID",
  VALID_UNTIL: "Gäller till",
  COMPANY_INFO: "FÖRETAGSINFO",
  ARE_YOU_SURE_YOU_WANT_TO: "Är du säker på att du vill",
  THE_JOB: "jobbet?",
  SELECT_JOB: "Välj jobb",
  NO_RECORDS_FOUND: "Inga poster hittades",
  NO_RECENT_SEARCHES: "Inga senaste sökningar",
  REJECT: "Avvisa",
  ACCEPT: "Acceptera",
  EDIT_EDUCATION_INFO: "Redigera utbildningsinformation",
  EDIT_EXPRIENCE_INFO: "Redigera upplevelseinformation",
  EDIT_ADVANCED_SKILLS: "Edit_Advanced_Skills",
  EDIT_PREFERENCES: "Redigera inställningar",
  PREV: "Föregående",
  NEXT: "Nästa",
  ADD_JOB_INFO: "Lägg till jobbinformation",
  WELCOME_LABEL: "Välkommen till",
  PRODUCT_INTRO:
    "Utformad för arbetssökande och rekryterare. Gör dina anställningsbeslut snabbare, enklare Vi berättar exakt matchande procentandel av jobb och profil.",
  PH_EMAIL_PHONE_NUMBER: "E-post/telefonnummer",
  PASSWORD: "Lösenord",
  ERROR_MSG_INVALID_CREDENTIALS: "Ogiltiga inloggningsuppgifter",
  REGISTER_AS: "Registrera som",
  CONFIRM_PASSWORD: "Bekräfta lösenord",
  REGISTER_INTO: "Ange detaljerna för att registrera dig på plattformen",
  ALERT_OTP_NOT_VERIFIED: "OTP Ej verifierad",
  ALREADY_HAVE_A_ACCOUNT_LABLE: "Har du redan ett konto?",
  SETUP_YOUR_PROFILE: "Ange detaljerna för att konfigurera din profil",
  ROLE: "Roll",
  ADD_YOUR_COMPANY_LINKEDIN_PROFILE: "Lägg till ditt företags LinkedIn-profil",
  FORGOT_PASSWORD_HEADING: "Glömt lösenord",
  DATA_UPDATE_SUCCESSFULLY: "Datauppdatering framgångsrikt",
  PLEASE_SELECT_JOB_TITLE: "Välj jobbtitel",
  SELECT_EXPERIENCE: "Välj upplevelse",
  SELECT_COUNTRY: "Välj land",
  SELECT_CITY: "Välj stad",
  SELECT_START_YEAR: "Välj startår",
  SELECT_END_YEAR: "Välj slutår",
  DELETE: "Radera",
  EDIT: "Redigera",
  EDIT_EXPERIENCE: "Redigera upplevelse",
  COMPANY_UNBLOCK_SUCESSFULLY: "Företaget avblockeras framgångsrikt",
  ADD_EXPERIENCE: "Lägg till upplevelse",
  SETUP_YOUR_PROFILE: "Ställ in din profil",
  INTERMEDIATE: "Mellanliggande",
  DESIGNATION: "Beteckning",
  ROLES: "Roller",
  ENTER_FULL_NAME: "Ange fullständigt namn",
  ENTER_EMAIL: "Ange e-post",
  ENTER_DATE_OF_BIRTH: "Ange födelsedatum",
  ENTER_PHONE_NUMBER: "Ange telefonnummer",
  SELECT_COUNTRY_CODE: "Välj landskod ",
  ENTER_CITY: "Ange stad",
  ENTER_ABOUT_YOU: "Ange om dig",
  ENTER_COUNTRY: "Ange land",
  JOB_DESCRIPTION: "Jobbbeskrivning",
  REQUIRED_EXPERIENCE: "Obligatorisk erfarenhet",
  ADD_PREFERENCES: "Lägg till inställningar",
  LANGAUGES: "Språk",
  SELECT_SALARY: "Välj lön",
  SELECT: "Välj",
  ENTER_COMPANY_COUNTRY: "Ange företagsland",
  ENTER_YOUR_JOB_TITLE: "Ange din befattning",
  ENTER_COMPANY_SIZE: "Ange företagsstorlek",
  ENTER_COMPANY_CITY: "Ange företagsort",
  ENTER_DEPARTMENT: "Gå in i avdelningen",
  ENTER_INDUSTRY: "Gå in i branschen",
  SELECT_JOB_TITLE: "Välj jobbtitel",
  SELECT_EDUCATION_TYPE: "Välj utbildningstyp",
  ENTER_INTITUTE_NAME: "Ange Intitute Name",
  ENTER_AREA_OF_STUDY: "Ange studieområde",
  ENTER_YEAR_OF_GRADUATION: "Ange examensår",
  EDIT_EDUCATION: "REDIGERA UTBILDNING",
  INTERESTED: "Intresserad",
  SKILLS_REQUIRED: "Färdigheter som krävs",
  MATCH_PERCENTAGE: "MATCHPROCENT",
  VIDEO_RECORD_TIME_MSG: "Video kommer endast att spelas in i 45 sekunder",
  START_RECORDING: "Starta inspelning",
  STOP_RECORDING: "Stoppa inspelning",
  ENTER_SUBJECT: "Ange ämne",
  ENTER_MESSAGE: "Ange meddelande",
  MESSAGE_SENT_SUCCESSFULLY: "Meddelande har skickats",
  JOB_SAVED_SUCCESSFULLY: "Jobb sparat framgångsrikt",
  JOB_NOT_SAVED_SUCCESSFULLY: "Jobb har inte sparats framgångsrikt",
  SELECT_AN_ITEM: "Välj ett objekt",
  ERROR_TRY_AFTER_SOMETIME:
    "Något fel uppstod. Vänligen försök efter någon gång",
  REASON_NEEDED_PROCEED: "Anledning behövs för att fortsätta",
  S_No: "S.No",
  PERSONAL_BANKED_TO_LOGIN: "Ange din personliga bank för att logga in.",
  VALID_DIGIT_OTP: "Ange en giltig 4-siffrig OTP",
  GO_BACK: "Gå tillbaka",
  SETUP_CAREER_PROFILE:
    "Sätt upp din karriärprofil genom att lägga till din erfarenhet",
  ADD_EDUCATION_LEVELS: "Lägg till utbildningsnivåer",
  SELECT_EDUCATION_TYPE: "Välj utbildningstyp",
  ENTER_EMAIL_IN_CURRECT_FORMAT: "Ange e-post i rätt format",
  ENTER_PASSWORD: "Ange lösenord",
  PASSWORDS_DOES_NOT_MATCH: "Lösenorden matchar inte",
  COOKIES_POLICY: "Cookiespolicy",
  PERSONAL_ID: "Personligt ID",
  UPLOAD_PICTURE: "Ladda upp bild",
  ADD_EDUCATION: "Lägg till utbildning",
  CHECKOUT_MORE_PROFILES:"Kolla in fler profiler i avsnittet Utforska"
};
