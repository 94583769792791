import React, { useState, useEffect } from "react";
import upload from "../../../assets/img/upload.svg";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import ApiConfig from "../../../api/ApiConfig";
import {
  simpleGetCallWithErrorResponse,
  getWithAuthCallWithErrorResponse,
} from "../../../api/ApiServices";
import SpinnerCmp from "../../../sharedComponents/SpinnerCmp";
import useTranslation from "../../../customHooks/translations";

export default function JobsInfo(props) {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    job_title: props.jobDetails ? props.jobDetails.job_title : "",
    selected_jobTitle: props.jobDetails
      ? props.jobDetails.selected_jobTitle
      : [],
    job_description: props.jobDetails ? props.jobDetails.job_description : "",
    job_type: props.jobDetails ? props.jobDetails.job_type : "",
    work_location: props.jobDetails ? props.jobDetails.work_location : [],
    job_posting_language: props.jobDetails
      ? props.jobDetails.job_posting_language
      : "",
    salary: props.jobDetails ? props.jobDetails.salary : "",
    ad_duration: props.jobDetails ? props.jobDetails.ad_duration : "",
    required_experience: props.jobDetails
      ? props.jobDetails.required_experience
      : [],
    //languages_required: props.jobDetails ? props.jobDetails.languages_required : [],
    video_name: props.jobDetails ? props.jobDetails.video_name : "",
    video: props.jobDetails ? props.jobDetails.video : "",
    selectedExperiences: props.jobDetails
      ? props.jobDetails.selectedExperiences
      : [],
    selectedLanguages: props.jobDetails
      ? props.jobDetails.selectedLanguages
      : [],
    selectedLanguagesWithLevel: props.jobDetails
      ? props.jobDetails.selectedLanguagesWithLevel
      : [],
    jobRoleOptions: [],
  });
  const [experienceState, setExperienceState] = useState();
  const [languageState, setLanguageState] = useState();
  const [jobRoleState, setJobRoleState] = useState();

  function handleSaveAndNext() {
    props.saveJobsInfo(state);
    props.handleNext();
  }

  function handleSalaryChange(e) {
    setState({ ...state, salary: e.target.value });
  }

  const handleVideoChange = async (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setState({
        ...state,
        video: file,
        video_name: file.name,
      });
    };
    reader.readAsDataURL(file);
  };

  function changeWorkLocation(work_location) {
    var temp_work_location = state.work_location;
    if (temp_work_location.indexOf(work_location) > -1) {
      temp_work_location.splice(temp_work_location.indexOf(work_location), 1);
    } else {
      temp_work_location.push(work_location);
    }

    setState({ ...state, work_location: temp_work_location });
  }

  /* job role/title section starts */
  const getJobRoleList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.JOBROLE_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseJobRoleList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseJobRoleList = (data) => {
    let temp_jobRoleOptions = [];
    data.map((item, index) => {
      temp_jobRoleOptions.push({ label: item.name, value: item.name });
    });
    //setState({ ...state, jobRoleOptions: temp_jobRoleOptions });
    setJobRoleState(temp_jobRoleOptions);
  };

  function onJobTitleChange(value) {
    setState({ ...state, job_title: value.label, selected_jobTitle: value });
  }

  function onTitleKeyDown(value) {
    let temp_jobRoleOptions = jobRoleState;

    temp_jobRoleOptions.push({ label: value, value: value });
    setJobRoleState(temp_jobRoleOptions);
  }
  /* job role/title section ends */

  /* experience list section starts */
  const getExperienceList = () => {
    setState({ ...state, isProcessing: true });
    simpleGetCallWithErrorResponse(ApiConfig.EXPERIENCE_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseExpLevelList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseExpLevelList = (data) => {
    let temp = [];
    data.map((item, index) => {
      temp.push({ name: item.level, id: item.id });
    });

    setExperienceState(temp);
  };

  function onExperienceChange(selectedList, selectedItem) {
    let temp_required_experience = [];
    selectedList.map((item, index) => {
      temp_required_experience.push(item.name);
    });

    setState({
      ...state,
      selectedExperiences: selectedList,
      required_experience: temp_required_experience,
    });
  }
  /* experience list section ends */

  /* languages list section starts */
  const getLanguageList = () => {
    setState({ ...state, isProcessing: true });
    simpleGetCallWithErrorResponse(ApiConfig.LANGUAGE_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseLanguagelList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseLanguagelList = (data) => {
    let temp_lang = [];
    data.map((item, index) => {
      temp_lang.push({ name: item.name, id: item.id });
    });
    setLanguageState(temp_lang);
  };

  function onRequiredLanguageChange(selectedList, selectedItem) {
    let temp_required_language = [];
    let temp_selectedLanguagesWithLevel = [];

    selectedList.map((item, index) => {
      temp_required_language.push(item.name);
      temp_selectedLanguagesWithLevel.push({
        language: item.name,
        level: "Beginner",
      });
    });

    setState({
      ...state,
      selectedLanguages: selectedList,
      selectedLanguagesWithLevel: temp_selectedLanguagesWithLevel,
    });
  }

  function onChangeLanguageLevel(language, level) {
    let temp_selectedLanguagesWithLevel = [];
    state.selectedLanguagesWithLevel.map((item, index) => {
      temp_selectedLanguagesWithLevel.push({
        language: item.language,
        level: item.language == language ? level : item.level,
      });
    });
    setState({
      ...state,
      selectedLanguagesWithLevel: temp_selectedLanguagesWithLevel,
    });
  }
  /* languages list section starts */

  const selectStyles = {
    control: (provided) => ({ ...provided, backgroundColor:'transparent', borderRadius:"8px", border:"1px solid #b3c2c9",fontSize:"13px" }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)", backgroundColor:'transparent', }),
  };

  useEffect(() => {
    getExperienceList();
    getLanguageList();
    getJobRoleList();
  }, []);

  return (
    <>
      <SpinnerCmp loading={state.isProcessing} marginTop={"-20vh"} />
      <div
        className="col-lg-8 col-md-12"
        style={{ display: state.isProcessing ? "none" : "" }}
      >
        <div className="card login settings_card create_job_card">
          <div className="card-body">
            <div className="form_block w-100 pt-0">
              <form className="row g-3 text-start">
                <div className="col-md-6 regsiterrf">
                  <label htmlFor="inputEmail4" className="form-label fs-14">
                    {translation.JOB_TITLE}
                  </label>
                  <CreatableSelect
                    //onKeyDown={(e) => onTitleKeyDown(e.target.value)
                    isClearable={false}
                    onChange={onJobTitleChange}
                    options={jobRoleState}
                    placeholder={translation.SEARCH}
                    styles={selectStyles}
                    //tabSelectsValue={false}
                    value={state.selected_jobTitle}
                  />
                </div>
                <div className="col-md-6 upload-btn-wrapper">
                  <label htmlFor="inputPassword4" className="form-label fs-14">
                    {translation.ADD_VIDEO}
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={state.video_name}
                    />
                    <span
                      className="input-group-text"
                      style={{
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                      }}
                    >
                      <img src={upload} alt="" />
                    </span>
                    <input
                      type="file"
                      name="myfile"
                      accept="video/*"
                      onChange={handleVideoChange}
                    />
                  </div>
                </div>
                <div className="col-md-12 regsiterrf mb-4">
                  <label htmlFor="inputPassword4" className="form-label fs-14">
                    {translation.JOB_DESCRIPTION}
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    style={{ height: "69px" }}
                    value={state.job_description}
                    onChange={(e) =>
                      setState({ ...state, job_description: e.target.value })
                    }
                  ></textarea>
                </div>

                <div className="col-md-6 regsiterrf mb-4">
                  <div>
                    <label className="form-label fs-14">{translation.JOB_TYPE}</label>
                  </div>
                  <div className="swith_btn btn-group btn-toggle">
                    <button
                      className={
                        state.job_type === "Part Time"
                          ? `btn btn-lg btn-primary active `
                          : `btn btn-lg btn-default`
                      }
                      onClick={(e) => {
                        e.preventDefault();

                        setState({ ...state, job_type: "Part Time" });
                      }}
                    >
                      {translation.PART_TIME}
                    </button>
                    <button
                      className={
                        state.job_type === "Full Time"
                          ? `btn btn-lg btn-primary active`
                          : `btn btn-lg btn-default `
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setState({ ...state, job_type: "Full Time" });
                      }}
                    >
                      {translation.FULL_TIME}
                    </button>
                  </div>
                </div>

                <div className="col-md-6 regsiterrf">
                  <label htmlFor="inputEmail4" className="form-label fs-14">
                    {translation.REQUIRED_EXPERIENCE}
                  </label>
                  <Multiselect
                    className="form-select bg-transparent"
                    aria-label="Default select example"
                    showCheckbox={true} // To show checkbox in the dropdown
                    options={experienceState} // Options to display in the dropdown
                    selectedValues={state.selectedExperiences} // Preselected value to persist in dropdown
                    displayValue="name" // Property name to display in the dropdown options
                    onSelect={onExperienceChange}
                    onRemove={onExperienceChange}
                    placeholder={translation.SELECT}
                  />
                </div>

                <div className="col-md-6 regsiterrf mb-4">
                  <div>
                    <label className="form-label fs-14">{translation.WORK_LOCATION}</label>
                  </div>
                  <div className="swith_btn btn-group btn-toggle">
                    <button
                      className={
                        state.work_location.indexOf("OnSite") > -1
                          ? `btn btn-lg btn-primary active`
                          : `btn btn-lg btn-default`
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        changeWorkLocation("OnSite");
                      }}
                    >
                      {translation.ONSITE}
                    </button>
                    <button
                      className={
                        state.work_location.indexOf("Hybrid") > -1
                          ? `btn btn-lg btn-primary active`
                          : `btn btn-lg btn-default`
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        changeWorkLocation("Hybrid");
                      }}
                    >
                      {translation.HYBRID}
                    </button>
                    <button
                      className={
                        state.work_location.indexOf("Remote") > -1
                          ? `btn btn-lg btn-primary active`
                          : `btn btn-lg btn-default`
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        changeWorkLocation("Remote");
                      }}
                    >
                      {translation.REMOTE}
                    </button>
                  </div>
                </div>

                <div className="col-md-6 regsiterrf">
                  <label htmlFor="inputEmail4" className="form-label fs-14">
                    {translation.REQUIRED_LANGUAGE}
                  </label>
                  <Multiselect
                    className="form-select bg-transparent"
                    aria-label="Default select example"
                    showCheckbox={true} // To show checkbox in the dropdown
                    options={languageState} // Options to display in the dropdown
                    selectedValues={state.selectedLanguages} // Preselected value to persist in dropdown
                    displayValue="name" // Property name to display in the dropdown options
                    onSelect={onRequiredLanguageChange}
                    onRemove={onRequiredLanguageChange}
                    placeholder={translation.SELECT}
                  />
                  <div className="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                    {state.selectedLanguagesWithLevel.map(
                      (selected_language, index) => {
                        return (
                          <div className="skill_col me-3" key={index}>
                            <div className="d-flex justify-content-between">
                              <p className="m-0 fs-14">
                                {selected_language.language}
                              </p>
                              {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                            </div>

                            <div className="col-md-12 regsiterrf">
                              <select
                                className="form-select fs-14 fw-600 border-primary bg-transparent"
                                value={selected_language.level}
                                onChange={(e) =>
                                  onChangeLanguageLevel(
                                    selected_language.language,
                                    e.target.value
                                  )
                                }
                                aria-label="Default select example"
                              >
                                <option value="Beginner">{translation.BEGINNER}</option>
                                <option value="Competent">{translation.COMPETENT}</option>
                                <option value="Proficient">{translation.PROFICIENT}</option>
                                <option value="Expert">{translation.EXPERT}</option>
                              </select>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>

                <div className="col-md-6 regsiterrf mb-4">
                  <div>
                    <label className="form-label fs-14">
                      {translation.JOB_POSTING_LANGUAGE}
                    </label>
                  </div>
                  <div className="swith_btn btn-group btn-toggle">
                    <button
                      className={
                        state.job_posting_language === "English"
                          ? `btn btn-lg btn-primary active`
                          : `btn btn-lg btn-default`
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setState({
                          ...state,
                          job_posting_language: "English",
                        });
                      }}
                    >
                      English
                    </button>
                    <button
                      className={
                        state.job_posting_language === "Swedish"
                          ? `btn btn-lg btn-primary active`
                          : `btn btn-lg btn-default`
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setState({
                          ...state,
                          job_posting_language: "Swedish",
                        });
                      }}
                    >
                      Swedish
                    </button>
                    <button
                      className={
                        state.job_posting_language === "German"
                          ? `btn btn-lg btn-primary active`
                          : `btn btn-lg btn-default`
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        setState({
                          ...state,
                          job_posting_language: "German",
                        });
                      }}
                    >
                      German
                    </button>
                  </div>
                </div>

                <div className="col-md-6 regsiterrf">
                  <label htmlFor="inputEmail4" className="form-label fs-14">
                    {translation.AD_DURATION}
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputEmail4"
                    value={state.ad_duration}
                    onChange={(e) => {
                      setState({ ...state, ad_duration: e.target.value });
                    }}
                  />
                </div>

                <div className="col-md-12 regsiterrf">
                  <label htmlFor="inputEmail4" className="form-label fs-14">
                    {translation.MONTHLY_SALARY}
                  </label>
                  <select
                    className="form-select bg-transparent"
                    aria-label="Default select example"
                    value={state.salary}
                    onChange={handleSalaryChange}
                  >
                    <option value="">{translation.SELECT_SALARY}</option>
                    <option value="$1000 - $5000">$1000 - $5000</option>
                    <option value="$5000 - $10000">$5000 - $10000</option>
                    <option value="$10000 - $15000">$10000 - $15000</option>
                  </select>
                </div>

                <hr className="mt-4" />
                <div className="card_footer_btn d-flex justify-content-between pt-0 mt-0">
                  <a
                    href="#"
                    className="fw-600 fs-18"
                    onClick={() => {
                      props.handlePrevious();
                    }}
                  >
                    {translation.PREV}
                  </a>
                  <a
                    href="#"
                    className="fw-600 fs-18"
                    onClick={() => {
                      handleSaveAndNext();
                    }}
                  >
                    {translation.NEXT}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
