import React, { useState, useEffect } from "react";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import profile_banner_bg from "../../assets/img/profile_banner_bg.svg";
import default_profile_pic from "../../assets/img/profile_pic.svg";
import arrow_next_grey from "../../assets/img/arrow_next_grey.svg";
import saved_jobs from "../../assets/img/saved_jobs.svg";
import add_video from "../../assets/img/add_video.svg";
import upload_from_storage from "../../assets/img/upload_from_storage.svg";
import take_picture from "../../assets/img/take_picture.svg";
import set_status from "../../assets/img/set_status.svg";
import edit_cert from "../../assets/img/edit_cert.svg";
import play_video from "../../assets/img/play_video.svg";
import edit_profile from "../../assets/img/edit_profile.svg";
import create_ad from "../../assets/img/create_ad.svg";
import matched_profiles from "../../assets/img/matched_profiles.svg";
import { Link } from "react-router-dom";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import ReactTooltip from "react-tooltip";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";
import useTranslation from "../../customHooks/translations";

function RecuiterProfile() {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    checkData: true,
    full_name: "",
    profile_pic: "",
    city: "",
    country: "",
    email: "",
    phone_number: "",
    role: "",
    linkedin_profile: "",
    industry: "",
    department: "",
    company_city: "",
    company_country: "",
    company_name: "",
    company_size: "",
    company_registration_number: "",
    actively_hiring: false,
    about: "",
    dob: "",
  });
  const getRecruiterDetails = async () => {
    setState({ ...state, isProcessing: true, checkData: false });
    getWithAuthCallWithErrorResponse(ApiConfig.RECRUITER_DETAILS_API)
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
        } else {
          var full_name = data.json.user.full_name;
          var profile_pic = data.json.user.profile_pic;
          var city = data.json.user.city;
          var country = data.json.user.country;
          var email = data.json.user.email;
          var phone_number = data.json.user.phone_number;
          var role = data.json.role;
          var linkedin_profile = data.json.linkedin_profile;
          var industry = data.json.industry;
          var department = data.json.department;
          var company_city = data.json.city;
          var company_country = data.json.country;
          var company_name = data.json.company_name;
          var company_size = data.json.company_size;
          var company_registration_number =
            data.json.company_registration_number;
          var actively_hiring = data.json.actively_hiring;
          var dob = data.json.user.dob;
          var about = data.json.user.about;
          var joblist = data.json.job_list;
          var actively_hiring = data.json.actively_hiring
          setState({
            ...state,
            isProcessing: false,
            full_name: full_name,
            profile_pic: profile_pic,
            city: city,
            country: country,
            email: email,
            phone_number: phone_number,
            role: role,
            linkedin_profile: linkedin_profile,
            industry: industry,
            department: department,
            company_city: company_city,
            company_country: company_country,
            company_name: company_name,
            company_size: company_size,
            company_registration_number: company_registration_number,
            actively_hiring: actively_hiring,
            dob: dob,
            about: about,
            joblist: joblist,
            actively_hiring: actively_hiring,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };

  useEffect(() => {
    getRecruiterDetails();
  }, []);

  const activelyHiring = async (option_value) => {
    setState({ ...state, isProcessing: true, checkData: false });
    let requestBody = JSON.stringify({
      option: option_value,
    });
    postWithAuthCallWithErrorResponse(
      ApiConfig.RECRUITER_HIRING_STATUS_API,
      requestBody
    )
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
        } else {
          setState({
            ...state,
            isProcessing: false,
            actively_hiring: option_value == "no" ? false : true,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };

  return (
    <>
      <RecruiterHeader />
      <ReactTooltip />
      <SpinnerCmp loading={state.isProcessing} />
      <section
        id="profile"
        className="profile"
        style={{ display: state.isProcessing ? "none" : "" }}
      >
        <div className="container">
          <div className="row">
            <div className="">
              <img src={profile_banner_bg} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card profile_lfcard">
                <div className="card-body">
                  <div className="profile_cardimg text-center">
                    <img
                      src={
                        state.profile_pic
                          ? state.profile_pic
                          : default_profile_pic
                      }
                      alt=""
                      style={{ width: "94px", height: "94px" }}
                    />
                  </div>
                  <div className="text-center mt-4">
                    <h3 className="fw-600 fs-18">{state.full_name}</h3>
                    <p className="fs-14">
                      {state.about}
                    </p>
                  </div>
                  <div className="profile_actionbtn text-center py-3">
                    <Link to="/recruiter-saved-profiles"
                      state={{ isFrom: "Saved" }}
                      className="btn rounded-circle"
                      data-tip={translation.SAVED_PROFILES}
                    >
                      <img src={saved_jobs} alt="" />
                    </Link>
                    {/* <button
                      type="button"
                      className="btn rounded-circle"
                      data-tip={translation.SAVED_PROFILES}
                    >
                      <img src={saved_jobs} alt="" />
                    </button> */}
                    {/* <div className="btn-group profilebtn_block">
                      <button
                        type="button"
                        data-tip="Upload Intro Video"
                        className="btn dropdown-toggle rounded-circle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={add_video} alt="" />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li className="profileaction_bg outlineaction_btn">
                          <p className="m-0 mb-3 text-white">
                            Upload Intro Video
                          </p>
                          <button type="button" className="btn open_actionbtn">
                            <img src={upload_from_storage} alt="" />
                          </button>
                          <button
                            type="button"
                            className="btn open_actionbtn active"
                            data-bs-toggle="modal"
                            data-bs-target="#ProfileVideosModal"
                          >
                            <img src={take_picture} alt="" />
                          </button>
                        </li>
                      </ul>
                    </div> */}

                    <div className="btn-group profilebtn_block">
                      <button
                        type="button"
                        data-tip={translation.ARE_YOU_ACTIVELY_HIRING}
                        className="btn dropdown-toggle rounded-circle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={set_status} alt="" />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li className="profileaction_bg">
                          <p className="m-0 mb-3 text-white">
                            {translation.ARE_YOU_ACTIVELY_HIRING}
                          </p>
                          <button
                            type="button"
                            className={
                              state.actively_hiring == false
                                ? "btn open_actionbtn active"
                                : "btn open_actionbtn"
                            }
                            onClick={() => activelyHiring("no")}
                          >
                            {translation.NO}
                          </button>
                          <button
                            type="button"
                            className={
                              state.actively_hiring == true
                                ? "btn open_actionbtn active"
                                : "btn open_actionbtn"
                            }
                            // data-bs-toggle="offcanvas"
                            // href="#offcanvasSearchFilter"
                            // aria-controls="offcanvasSearchFilter"
                            href="#"
                            onClick={() => activelyHiring("yes")}
                          >
                            {translation.YES}
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {state.actively_hiring && <>
                    <hr className="mt-2 mb-4" />
                    <div className="d-flex justify-content-between  align-items-center">
                      <div>
                        <h4 className="fs-16 mb-1">{translation.ACTIVELY_HIRING}</h4>
                        <p className="fs-14 m-0">{state.joblist && state.joblist.join(",")}</p>
                      </div>
                      <div>
                        <Link to="/recruiter-ad-listing">
                          <img src={edit_cert} alt="" />
                        </Link>
                      </div>
                    </div>
                  </>
                  }

                  <hr className="mt-2 mb-4" />
                  <div className="d-flex justify-content-between">
                    <div>
                      <h4 className="fs-16 mb-1">
                        {translation.CREATE_NEW_JOB_AD}
                      </h4>
                    </div>
                    <div>
                      <Link to="/create-new-job">
                        <img
                          src={create_ad}
                          alt=""
                          data-tip={translation.CREATE_NEW_JOB_AD}
                        />
                      </Link>
                    </div>
                  </div>
                  <hr className="mt-2 mb-4" />
                  <div className="d-flex justify-content-between">
                    <div></div>
                    <div>
                      <Link to="/recruiter-ad-listing">
                        {/* <img src={arrow_next_grey} alt="" data-tip="See All Ads" /> */}
                        {translation.SEE_ALL_ADS}
                      </Link>
                    </div>
                  </div>
                  {/* <hr className="my-4" /> */}
                  {/* <div className="d-flex justify-content-between">
                    <h4 className="fs-16 m-0">
                      Play intro video{" "}
                      <span className="fs-14 ms-2">(10:30)</span>
                    </h4>
                    <a href="#">
                      <img src={play_video} alt="" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="profile_rfcard pt-5 px-4">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="BasicInfo-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#BasicInfo"
                      type="button"
                      role="tab"
                      aria-controls="BasicInfo"
                      aria-selected="true"
                    >
                      {translation.BASIC_INFO}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Education-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Education"
                      type="button"
                      role="tab"
                      aria-controls="Education"
                      aria-selected="false"
                    >
                      {translation.COMPANY}
                    </button>
                  </li>
                  {/* <!-- <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="Jobs-tab" data-bs-toggle="tab" data-bs-target="#Jobs" type="button" role="tab" aria-controls="Jobs" aria-selected="false">Jobs</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="Prerequisite-tab" data-bs-toggle="tab" data-bs-target="#Prerequisite" type="button" role="tab" aria-controls="Prerequisite" aria-selected="false">Prerequisite</button>
                                        </li> --> */}
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="BasicInfo"
                    role="tabpanel"
                    aria-labelledby="BasicInfo-tab"
                  >
                    <div className="d-flex justify-content-end mb-4">
                      <div
                        className="profile_actionbtn"
                        data-tip={translation.EDIT_BASIC_INFO}
                      >
                        <Link to="/recruiter-edit-basic-info">
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px", height: "42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.LOCATION}</p>
                      <p className="m-0 profile_role">
                        {state.city + ", " + state.country}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.EMAIL}</p>
                      <p className="m-0 profile_role">{state.email}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.MOBILE_NUMBER}</p>
                      <p className="m-0 profile_role">{state.phone_number}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0"> {translation.DATE_OF_BIRTH}</p>
                      <p className="m-0 profile_role">{state.dob}</p>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Education"
                    role="tabpanel"
                    aria-labelledby="Education-tab"
                  >
                    <div className="d-flex justify-content-end mb-4">

                      <div className="profile_actionbtn">
                        <Link
                          to="/recruiter-edit-company-info"
                          data-tip={translation.EDIT_COMPANY_INFO}
                        >
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px", height: "42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.INDUSTRY}</p>
                      <p className="m-0 profile_role">{state.industry}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.DEPARTMENT}</p>
                      <p className="m-0 profile_role">{state.department}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.JOB_TITLE}</p>
                      <p className="m-0 profile_role">{state.role}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.CITY}</p>
                      <p className="m-0 profile_role">{state.company_city}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.COUNTRY}</p>
                      <p className="m-0 profile_role">
                        {state.company_country}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.COMPANY_NAME}</p>
                      <p className="m-0 profile_role">{state.company_name}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.COMPANY_SIZE}</p>
                      <p className="m-0 profile_role">{state.company_size}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">
                        {translation.VAT_COMPANY_REGISTRATION_NUMBER}
                      </p>
                      <p className="m-0 profile_role">
                        {state.company_registration_number}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">
                        {translation.COMPANY_LINKEDIN_PROFILE}
                      </p>
                      <a
                        href={state.linkedin_profile}
                        target={"_blank"}
                        className="profile_role"
                      >
                        {state.linkedin_profile}
                      </a>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Jobs"
                    role="tabpanel"
                    aria-labelledby="Jobs-tab"
                  >
                    {translation.JOBS}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Prerequisite"
                    role="tabpanel"
                    aria-labelledby="Prerequisite-tab"
                  >
                    {translation.PREREQUISITES}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RecuiterProfile;
