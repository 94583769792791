import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import chevron_right_colored from "../assets/img/chevron_right_colored.svg";
import { simplePostCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import useTranslation from "../customHooks/translations";

const ForgotPassword = (props) => {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    userID: "",
    errorMsg: "",
  });

  const forgotPassword = async () => {
    let requestBody = JSON.stringify({
      user_id: state.userID,
    });

    simplePostCallWithErrorResponse(ApiConfig.FORGOT_PASSWORD_API, requestBody)
      .then((data) => {
        setState({ ...state, isProcessing: true });
        if (data.error == true) {
          setState({
            ...state,
            isProcessing: false,
            errorMsg: data.json.message,
          });
        } else {
          localStorage.setItem("forgetPword_UserID", state.userID);
          localStorage.setItem("forgetPword_OTP", data.json.otp);

          window.location.href = "/forgot-password-otp";
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };
  return (
    <>
      <section id="login" className="login">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 g-0 p-0">
              {/* <div className="login_ltblock text-center">
                                    <img src="img/logo.png" alt="">
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p>
                                </div> */}


              <div className="doctor_leftimg">
                <div className="overlay_content text-center">
                  <img src={logo} className="mb-3" />
                  <p className="m-t-25 text-white">{translation.PRODUCT_INTRO}</p>
                </div>
                {/* <div className="overlay_logo">
                                        <img src="img/logo.png">
                                    </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="login_rtblock position-relative pt-4">
                <div className="d-flex justify-content-end">
                  <Link to="/login" className="fw-600">
                    {translation.GO_BACK}
                  </Link>
                </div>

                <div className="form_block forgot_pass">
                  <div className="text-center">
                    <h2>{translation.FORGOT_PASSWORD_HEADING}</h2>
                    <p className="fs-14 mt-3 mb-4">
                      {translation.ENTER_YOUR_MOBILE_NUMBER_OR_EMAIL_ID_TO_CONTINUE}
                    </p>
                  </div>

                  <form action="action" className="form_block pt-0">
                    <div className="regsiterrf text-center">
                      <input
                        type="text"
                        placeholder={translation.PH_EMAIL_PHONE_NUMBER}
                        className="form-control"
                        id="inputEmail4"
                        value={state.userID}
                        onChange={(e) => {
                          setState({ ...state, userID: e.target.value });
                        }}
                      />
                    </div>
                  </form>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <span
                      className="fs-14"
                      style={{ float: "left", color: "red", width: "100%" }}
                      id="error_msg"
                    >
                      {state.errorMsg}
                    </span>
                  </div>
                </div>
              </div>

              <div className="loginwithlinks">
                <div className="d-lg-flex d-md-flex justify-content-end">
                  <div className="loginwithbtn">
                    <p className="fs-22 fw-600 text-primary">
                      {translation.CONTINUE}
                      {/* to="/forgot-password-otp" */}
                      <a onClick={forgotPassword}>
                        <button
                          type="button"
                          className="btn btn-outline-primary rounded-circle ms-2"
                        >
                          <img src={chevron_right_colored} alt="" />
                        </button>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ForgotPassword;
