import React, { useState, useEffect } from "react";
import Header from "../../sharedComponents/Header";
import explore_image from "../../assets/img/explore_image.svg";
import { Link, useLocation } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";
import { Buffer } from "buffer";
import useTranslation from "../../customHooks/translations";

function Explore() {
  const translation = useTranslation();
  const filterData = useLocation().state;
  const [state, setState] = useState({
    isProcessing: false,
    industryList: [],
  });

  const getExploreList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.EXPLORE_JOB_SEEKER)
      .then((data) => {
        setState({ ...state, isProcessing: false, industryList: data.json });
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  useEffect(() => {
    getExploreList();
  }, []);
  useEffect(() => {
    if (filterData) {
      // let requestBody = JSON.stringify(filterData);
      // console.log(requestBody, "requestBody");
      // postWithAuthCallWithErrorResponse(
      //   ApiConfig.FILTER_JOB_SEEKER,
      //   requestBody
      // ).then((data) => {
      //   if (data.error !== true) {
      //     setState({ ...state, isProcessing: false, industryList: data.json });
      //   } else {
      //     alert(data.json.message);
      //   }
      // });
    }
  }, [filterData]);

  return (
    <>
      <Header />
      <section id="explore" class="explore">
        <div class="container">
          <div class="row explore_card">
            <div class="col-lg-8 pt-5">
              <div class="explore_banner">
                <h2 class="text-white fs-24 fw-600">
                  {translation.LOOKING_FOR_A_JOB}
                </h2>
                <p class="text-white">{translation.YOUR_PREFERRED_INDUSTRY}</p>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="explore_img">
                <img src={explore_image} alt="" />
              </div>
            </div>
          </div>
          <SpinnerCmp loading={state.isProcessing} />

          <div
            class="row"
            style={{ display: state.isProcessing ? "none" : "" }}
          >
            <div class="col-lg-12">
              <div class="job_cards mt-5">
                {state.industryList.map((jobrole, index) => {
                  var id_string = jobrole.name + "--" + jobrole.id;
                  const id = Buffer.from(id_string).toString("base64");
                  return (
                    <div className="card explorejob_card" key={index}>
                      <div className="card-body">
                        <p>{jobrole.name}</p>

                        <Link
                          to={"/Explore_Jobs?industry=" + jobrole.name}
                          className="stretched-link"
                        ></Link>
                      </div>
                    </div>
                  );
                })}

                {/* <div class="card explorejob_card">
                  <div class="card-body">
                    <p>Retail</p>
                    
                    <Link to="/Explore_Jobs" class="stretched-link"></Link>
                  </div>
                </div>
                <div class="card explorejob_card">
                    <div class="card-body">
                      <p>Finance</p>
                      <Link to="/Explore_Jobs" class="stretched-link"></Link>
                    </div>
                </div>
                <div class="card explorejob_card">
                    <div class="card-body">
                      <p>IT</p>
                      <Link to="/Explore_Jobs" class="stretched-link"></Link>
                    </div>
                </div>
                <div class="card explorejob_card">
                    <div class="card-body">
                      <p>Healthcare</p>
                      <Link to="/Explore_Jobs" class="stretched-link"></Link>
                    </div>
                </div>
                <div class="card explorejob_card">
                    <div class="card-body">
                      <p>Agriculture</p>
                      <Link to="/Explore_Jobs" class="stretched-link"></Link>
                    </div>
                </div>
                <div class="card explorejob_card">
                    <div class="card-body">
                      <p>Insurance</p>
                      <Link to="/Explore_Jobs" class="stretched-link"></Link>
                    </div>
                </div>
                <div class="card explorejob_card">
                    <div class="card-body">
                      <p>Food</p>
                      <Link to="/Explore_Jobs" class="stretched-link"></Link>
                    </div>
                </div>
                <div class="card explorejob_card">
                    <div class="card-body">
                      <p>Retail</p>
                      <Link to="/Explore_Jobs" class="stretched-link"></Link>
                    </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Explore;
