import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../../sharedComponents/Header";
import { getWithAuthCallWithErrorResponse, deleteMultipartWithAuthCallWithErrorResponse, putMultipartWithAuthCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Country from "../../../assets/CountrtiesData/Country";
import listContryCity from "../../../assets/CountrtiesData/CoutrycityList";
import Select from "react-select";
import SpinnerCmp from "../../../sharedComponents/SpinnerCmp";
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import chevron_right_colored from "../../../assets/img/chevron_right_colored.svg";
import { Plus } from "react-feather";
import Add_Experiance from "./Add_Experiance";
import useTranslation from "../../../customHooks/translations";
toast.configure()

export default function () {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    errMsg: "",
    company_name: "",
    role: "",
    id: "",
    job_type: "",
    experience_level: "",
    country: "",
    city: "",
    start_year: "",
    end_year: "",
    is_present: "",
    selected_Country: {},
    selected_City: {},
    errorMsg: {},
    TodayDate_yyyy_mm_dd: moment().format("YYYY"),
  });

  const [experiance, setExperiance] = useState({
    isProcessing: false,
    errMsg: {},
    company_name: "",
    role: "",
    job_type: "",
    experience_level: "",
    country: "",
    city: "",
    is_present: "",
    selected_Country: {},
    selected_City: {},
    errorMsg: {},
  });

  const [countriesState, setCountriesState] = useState();
  const [citiesState, setCitiesState] = useState();
  const [jobtype, setJobType] = useState();
  const [joblist, setJobList] = useState();
  const [expList, setExpList] = useState();

  // GET 
  const getSeekerDetails = async () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.GET_JOB_SEEKER_PROFILE)
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
        } else {
          var full_name = data.json.user.full_name;
          var city = data.json.user.city;
          var country = data.json.user.country;
          var email = data.json.user.email;
          var phone_number = data.json.user.phone_number;
          var dob = data.json.user.dob;
          var about = data.json.user.about;
          var country_code = data.json.user.country_code;
          var selected_dob = dob.split("-").reverse().join("-");
          setState({
            ...state,
            full_name: full_name,
            city: city,
            country: country,
            selected_Country: { label: country, value: country },
            selected_City: { label: city, value: city },
            email: email,
            phone_number: phone_number,
            dob: dob,
            selected_dob: selected_dob,
            about: about,
            country_code: country_code,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };
  const notify = () => {
    // Calling toast method by passing string
    toast.success(translation.DATA_UPDATE_SUCCESSFULLY, { autoClose: 1000 });
  };

  // END GET

  const GetExperiance = () => {
    setExperiance({ ...experiance, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.EXPERIENCE_API)
      .then((data) => {
        if (data.error == true) {
          setExperiance({ ...experiance, isProcessing: false });
        } else {
          var city = data.json.city;
          var country = data.json.country;
          setExperiance(data.json);
        }
      })
      .catch((error) => {
        setExperiance({ ...experiance, isProcessing: false });
      });
  };


  // Job Type start
  const getJobType = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.JOBTYPE_LISTING_API)
      .then((data) => {
        setJobType(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Job Type End
  // Job List start
  const getJobList = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.JOBROLE_LISTING_API)
      .then((data) => {
        setJobList(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Job List End
  // Experiance List start
  const getExperianceList = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.EXPERIENCE_LISTING_API)
      .then((data) => {
        setExpList(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteExperience = (id) => {
    deleteMultipartWithAuthCallWithErrorResponse(ApiConfig.EXPERIENCE_API + id + "/")
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
        } else {
          toast.success(data.json.message, { autoClose: 1000 })
          setState({ ...state, errorMsg: data });
          GetExperiance();
        }
      })
      .catch((error) => {
        console.log("api response", error);
      }
      );

  }
  /* city section starts */
  function getCitiesList(selected_country) {
    Object.keys(listContryCity.listContryCity).forEach(function (key, index) {
      var newJson = listContryCity.listContryCity[key];
      if (newJson.country == selected_country) {
        updateCity(newJson.city);
      }
    });
  }

  function updateCity(data) {
    let temp_cities = [];
    data.map((item, index) => {
      temp_cities.push({ label: item, value: item });
    });
    setCitiesState(temp_cities);
  }

  function onCityChange(value) {
    setState({ ...state, selected_City: value, city: value.label });
  }
  /* city section ends */

  /* country section starts */
  const selectStyles = {
    control: (provided) => ({ ...provided, backgroundColor: 'transparent', borderRadius: "8px", border: "1px solid #b3c2c9", fontSize: "13px" }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)", backgroundColor: 'transparent' }),
  };

  function getCountriesList() {
    setCountriesState(Country.Country);
  }

  function onCountryChange(value) {
    setState({ ...state, selected_Country: value, country: value.label });
    getCitiesList(value.label);
  }
  /* country section ends */

  // EDIT EXPERIENCE
  const editExperianceDetails = async (id) => {
    if (
      state.company_name == "" ||
      state.role == "" ||
      state.job_type == "" ||
      state.experience_level == "" ||
      state.country == "" ||
      state.city == "" ||
      state.start_year == "" ||
      state.end_year == "" ||
      state.company_name == null ||
      state.role == null ||
      state.job_type == null ||
      state.experience_level == null ||
      state.country == null ||
      state.city == null ||
      state.start_year == null ||
      state.end_year == null

    ) {
      if (state.company_name == "" || state.company_name == null) {
        setState({
          ...state,
          errMsg: { company_name: translation.PLEASE_ENTER_COMPANY_NAME },
        });
      } else if (state.role == "" || state.role == null) {
        setState({
          ...state,
          errMsg: { role: translation.PLEASE_SELECT_JOB_TITLE },
        });
      } else if (state.job_type == "" || state.job_type == null) {
        setState({
          ...state,
          errMsg: { job_type: translation.PLEASE_SELECT_JOB_TYPE },
        });
      } else if (
        state.experience_level == "" ||
        state.experience_level == null
      ) {
        setState({
          ...state,
          errMsg: { experience_level: translation.SELECT_EXPERIENCE },
        });
      } else if (state.country == "" || state.country == null) {
        setState({
          ...state,
          errMsg: { country: translation.SELECT_COUNTRY },
        });
      } else if (state.city == "" || state.city == null) {
        setState({
          ...state,
          errMsg: { city: translation.SELECT_CITY },
        });
      } else if (state.start_year == "" || state.start_year == null) {
        setState({
          ...state,
          errMsg: { start_year: translation.SELECT_START_YEAR },
        });
      } else if (state.end_year == "" || state.end_year == null) {
        setState({
          ...state,
          errMsg: { end_year: translation.SELECT_END_YEAR },
        });
      }
    } else {
      let formData = new FormData();
      formData.append("company_name", state.company_name);
      formData.append("role", state.role);
      formData.append("job_type", state.job_type);
      formData.append("experience_level", state.experience_level);
      formData.append("country", state.country);
      formData.append("city", state.city);
      formData.append("start_year", state.start_year);
      formData.append("end_year", state.end_year);

      putMultipartWithAuthCallWithErrorResponse(ApiConfig.EXPERIENCE_API + id + "/", formData)
        .then((data) => {
          if (data.error == true) {
            setState({ ...state, isProcessing: false });
            window.location.href = "/Edit_Experiance";
          } else {
            toast.success(data.json.message, { autoClose: 1000 })
            setState({ ...state, errorMsg: {} });
            GetExperiance();
          }
        })
        .catch((error) => {
          console.log("api response", error);
        });
    }
  };
  // END EDIT EXPERIENCE
  useEffect(() => {
    getCountriesList();
    getJobType();
    getJobList();
    getExperianceList();
    GetExperiance();
    getSeekerDetails();

  }, []);

  /* Add Experiance */




  return (
    <div>
      <Header />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/Seeker_Profile">{translation.PROFILE}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.ADD_EXPERIENCE}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative">
            <div class="col-lg-6 col-md-12 offset-lg-3">
              <Add_Experiance GetExperiance={GetExperiance} />
              <div class="card login settings_card mt-5 ">
                <div class="card-body">
                  <div class="AddEducationLevel pt-0 w-100">
                    <div class="d-flex justify-content-end">
                      <p class="text-primary">
                        {translation.ADD_EXPERIENCE + " "}
                        <a
                          class="addlevel ms-2"
                          data-bs-toggle="offcanvas"
                          href="#offcanvasAddEducationa"
                          role="button"
                          aria-controls="offcanvasAddEducation"
                        >
                          <Plus />
                        </a>
                      </p>
                    </div>
                    <SpinnerCmp loading={state.isProcessing} />

                    <div class="edulevels" style={{ marginLeft: " 25px" }}>

                      {experiance.length > 0
                        ? experiance.map((list) => (
                          <div className="levelcircle active mt-4">
                            <h3 className="fs-16">
                              {list.role}
                            </h3>
                            <p className="fs-14 m-0">
                              {list.company_name} . {list.experience_level}
                            </p>
                            <p className="fs-14 m-0">
                              {list.start_year} . {list.end_year}
                            </p>
                            <div id="edit-delete-btn">
                              <a
                                class="addlevel ms-2  text-primary"
                                href="#"
                                role="button"
                                onClick={() => deleteExperience(list.id)}
                              >
                                {translation.DELETE}
                              </a>
                              <a
                                class="addlevel ms-2 text-primary"
                                data-bs-toggle="offcanvas"
                                href="#offcanvasAddEducation"
                                role="button"
                                aria-controls="offcanvasAddEducation"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    company_name: list.company_name,
                                    role: list.role,
                                    job_type: list.job_type,
                                    experience_level:
                                      list.experience_level,
                                    country: list.country,
                                    city: list.city,
                                    start_year: list.start_year,
                                    end_year: list.end_year,
                                    id: list.id



                                  });
                                }}
                              >
                                {translation.EDIT}
                              </a>
                            </div>
                          </div>
                        ))
                        : translation.NO_RECORDS_FOUND}


                      {/* <div class=" d-flex justify-content-between mt-4">
                        <div></div>
                        <p className="text-primary">
                          <a
                            class="addlevel ms-2 text-primary"
                            data-bs-toggle="offcanvas"
                            href="#offcanvasEditEducation"
                            role="button"
                            aria-controls="offcanvasAddEducation"
                          >
                            <img src={edit_profile} alt="" />
                          </a>
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div class="loginwithlinks editprofile_block">
                <div class="d-lg-flex d-md-flex justify-content-end">
                  <div class="loginwithbtn">
                    <p class="fs-22 fw-600 text-primary">
                      {translation.UPDATE + " "}
                      <Link to="/Seeker_Profile">
                        <button
                          type="button"
                          class="btn btn-outline-primary rounded-circle ms-2"
                        >
                          <img src={chevron_right_colored} lt="" />
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* edit experence */}
      <div
        class="AddLevelCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasAddEducation"
        aria-labelledby="offcanvasAddEducationLabel"
      >
        <div class="offcanvas-header">
          <h5
            class="offcanvas-title fs-16 fw-700"
            id="offcanvasAddEducationLabel"
          >
            {translation.EDIT_EXPERIENCE}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <form class="row g-3 text-start">
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.COMPANY_NAME}
              </label>
              <input
                type="text"
                class="form-control border-primary"
                id="inputEmail4"
                value={state.company_name}
                onChange={(e) => {
                  setState({ ...state, company_name: e.target.value });
                }}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.company_name ? state.errMsg.company_name : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.JOB_TITLE}
              </label>
              <select
                type="text"
                placeholder={translation.ADD_SKILLS}
                value={state.role}
                onChange={(e) => {
                  setState({ ...state, role: e.target.value });
                }}
                class="form-select border-primary"
                aria-label="Default select example"
              >
                <option value="">{translation.PLEASE_SELECT_JOB_TITLE}</option>
                {joblist && joblist.length > 0
                  ? joblist.map((result, index) => {
                    return <option>{result.name}</option>;
                  })
                  : null}
              </select>
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.role
                  ? state.errMsg.role
                  : ""}
              </span>
            </div>

            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.JOB_TYPE}
              </label>
              <select
                type="text"
                placeholder={translation.ADD_SKILLS}
                value={state.job_type}
                class="form-select border-primary"
                aria-label="Default select example"
                onChange={(e) => {
                  setState({ ...state, job_type: e.target.value });
                }}
              >
                <option value="">{translation.PLEASE_SELECT_JOB_TYPE}</option>
                {jobtype && jobtype.length > 0
                  ? jobtype.map((result, index) => {
                    return <option>{result.name}</option>;
                  })
                  : null}
              </select>
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.job_type
                  ? state.errMsg.job_type
                  : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.EXPERIANCE}
              </label>
              <select
                type="text"
                value={state.experience_level}
                onChange={(e) => {
                  setState({ ...state, experience_level: e.target.value });
                }}
                class="form-select border-primary"
                aria-label="Default select example"
              >
                <option value="">{translation.PLEASE_SELECT_JOB_EXPERIENCE}</option>
                {expList && expList.length > 0
                  ? expList.map((result, index) => {
                    return <option>{result.level}</option>;
                  })
                  : null}
              </select>
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.experience_level
                  ? state.errMsg.experience_level
                  : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.COUNTRY}
              </label>
              <Select
                isClearable={false}
                onChange={onCountryChange}
                options={countriesState}
                placeholder={translation.SEARCH}
                styles={selectStyles}
                //tabSelectsValue={false}
                value={state.selected_Country}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.country
                  ? state.errMsg.country
                  : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.CITY}
              </label>
              <Select
                isClearable={false}
                onChange={onCityChange}
                options={citiesState}
                placeholder={translation.SEARCH}
                styles={selectStyles}
                //tabSelectsValue={false}
                value={state.selected_City}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.city
                  ? state.errMsg.city
                  : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.START_YEAR}
              </label>
              <input
                type="date"
                class="form-control border-primary"
                id="inputPassword4"
                value={state.start_year}
                onChange={(e) => {
                  setState({ ...state, start_year: e.target.value });
                }}
              />
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.END_YEAR}
              </label>
              <input
                type="date"
                class="form-control border-primary"
                id="inputPassword4"
                value={state.end_year}
                onChange={(e) => {
                  setState({ ...state, end_year: e.target.value });
                }}
              />
            </div>
          </form>
        </div>
        <div class="educationcanvas-footer">
          <div class="d-flex justify-content-end">
            <a href="#" class=" fs-16 fw-600 me-3 p-2" onClick={() => editExperianceDetails(state.id)}>
              {translation.UPDATE}
            </a>
          </div>
        </div>
      </div>
      {/* end edit experience */}
    </div>
  );
}

