import React, { useState, useContext } from "react";
import authContext from "./GetUserStatus";
import Logo from "../assets/img/logo.png";
import Otp_verification_image from "../assets/img/otp_verification_image.svg";
import Chevron_right_colored from "../assets/img/chevron_right_colored.svg";
import { NavLink } from "react-router-dom";
import { simplePostCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import useTranslation from "../customHooks/translations";

function Otp_register() {
  const translation = useTranslation();
  const { setAuthenticated } = useContext(authContext);
  const [state, setState] = useState({
    isProcessing: false,
    otpDigit1: "",
    otpDigit2: "",
    otpDigit3: "",
    otpDigit4: "",
    errorMsg: "",
    resendOTPerrorMsg: "",
    resendOTPsuccessMsg: "",
  });

  const verifyOtp = async () => {
    var otp = state.otpDigit1 + state.otpDigit2 + state.otpDigit3 + state.otpDigit4;
    let requestBody = JSON.stringify({
      phone_number: localStorage.getItem("PhNo"),
      otp: otp,
    });

    simplePostCallWithErrorResponse(ApiConfig.OTP_VERIFICATION_API, requestBody)
      .then((data) => {
        setState({ ...state, isProcessing: true });
        if (data.error == true) {
          setState({
            ...state,
            isProcessing: false,
            errorMsg: data.json.message,
          });
        } else {
          const lang = localStorage.getItem("language")
            ? localStorage.getItem("language")
            : "IN_en";
          localStorage.clear();
          localStorage.setItem("language", lang);
          localStorage.setItem("successMSG", data.json.message);
          if (data.json.user.role == "Job_seeker") {
            localStorage.setItem("USER_ID", data.json.user.id);
            localStorage.setItem("USER_FULL_NAME", data.json.user.full_name);
            localStorage.setItem(
              "USER_PROFILE_PIC",
              data.json.user.profile_pic
            );
            localStorage.setItem("USER_EMAIL", data.json.user.email);
            localStorage.setItem("USER_MOBILE", data.json.user.phone_number);
            localStorage.setItem("USER_ROLE", data.json.user.role);
            localStorage.setItem("USER_CITY", data.json.user.city);
            localStorage.setItem("USER_COUNTRY", data.json.user.country);
            localStorage.setItem("USER_AUTH_TOKEN", data.json.user.auth_token);

            setAuthenticated(true);
            localStorage.setItem("is_logged_in", true);

            window.location.href = "/profile";
          } else {
            window.location.href = "/login";
          }
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };

  const resendOTP = async () => {
    let requestBody = JSON.stringify({
      phone_number: localStorage.getItem("PhNo"),
    });

    simplePostCallWithErrorResponse(ApiConfig.RESEND_OTP_API, requestBody)
      .then((data) => {
        setState({ ...state, isProcessing: true });
        if (data.error == true) {
          setState({
            ...state,
            isProcessing: false,
            resendOTPerrorMsg: data.json.phone_number,
          });
        } else {
          setState({
            ...state,
            isProcessing: false,
            resendOTPsuccessMsg: data.json.message + "-" + data.json.otp,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };

  const handleEnter = (event) => {
    var validkeys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    if (validkeys.includes(event.key)) {
      event.target.value = event.key;
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1] && form.elements[index + 1].focus();
      event.preventDefault();
    }
  };
  return (
    <section id="login" className="login">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-12 g-0 p-0">
            {/* <!-- <div className="login_ltblock text-center">
                  <img src="img/logo.png" alt="">
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p>
                </div> --> */}

            <div className="doctor_leftimg">
              <div className="overlay_content text-center">
                <img src={Logo} className="mb-3" alt="" />
                <p className="m-t-25 text-white">
                  {translation.PRODUCT_INTRO}
                </p>
              </div>
              {/* <!-- <div className="overlay_logo">
                        <img src="img/logo.png">
                    </div> --> */}
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="login_rtblock position-relative pt-4">
              <div className="d-lg-flex d-md-flex justify-content-end">
                <p>
                  {translation.DONT_HAVE_AN_ACCOUNT}{" "}
                  <NavLink to="/register" className="fw-600">
                    {translation.REGISTER}
                  </NavLink>
                </p>
              </div>

              <div className="form_block">
                <div className="text-center">
                  <h2 className="fs-18 fw-600">
                    {translation.OTP_VERIFICATION}
                  </h2>
                  <p className="fs-14 mt-3 mb-4">
                    {localStorage.getItem("MESSAGE")
                      ? localStorage.getItem("MESSAGE")
                      : "Please Enter OTP"}
                    - ( {localStorage.getItem("OTP")} )
                  </p>

                  <img src={Otp_verification_image} alt="" />

                  <form action="#" className="otp_box my-5">
                    <input className="otp" type="text" value={state.otpDigit1} maxLength='1' required=""
                      onKeyDown={(e) => {
                        if (String(e.key).match("^[0-9]+$")) {
                          setState({ ...state, otpDigit1: e.key });
                          handleEnter(e)
                        }
                      }} />
                    <input className="otp" type="text" value={state.otpDigit2} maxLength='1' required=""
                      onKeyDown={(e) => {
                        if (String(e.key).match("^[0-9]+$")) {
                          setState({ ...state, otpDigit2: e.key });
                          handleEnter(e)
                        }
                      }} />
                    <input className="otp" type="text" value={state.otpDigit3} maxLength='1' required=""
                      onKeyDown={(e) => {
                        if (String(e.key).match("^[0-9]+$")) {
                          setState({ ...state, otpDigit3: e.key });
                          handleEnter(e)
                        }
                      }} />
                    <input className="otp" type="text" value={state.otpDigit4} maxLength='1' required=""
                      onKeyDown={(e) => {
                        if (String(e.key).match("^[0-9]+$")) {
                          setState({ ...state, otpDigit4: e.key });
                          handleEnter(e)
                        }
                      }} />
                    <div style={{ width: "100%", textAlign: "center" }}>
                      <span
                        className="fs-14"
                        style={{ float: "left", color: "red", width: "100%" }}
                        id="error_msg"
                      >
                        {state.errorMsg}
                      </span>
                    </div>
                  </form>
                  <p>
                    {translation.DIDNT_RECEIVE_CODE}{" "}
                    <a
                      className="fw-600"
                      style={{ cursor: "pointer" }}
                      onClick={resendOTP}
                    >
                      {translation.RESEND}{" "}
                    </a>
                  </p>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <span
                      className="fs-14"
                      style={{ float: "left", color: "red", width: "100%" }}
                    >
                      {state.resendOTPerrorMsg}
                    </span>
                    <span
                      className="fs-14"
                      style={{ float: "left", color: "#2ddf2d", width: "100%" }}
                    >
                      {state.resendOTPsuccessMsg}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="loginwithlinks">
              <div className="d-lg-flex d-md-flex justify-content-end">
                <div className="loginwithbtn">
                  <p className="fs-22 fw-600 text-primary">
                    {translation.LOGIN}{" "}
                    <button
                      type="button"
                      className="btn btn-outline-primary rounded-circle ms-2"
                      onClick={verifyOtp}
                    >
                      <img src={Chevron_right_colored} alt="" />
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Otp_register;
