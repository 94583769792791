import React, { useEffect, useState } from "react";
import Header from "../../sharedComponents/Header";
import Bookmark from "../../assets/img/bookmark.svg";
import close_job from "../../assets/img/close_job.svg";
import check_job from "../../assets/img/check_job.svg";
import job_logo from "../../assets/img/job_logo.png";
import ReactTooltip from "react-tooltip";
import { Link, useLocation } from "react-router-dom";
import { Buffer } from "buffer";
import TinderCard from "react-tinder-card";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import useTranslation from "../../customHooks/translations";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";

const JobCard = (props) => {
  const translation = useTranslation();
  const [jobData, setProfileData] = useState(props.jobData);
  const onSwipe = (direction) => {
    if (direction == "right") {
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_SEEKER_TINDERL,
        JSON.stringify({
          action: "accept",
          job_id: jobData.job.id,
          user_id: props.id,
        })
      )
        .then((res) => {
          toast.success(res.message, { autoClose: 1000 });
          //   if(res.json.subscription_required)
          //     window.$("#HomeUpgrade").modal("show")
          //   // let jobData=jobData.slice(props.index,1)
          //   // props.setProfileList(profileList)
        })
        .catch((err) => console.log(err));
    } else if (direction == "left")
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_SEEKER_TINDERL,
        JSON.stringify({
          action: "reject",
          job_id: jobData.job.id,
          user_id: props.id,
        })
      )
        .then((res) => {
          toast.success(res.message, { autoClose: 1000 });
          // console.log(res)

          // let profileList=props.profileList.slice(props.index,1)
          // console.log("profilelist",profileList)
          // props.setProfileList(profileList)
        })
        .catch((err) => console.log(err));
  };
  return (
    <TinderCard className="card-body text-center" onSwipe={onSwipe}>
      <ReactTooltip />
      <div className="jobmatch_percent">
        <p className="m-0">
          {jobData.percent} {translation.MATCH}
        </p>
      </div>

      <div
        className="job_bookmark"
        style={{ cursor: "pointer" }}
        onClick={() => {
          props.saveJob(jobData.job.id);
        }}
      >
        <img src={Bookmark} alt="" />
      </div>

      <img
        style={{ height: 100, width: 100, borderRadius: "50%" }}
        src={
          jobData.job.photo_link !== null ? jobData.job.photo_link : job_logo
        }
        alt=""
      />

      <h3 className="fs-16 fw-600 mt-4">{jobData.job.job_title}</h3>
      <p className="m-0 fs-14">{jobData.job.company_name}</p>
      <p className="m-0 fs-14">
        {jobData.job.work_location.map((item) => {
          return <> {item}</>;
        })}
      </p>
      <p className="m-0 fs-14">
        {jobData.job.job_type}
        {jobData.job.required_experience.map((item) => {
          return <> {item.level}</>;
        })}
      </p>

      <p className="fs-14 text-truncate">{jobData.job.about_company}</p>

      <hr />

      <div className="row">
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.SKILLS}</h4>
          <p className="fs-16 ls-4 m-0">
            {jobData.job.job_skill.map((item, index) => {
              return <> {item.skill},</>;
            })}
          </p>
        </div>
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.TOOLS}</h4>
          <p className="fs-16 ls-4 m-0">
            {jobData.job.job_tool.map((item, index) => {
              return <> {item.tool},</>;
            })}
          </p>
        </div>
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.CERTIFICATES}</h4>
          <p className="fs-16 ls-4 m-0 text-truncate ">
            {jobData.job.job_certificate.map((item, index) => {
              return <> {item.job_certificates},</>;
            })}
          </p>
        </div>
      </div>
      {/* <div className="links job_actions text-center">
        <button
          type="button"
          // disabled={
          // count < profileList.length - 1 ? false : true
          // }
          className="btn btn-outline-primary rounded-circle"
          onClick={() => {
            onSwipe("left");
          }}
          style={{ marginRight: "5rem" }}
        >
          <img src={close_job} alt="" />
        </button>

        <button
          type="button"
          className="btn btn-outline-primary rounded-circle"
          data-bs-toggle="modal"
          // data-bs-target="#HomeUpgrade"
          onClick={() => onSwipe("right")}
        >
          <img src={check_job} alt="" />
        </button>
      </div> */}
    </TinderCard>
  );
};
function Explore_Jobs() {
  const location = useLocation();
  let isFrom = "";
  const filterData = useLocation().state;
  const translation = useTranslation();
  const [userData, setUserData] = useState([]);
  const [jobRoleState, setJobRoleState] = useState({
    name: "",
    id: 0,
  });
  const [jobsData, setJobsData] = useState([]);
  const [state, setState] = useState({
    isProcessing: false,
  });
  function getPropsData() {
    const currentURL = window.location.href;
    const urlArray = currentURL != "" ? currentURL.split("?industry=") : [];
    let industry = urlArray[1];
    setState({ ...state, isProcessing: true });


    getWithAuthCallWithErrorResponse
      (
        ApiConfig.EXPLORER_JOB_BY_INDUSTRY + industry
      ).then((res) => {
        setState({ ...state, isProcessing: false });
        let json = res.json;
        setJobsData(json.list);
      });
  }
  useEffect(() => {
    if (filterData) {
      let requestBody = JSON.stringify(filterData);
      let uri;
      if (filterData.isFrom === "Explore_Jobs") {
        uri = ApiConfig.FILTER_JOB_SEEKER;
      } else {
        uri = ApiConfig.JOB_SEARCH_SEEKER;
      }

      postWithAuthCallWithErrorResponse(uri, requestBody).then((data) => {
        setState({ ...state, isProcessing: false });
        if (data.error !== true) {
          setJobsData(data.json.list);
        } else {
          alert(data.json.message);
        }
      });
    }
  }, [filterData]);
  const saveJob = (jobId) => {
    let requestBody = JSON.stringify({
      job_id: jobId,
    });
    postWithAuthCallWithErrorResponse(
      ApiConfig.FAVOURITE_JOB_API,
      requestBody
    ).then((data) => {
      if (data.error !== true) {
        toast.success("Job saved Successfully", { autoClose: 1000 });
        // if (count < jobsData.length - 1) {
        //   setCount(count + 1);
        // }
      } else {
        alert(data.json.message);
      }
    });
  };

  const onSwipe = (direction, id) => {
    if (direction == "right") {
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_SEEKER_TINDERL,
        JSON.stringify({
          action: "accept",
          job_id: id,
          user_id: userData.id,
        })
      )
        .then((res) => {
          let jobListFiltered = jobsData.filter((job) => job.job.id !== id);
          toast.success(res.json.message, { autoClose: 1000 });
          setJobsData(jobListFiltered);
        })
        .catch((err) => console.log(err));
    } else if (direction == "left")
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_SEEKER_TINDERL,
        JSON.stringify({
          action: "reject",
          job_id: id,
          user_id: userData.id,
        })
      )
        .then((res) => {
          let jobListFiltered = jobsData.filter((job) => job.job.id !== id);
          toast.success(res.json.message, { autoClose: 1000 });
          setJobsData(jobListFiltered);
        })
        .catch((err) => console.log(err));
  };

  useEffect(() => {
    getWithAuthCallWithErrorResponse(ApiConfig.GET_JOB_SEEKER_PROFILE).then(
      (data) => {
        if (data.error !== true) {
          setUserData(data.json);
        } else {
          alert(data.json.message);
        }
      }
    );
    getPropsData();
  }, []);
  return (
    <div>
      <Header />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item">
                    <Link to="/Explore">{translation.EXPLORE}</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {jobRoleState.name}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section id="home" class="home">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-8 col-md-12 offset-lg-2 ">
              {jobsData.length ? (
                jobsData.map((job, index) => {
                  return (
                    <div class="job_card overlap_card card">
                      <JobCard
                        id={userData.id}
                        jobData={job}
                        index={index}
                        saveJob={saveJob}
                      />
                    </div>
                  );
                })
              ) : (
                state.isProcessing ? <SpinnerCmp loading={state.isProcessing} /> :
                  <div className="row">
                    <div className="col-lg-8 col-md-12 offset-lg-2">
                      <div className="card-body text-center no-record">
                        <p className="m-0 fs-50 no-record-style" id="no-record">
                          {" "}
                          {translation.NO_RECORDS_FOUND}
                        </p>
                      </div>
                    </div>
                  </div>
              )}
            </div>
            {jobsData.length > 0 &&
              <div className="job_actions Card-button">
                <button
                  href=""
                  style={{ marginRight: "10rem" }}
                  type="button"
                  // disabled={
                  // count < profileList.length - 1 ? false : true
                  // }
                  className="btn "
                  data-tip={translation.REJECT}
                  onClick={() => {
                    onSwipe("left", jobsData[jobsData.length - 1].job.id);
                  }}
                >
                  <img src={close_job} alt="" />
                </button>

                <button
                  href=""
                  type="button"
                  className="btn "
                  style={{ marginRight: "100px" }}
                  data-tip={translation.ACCEPT}
                  // data-bs-toggle="modal"
                  // data-bs-target="#HomeUpgrade"
                  onClick={() =>
                    onSwipe("right", jobsData[jobsData.length - 1].job.id)
                  }
                >
                  <img src={check_job} alt="" />
                </button>
              </div>
            }
          </div>
        </div>
      </section>
    </div>
  );
}

export default Explore_Jobs;
