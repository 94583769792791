import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Header from '../../../sharedComponents/Header'
import block_company from '../../../assets/img/block_company.svg'
import chat from '../../../assets/img/chat.svg'
import thumbs_up from '../../../assets/img/thumbs_up.svg'
import play_video from '../../../assets/img/play_video.svg'
import profile_banner_bg from '../../../assets/img/profile_banner_bg.svg'
import nvidia_logo_big from '../../../assets/img/nvidia_logo_big.png';
import useTranslation from '../../../customHooks/translations';
import ApiConfig from '../../../api/ApiConfig';
import { getWithAuthCallWithErrorResponse, postWithAuthCallWithErrorResponse } from '../../../api/ApiServices';
import { toast } from 'react-toastify';

function Job_Details(props) {
  const translation = useTranslation();
  const location = useLocation();
  const [userData, setUserData] = useState([]);
  const { job } = location.state ? location.state : "";
  const { isFrom } = location.state ? location.state : ""
  const backToUri = isFrom === "Saved" ? "/Saved_Jobs" : "/Match_Job";
  const [state, setState] = useState({
    jobDetails: job ? job.job : {},
    matchedPercent: job && job.percent ? job.percent : 0,
    isCallFrom: isFrom ? isFrom : ''
  });
  useEffect(() => {

    getWithAuthCallWithErrorResponse(ApiConfig.GET_JOB_SEEKER_PROFILE).then(
      (data) => {
        if (data.error !== true) {
          setUserData(data.json);
          if (!data.json.preferences.length || !data.json.certificate.length || !data.json.education.length || !data.json.preferences.length || !data.json.skill.length || !data.json.tools.length) {
            toast.error("Please Upadate the profile to get desired job.", { autoClose: 1000 })
          }
        } else {
          alert(data.json.message);
        }
      }
    );
  }, []);
  const interestedJob = (direction, id) => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.JOB_SEEKER_TINDERL,
      JSON.stringify({
        action: "accept",
        job_id: state.jobDetails.id,
        user_id: userData.user.id,
      })
    )
      .then((res) => {
        if (!res.error) {
          toast.success(res.json.message, { autoClose: 1000 })
        }
      })
      .catch((err) => console.log(err));
  };
  const blockCompany = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.BLOCK_COMPANY_API,
      JSON.stringify({
        company_name: state.jobDetails.company_name,
      })
    )
      .then((res) => {
        if (!res.error) {
          toast.success(res.json.message, { autoClose: 1000 })
        }
      })
      .catch((err) => console.log(err));

  }
  return (
    <div>
      <Header />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item"><Link to="/Seeker_Profile">{translation.PROFILE}</Link></li>
                  <li class="breadcrumb-item">
                    <Link to={backToUri}
                      state={state.isCallFrom === "Saved" ? { isFrom: 'Saved' } : {}}>
                      {(state.isCallFrom === "Saved" ? "Saved" : "Matched") + " Jobs"}</Link></li>
                  <li class="breadcrumb-item active" aria-current="page">{state.jobDetails.job_title}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="profile" class="profile">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 g-0">
              <img src={profile_banner_bg} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="card profile_lfcard">
                <div class="card-body">
                  <div class="profile_cardimg text-center">
                    <img src={state.jobDetails.photo ? state.jobDetails.photo : nvidia_logo_big} alt="" />
                  </div>
                  <div class="text-center mt-4">
                    <h3 class="fw-600 fs-18">{state.jobDetails.job_title}</h3>
                    <p class="fs-16">{state.jobDetails.company_name}</p>
                  </div>
                  <div class="profilejobmatch_percent">
                    <p class="m-0">{state.matchedPercent + "% Match"}</p>
                  </div>
                  <div class="profile_actionbtn text-center py-3">
                    <button type="button" class="btn rounded-circle" onClick={blockCompany}><img src={block_company} alt="" /></button>

                    <div class="btn-group profilebtn_block jobprofile_block">
                      <button type="button" class="btn dropdown-toggle rounded-circle" data-bs-toggle="dropdown" aria-expanded="false"><img src={thumbs_up} alt="" /></button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li class="profileaction_bg">
                          <button class="dropdown-item" type="button" onClick={interestedJob}>{translation.INTERESTED}</button>
                        </li>
                      </ul>
                    </div>

                    {/* <div class="btn-group profilebtn_block jobprofile_block">
                      <button type="button" class="btn dropdown-toggle rounded-circle" data-bs-toggle="dropdown" aria-expanded="false"><img src={chat} alt="" /></button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li class="profileaction_bg">
                          <Link to="/Chat"> <button class="dropdown-item" type="button">{translation.CHAT}</button> </Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <hr class="mt-2 mb-4" />
                  <div class="d-flex justify-content-between">
                    <h4 class="fs-16 m-0">{translation.PLAY_INTRO_VIDEO} <span class="fs-14 ms-2"></span></h4>
                    {state.video && (
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#HomeUpgrade"
                      >
                        <img src={play_video} alt="" />
                      </a>
                    )}
                  </div>
                  <div
                    class="modal fade"
                    id="HomeUpgrade"
                    tabIndex="-1"
                    aria-labelledby="HomeUpgradeLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-body text-center">
                          <video width={"100%"} controls src={state.video_url} />
                        </div>
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-bs-dismiss="modal"
                        >
                          {translation.CLOSE}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="profile_rfcard pt-5 px-4">

                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="BasicInfo-tab" data-bs-toggle="tab" data-bs-target="#BasicInfo" type="button" role="tab" aria-controls="BasicInfo" aria-selected="true">{translation.JOB_INFO}</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="Education-tab" data-bs-toggle="tab" data-bs-target="#Education" type="button" role="tab" aria-controls="Education" aria-selected="false">{translation.ABOUT_THE_JOB}</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="Jobs-tab" data-bs-toggle="tab" data-bs-target="#Jobs" type="button" role="tab" aria-controls="Jobs" aria-selected="false">{translation.PREREQUISITES}</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="Prerequisite-tab" data-bs-toggle="tab" data-bs-target="#Prerequisite" type="button" role="tab" aria-controls="Prerequisite" aria-selected="false">{translation.ABOUT_THE_COMPANY}</button>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="BasicInfo" role="tabpanel" aria-labelledby="BasicInfo-tab">
                    <div class="d-flex justify-content-between mb-4">
                      <p class="m-0">{translation.INDUSTRY}</p>
                      <p class="m-0 profile_role">{state.jobDetails.industry}</p>
                    </div>
                    <div class="d-flex justify-content-between mb-4">
                      <p class="m-0">{translation.EXPERIANCE}</p>
                      <p class="m-0 profile_role"> {state.jobDetails.required_experience && state.jobDetails.required_experience.length > 0 ? state.jobDetails.required_experience.map((experience, index) => {
                        return experience.level;
                      }).join(", ") : "-"}</p>
                    </div>
                    <div class="d-flex justify-content-between mb-4">
                      <p class="m-0">{translation.JOB_TYPE}</p>
                      <p class="m-0 profile_role">{state.jobDetails.job_type}</p>
                    </div>
                    <div class="d-flex justify-content-between mb-4">
                      <p class="m-0">{translation.WORK_LOCATION}</p>
                      <p class="m-0 profile_role">
                        {state.jobDetails.work_location && state.jobDetails.work_location.length > 0 ? state.jobDetails.work_location.map((location, index) => {
                          return location;
                        }).join(", ") : "-"}
                      </p>
                    </div>
                    <div class="d-flex justify-content-between mb-4">
                      <p class="m-0">{translation.REQUIRED_LANGUAGE}</p>
                      <p class="m-0 profile_role">{state.jobDetails.language_required && state.jobDetails.language_required.length > 0 ? state.jobDetails.language_required.map((lang, index) => {
                        return lang.language;
                      }).join(", ") : "-"}</p>
                    </div>
                    <div class="d-flex justify-content-between mb-4">
                      <p class="m-0">{translation.MONTHLY_SALARY}</p>
                      <p class="m-0 profile_role">{state.jobDetails.salary}</p>
                    </div>
                    <div class="d-flex justify-content-between mb-4">
                      <p class="m-0">{translation.LINKEDIN_PROFILE}</p>
                      <p class="m-0 profile_role">{state.jobDetails.linkedin_id}</p>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="Education" role="tabpanel" aria-labelledby="Education-tab">{state.jobDetails.about_company}</div>
                  <div class="tab-pane fade" id="Jobs" role="tabpanel" aria-labelledby="Jobs-tab">
                    <div class="skills_jobs">
                      <h4 class="mb-4">{translation.SKILLS_REQUIRED}</h4>

                      <div class="row mb-5">
                        {state.jobDetails.job_skill
                          && state.jobDetails.job_skill.length > 0 ?
                          state.jobDetails.job_skill.map((skill, index) => {
                            return (
                              <div class="col-lg-3 col-md-6 g-5 mt-0 pt-0">
                                <p class="m-0">{skill.skill} <span>[{skill.level}]</span></p>
                                <div class="row skills_progress">

                                  {skill.level == "Beginner" &&
                                    <>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                    </>
                                  }
                                  {skill.level == "Intermediate" &&
                                    <>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                    </>
                                  }

                                  {skill.level == "Competent" &&
                                    <>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                    </>
                                  }
                                  {skill.level == "Expert" &&
                                    <>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                    </>
                                  }

                                </div>
                              </div>
                            )
                          }) : translation.NO_RECORDS_FOUND}
                      </div>

                      <h4 class="mb-4">{translation.TOOLS_REQUIRED}</h4>
                      <div class="row mb-5">
                        {state.jobDetails.job_tool
                          && state.jobDetails.job_tool.length > 0 ?
                          state.jobDetails.job_tool.map((tool, index) => {
                            return (
                              <div class="col-lg-3 col-md-6 g-5 mt-0 pt-0">
                                <p class="m-0">{tool.tool} <span>[{tool.level}]</span></p>

                                <div class="row skills_progress">

                                  {tool.level == "Beginner" &&
                                    <>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                    </>
                                  }
                                  {tool.level == "Intermediate" &&
                                    <>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                    </>
                                  }

                                  {tool.level == "Competent" &&
                                    <>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                    </>
                                  }
                                  {tool.level == "Expert" &&
                                    <>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                      <div class="col-lg-3 col-3 g-0">
                                        <div class="filled_skill"></div>
                                      </div>
                                    </>
                                  }

                                </div>
                              </div>

                            )
                          }) : translation.NO_NEW_RECORDS_FOUND}
                      </div>

                      <h4 class="mb-2">{translation.CERTIFICATES}</h4>
                      <p class="m-0"> {state.jobDetails.job_certificate && state.jobDetails.job_certificate.length > 0 ? state.jobDetails.job_certificate.map((certificate, index) => {
                        return certificate.job_certificates;
                      }).join(", ") + " . " : ""}</p>

                    </div>
                  </div>
                  <div class="tab-pane fade" id="Prerequisite" role="tabpanel" aria-labelledby="Prerequisite-tab">{translation.PREREQUISITES}</div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Job_Details;
