import React from "react";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import { Link } from "react-router-dom";
import tick_timeline from "../../assets/img/tick_timeline.svg";
import upload from "../../assets/img/upload.svg";
import useTranslation from "../../customHooks/translations";

function CreateJobInfo() {
  const translation = useTranslation();
  return (
    <>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="#">{translation.PROFILE}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.CREATE_JOB_AD}{" "}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative" style={{ marginTop: "4em" }}>
            <div className="col-lg-4 col-md-12">
              <h2 className="fs-18 fw-600">{translation.CREATE_JOB_AD}</h2>

              <div className="create_job_tracking trackingstep">
                <div className="step step-complete">
                  <div>
                    <div className="circle">
                      <img src={tick_timeline} alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="title">{translation.COMPANY_INFO}</div>
                  </div>
                </div>
                <div className="step step-active">
                  <div>
                    <div className="circle">2</div>
                  </div>
                  <div>
                    <div className="title">{translation.JOBS}</div>
                  </div>
                </div>
                <div className="step inactive">
                  <div>
                    <div className="circle">3</div>
                  </div>
                  <div>
                    <div className="title">{translation.PREREQUISITES}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="card login settings_card create_job_card">
                <div className="card-body">
                  <div className="form_block w-100 pt-0">
                    <form className="row g-3 text-start">
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.JOB_TITLE}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                        />
                      </div>
                      <div className="col-md-6 upload-btn-wrapper">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.ADD_VIDEO}
                        </label>
                        <div className="input-group">
                          <input type="text" className="form-control" />
                          <span
                            className="input-group-text"
                            style={{
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px",
                            }}
                          >
                            <img src={upload} alt="" />
                          </span>
                          <input type="file" name="myfile" />
                        </div>
                      </div>
                      <div className="col-md-12 regsiterrf mb-4">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.JOB_DESCRIPTION}
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ height: "69px" }}
                        ></textarea>
                      </div>

                      <div className="col-md-6 regsiterrf mb-4">
                        <div>
                          <label className="form-label fs-14">
                            {translation.JOB_TYPE}
                          </label>
                        </div>
                        <div className="swith_btn btn-group btn-toggle">
                          <button className="btn btn-lg btn-default">
                            {translation.PART_TIME}
                          </button>
                          <button className="btn btn-lg btn-primary active">
                            {translation.FULL_TIME}
                          </button>
                        </div>
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.REQUIRED_EXPERIENCE}
                        </label>
                        <select
                          className="form-select bg-transparent"
                          aria-label="Default select example"
                        >
                          <option></option>
                          <option value="1">1 Year</option>
                          <option value="2">2 Year</option>
                          <option value="3">3 Year</option>
                        </select>
                      </div>

                      <div className="col-md-6 regsiterrf mb-4">
                        <div>
                          <label className="form-label fs-14">
                            {translation.WORK_LOCATION}
                          </label>
                        </div>
                        <div className="swith_btn btn-group btn-toggle">
                          <button className="btn btn-lg btn-default">
                            {translation.ONSITE}
                          </button>
                          <button className="btn btn-lg btn-primary active">
                            {translation.HYBRID}
                          </button>
                          <button className="btn btn-lg btn-lobster">
                            {translation.REMOTE}
                          </button>
                        </div>
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.REQUIRED_LANGUAGE}
                        </label>
                        <select
                          className="form-select bg-transparent"
                          aria-label="Default select example"
                        >
                          <option></option>
                          <option value="1">English</option>
                          <option value="2">Hindi</option>
                          <option value="3">Marathi</option>
                        </select>
                      </div>

                      <div className="col-md-6 regsiterrf mb-4">
                        <div>
                          <label className="form-label fs-14">
                            {translation.JOB_POSTING_LANGUAGE}
                          </label>
                        </div>
                        <div className="swith_btn btn-group btn-toggle">
                          <button className="btn btn-lg btn-default">
                            English
                          </button>
                          <button className="btn btn-lg btn-primary active">
                            Swedish
                          </button>
                          <button className="btn btn-lg btn-lobster">
                            German
                          </button>
                        </div>
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.AD_DURATION}
                        </label>
                        <select
                          className="form-select bg-transparent"
                          aria-label="Default select example"
                        >
                          <option></option>
                          <option value="1">2 Days</option>
                          <option value="2">9 Days</option>
                          <option value="3">15 Days</option>
                        </select>
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.MONTHLY_SALARY}
                        </label>
                        <select
                          className="form-select bg-transparent"
                          aria-label="Default select example"
                        >
                          <option></option>
                          <option value="1">$1000 - $5000</option>
                          <option value="2">$5000 - $10000</option>
                          <option value="3">$10000 - $15000</option>
                        </select>
                      </div>

                      <hr className="mt-4" />
                      <div className="card_footer_btn d-flex justify-content-between pt-0 mt-0">
                        <Link to="/create-job" className="fw-600 fs-18">
                        {translation.PREV}
                        </Link>
                        <Link to="/create-job-ad" className="fw-600 fs-18">
                        {translation.NEXT}
                        </Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CreateJobInfo;
