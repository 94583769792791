import React, { useState, useEffect } from "react";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import { Link } from "react-router-dom";
import job_default_icon from "../../assets/img/job_default_icon.svg";
import menu_options from "../../assets/img/menu_options.svg";
import ApiConfig from "../../api/ApiConfig";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import useTranslation from "../../customHooks/translations";
import { toast } from "react-toastify";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";

function RecruiterAdListing() {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    errMsg: {},
    jobList: [],
    status: "open",
  });

  const getjobs = async () => {
    setState({ ...state, isProcessing: true, jobList: [] });
    getWithAuthCallWithErrorResponse(
      ApiConfig.RECRUITER_JOB_LISTING_BY_STATUS_API + state.status
    )
      .then((data) => {
        if (!data.error) {
          setState({ ...state, isProcessing: false, jobList: data.json.list });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };

  useEffect(() => {
    getjobs();
  }, [state.status]);
  const handleStatusChange = (e) => {
    setState({ ...state, status: e.target.value });
  };
  const onChangeStatus = (e) => {
    setState({ ...state, isProcessing: true });
    const { name, value } = e.target;
    let requestBody = JSON.stringify({
      job_id: value,
      job_status: name,
    });
    postWithAuthCallWithErrorResponse(
      ApiConfig.RECRUITER_CHANGE_JOB_STATUS_API,
      requestBody
    )
      .then((data) => {
        setState({ ...state, isProcessing: false });
        if (!data.error == true) {
          toast.success(data.json.message, { autoClose: 1000 })
          setState({ ...state, isProcessing: false });
          getjobs();
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };
  return (
    <>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/recruiter-profile">{translation.PROFILE}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.JOB_ADS}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" className="matched_jobs">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 chat_message mt-5">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    onClick={handleStatusChange}
                    className="nav-link active"
                    id="pills-joblist-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-joblist"
                    type="button"
                    value="open"
                    role="tab"
                    aria-controls="pills-joblist"
                    aria-selected="true"
                  >
                    {translation.OPEN}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    onClick={handleStatusChange}
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-joblist"
                    type="button"
                    value="pause"
                    role="tab"
                    aria-controls="pills-joblist"
                    aria-selected="false"
                  >
                    {translation.PAUSED}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    onClick={handleStatusChange}
                    className="nav-link"
                    id="pills-contact-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-joblist"
                    type="button"
                    value="close"
                    role="tab"
                    aria-controls="pills-joblist"
                    aria-selected="false"
                  >
                    {translation.CLOSED}
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-joblist"
                  role="tabpanel"
                  aria-labelledby="pills-joblist-tab"
                >

                  {state.jobList && state.jobList.length > 0 ? (
                    state.jobList.map((job, index) => {
                      return (
                        <div className="card settings_card mt-4">
                          <div className="card-body">
                            <div className="d-flex justify-content-between">
                              <Link
                                to="/recruiter-job-details"
                                className="itemside"
                                state={job}
                              >
                                <div className="aside">
                                  <img
                                    src={job_default_icon}
                                    className="img-sm"
                                    alt=""
                                  />
                                </div>
                                <div className="info align-self-center">
                                  <p className="title fs-18 mb-1">
                                    {job.job_title}
                                  </p>
                                  <div className="chat_role">
                                    <p className="m-0 fs-14">
                                      {job.company_name +
                                        " . " +
                                        job.job_type +
                                        " . " +
                                        (job.required_experience
                                          ? job.required_experience
                                            .map((experience, index) => {
                                              return experience.level;
                                            })
                                            .join(", ") + " . "
                                          : null) +
                                        job.work_location}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                              <div className="linco_dropdown btn-group dropstart">
                                <button
                                  type="button"
                                  className="btn dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img src={menu_options} alt="" />
                                </button>
                                <ul className="dropdown-menu">
                                  {state.status !== "open" ? (
                                    <li>
                                      <button
                                        name="open"
                                        value={job.id}
                                        onClick={onChangeStatus}
                                        className="dropdown-item"
                                        type="button"
                                      >
                                        {translation.OPEN}
                                      </button>
                                    </li>
                                  ) : null}
                                  {state.status !== "pause" &&
                                    state.status !== "close" ? (
                                    <li>
                                      <button
                                        name="pause"
                                        value={job.id}
                                        onClick={onChangeStatus}
                                        className="dropdown-item"
                                        type="button"
                                      >
                                        {translation.PAUSE}
                                      </button>
                                    </li>
                                  ) : null}
                                  {state.status !== "close" ? (
                                    <li>
                                      <button
                                        name="close"
                                        value={job.id}
                                        onClick={onChangeStatus}
                                        className="dropdown-item"
                                        type="button"
                                      >
                                        {translation.CLOSE}
                                      </button>
                                    </li>
                                  ) : null}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    state.isProcessing ?
                      <SpinnerCmp loading={state.isProcessing} /> :

                      <div className="card settings_card mt-4">
                        <div className="card-body">
                          <div className="d-flex justify-content-between">
                            <p className="m-0 fs-14">{translation.NO_RECORDS_FOUND}</p>
                          </div>
                        </div>
                      </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RecruiterAdListing;
