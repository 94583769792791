import IN_en from "./IN_en";
import IN_hn from "./IN_hn";
import IN_mr from "./IN_mr";
import SE_sv from "./SE_sv";

export default {
  IN_en,
  IN_hn,
  IN_mr,
  SE_sv,
};
