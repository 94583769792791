import React, { useEffect, useState } from "react";
import { Plus } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../sharedComponents/Header";
import chevron_right_colored from "../../../assets/img/chevron_right_colored.svg";
import edit_profile from "../../../assets/img/edit_profile.svg";
import {
  getWithAuthCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  deleteMultipartWithAuthCallWithErrorResponse,
} from "../../../api/ApiServices.js";
import ApiConfig from "../../../api/ApiConfig";
import SpinnerCmp from "../../../sharedComponents/SpinnerCmp";
import useTranslation from "../../../customHooks/translations";
import { toast } from "react-toastify";

function Edit_Education() {
  const navigation = useNavigate()
  const translation = useTranslation();
  // add education
  const [state, setState] = useState({
    isProcessing: false,
    errMsg: {},
    education_type: "",
    institute_name: "",
    area_of_study: "",
    year_of_graduation: "",
    id: "",
    selected_Country: {},
    selected_City: {},
    selected_CompanySize: {},
  });

  const AddEducation = async () => {
    if (
      state.education_type == "" ||
      state.institute_name == "" ||
      state.area_of_study == "" ||
      state.year_of_graduation == "" ||
      state.education_type == null ||
      state.institute_name == null ||
      state.area_of_study == null ||
      state.year_of_graduation == null
    ) {
      if (state.education_type == "" || state.education_type == null) {
        setState({
          ...state,
          errMsg: { education_type: translation.SELECT_EDUCATION_TYPE },
        });
      } else if (state.institute_name == "" || state.institute_name == null) {
        setState({
          ...state,
          errMsg: { institute_name: translation.PLEASE_ENTER_INSTITUTE_NAME },
        });
      } else if (state.area_of_study == "" || state.area_of_study == null) {
        setState({
          ...state,
          errMsg: { area_of_study: translation.ENTER_AREA_OF_STUDY },
        });
      } else if (
        state.year_of_graduation == "" ||
        state.year_of_graduation == null
      ) {
        setState({
          ...state,
          errMsg: { year_of_graduation: translation.ENTER_YEAR_OF_GRADUATION },
        });
      }
    } else {
      let requestBody = JSON.stringify({
        education_type: state.education_type,
        institute_name: state.institute_name,
        area_of_study: state.area_of_study,
        year_of_graduation: state.year_of_graduation,
      });
      postWithAuthCallWithErrorResponse(ApiConfig.ADD_EDUCATION, requestBody)
        .then((data) => {
          setState({ ...state, isProcessing: true });
          if (data.error == true) {
            setState({ ...state, isProcessing: false });
          } else {
            toast.success("Education added successfully", { autoClose: 1000 })
            setState({ ...state, isProcessing: false });
            getEducationList();
            // navigation("/Edit_Education")
            // window.location.href = "/Edit_Education";
          }
        })
        .catch((error) => {
          setState({ ...state, isProcessing: false });
        });
    }
  };

  // end add education
  //fetch('https://jsonplaceholder.typicode.com/posts/1', { method: 'DELETE' });
  //setStatus('Delete successful');
  //}
  const deleteEducation = (id) => {
    deleteMultipartWithAuthCallWithErrorResponse(ApiConfig.ADD_EDUCATION + id + "/")
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
        } else {
          toast.success(data.json.message, { autoClose: 1000 })
          setState({ ...state, errorMsg: data });
          getEducationList();
        }
      })
      .catch((error) => {
        console.log("api response", error);
      }
      );
  };

  // Education type list
  const [educationTypesState, seteducationTypesState] = useState([
    { label: "Elementary /Primary", value: "Elementary /Primary" },
    { label: "Middle School", value: "Middle School" },
    { label: "Home School", value: "Home School" },
    { label: "Private School", value: "Private School" },
    { label: "Junior High School", value: "Junior High School" },
    { label: "High School", value: "High School" },
    { label: "Undergraduate", value: "Undergraduate" },
    { label: "Professional Certificate", value: "Professional Certificate" },
    { label: "Transfer", value: "Transfer" },
    { label: "Associate", value: "Associate" },
    { label: "Bachelor", value: "Bachelor" },
    { label: "Graduate", value: "Graduate" },
    { label: "Master", value: "Master" },
    { label: "Doctoral", value: "Doctoral" },
    { label: "Professional", value: "Professional" },
    { label: "Specialist", value: "Specialist" },
  ]);

  // Education list
  const [getEducation, setGetEducation] = useState({
    isProcessing: false,
    errMsg: {},
    id: "",
    education_type: "",
    institute_name: "",
    area_of_study: "",
    year_of_graduation: "",
  });

  const getEducationList = async () => {
    setGetEducation({ ...getEducation, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.ADD_EDUCATION)
      .then((data) => {
        if (data.error == true) {
          setGetEducation({ ...getEducation, isProcessing: false });
        } else {
          setGetEducation(data.json);
        }
      })
      .catch((error) => {
        setGetEducation({ ...getEducation, isProcessing: false });
      });
  };

  // End education list

  //EDIT EDUCATION
  const editEducation = async (id) => {
    // debugger
    if (
      state.education_type == "" ||
      state.institute_name == "" ||
      state.area_of_study == "" ||
      state.year_of_graduation == "" ||
      state.education_type == null ||
      state.institute_name == null ||
      state.area_of_study == null ||
      state.year_of_graduation == null
    ) {
      if (state.education_type == "" || state.education_type == null) {
        setState({
          ...state,
          errMsg: { education_type: translation.SELECT_EDUCATION_TYPE },
        });
      } else if (state.institute_name == "" || state.institute_name == null) {
        setState({
          ...state,
          errMsg: { institute_name: translation.PLEASE_ENTER_INSTITUTE_NAME },
        });
      } else if (state.area_of_study == "" || state.area_of_study == null) {
        setState({
          ...state,
          errMsg: { area_of_study: translation.ENTER_AREA_OF_STUDY },
        });
      } else if (
        state.year_of_graduation == "" ||
        state.year_of_graduation == null
      ) {
        setState({
          ...state,
          errMsg: { year_of_graduation: translation.ENTER_YEAR_OF_GRADUATION },
        });
      }
    } else {
      let formData = new FormData();
      formData.append("education_type", state.education_type);
      formData.append("institute_name", state.institute_name);
      formData.append("area_of_study", state.area_of_study);
      formData.append("year_of_graduation", state.year_of_graduation);

      const uri = ApiConfig.ADD_EDUCATION + id + "/";
      putMultipartWithAuthCallWithErrorResponse(
        uri,
        formData
      )
        .then((data) => {
          if (data.error == false) {
            toast.success("Education updated successfully", { autoClose: 1000 })
            // window.location.href = "/Edit_Education";
            getEducationList();

          } else {
            setState({ ...state, errorMsg: data });
          }
        })
        .catch((error) => {
          console.log("api response", error);

        });
    }

  };

  //END EDIT EDUCATION
  useEffect(() => {
    getEducationList();
    // AddEducation();
    // deleteEducation();
  }, []);
  return (
    <div>
      <Header />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item">
                    <Link to="/Seeker_profile">{translation.PROFILE}</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {translation.EDIT_EDUCATION_INFO}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <SpinnerCmp loading={state.isProcessing} />
      <section id="settings" class="settings">
        <div class="container">
          <div class="row position-relative">
            <div class="col-lg-6 col-md-12 offset-lg-3">
              <div class="card login settings_card mt-5 ">
                <div class="card-body">
                  <div class="AddEducationLevel pt-0 w-100">
                    <div class="d-flex justify-content-end">
                      <p class="text-primary">
                        {translation.ADD_EDUCATION + " "}
                        <a
                          class="addlevel ms-2"
                          data-bs-toggle="offcanvas"
                          href="#offcanvasAddEducation"
                          role="button"
                          aria-controls="offcanvasAddEducation"
                        >
                          <Plus />
                        </a>
                      </p>
                    </div>

                    <div class="edulevels" style={{ marginLeft: " 25px" }}>
                      {getEducation.length > 0
                        ? getEducation.map((edulist) => (
                          <div className="levelcircle active mt-4">
                            <h3 className="fs-16">
                              {edulist.education_type}
                            </h3>
                            <p className="fs-14 m-0">
                              {edulist.institute_name}
                            </p>
                            <p className="fs-14 m-0">
                              {edulist.area_of_study}
                            </p>
                            <p className="fs-14 m-0">
                              {edulist.year_of_graduation}
                            </p>
                            <div id="edit-delete-btn">
                              <a
                                class="addlevel ms-2  text-primary"
                                href=""
                                role="button"
                                onClick={(e) => { e.preventDefault(); deleteEducation(edulist.id) }}
                              >
                                {translation.DELETE}
                              </a>
                              <a
                                class="addlevel ms-2 text-primary"
                                data-bs-toggle="offcanvas"
                                href="#offcanvasEditEducation"
                                role="button"
                                aria-controls="offcanvasAddEducation"
                                onClick={() => {
                                  setState({
                                    ...state,
                                    education_type: edulist.education_type,
                                    institute_name: edulist.institute_name,
                                    area_of_study: edulist.area_of_study,
                                    year_of_graduation: edulist.year_of_graduation,
                                    id: edulist.id,
                                  });
                                }}
                              >
                                {translation.EDIT}
                              </a>
                            </div>
                          </div>
                        ))
                        : translation.NO_RECORDS_FOUND}

                      {/* <div class=" d-flex justify-content-between mt-4">
                        <div></div>
                        <p className="text-primary">
                          <a
                            class="addlevel ms-2 text-primary"
                            data-bs-toggle="offcanvas"
                            href="#offcanvasEditEducation"
                            role="button"
                            aria-controls="offcanvasAddEducation"
                          >
                            <img src={edit_profile} alt="" />
                          </a>
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="loginwithlinks editprofile_block">
                <div class="d-lg-flex d-md-flex justify-content-end">
                  <div class="loginwithbtn">
                    <p class="fs-22 fw-600 text-primary">
                      {translation.UPDATE + " "}
                      <Link to="/Seeker_Profile">
                        <button
                          type="button"
                          class="btn btn-outline-primary rounded-circle ms-2"
                        >
                          <img src={chevron_right_colored} alt="" />
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Add Education Level Offcanvas --> */}

      <div
        class="AddLevelCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasAddEducation"
        aria-labelledby="offcanvasAddEducationLabel"
      >
        <div class="offcanvas-header">
          <h5
            class="offcanvas-title fs-16 fw-700"
            id="offcanvasAddEducationLabel"
          >
            {translation.ADD_EDUCATION}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <form class="row g-3 text-start">
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.EDUCATION_TYPE}
              </label>
              <select
                class="form-select border-primary"
                aria-label="Default select example"
                onChange={(e) => {
                  setState({ ...state, education_type: e.target.value });
                }}
                value={state.education_type}
              >
                <option selected></option>
                {educationTypesState.map((edutype) => (
                  <option>{edutype.label}</option>
                ))}
              </select>
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.education_type ? state.errMsg.education_type : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.INSTITUTE_NAME}
              </label>
              <input
                type="text"
                class="form-control border-primary"
                id="inputEmail4"
                value={state.institute_name}
                onChange={(e) => {
                  setState({ ...state, institute_name: e.target.value });
                }}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.institute_name ? state.errMsg.institute_name : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.AREA_OF_STUDY}
              </label>
              <input
                type="text"
                class="form-control border-primary"
                id="inputPassword4"
                value={state.area_of_study}
                onChange={(e) => {
                  setState({ ...state, area_of_study: e.target.value });
                }}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.area_of_study ? state.errMsg.area_of_study : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.YEAR_OF_GRADUATION}
              </label>
              <input
                type="text"
                class="form-control border-primary"
                id="inputPassword4"
                value={state.year_of_graduation}
                onChange={(e) => {
                  setState({ ...state, year_of_graduation: e.target.value });
                }}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.year_of_graduation
                  ? state.errMsg.year_of_graduation
                  : ""}
              </span>
            </div>
          </form>
          <div
            class="educationcanvas-footer"
            style={{ position: "absolute", bottom: "10px", right: "10px" }}
          >
            <div class="d-flex justify-content-end">
              <a class="   fs-16 fw-600 me-3 pb-3" onClick={AddEducation} style={{ cursor: "pointer" }}>
                {translation.APPLY}
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* edit education */}
      <div
        class="AddLevelCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasEditEducation"
        aria-labelledby="offcanvasAddEducationLabel"
      >
        <div class="offcanvas-header">
          <h5
            class="offcanvas-title fs-16 fw-700"
            id="offcanvasAddEducationLabel"
          >
            {translation.EDIT_EDUCATION}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <form class="row g-3 text-start">
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.EDUCATION_TYPE}
              </label>
              <select
                class="form-select border-primary"
                aria-label="Default select example"
                onChange={(e) => {
                  setState({ ...state, education_type: e.target.value });
                }}
                value={state.education_type}
              >
                <option selected>Select Education Type</option>
                {educationTypesState.map((edutype) => (
                  <option>{edutype.label}</option>
                ))}
              </select>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.INSTITUTE_NAME}
              </label>
              <input
                type="text"
                class="form-control border-primary"
                id="inputEmail4"
                value={state.institute_name}
                onChange={(e) => {
                  setState({ ...state, institute_name: e.target.value });
                }}
              />
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.AREA_OF_STUDY}
              </label>
              <input
                type="email"
                class="form-control border-primary"
                id="inputPassword4"
                value={state.area_of_study}
                onChange={(e) => {
                  setState({ ...state, area_of_study: e.target.value });
                }}
              />
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.YEAR_OF_GRADUATION}
              </label>
              <input
                type="email"
                class="form-control border-primary"
                id="inputPassword4"
                value={state.year_of_graduation}
                onChange={(e) => {
                  setState({ ...state, year_of_graduation: e.target.value });
                }}
              />
            </div>
          </form>
          <div
            class="educationcanvas-footer"
            style={{ position: "absolute", bottom: "10px", right: "10px" }}
          >
            <div class="d-flex justify-content-end">
              <a href="#" class="fs-16 fw-600 me-3 pb-3" onClick={() => editEducation(state.id)} >
                {translation.UPDATE}
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* end edit education */}
    </div>
  );
}

export default Edit_Education;
