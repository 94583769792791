import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../../sharedComponents/Header";
import clogo_01 from "../../../assets/img/clogo_01.png";
import clogo_02 from "../../../assets/img/clogo_02.png";
import clogo_03 from "../../../assets/img/clogo_03.png";
import menu_options from "../../../assets/img/menu_options.svg";
import ApiConfig from "../../../api/ApiConfig";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../../api/ApiServices";
import useTranslation from "../../../customHooks/translations";

function Match_Job() {
  const translation = useTranslation();
  const location = useLocation();
  let isFrom = "";
  if (location.state) isFrom = location.state.isFrom;
  const [industryList, setIndustryList] = useState([]);
  const [matchingJobs, setMatchingJobs] = useState([])
  const [state, setState] = useState({
    isProcessing: false,
    errMsg: {},
    matchingJobs: [],
    matchedJob: {},
    matchedPercent: 0,
    isCallFrom: isFrom ? isFrom : "",
  });
  const getExploreList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.EXPLORE_JOB_SEEKER)
      .then((data) => {
        setIndustryList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };
  const getjobs = () => {
    const url =
      state.isCallFrom === "Saved"
        ? ApiConfig.FAVOURITE_JOB_API
        : ApiConfig.GET_MATCHINgJOB;
    getWithAuthCallWithErrorResponse(url)
      .then((data) => {
        if (!data.error) {
          let list = data.json.list;
          setMatchingJobs(list)
          setState({
            ...state,
            matchingJobsOriginal: list,
            matchingJobs: list,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };

  useEffect(() => {
    getExploreList();
    getjobs();
  }, []);

  const handleGridAction = (e) => {
    setState({ ...state, isProcessing: true });
    const { id } = e.target;
    // let value = id.split('_')[1];
    let action = id.split("_")[2];
    switch (action.toLowerCase()) {
      case "chat":
        //not implemented yet
        break;
      case "notinterested":
        //not implemented yet
        break;
      case "blockcompany":
        let requestBody = JSON.stringify({
          company_name: id.split("_")[1],
        });
        postDataCall(ApiConfig.BLOCK_COMPANY_API, requestBody);
        break;
    }
  };
  const postDataCall = (url, body) => {
    postWithAuthCallWithErrorResponse(url, body)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        if (!data.error == true) {
          setState({ ...state, isProcessing: false });
          getjobs();
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };
  const filterJobs = (value) => {
    if (value.length) {
      let jobListFitered = state.matchingJobs.filter(job => job.job.industry == value)
      setMatchingJobs(jobListFitered);
    }
  }
  const CompanyLogoStyle = {
    width: "60px !important",
    height: "60px !important",
  };
  return (
    <div>
      <Header />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/Seeker_Profile">{translation.PROFILE}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {state.isCallFrom === "Saved"
                      ? translation.SAVED_JOBS
                      : translation.MATCHED_JOBS}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" className="settings">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12 chat_message"
              style={{ borderRadius: "4px" }}
            >
              <div class="col-md-4 regsiterrf mb-3 my-3">
                <select
                  class="form-select bg-transparent border-primary"
                  aria-label="Default select example"
                  onChange={(e) => filterJobs(e.target.value)}
                >
                  <option>{translation.SELECT_JOB_TITLE}</option>
                  {industryList.length &&
                    industryList.map((industry) => {
                      return (
                        <option key={industry.name} value={industry.name}>
                          {industry.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              {matchingJobs && matchingJobs.length > 0 ? (
                matchingJobs.map((match, index) => {
                  let job = match.job;
                  return (
                    <div className="card settings_card mt-4">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <Link
                            to={"/Job_Details"}
                            state={{
                              job: match,
                              isFrom: state.isCallFrom,
                            }}
                          >
                            <div className="itemside">
                              <div className="aside">
                                <img
                                  style={
                                    job.photo
                                      ? { width: "60px", height: "60px" }
                                      : {}
                                  }
                                  src={job.photo ? job.photo : clogo_01}
                                  className="img-sm"
                                />
                              </div>
                              <div className="info align-self-center">
                                <p className="title fs-18 mb-1">
                                  {job.job_title}
                                </p>
                                <div className="chat_role">
                                  <p className="m-0 fs-14">
                                    {job.company_name +
                                      " . " +
                                      job.job_type +
                                      " . " +
                                      (job.required_experience &&
                                        job.required_experience.length > 0
                                        ? job.required_experience
                                          .map((experience, index) => {
                                            return experience.level;
                                          })
                                          .join(", ") + " . "
                                        : "") +
                                      (job.work_location &&
                                        job.work_location.length > 0
                                        ? job.work_location
                                          .map((location, index) => {
                                            return location;
                                          })
                                          .join(", ") + " . "
                                        : "")}
                                    {match.percent ? (
                                      <span
                                        className="fw-500"
                                        style={{ color: "#009A12" }}
                                      >
                                        {match.percent + "% Match"}
                                      </span>
                                    ) : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                          {/* <div className="linco_dropdown btn-group dropstart">
                            <button
                              type="button"
                              className="btn dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={menu_options} alt="" />
                            </button>
                            <ul className="dropdown-menu">
                              <li>
                                <Link to="/Chat">
                                  <button
                                    onClick={handleGridAction}
                                    className="dropdown-item"
                                    id={"btn_" + job.id + "_Chat"}
                                    name="Chat"
                                    value={job.id}
                                    type="button"
                                  >
                                    {translation.CHAT}
                                  </button>
                                </Link>
                              </li>
                              <li>
                                <button
                                  onClick={handleGridAction}
                                  className="dropdown-item"
                                  id={"btn_" + job.id + "_NotInterested"}
                                  name="NotInterested"
                                  value={job.id}
                                  type="button"
                                >
                                  {translation.NOT_INTERESTED}
                                </button>
                              </li>
                              <li>
                                <button
                                  onClick={handleGridAction}
                                  className="dropdown-item"
                                  id={
                                    "btn_" + job.company_name + "_BlockCompany"
                                  }
                                  name="BlockCompany"
                                  value={job.company_name}
                                  type="button"
                                >
                                  {translation.BLOCK_COMPANY}
                                </button>
                              </li>
                            </ul>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="card settings_card mt-4">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <p className="m-0 fs-14">{translation.NO_RECORDS_FOUND}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Match_Job;
