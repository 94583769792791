import React from 'react';
import { Link } from 'react-router-dom';
import RecruiterHeader from '../../../sharedComponents/RecruiterHeader';
import success from "../../../assets/img/success.svg";
import useTranslation from '../../../customHooks/translations';

function Pass_Suceess() {
  const translation = useTranslation();
  return (
    <div>
      <RecruiterHeader />
      <div class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item"><Link to="/Setting">{translation.SETTINGS}</Link></li>
                  <li class="breadcrumb-item active" aria-current="page">{translation.CHANGE_PASSWORD}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div id="settings" class="settings">
        <div class="container">
          <div class="row position-relative">
            <div class="col-lg-4 col-md-12 offset-lg-4">
              <div class="card settings_card" style={{ marginTop: "7em" }}>
                <div class="card-body text-center">
                  <div class="w-100 pt-0">
                    <div>
                      <img src={success} alt="" />
                    </div>
                    <p class="fw-500 py-3">{translation.YOUR_PASSWORD_HAS_BEEN_CHANGED_SUCCESSFULLY}</p>
                    <Link to="/recruiter-setting"><button class="btn gradient_btn w-100">{translation.CONTINUE}</button></Link>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Pass_Suceess
