import React from "react";
import useTranslation from "../../customHooks/translations";
import Header from "../../sharedComponents/Header";

function Faq() {
  const translation = useTranslation();
  return (
    <div>
      <Header />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item active" aria-current="page">
                    {translation.FAQ}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" class="faq">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="accordion mt-5" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div class="accordion-body">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwo"
                  >
                    <div class="accordion-body">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est.
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree"
                    class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingThree"
                  >
                    <div class="accordion-body">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- <div class="col-lg-6 col-md-12 offset-lg-3">
                <div class="card delete_profile login settings_card" style="margin-top: 4em;">
                  <div class="card-body">
                      <p class="text-muted mb-4">Select a reason for deleting the account</p>

                      <div class="d-flex justify-content-between">
                        <p>This is temporary, I will be back.</p>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                        </div>
                      </div>
                      <hr>
                      <div class="d-flex justify-content-between">
                          <p>I get too many notifications.</p>
                          <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                          </div>
                      </div>
                      <hr>
                      <div class="d-flex justify-content-between">
                          <p>I spend too much time in Linco.</p>
                          <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                          </div>
                      </div>
                      <hr>
                      <div class="d-flex justify-content-between">
                          <p>My account was hacked.</p>
                          <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                          </div>
                      </div>
                      <hr>
                      <div class="d-flex justify-content-between">
                          <p>I didn’t find Linco useful.</p>
                          <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                          </div>
                      </div>
                      <hr>
                      <div class="d-flex justify-content-between">
                          <p>I have a privacy concern.</p>
                          <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                          </div>
                      </div>
                      <hr>

                      <div class="form_block w-100 pt-0">
                          <form class="row g-3 text-start">
                              <div class="col-md-12 regsiterrf">
                                  <label for="inputPassword4" class="form-label fs-14">Other</label>
                                  <textarea class="form-control" id="exampleFormControlTextarea1" rows="5" style="height: 69px;"></textarea>
                              </div>
                            </form>
                        </div>
                    </div>
                  </div>

                  <div class="loginwithlinks editprofile_block">
                      <div class="d-lg-flex d-md-flex justify-content-end">
                        <div class="loginwithbtn">
                          <p class="fs-22 fw-600 text-primary">Continue  <button type="button" class="btn btn-outline-primary rounded-circle ms-2" data-bs-toggle="modal" data-bs-target="#HomeUpgrade"><img src="img/chevron_right_colored.svg" alt=""></button>
                          </p>
                        </div>
                      </div>
                    </div>

                </div> --> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Faq;
