import React, { useEffect, useState } from "react";
import ApiConfig from "../../api/ApiConfig";
import { getWithAuthCallWithtext } from "../../api/ApiServices";
import useTranslation from "../../customHooks/translations";
import Header from "../../sharedComponents/Header";
import Privacy from "../../sharedComponents/Privacy";
import PrivcayPolicy from "../../sharedComponents/PrivcayPolicy";
import TermsAndCondtion from "../../sharedComponents/TermsAndCondtion";

function Legal() {
  const translation = useTranslation();

  return (
    <div>
      <Header />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item active" aria-current="page">
                    {translation.LEGAL}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" class="faq">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="accordion mt-5" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                  <h1 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      {translation.PRIVACY_POLICY}
                    </button>
                  </h1>
                  <div
                    id="panelsStayOpen-collapseOne"
                    class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div class="accordion-body">
                      <Privacy />
                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                    <button
                      class="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      {translation.TERMS_CONDITIONS}
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    class="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwo"
                  >
                    <div class="accordion-body">
                      <TermsAndCondtion />
                    </div>
                  </div>
                </div>
                {/* <div class="accordion-item">
                   <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                     <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                        Privacy Preference
                     </button>
                   </h2>
                   <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                     <div class="accordion-body">
                         Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est.
                     </div>
                   </div>
                 </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Legal;
