import React, { useContext, useEffect, useState } from 'react';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import linkedin from 'react-linkedin-login-oauth2/assets/linkedin.png';
import ApiConfig from '../api/ApiConfig';
import authContext from '../pages/GetUserStatus';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: "200px"
  },
};
function LinkedInPage() {
  const navigate = useNavigate();
  const [role, setrole] = useState('Job_seeker')
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [token, settoken] = useState(null)
  const handleRbChange = async (e) => {
    const { value } = e.target;
    setrole(value);

  };
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }
  const addRole = async (e) => {

    let payload = {
      role: role,

    }
    const response = await fetch(ApiConfig.ADD_ROLE_LINKEDIN_LOGIN, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Token " + token
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();
    if (data.message === "Role added successfully") {
      localStorage.setItem("USER_ID", data.user_details.id);
      localStorage.setItem(
        "USER_FULL_NAME",
        data.user_details.full_name
      );
      localStorage.setItem(
        "USER_PROFILE_PIC",
        data.user_details.profile_pic
      );
      localStorage.setItem("USER_EMAIL", data.user_details.email);
      localStorage.setItem(
        "USER_MOBILE",
        data.user_details.phone_number
      );
      localStorage.setItem("USER_ROLE", data.user_details.role);
      localStorage.setItem("USER_CITY", data.user_details.city);
      localStorage.setItem(
        "USER_COUNTRY",
        data.country
      );
      localStorage.setItem(
        "USER_AUTH_TOKEN",
        data.user_details.auth_token
      );

      setAuthenticated(true);
      localStorage.setItem("is_logged_in", true);
      if (data.user_details.role == "Recruiter") {
        window.location.href = "/Recuiter_Home";
      } else {
        window.location.href = "/Profile";
      }
    }





  }

  function closeModal() {
    setIsOpen(false);
  }
  const { setAuthenticated } = useContext(authContext);
  const { linkedInLogin } = useLinkedIn({
    clientId: '77i74lsnetj4nv',
    scope: 'r_emailaddress r_liteprofile',
    redirectUri: `${window.location.origin}/linkedin`, // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
    onSuccess: (code) => getAuthToken(code),
    onError: (error) => {
      console.log(error);
    },
  });
  Modal.setAppElement('#root');
  const getAuthToken = async (code) => {
    const baseApi = `${ApiConfig.LOGIN_API}`;

    const payload = new FormData();
    payload.append("c_id", "77i74lsnetj4nv");
    payload.append("code", code);
    payload.append("c_sc", "mMRoyX5lbpYfEnA0");
    payload.append("redirect_uri", `${window.location.origin}/linkedin`);
    payload.append("grant_type", "authorization_code");
    payload.append("login_type", "linkedin_web");
    /* var payload = {
      grant_type: 'authorization_code',
      code: code,
      client_id: "77i74lsnetj4nv",
      client_secret: "mMRoyX5lbpYfEnA0",
      redirect_uri: `${window.location.origin}/Seeker_Home`
    }; */
    const response = await fetch(baseApi, {
      method: "POST",
      body: payload,
    });
    const data = await response.json();
    console.log(data.user_details)
    if (data.first_time_user === false) {
      localStorage.setItem("USER_ID", data.user_details.id);
      localStorage.setItem(
        "USER_FULL_NAME",
        data.user_details.full_name
      );
      localStorage.setItem(
        "USER_PROFILE_PIC",
        data.user_details.profile_pic
      );
      localStorage.setItem("USER_EMAIL", data.user_details.email);
      localStorage.setItem(
        "USER_MOBILE",
        data.user_details.phone_number
      );
      localStorage.setItem("USER_ROLE", data.user_details.role);
      localStorage.setItem("USER_CITY", data.user_details.city);
      localStorage.setItem(
        "USER_COUNTRY",
        data.country
      );
      localStorage.setItem(
        "USER_AUTH_TOKEN",
        data.user_details.auth_token
      );

      setAuthenticated(true);
      localStorage.setItem("is_logged_in", true);
      if (data.user_details.role == "Recruiter") {
        window.location.href = "/Recuiter_Home";
      } else {
        window.location.href = "/Seeker_Home";
      }

    }
    else {
      settoken(data.user_details.auth_token)
      openModal()
    }

  }







  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >

        <form>

          <b>Are you a ? </b>
          <br></br>
          <br></br>

          <input type="radio" name='type' defaultChecked={role === "Job_seeker"} value="Job_seeker" onClick={handleRbChange} className='mx-2 my-2' ></input>
          Job Seeker
          <input type="radio" name='type' defaultChecked={role === "Recruiter"} value="Recruiter" onClick={handleRbChange} className='mx-2 my-2'></input>
          Recruiter       <br></br>
          <div className="login-nativeBtn-wrapper btn-desktop desktop-display-none">
            <button type="button" onClick={(e) => addRole(e)} className='login-nativeBtn my-3' >Submit</button>
          </div>
        </form>
      </Modal>
      <img
        onClick={linkedInLogin}
        src={linkedin}
        alt="Linked In"
        style={{ maxWidth: '180px', cursor: 'pointer' }}
      />
    </>

  );
}
export default LinkedInPage;