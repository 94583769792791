import React from 'react';
import Bankid_logo_small from '../assets/img/bankid_logo_small.svg'
import Chevron_right_colored from '../assets/img/chevron_right_colored.svg'
import Logo from '../assets/img/logo.png'
import Bankid_logo from '../assets/img/bankid_logo.svg'
import Language from '../assets/img/language.png'
import useTranslation from '../customHooks/translations';



function Bankid() {
  const translation = useTranslation();
  return (
    <div id="login" class="login">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 g-0 p-0">
              {/* <!-- <div class="login_ltblock text-center">
                <img src="img/logo.png" alt="">
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p>
              </div> --> */}


                <div class="doctor_leftimg">
                  <div class="overlay_content text-center">
                      <img src={Logo} class="mb-3" alt="" />
                      <p class="m-t-25 text-white">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p>
                  </div>
                  {/* <!-- <div class="overlay_logo">
                      <img src="img/logo.png">
                  </div> --> */}
              </div>

            </div>

            <div class="col-lg-6 col-md-12">
              <div class="login_rtblock position-relative pt-4">
                <div class="d-lg-flex d-md-flex justify-content-between">
                  <p>{translation.DONT_HAVE_AN_ACCOUNT} &nbsp; <a href="#" class="fw-600">{translation.REGISTER}</a></p>
                  <a href="#" class="fw-600">{translation.LANGUAGE} <img src={Language} alt="" class="ms-1" /></a>
                </div>

                <div class="form_block">
                  <div class="text-center">
                    <img src={Bankid_logo} alt="" />
                    <p class="fs-14 mt-3 mb-4">{translation.PERSONAL_BANKED_TO_LOGIN}</p>
                  </div>

                  <form action="action" class="">
                    <div class="input-group mb-4">
                      <input type="text" class="form-control" placeholder={translation.PERSONAL_ID} aria-label="PersonalID" aria-describedby="basic-addon1" />
                      <span class="input-group-text" id="basic-addon1"><img src={Bankid_logo_small} alt="" /></span>
                    </div>
                  </form>

                  <p class="fs-14">{translation.LOGIN_AGREE_LABEL_MSG}<a href="#" class="text-decoration-underline"> {translation.TERMS}</a>. {translation.LOGIN_PRIVACY_COOKIES_POLICY_LABEL_MSG} <a href="#" class="text-decoration-underline"> {translation.PRIVACY_POLICY}</a> and <a href="#" class="text-decoration-underline"> Cookies Plolicy</a>.</p>
                </div>

                
              </div>


              <div class="loginwithlinks">
                <div class="d-lg-flex d-md-flex justify-content-end">
                  <div class="loginwithbtn">
                    <p class="fs-22 fw-600 text-primary">{translation.LOGIN}  <button type="button" class="btn btn-outline-primary rounded-circle ms-2"><img src={Chevron_right_colored} alt="" /></button>
                    </p>
                  </div>
                </div>
              </div>

            </div>
        </div>
    </div>
  </div>
  )
 ;
}

export default Bankid;
