import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import eye from "../assets/img/eye.svg";
import next_arrow from "../assets/img/next_arrow.svg";
import chevron_right_colored from "../assets/img/chevron_right_colored.svg";
import useTranslation from '../customHooks/translations';

const RecruiterProfile = (props) => {
    const translation = useTranslation();
    return (
        <>
            <body className="logn">

                <section id="login" className="login">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 g-0 p-0">
                                {/* <div className="login_ltblock text-center">
                                    <img src="img/logo.png" alt="">
                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p>
                                </div> */}


                                <div className="doctor_leftimg rect_prof">
                                    <div className="overlay_content text-start">
                                        <h2 className="mb-3 text-white">{translation.PROFILE}</h2>
                                        <p className="m-t-25 text-white">{translation.SETUP_YOUR_PROFILE}</p>
                                    </div>
                                    {/* <div className="overlay_logo">
                                        <img src="img/logo.png">
                                    </div> */}
                                </div>

                            </div>

                            <div className="col-lg-6 col-md-12">
                                <div className="login_rtblock position-relative pt-4">
                                    <div className="d-lg-flex d-md-flex justify-content-end">
                                        <p>{translation.ALREADY_HAVE_A_ACCOUNT_LABLE} &nbsp; <a href="2_login.html" className="fw-600">{translation.LOGIN}</a></p>
                                    </div>

                                    <div className="form_block RecruiterProfile w-100 px-3">
                                        <form className="row g-3 text-start">
                                            <div className="col-md-6 regsiterrf">
                                                <label htmlFor="inputEmail4" className="form-label fs-14">{translation.INDUSTRY}</label>
                                                <input type="text" className="form-control" id="inputEmail4" />
                                            </div>
                                            <div className="col-md-6 regsiterrf">
                                                <label htmlFor="inputPassword4" className="form-label fs-14">{translation.DEPARTMENT}</label>
                                                <input type="text" className="form-control" id="inputPassword4" />
                                            </div>
                                            <div className="col-md-6 regsiterrf">
                                                <label htmlFor="inputEmail4" className="form-label fs-14">{translation.ROLE}</label>
                                                <input type="text" className="form-control" id="inputEmail4" />
                                            </div>
                                            <div className="col-md-3 regsiterrf">
                                                <label htmlFor="inputPassword4" className="form-label fs-14">{translation.CITY}</label>
                                                <input type="text" className="form-control" id="inputPassword4" />
                                            </div>
                                            <div className="col-md-3 regsiterrf">
                                                <label htmlFor="inputPassword4" className="form-label fs-14">{translation.COUNTRY}</label>
                                                <input type="text" className="form-control" id="inputPassword4" />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label htmlFor="inputPassword4" className="form-label fs-14">{translation.COMPANY_NAME}</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" aria-label="Upload Profile Pic" aria-describedby="basic-addon1" />
                                                    <span className="input-group-text" id="basic-addon1"><img src={eye} alt="" /></span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 regsiterrf">
                                                <label htmlFor="inputEmail4" className="form-label fs-14">{translation.COMPANY_SIZE}</label>
                                                <select className="form-select bg-transparent" aria-label="Default select example">
                                                    <option></option>
                                                    <option value="1">18</option>
                                                    <option value="2">24</option>
                                                    <option value="3">30</option>
                                                </select>
                                            </div>
                                            <div className="col-md-6 regsiterrf">
                                                <label htmlFor="inputPassword4" className="form-label fs-14">{translation.VAT_COMPANY_REGISTRATION_NUMBER}</label>
                                                <input type="text" className="form-control" id="inputPassword4" />
                                            </div>
                                            <div className="col-md-6 ">
                                                <label htmlFor="inputPassword4" className="form-label fs-14">{translation.ADD_YOUR_COMPANY_LINKEDIN_PROFILE}</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" aria-label="Upload Profile Pic" aria-describedby="basic-addon1" />
                                                    <span className="input-group-text" id="basic-addon1"><img src={next_arrow} alt="" /></span>
                                                </div>
                                            </div>

                                        </form>
                                    </div>


                                </div>


                                <div className="loginwithlinks">
                                    <div className="d-lg-flex d-md-flex justify-content-end">
                                        <div className="loginwithbtn">
                                            <p className="fs-22 fw-600 text-primary">{translation.REGISTER}  <Link to="/Recuiter_Home"><button type="button" className="btn btn-outline-primary rounded-circle ms-2"><img src={chevron_right_colored} alt="" /></button></Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            </body>

        </>
    );
}
export default RecruiterProfile
