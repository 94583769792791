import { Link, useLocation } from "react-router-dom";
import Header from "../../sharedComponents/Header";
import block_company from "../../assets/img/block_company.svg";
import chat from "../../assets/img/chat.svg";
import thumbs_up from "../../assets/img/thumbs_up.svg";
import play_video from "../../assets/img/play_video.svg";
import profile_banner_bg from "../../assets/img/profile_banner_bg.svg";
import nvidia_logo_big from "../../assets/img/nvidia_logo_big.png";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import useTranslation from "../../customHooks/translations";
import { useEffect, useState } from "react";
import { getWithAuthCallWithErrorResponse, postWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
const Match_Profile = () => {
  const state = useLocation().state;
  const [userDetails, setUserDetails] = useState({})
  useEffect(() => {
    getUserDetails();
  }, [])
  const getUserDetails = () => {
    getWithAuthCallWithErrorResponse(ApiConfig.GET_PROFILE_DETAILS_JOB_SEEKER + state.user.id)
      .then((data) => {
        console.log(data.json);
        setUserDetails(data.json)
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const translation = useTranslation();
  const langaugelevel = (level) => {
    if (level == "Beginner") {
      return (
        <>
          <div class="row skills_progress">
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
          </div>
        </>
      );
    } else if (level == "Competent")
      return (
        <>
          <div class="row skills_progress">
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
          </div>
        </>
      );
    else if (level == "Proficient")
      return (
        <>
          <div class="row skills_progress">
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
          </div>
        </>
      );
    else
      return (
        <>
          <div class="row skills_progress">
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
          </div>
        </>
      );
  };
  function blockJobSeeker() {
    let requestBody = JSON.stringify({
      user_id: state.user.id,
    });
    postWithAuthCallWithErrorResponse(ApiConfig.BLOCK_JOB_SEEKER, requestBody)
      .then((data) => {
        if (data.error == true) {
          toast.error(translation.ERROR_TRY_AFTER_SOMETIME, { autoClose: 1000 });
        } else {
          toast.success(data.json.message, { autoClose: 1000 });
        }
      })
      .catch((error) => {
        toast.error(translation.ERROR_TRY_AFTER_SOMETIME, { autoClose: 1000 });
      });
  }
  const SetInterested = () => {
    postWithAuthCallWithErrorResponse(
      ApiConfig.JOB_RECRUITER_TINDERL,
      JSON.stringify({
        action: "accept",
        job_id: state.job_id,
        user_id: state.user.id,
      })
    ).then((res) => {
      toast.success(res.json.message, { autoClose: 1000 });
    })
      .catch((err) => console.log(err));

  };
  return (
    <div>
      <RecruiterHeader />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item">
                    <Link to="/Seeker_Profile">{translation.PROFILE}</Link>
                  </li>
                  <li class="breadcrumb-item">
                    <Link to="/recruiter-matching-profiles">
                      {translation.MATCHED_PROFILES}
                    </Link>
                  </li>
                  {/* <li class="breadcrumb-item">
                    Match Profile
                    </li> */}
                  {/* <li class="breadcrumb-item active" aria-current="page"></li> */}
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="profile" class="profile">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12 g-0">
              <img src={profile_banner_bg} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="card profile_lfcard">
                <div class="card-body">
                  <div class="profile_cardimg text-center">
                    <img src={userDetails.user && userDetails.user.profile_pic || nvidia_logo_big} alt="" />
                  </div>
                  <div className="text-center mt-4">
                    <h3 className="fw-600 fs-18">{translation.JOB_SEEKER}</h3>
                    <p className="fs-14">
                      {userDetails.user && userDetails.user.about || ""}
                    </p>
                  </div>
                  <div class="profile_actionbtn text-center py-3">
                    <button type="button" class="btn rounded-circle" onClick={blockJobSeeker}>
                      <img src={block_company} alt="" />
                    </button>

                    <div class="btn-group profilebtn_block jobprofile_block">
                      <button
                        type="button"
                        class="btn dropdown-toggle rounded-circle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={thumbs_up} alt="" />
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li class="profileaction_bg">
                          <button class="dropdown-item" type="button" onClick={SetInterested}>
                            {translation.INTERESTED}
                          </button>
                        </li>
                      </ul>
                    </div>

                    {/* <div class="btn-group profilebtn_block jobprofile_block">
                      <button
                        type="button"
                        class="btn dropdown-toggle rounded-circle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={chat} alt="" />
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li class="profileaction_bg">
                          <Link to="/Chat">
                            {" "}
                            <button class="dropdown-item" type="button">
                              {translation.CHAT}
                            </button>{" "}
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                  <hr class="mt-2 mb-4" />
                  <div className="d-flex justify-content-between">
                    {(userDetails.user && userDetails.user.video) && (<>
                      <h4 className="fs-16 m-0">
                        {translation.PLAY_INTRO_VIDEO}{" "}
                        {/* <span className="fs-14 ms-2">(10:30)</span> */}
                      </h4>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#HomeUpgrade"
                      >
                        <img src={play_video} alt="" />
                      </a>
                    </>)}
                  </div>
                </div>
                <div
                  class="modal fade"
                  id="HomeUpgrade"
                  tabIndex="-1"
                  aria-labelledby="HomeUpgradeLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body text-center">
                        <video width={"100%"} controls src={userDetails.user && userDetails.user.video} />
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                      >
                        {translation.CLOSE}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="profile_rfcard pt-5 px-4">
                <ul
                  className="profile-tabWrapper nav nav-tabs"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="BasicInfo-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#BasicInfo"
                      type="button"
                      role="tab"
                      aria-controls="BasicInfo"
                      aria-selected="true"
                    >
                      {translation.BASIC_PROFILE}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Education-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Education"
                      type="button"
                      role="tab"
                      aria-controls="Education"
                      aria-selected="false"
                    >
                      {translation.EDUCATION}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Jobs-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Jobs"
                      type="button"
                      role="tab"
                      aria-controls="Jobs"
                      aria-selected="false"
                    >
                      {translation.EXPERIANCE}
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Preferences-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Preferences"
                      type="button"
                      role="tab"
                      aria-controls="Preferences"
                      aria-selected="false"
                    >
                      {translation.PREFERNCES}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="AdvancedSkills-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#AdvancedSkills"
                      type="button"
                      role="tab"
                      aria-controls="AdvancedSkills"
                      aria-selected="false"
                    >
                      {translation.SKILLS}
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="BasicInfo"
                    role="tabpanel"
                    aria-labelledby="BasicInfo-tab"
                  >
                    {/* <div className="d-flex justify-content-end mb-4">
                      <div className="profile_actionbtn">
                        <Link to="/Edit_Basic_Info" data-tip="Edit Basic Info">
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px; height: 42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div> */}
                    {/* <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">Job Title</p>
                      <p className="m-0 profile_role">Android Developer</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">Location</p>
                      <p className="m-0 profile_role">Tottenham, England</p>
                    </div> */}
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.EMAIL}</p>
                      <p className="m-0 profile_role">{userDetails.user && userDetails.user.email || translation.NO_RECORDS_FOUND}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.MOBILE_NUMBER}</p>
                      <p className="m-0 profile_role">{userDetails.user && userDetails.user.phone_number || translation.NO_RECORDS_FOUND}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.DATE_OF_BIRTH}</p>
                      <p className="m-0 profile_role">{userDetails.user && userDetails.user.dob || translation.NO_RECORDS_FOUND}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.EXPERIANCE}</p>
                      <p className="m-0 profile_role">{userDetails.user && userDetails.user.user_experience_level || translation.NO_RECORDS_FOUND}</p>
                    </div>
                    {state.user.user.linkedin_profile &&
                      <div className="d-flex justify-content-between mb-4">
                        <p className="m-0">{translation.LINKEDIN_PROFILE}</p>
                        <Link to={userDetails.user && userDetails.user.linkedin_profile}></Link><a
                          href="ca.linkedin.com/in/natwest"
                          className="profile_role"
                          target="_blank"
                        >
                          {userDetails.user && userDetails.user.linkedin_profile}
                        </a>
                      </div>
                    }
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Education"
                    role="tabpanel"
                    aria-labelledby="Education-tab"
                  >
                    {/* <div className="d-flex justify-content-end mb-4">
                      <div className="profile_actionbtn">
                        <Link
                          to="/Edit_Education"
                          data-tip="Edit Education Info"
                        >
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px; height: 42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div> */}
                    <div className="edulevels">
                      {userDetails.education && userDetails.education.length ? userDetails.education.map(edu => {
                        return (<>
                          <div className="levelcircle active mt-4">
                            <h3 className="fs-16">{edu.education_type}</h3>
                            <p className="fs-14 m-0">
                              {edu.institute_name}
                            </p>
                            <p className="fs-14 m-0">
                              {edu.area_of_study + "Batch" + "of" + edu.year_of_graduation}
                            </p>
                          </div>
                        </>)
                      }) : translation.NO_RECORDS_FOUND}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Jobs"
                    role="tabpanel"
                    aria-labelledby="Jobs-tab"
                  >
                    {/* Exprience */}
                    {/* <div className="d-flex justify-content-end mb-4">
                      <div className="profile_actionbtn">
                        <Link
                          to="/Edit_Experiance"
                          data-tip="Edit Exprience Info"
                        >
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px; height: 42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div> */}

                    <div className="edulevels">
                      {userDetails.education && userDetails.job ? userDetails.job.map(edu => {
                        return (<>
                          <div className="levelcircle active mt-4">
                            <h3 className="fs-16">{edu.job_type}</h3>
                            <p className="fs-14 m-0">{edu.role}</p>
                            <p className="fs-14 m-0">{edu.start_year} -{edu.end_year || "Present"}</p>
                          </div>
                        </>)
                      }) : translation.NO_RECORDS_FOUND}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="AdvancedSkills"
                    role="tabpanel"
                    aria-labelledby="AdvancedSkills-tab"
                  >
                    {/* Advance Skills  */}
                    {/* <div className="d-flex justify-content-end mb-4">
                      <div className="profile_actionbtn">
                        <Link
                          to="/Edit_Advanced_Skills"
                          data-tip="Edit_Advanced_Skills"
                        >
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px; height: 42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div> */}
                    <div class="skills_jobs">
                      <h4 class="mb-4">{translation.SKILLS}</h4>

                      <div class="row mb-5">
                        {userDetails.skill && userDetails.skill.length ? state.user.skill.map(skl => {
                          return (<>
                            <div class="col-lg-3 col-md-6 g-5 mt-0 pt-0">
                              <p class="m-0">
                                {skl.skill}
                                <span>
                                  <br />
                                  {skl.level}
                                </span>
                              </p>

                              {langaugelevel(skl.level)}
                            </div>

                          </>)
                        }) : translation.NO_RECORDS_FOUND}

                      </div>

                      <h4 class="mb-4">{translation.TOOLS_REQUIRED}</h4>

                      <div class="row mb-5">
                        {state.user.tools.map(tool => {
                          return (<>
                            <div class="col-lg-3 col-md-6 g-5 mt-0 pt-0">
                              <p class="m-0">
                                {tool.tool} <span>[{tool.level}]</span>
                              </p>

                              {langaugelevel(tool.level)}
                            </div>
                          </>)
                        })}
                      </div>
                      <div className="skills_jobs justify-content-between mb-4">
                        <h4 class="mb-2 ">{translation.CERTIFICATES}</h4><br />
                        <p class="m-0" >{state.user.certificate ? state.user.certificate.map(cer => { return (<><p>{cer.user_certificates}</p> </>) }) : translation.NO_RECORDS_FOUND}</p>
                      </div>
                      <div className="skills_jobs d-flex justify-content-between mb-4">
                        <h4 className="m-0">{translation.DRIVING_LICENSE}</h4>
                        <p className="m-0 profile_role">{`${state.user.user.driving_license_available}`}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Preferences"
                    role="tabpanel"
                    aria-labelledby="Preferences-tab"
                  >
                    {/* <div className="d-flex justify-content-end mb-4">
                      <div className="profile_actionbtn">
                        <Link to="/Edit_preferences" data-tip="Edit Basic Info">
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px; height: 42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div> */}
                    {/* Preferences */}
                    <div class="skills_jobs">
                      <h4 class="mb-4">{translation.LANGAUGES}</h4>

                      <div class="row mb-5">
                        {state.user.languages ? state.user.languages.map(lng => {
                          <div class="col-lg-3 col-md-6 g-5 mt-0 pt-0">
                            <p class="m-0">
                              {lng.language}
                              <span>
                                <br />
                                [{lng.level}]
                              </span>
                            </p>

                            {langaugelevel(lng.level)}
                          </div>
                        }) :
                          translation.NO_RECORDS_FOUND
                        }
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <p class="mb-2">{translation.INDUSTRY}</p>
                        <p class="m-0">{state.user.user.industry || translation.NO_RECORDS_FOUND}</p>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <p className="m-0">{translation.MATCHING_PERCENTAGE}</p>
                        <p class="m-0">{state.percent + "%" || translation.NO_RECORDS_FOUND}</p>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <p className="m-0">{translation.JOB_TITLE}</p>
                        <p class="m-0">{state.user.user.designation || translation.NO_RECORDS_FOUND}</p>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <p className="m-0">{translation.MONTHLY_SALARY}</p>
                        <p className="m-0 profile_role">{state.user.user.monthy_salary || translation.NO_RECORDS_FOUND}</p>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <p className="m-0">{translation.DESIRED_LOCATION}</p>
                        <p className="m-0 profile_role">{state.user.user.city}</p>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <p class="mb-2">{translation.JOB_TYPE}</p>
                        <p class="m-0">{state.user.user.job_type || translation.NO_RECORDS_FOUND}</p>
                      </div>
                      <div className="d-flex justify-content-between mb-4">
                        <p class="mb-2">{translation.WORK_LOCATION}</p>
                        <p class="m-0">{state.user.user.work_location || translation.NO_RECORDS_FOUND}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Match_Profile;
