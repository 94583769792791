import React, { useState, useEffect } from "react";
import camera from "../../../assets/img/camera.svg";
import Select from 'react-select';
import ApiConfig from "../../../api/ApiConfig";
import { getWithAuthCallWithErrorResponse } from "../../../api/ApiServices";
import useTranslation from "../../../customHooks/translations";

export default function CompanyInfo(props) {
  const translation = useTranslation();
  const [state, setState] = useState({
    user_profile_pic: props.jobDetails ? props.jobDetails.user_profile_pic : "",
    profile_pic: props.jobDetails ? props.jobDetails.profile_pic : "",
    company_name: props.jobDetails ? props.jobDetails.company_name : "",
    about_company: props.jobDetails ? props.jobDetails.about_company : "",
    industry: props.jobDetails ? props.jobDetails.industry : "",
    company_linkedin: props.jobDetails ? props.jobDetails.company_linkedin : "",
    selected_industry: props.jobDetails ? props.jobDetails.selected_industry : [],
  });

  const [industryState, setIndustryState] = useState();

  const hiddenFileInput = React.useRef(null);
  //console.log(state, "locationState");

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleImageChange = async (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      /*this.setState({
            image: file,
            imagePreviewUrl: reader.result
        });*/
      setState({
        ...state,
        profile_pic: file,
        profile_pic_name: file.name,
        user_profile_pic: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };
  function handleSaveAndNext() {
    props.saveCompanyInfo(state);
    //console.log(state, "state");
    props.handleNext();
  }

  /* industry section starts */
  const getIndustryList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.INDUSTRY_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseIndustryList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  }

  const parseIndustryList = (data) => {
    let temp_industries = [];
    data.map((item, index) => {
      temp_industries.push({ label: item.name, value: item.id });
    });
    setIndustryState(temp_industries);
  }

  function onIndustryChange(value) {
    setState({ ...state, industry: value.label, selected_industry: value });
  }
  /* industry section ends */

  const selectStyles = {
    control: provided => ({ ...provided, backgroundColor:'transparent', borderRadius:"8px", border:"1px solid #b3c2c9",fontSize:"13px"}),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)', backgroundColor:'transparent', }),
  };

  useEffect(() => {
    getIndustryList();
  }, []);

  return (
    <>

      <div className="col-lg-8 col-md-12">
        <div className="card login settings_card create_job_card_profile">
          <div className="card-body">
            <div className="form_block w-100 pt-0">
              <form className="row g-3 text-start">
                <div className="col-md-12">
                  <div className="upload_profileimg mb-4">
                    <div className="profle_circle">
                      <div className="profle_upload">
                        <img
                          src={state.user_profile_pic}
                          alt=""
                          style={{
                            borderRadius: "50%",
                            width: "94px",
                            height: "94px",
                          }}
                        />
                        <a href="#" data-tip={translation.UPLOAD_PICTURE} onClick={handleClick}>
                          <img src={camera} className="upload-button" alt="" />
                        </a>
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                          accept="image/*"
                          id="profilePicFile"
                          onChange={(e) => handleImageChange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 regsiterrf">
                  <label htmlFor="inputEmail4" className="form-label fs-14">
                    {translation.COMPANY_NAME}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    value={state.company_name}
                    onChange={(e) => {
                      setState({
                        ...state,
                        company_name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-md-6 regsiterrf">
                  <label htmlFor="inputPassword4" className="form-label fs-14">
                   {translation.INDUSTRY}
                  </label>
                  <Select
                    isClearable={false}
                    onChange={onIndustryChange}
                    options={industryState}
                    placeholder={translation.SEARCH}
                    styles={selectStyles}
                    //tabSelectsValue={false}
                    value={state.selected_industry}
                  />
                </div>
                <div className="col-md-12 regsiterrf mb-4">
                  <label htmlFor="inputPassword4" className="form-label fs-14">
                   {translation.ABOUT_THE_COMPANY}
                  </label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="5"
                    style={{ height: "69px" }}
                    value={state.about_company}
                    onChange={(e) => {
                      setState({
                        ...state,
                        about_company: e.target.value,
                      });
                    }}
                  ></textarea>
                </div>
                <div className="col-md-12 regsiterrf">
                  <label htmlFor="inputEmail4" className="form-label fs-14">
                    {translation.COMPANY_LINKEDIN_PROFILE}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    value={state.company_linkedin}
                    onChange={(e) => {
                      setState({
                        ...state,
                        company_linkedin: e.target.value,
                      });
                    }}
                  />
                </div>
                <hr className="mt-4" />
                <div className="card_footer_btn d-flex justify-content-between pt-0 mt-0">
                  <p
                    className="fw-600 fs-18"
                    style={{ color: "#B3C2C9" }}
                    onClick={() => {
                      props.handlePrevious();
                    }}
                  >
                   {translation.PREV}
                  </p>
                  <a
                    onClick={() => {
                      handleSaveAndNext();
                    }}
                    className="fw-600 fs-18"
                    style={{ cursor: "pointer" }}
                  >
                    {translation.NEXT}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
