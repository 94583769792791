const BASE_URL = "https://workr.redbytes.in/";
const PATH = "api/";

const ApiConfig = {
  BASE_URL: BASE_URL /* base url */,
  LOGIN_API: BASE_URL + PATH + "login/" /* c */,
  REGISTER_API: BASE_URL + PATH + "signup/" /* c */,
  OTP_VERIFICATION_API: BASE_URL + PATH + "otp_verification/" /* c */,
  RESEND_OTP_API: BASE_URL + PATH + "generate_otp/" /* c */,
  FORGOT_PASSWORD_API: BASE_URL + PATH + "forgetpassword/" /* c */,
  RESET_PWORD_API: BASE_URL + PATH + "setpassword/" /* c */,
  RECRUITER_DETAILS_API: BASE_URL + PATH + "recruiter_details/" /* r */,
  UPDATE_PROFILE_API: BASE_URL + PATH + "update_profile/" /* c */,
  RECRUITER_HIRING_STATUS_API: BASE_URL + PATH + "actively_hiring/" /* r */,
  LOOKING_FOR_JOB_API: BASE_URL + PATH + "looking_for_job/" /* r */,
  CREATE_JOB_AD_API: BASE_URL + PATH + "create_job_new/" /* r */,
  CONTACT_US_API: BASE_URL + PATH + "contact_us/" /* c */,
  DELETE_ACCOUNT_API: BASE_URL + PATH + "account_delete/" /* c */,
  RECRUITER_CHANGE_JOB_STATUS_API: BASE_URL + PATH + "job_status/" /* r */,
  RECRUITER_JOB_LISTING_BY_STATUS_API:
    BASE_URL + PATH + "create_job/?status=" /* r */,
  MATCHING_JOB_API: BASE_URL + PATH + "matching_job/" /* j */,
  MATCHING_PROFILE_LISTING_API:
    BASE_URL + PATH + "profile_listing/?job_id=" /* r */,
  BLOCK_COMPANY_API: BASE_URL + PATH + "block_company/" /* c */,
  FAVOURITE_JOB_API:
    BASE_URL + PATH + "favourite_job/" /* c- getList/get single/post/delete */,
  FAVOURITE_JOB_API_RECRUITER:
    BASE_URL +
    PATH +
    "favourite_profile/" /* c- getList/get single/post/delete */,
  EXPERIENCE_LISTING_API: BASE_URL + PATH + "experience_list/" /* c */,
  LANGUAGE_LISTING_API: BASE_URL + PATH + "languages/" /* c */,
  JOBROLE_LISTING_API: BASE_URL + PATH + "job_role/" /* c */,
  INDUSTRY_LISTING_API: BASE_URL + PATH + "industries/" /* c */,
  SKILLS_LISTING_API: BASE_URL + PATH + "skills/" /* c */,
  TOOLS_LISTING_API: BASE_URL + PATH + "tools/" /* c */,
  CERTIFICATES_LISTING_API: BASE_URL + PATH + "certificates/" /* c */,
  RECRUITER_EXPLORE_PAGE_API: BASE_URL + PATH + "explore_api/" /* r */,
  PROFILE_LISTING_BY_JOB_TITLE_API:
    BASE_URL + PATH + "explorepageprofilelisting/?designation=",
  JOBTYPE_LISTING_API: BASE_URL + PATH + "job_type/" /* c */,
  BLOCK_JOB_SEEKER: BASE_URL + PATH + "block_user/" /* recruiter */,
  GET_JOB_SEEKER_PROFILE: BASE_URL + PATH + "user_profile/",
  EXPERIENCE_API: BASE_URL + PATH + "add_experience/",
  ADD_EDUCATION: BASE_URL + PATH + "add_education/",
  EXPLORE_JOB_SEEKER: BASE_URL + PATH + "explore_api/",
  JOB_ROLE_LIST: BASE_URL + PATH + "job_role/",
  SKILLS_API: BASE_URL + PATH + "prerequisite/",
  PROFILE_LISTING: BASE_URL + PATH + "profileListing/",
  USER_PROFILE: BASE_URL + PATH + "user_profile/",
  VIDEO_INTRO_JOBSEEKER: BASE_URL + PATH + "intro_video/",
  CHANGE_PASS: BASE_URL + PATH + "change_password/",
  USER_PROFILE: BASE_URL + PATH + "user_profile/",
  PROFILE_SEARCH_API: BASE_URL + PATH + "profile_search/",
  FILTER_JOB_SEEKER: BASE_URL + PATH + "jobfilter/",
  JOB_SEARCH_SEEKER: BASE_URL + PATH + "/job_search/",
  JOB_SEEKER_TINDERL: BASE_URL + PATH + "jobseekertinderswipe/",
  JOB_RECRUITER_TINDERL: BASE_URL + PATH + "recruitertinderswipe/",
  UPDATE_SKILLS_JOB_SEEKER: BASE_URL + PATH + "update_skill/",
  ADD_PREREUESITE_JOB_SEEKER: BASE_URL + PATH + "prerequisite/",
  Add_PREFERENCE_JOB_SEEKER: BASE_URL + PATH + "create_preferences/",
  EXPLORER_JOB_BY_INDUSTRY: BASE_URL + PATH + "job_listing_on_basis_of_industry/?industry=",
  TERMS_AND_CONDITION: BASE_URL + PATH + "terms_and_conditions/",
  PRIVACY_POLICY: BASE_URL + PATH + "privacy/",
  SET_APP_LANGUAGE: BASE_URL + PATH + "set_app_language/",
  DELETE_PROFILE: BASE_URL + PATH + "account_delete/",
  HIDE_PROFILE_STATUS_FROM_RECRUITER: BASE_URL + PATH + "hide_profile/",
  NOTIFICATION_LIST: BASE_URL + PATH + "notification_list/",
  PROFILE_FILTER: BASE_URL + PATH + "profilefilter/",
  ADD_ROLE_LINKEDIN_LOGIN: BASE_URL + PATH + "add_role/",
  JOB_BY_ID: BASE_URL + PATH + "create_job/",
  GET_PROFILE_DETAILS_JOB_SEEKER: BASE_URL + PATH + "getjobseekerdetailsfromlist/?user_id=",
  GET_MATCHINgJOB: BASE_URL + PATH + "matchingjob"
};
export default ApiConfig;
