import React from 'react';
import { Link } from 'react-router-dom';
import useTranslation from '../../../customHooks/translations';
import Header from '../../../sharedComponents/Header';

function Setting_BlockUser() {
  const translation = useTranslation();
  return (
  <div>
<Header />
<section class="breadcrumbs_block">
        <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                      <li class="breadcrumb-item"><Link to="/Setting">{translation.SETTINGS}</Link></li>
                      <li class="breadcrumb-item active" aria-current="page">{translation.BLOCKING}</li>
                    </ol>
                </nav>
              </div>
            </div>
          </div>
    </section>

    <section id="settings" class="settings">
      <div class="container">
          <div class="row">
              <div class="col-lg-6 col-md-12 offset-lg-3">
                <div class="text-center mt-5">
                  <p>{translation.BLOCK_COMPANY_LABEL_MSG}</p>
                  <form action="#">
                    <div class="settings_userblock input-group">
                        <input type="text" class="form-control" placeholder={translation.TYPE_NAME_HERE} aria-label="Type name here" aria-describedby="button-addon2" />
                        <button class="btn btn-primary" type="button" id="button-addon2">{translation.BLOCK}</button>
                    </div>
                  </form>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="blocked_list mt-5">
                    <h2 class="fs-16">{translation.BLOCKED_COMPANY_LIST}</h2>

                    <table class="table table-borderless" style={{borderCollapse: "separate",borderSpacing:"0 0.5em"}}>
                        <thead>
                            <tr>
                              <th scope="col">{translation.S_No}</th>
                              <th scope="col">{translation.COMPANY_NAME}</th>
                              <th scope="col" class="text-end">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td scope="row">1</td>
                              <td>Vipro</td>
                              <td class="text-end"><a href="#">{translation.UNBLOCK}</a></td>
                            </tr>
                            <tr>
                              <td scope="row">2</td>
                              <td>TCS </td>
                              <td class="text-end"><a href="#">{translation.UNBLOCK}</a></td>
                            </tr>
                            <tr>
                                <td scope="row">3</td>
                                <td>TCS </td>
                                <td class="text-end"><a href="#">{translation.UNBLOCK}</a></td>
                            </tr>
                          </tbody>
                    </table>
                </div>
              </div>

          </div>
      </div>
    </section>
  </div>
  )
}

export default Setting_BlockUser;
