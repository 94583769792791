import { createContext, useEffect, useState } from "react";
import ApiConfig from "../api/ApiConfig";
import { getWithAuthCallWithErrorResponse } from "../api/ApiServices";

export const UserContext = createContext();

export default function UserContextProvider({ children }) {
    const [UserData, setUserData] = useState([]);
    const getUserData = () => {
        getWithAuthCallWithErrorResponse(ApiConfig.USER_PROFILE)
            .then((data) => {
                if (!data.error) {
                    setUserData(data.json)
                }

            })
            .catch((error) => {
            });
    }
    useEffect(() => {
        getUserData()
    }, [])
    return (
        <UserContext.Provider value={{ UserData, getUserData }}>
            {children}
        </UserContext.Provider>
    );
}
