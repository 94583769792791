import React, { useContext, useEffect, useState } from "react";
import english_lang from "../assets/img/english_lang.png";
import hindi_lang from "../assets/img/hindi.png";
import swedish_lang from "../assets/img/swedish_lang.png";
import marathi_lang from "../assets/img/ic_marathi.svg";
import { useLanguageContext } from "../contexts/LanguageContext";
import useTranslation from "../customHooks/translations";
import ApiConfig from "../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../api/ApiServices";
import { toast } from "react-toastify";
const langs = [
  {
    language: "English",
    code: "IN_en",
    icon: english_lang,
  },
  {
    language: "Hindi",
    code: "IN_hn",
    icon: hindi_lang,
  },
  {
    language: "Marathi",
    code: "IN_mr",
    icon: hindi_lang,
  },
  {
    language: "Sweden",
    code: "SE_sv",
    icon: swedish_lang,
  },
];
function SelectLanguage(props) {
  const translation = useTranslation();
  const { language, changeLanguage, changeLanguageIcon } = useLanguageContext();
  const setDefaultLangIcon = (langCode) => {
    const objLang = langs.filter((lang) => lang.code === langCode);
    let selectedLangIcon =
      objLang && objLang.length > 0 ? objLang[0].icon : english_lang;
    changeLanguage(langCode);
    changeLanguageIcon(selectedLangIcon);
    return selectedLangIcon;
  };

  const [state, setState] = useState({
    LangIcon: setDefaultLangIcon(localStorage.getItem("language")),
  });
  const onChangeHandle = (e) => {
    const { value } = e.currentTarget;
    let selectedLangIcon = setDefaultLangIcon(value);
    const objLang = langs.filter((lang) => lang.code === value);
    let requestBody = JSON.stringify({ "app_language": objLang[0].language })
    if (localStorage.getItem("USER_AUTH_TOKEN"))
      postWithAuthCallWithErrorResponse(ApiConfig.SET_APP_LANGUAGE, requestBody)
        .then((data) => {
          toast.success(data.json.message, { autoClose: 1000 })
          localStorage.setItem("language", objLang[0].code)
        })
        .catch((error) => {
          setState({ ...state, isProcessing: false });
        });
  };

  return (
    <ul className="dropdown-menu dropdown-menu-end">
      <li className="lang_bg">{translation.SELECT_LANGUAGE}</li>
      {langs.map((lang, index) => {
        return (
          <li key={index}>
            <button
              className={
                "dropdown-item" + (lang.code === language ? " active" : "")
              }
              type="button"
              value={lang.code}
              onClick={onChangeHandle}
            >
              <img src={lang.icon} alt="" />
              {lang.language}
            </button>
          </li>
        );
      })}
    </ul>
  );
}

export default SelectLanguage;
