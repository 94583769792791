import React, { useContext, useState } from "react";
import Logo from "../assets/img/logo.png";
import logoColorWelcome from "../assets/img/logo_color_welcome.png";
import Language from "../assets/img/language.png";
import Mail_icon from "../assets/img/mail_icon.svg";
import Pwd_icon from "../assets/img/pwd_icon.svg";
import Chevron_right_colored from "../assets/img/chevron_right_colored.svg";
import authContext from "./GetUserStatus";
import { simplePostCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import Linedin from "../assets/img/linedin.svg";
import Bankid from "../assets/img/bankid.svg";
import Linkedin_icn from "../assets/img/linkedin_icn.svg";
import bankid_icn from "../assets/img/bankid_icn.svg";
import { Link, NavLink } from "react-router-dom";
import swedish_lang from "../assets/img/swedish_lang.png";
import Hindi from "../assets/img/hindi.png";
import Marathi from "../assets/img/ic_marathi.svg";
import english_lang from "../assets/img/english_lang.png";
import german_lang from "../assets/img/german_lang.png";
import check_job from "../assets/img/check_job.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginWithLinkedin from "../sharedComponents/LinkedLogin";
import { LinkedIn } from "react-linkedin-login-oauth2";
import LinkedInPage from "../sharedComponents/LinkedLogin";
import useTranslation from "../customHooks/translations";
import SelectLanguage from "../sharedComponents/SelectLanguage";
import { useLanguageContext } from "../contexts/LanguageContext";

function Login() {
  const { language, changeLanguage, changeLanguageIcon } = useLanguageContext();
  const translation = useTranslation();
  const { setAuthenticated } = useContext(authContext);
  const [state, setState] = useState({
    userName: "",
    password: "",
    deviceToken: "",
    deviceID: "",
    isProcessing: false,
    invalid: false,
    isLoggedIn: false,
    successMSG: "",
    LangIcon: english_lang,
  });




  const clearLocalStorage = () => {
    const lang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "IN_en";
    localStorage.clear();
    localStorage.setItem("language", lang);
  };
  if (localStorage.getItem("successMSG")) {
    setState({ ...state, successMSG: localStorage.getItem("successMSG") });
    clearLocalStorage();
  }

  const getLoginDetails = () => {
    clearLocalStorage();
    setState({ ...state, isProcessing: true });
    let requestBody = JSON.stringify({
      app_language: language,
      user_id: state.userName.toLowerCase(),
      password: state.password,
      device_type: "web",
      device_token: state.deviceToken,
    });
    localStorage.setItem("is_logged_in", false);
    simplePostCallWithErrorResponse(ApiConfig.LOGIN_API, requestBody)
      .then((data) => {
        setState({ ...state, isProcessing: false, isLoggedIn: false });
        if (data.error == true) {
          //document.getElementById("error_msg").style.display = "block";
          //document.getElementById("error_msg").innerHTML = data.json.message;
          toast.error(data.json.message, { autoClose: 1000 });
        } else {
          setState({ ...state, isProcessing: false, isLoggedIn: true });
          document.getElementById("error_msg").style.display = "none";

          if (data.json.otp_verify) {
            localStorage.setItem("USER_ID", data.json.user_details.id);
            localStorage.setItem(
              "USER_FULL_NAME",
              data.json.user_details.full_name
            );
            localStorage.setItem(
              "USER_PROFILE_PIC",
              data.json.user_details.profile_pic
            );
            localStorage.setItem("USER_EMAIL", data.json.user_details.email);
            localStorage.setItem(
              "USER_MOBILE",
              data.json.user_details.phone_number
            );
            localStorage.setItem("USER_ROLE", data.json.user_details.role);
            localStorage.setItem("USER_CITY", data.json.user_details.city);
            localStorage.setItem(
              "USER_COUNTRY",
              data.json.user_details.country
            );
            localStorage.setItem(
              "USER_AUTH_TOKEN",
              data.json.user_details.auth_token
            );

            setAuthenticated(true);
            localStorage.setItem("is_logged_in", true);
            if (data.json.user_details.role == "Recruiter") {
              window.location.href = "/Recuiter_Home";
            } else {
              window.location.href = "/Seeker_Home";
            }
          } else {
            alert(translation.ALERT_OTP_NOT_VERIFIED);
          }
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      getLoginDetails();
    }
  };
  const onLangChange = (e) => {
    // const { value } = e.currentTarget;
    // let selectedLangIcon = english_lang;
    // switch (value) {
    //   case "en":
    //     selectedLangIcon = english_lang;
    //     break;
    //   case "se":
    //     selectedLangIcon = swedish_lang;
    //     break;
    //   case "de":
    //     selectedLangIcon = german_lang;
    //     break;
    // }
    setState({ ...state, language: e });
  };
  return (
    <div className="login_outerWrapper">
      <ToastContainer />
      <section className="login">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-md-12 g-0 p-0 left-section">
              {/* <!-- <div className="login_ltblock text-center">
                  <img src="img/logo.png" alt="" />
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p>
                </div> --> */}

              <div className="doctor_leftimg mobile-display-none">
                <div className="overlay_content text-center">
                  <img src={Logo} className="mb-3" alt="" />
                  <p className="m-t-25 text-white">
                    {translation.PRODUCT_INTRO}
                  </p>
                </div>
                {/* <!-- <div className="overlay_logo">
                        <img src="img/logo.png /">
                    </div> --> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-12 right-register">
              <div className="login_rtblock position-relative pt-4">
                <div className="d-lg-flex d-md-flex justify-content-between mobile-display-none">
                  <p>
                    {translation.DONT_HAVE_AN_ACCOUNT} &nbsp;{" "}
                    <NavLink to="/register" className="fw-600">
                      {translation.REGISTER}
                    </NavLink>
                  </p>
                  <ul className="navbar-nav ms-auto pb-4">
                    <li className="nav-item lang_dropdown">
                      <div className="btn-group">
                        <a
                          href="#"
                          className="fw-600 dropdown-toggle nav-link lang_flag me-3"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {translation.LANGUAGE}{" "}
                          {/* <img src={Language} alt="" className="ms-1"  data-tip={translation.SELECT_LANGUAGE}/> */}
                        </a>
                        <SelectLanguage />
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="form_block">
                  <div className="text-center">
                    <img src={logoColorWelcome} className="mb-3" alt="" />
                    <p className="fs-14 mt-3 mb-4">
                      {translation.LOGIN_INSTRUCTION_LABEL_MSG}
                    </p>
                    <p
                      className="fs-14 mt-3 mb-4"
                      style={{ color: "#2ddf2d" }}
                      id="success_msg"
                    >
                      {state.successMSG}
                    </p>
                  </div>

                  <form action="action" className="">
                    <div className="input-group mb-4">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={translation.PH_EMAIL_PHONE_NUMBER}
                        value={state.userName}
                        required=""
                        onChange={(e) => {
                          setState({ ...state, userName: e.target.value });
                        }}
                        onKeyDown={handleKeyDown}
                      />
                      <span className="input-group-text">
                        <img src={Mail_icon} alt="" />
                      </span>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        className="form-control"
                        placeholder={translation.PASSWORD}
                        value={state.password}
                        required=""
                        onChange={(e) => {
                          setState({ ...state, password: e.target.value });
                        }}
                        onKeyDown={handleKeyDown}
                      />
                      <span className="input-group-text">
                        <img src={Pwd_icon} alt="" />
                      </span>
                    </div>
                  </form>

                  <div
                    className="justify-content-end"
                    style={{ marginBottom: "3.5rem", marginTop: "1.5rem" }}
                  >
                    <span
                      className="fs-14"
                      style={{ float: "left", color: "red", display: "none" }}
                      id="error_msg"
                    >
                      {translation.ERROR_MSG_INVALID_CREDENTIALS}
                    </span>
                    <Link
                      to="/forgot-password"
                      style={{ float: "right" }}
                      className="fs-14"
                    >
                      {translation.FORGOT_PASSWORD}
                    </Link>
                  </div>

                  <div className="login-nativeBtn-wrapper btn-desktop desktop-display-none">
                    <button
                      type="button"
                      className="login-nativeBtn"
                      onClick={getLoginDetails}
                    >
                      {translation.LOGIN}
                    </button>
                  </div>

                  <p className="fs-14">
                    {translation.LOGIN_AGREE_LABEL_MSG}{" "}
                    <Link to="/Terms" className="text-decoration-underline">
                      {" "}
                      {translation.TERMS}
                    </Link>
                    {translation.LOGIN_PRIVACY_COOKIES_POLICY_LABEL_MSG}{" "}
                    <Link
                      to="/PrivcayPolicy"
                      className="text-decoration-underline"
                    >
                      {" "}
                      {translation.PRIVACY_POLICY}
                    </Link>{" "}
                    {/* and{" "}
                    <Link
                      to="/CookiesPolicy"
                      className="text-decoration-underline"
                    >
                      {" "}
                      Cookies Policy
                    </Link>
                    . */}
                  </p>
                </div>
              </div>

              <div className="loginwithlinks">
                <div className="login-webnative-wrapper ">
                  <div className="login-webnative-titleWrapper">
                    <p>{translation.OR_LOGIN_WITH}</p>
                  </div>
                  <div className="login-webnative-btnWrapper">
                    <LinkedInPage />
                    {/* <NavLink to="/">
                      <img src={bankid_icn} alt="" />
                      BankId
                    </NavLink> */}
                  </div>
                </div>
                <div className="d-lg-flex d-md-flex justify-content-between ">
                  <div>
                    <p className="d-flex justify-content-between">
                      <div style={{ marginTop: "0.5rem" }}>
                        {/*   {translation.LOGIN_WITH} */}
                      </div>
                      <div style={{ marginLeft: "1rem" }}>
                        {/*    <LinkedInPage /> */}
                      </div>
                      {/* <NavLink to="/">
                        <img src={Bankid} alt="" />
                      </NavLink> */}
                    </p>
                  </div>
                  {/* <div className="loginwithbtn mobile-display-none">
                    <p className="fs-22 fw-600 text-primary">
                      {translation.LOGIN}
                      <button
                        type="button"
                        className="btn btn-outline-primary rounded-circle ms-2"
                        onClick={getLoginDetails}
                      >
                        <img src={Chevron_right_colored} alt="" />
                      </button>
                    </p>
                  </div> */}
                </div>
              </div>

              <div className="mobile-register desktop-display-none">
                <p>
                  {translation.DONT_HAVE_AN_ACCOUNT} &nbsp;{" "}
                  <NavLink to="/register" className="fw-600">
                    {translation.REGISTER}
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
