import React from "react";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import { Link } from "react-router-dom";
import tick_timeline from "../../assets/img/tick_timeline.svg";
import useTranslation from "../../customHooks/translations";

function CreateJobAd() {
  const translation = useTranslation();
  return (
    <>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="#">{translation.PROFILE}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.CREATE_JOB_AD}{" "}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative" style={{ marginTop: "4em" }}>
            <div className="col-lg-4 col-md-12">
              <h2 className="fs-18 fw-600">{translation.CREATE_JOB_AD}</h2>

              <div className="create_job_tracking trackingstep">
                <div className="step step-complete">
                  <div>
                    <div className="circle">
                      <img src={tick_timeline} alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="title">{translation.COMPANY_INFO}</div>
                  </div>
                </div>
                <div className="step step-complete">
                  <div>
                    <div className="circle">
                      <img src={tick_timeline} alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="title">{translation.JOBS}</div>
                  </div>
                </div>
                <div className="step step-active">
                  <div>
                    <div className="circle">3</div>
                  </div>
                  <div>
                    <div className="title">{translation.PREREQUISITES}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="card login settings_card create_job_card">
                <div className="card-body">
                  {/* First Skill */}
                  <div className="col-md-12 regsiterrf mb-3">
                    <label className="skl_block mb-3">
                      {translation.SKILLS}
                    </label>
                    <input
                      type="text"
                       placeholder={translation.ADD_SKILLS}
                      className="form-control border border-primary"
                    />
                    <div className="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                      {/* Skill 1 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">HTML</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 2 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">Javascript</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 3 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">CSS</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 4 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">Bootstrap</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Second Skill */}
                  <div className="col-md-12 regsiterrf mb-3 mt-5">
                    <label className="skl_block mb-3">
                      {translation.TOOLS}
                    </label>
                    <input
                      type="text"
                      placeholder={translation.ADD_TOOLS}
                      className="form-control border border-primary"
                    />
                    <div className="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                      {/* Skill 1 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">Adobe XD</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 2 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">Photoshop</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 3 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">illustrator</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 4 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">Bootstrap</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className="mt-4" />
                  <div className="card_footer_btn d-flex justify-content-between pt-0 mt-0">
                    <Link to="/create-job-info" className="fw-600 fs-18">
                    {translation.PREV}
                    </Link>
                    <Link to="/recruiter-ad-listing" className="fw-600 fs-18">
                    {translation.NEXT}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CreateJobAd;
