import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Header from '../../sharedComponents/Header';
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import clogo_01 from "../../assets/img/clogo_01.png";
import clogo_02 from "../../assets/img/clogo_02.png";
import clogo_03 from "../../assets/img/clogo_03.png";
import menu_options from "../../assets/img/menu_options.svg";
import read_receipt from "../../assets/img/read_receipt.svg";
import add_video from "../../assets/img/add_video.svg";
import doble_arrow_green from "../../assets/img/doble_arrow_green.svg";
import double_arrow_grey from "../../assets/img/double_arrow_grey.svg";
import delete_video from "../../assets/img/delete_video.svg";
import edit_video from "../../assets/img/edit_video.svg";
import attachment from "../../assets/img/attachment.svg";
import send from "../../assets/img/send.svg";
import useTranslation from "../../customHooks/translations";

const TopMatch = (props) => {
  return (
    <>
      {/* <!-- Chat Top Matches --> */}
      <div class="chat_lfpanel">
        <div class="d-flex justify-content-between">
          <div class="itemside">
            <div class="aside">
              <img src={clogo_01} class="img-sm" />
            </div>
            <div class="info align-self-center">
              <p class="title mb-1">Nvidia</p>
              <div class="chat_role">
                <span>Android Developer . Full Time</span>
              </div>
            </div>
          </div>
          <div>
            <a href="#">
              <img src={menu_options} alt="" />
            </a>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div class="itemside">
            <div class="aside">
              <img src={clogo_02} class="img-sm" />
            </div>
            <div class="info align-self-center">
              <p class="title mb-1">Airtel</p>
              <div class="chat_role">
                <span>Android Developer . Full Time</span>
              </div>
            </div>
          </div>
          <div>
            <a href="#">
              <img src={menu_options} alt="" />
            </a>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div class="itemside">
            <div class="aside">
              <img src={clogo_03} class="img-sm" />
            </div>
            <div class="info align-self-center">
              <p class="title mb-1">Electronic Arts</p>
              <div class="chat_role">
                <span>Android Developer . Full Time</span>
              </div>
            </div>
          </div>
          <div>
            <a href="#">
              <img src={menu_options} alt="" />
            </a>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div class="itemside">
            <div class="aside">
              <img src={clogo_02} class="img-sm" />
            </div>
            <div class="info align-self-center">
              <p class="title mb-1">Airtel</p>
              <div class="chat_role">
                <span>Android Developer . Full Time</span>
              </div>
            </div>
          </div>
          <div>
            <a href="#">
              <img src={menu_options} alt="" />
            </a>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div class="itemside">
            <div class="aside">
              <img src={clogo_02} class="img-sm" />
            </div>
            <div class="info align-self-center">
              <p class="title mb-1">Airtel</p>
              <div class="chat_role">
                <span>Android Developer . Full Time</span>
              </div>
            </div>
          </div>
          <div>
            <a href="#">
              <img src={menu_options} alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
const RecentChat = (props) => {
  return (
    <>
      {/* <!-- Chat Recent Chats --> */}
      <div class="chat_lfpanel">
        <div class="d-flex justify-content-between">
          <div class="itemside">
            <div class="aside">
              <img src={clogo_01} class="img-sm" />
            </div>
            <div class="info align-self-center">
              <p class="title mb-1">Nvidia Recent Chats</p>
              <div class="chat_role">
                <span>Android Developer . Full Time</span>
              </div>
            </div>
          </div>
          <div>
            <span class="m-0 fs-12">15 min</span>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div class="itemside">
            <div class="aside">
              <img src={clogo_02} class="img-sm" />
            </div>
            <div class="info align-self-center">
              <p class="title mb-1">Airtel Recent Chats</p>
              <div class="chat_role">
                <span>Android Developer . Full Time</span>
              </div>
            </div>
          </div>
          <div>
            <span class="m-0 fs-12">15 min</span>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div class="itemside">
            <div class="aside">
              <img src={clogo_03} class="img-sm" />
            </div>
            <div class="info align-self-center">
              <p class="title mb-1">Electronic Arts Recent Chats</p>
              <div class="chat_role">
                <span>Android Developer . Full Time</span>
              </div>
            </div>
          </div>
          <div>
            <span class="m-0 fs-12">15 min</span>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div class="itemside">
            <div class="aside">
              <img src={clogo_02} class="img-sm" />
            </div>
            <div class="info align-self-center">
              <p class="title mb-1">Airtel</p> Recent Chats
              <div class="chat_role">
                <span>Android Developer . Full Time</span>
              </div>
            </div>
          </div>
          <div>
            <span class="m-0 fs-12">15 min</span>
          </div>
        </div>
        <hr />
        <div class="d-flex justify-content-between">
          <div class="itemside">
            <div class="aside">
              <img src={clogo_02} class="img-sm" />
            </div>
            <div class="info align-self-center">
              <p class="title mb-1">Airtel</p>
              <div class="chat_role">
                <span>Android Developer . Full Time</span>
              </div>
            </div>
          </div>
          <div>
            <span class="m-0 fs-12">15 min</span>
          </div>
        </div>
      </div>
    </>
  );
};
function RecruiterChat() {
  const translation = useTranslation();
  const [state, setState] = useState(0);
  const myData = [1];

  return (
    <div>
      <RecruiterHeader />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item">
                    <Link to="/Seeker_Home">{translation.HOME}</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {translation.CHAT}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="chat" class="chat">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-12 mt-5">
              <div class="chat_block">
                <div class="d-flex chatheader_block">
                  <div class="chat_header" style={{ zIndex: "1" }}>
                    <a type="button">
                      <h4
                        class="fs-16 m-0"
                        onClick={() => {
                          setState(1);
                        }}
                      >
                        {translation.TOP_MATCHES}
                      </h4>
                    </a>
                  </div>
                  <div class="chat_header active">
                    <a type="button">
                      <h4
                        class="fs-16 m-0"
                        onClick={() => {
                          setState(0);
                        }}
                      >
                        {translation.RECENT_CHATS}
                      </h4>
                    </a>
                  </div>
                </div>

                <form
                  className="form-wizard"
                  id="regForm"
                  action="#"
                  method="POST"
                >
                  <div>
                    {myData.map((item, index) => {
                      return (
                        <>
                          {state == 0 ? <RecentChat tabsData={item} /> : null}
                          {state == 1 ? <TopMatch tabsData={item} /> : null}
                        </>
                      );
                    })}
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-8 col-md-12">
              <div class="chat_message mt-5">
                <div class="card">
                  <div class="card-body">
                    <div class="d-flex justify-content-between">
                      <div class="itemside">
                        <div class="aside">
                          <img src={clogo_01} class="img-sm" />
                        </div>
                        <div class="info align-self-center">
                          <p class="title fs-18 mb-1">nVidia</p>
                          <div class="chat_role">
                            <p class="m-0 fs-14">
                              Android Developer . Full Time . 3+ Yrs Experience
                              . Onsite .{" "}
                              <span class="fw-500" style={{ color: "#009A12" }}>
                                90% {translation.MATCH}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#ProfileVideosModal"
                        >
                          <img src={add_video} alt="" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="chat_time text-center">
                  <p class="m-0 mt-4">25th Jan 2021</p>
                </div>

                <div class="chat_msg mt-3">
                  <div class="msg_received mb-4">
                    <div class="card">
                      <div class="card-body">
                        <p class="m-0">Hi, Good Morning!</p>
                      </div>
                    </div>
                    <p class="m-0 chatreceive_time">
                      10:30 AM <img src={read_receipt} alt="" />
                    </p>
                  </div>

                  <div class="msg_received mb-4">
                    <div class="card">
                      <div class="card-body">
                        <p class="m-0">Lorem Ipsum Dolar Sit Amet</p>
                      </div>
                    </div>
                    <p class="m-0 chatreceive_time">
                      10:30 AM <img src={doble_arrow_green} alt="" />
                    </p>
                  </div>

                  <div class="msg_received mb-4">
                    <div class="card">
                      <div class="card-body">
                        <p class="m-0">Lorem Ipsum Dolar Sit Amet</p>
                      </div>
                    </div>
                    <p class="m-0 chatreceive_time">
                      10:30 AM <img src={double_arrow_grey} alt="" />
                    </p>
                  </div>

                  <div class="msg_received msg_send d-flex justify-content-end mb-4">
                    <div>
                      <div class="card">
                        <div class="card-body">
                          <p class="m-0">Lorem Ipsum Dolar Sit Amet</p>
                        </div>
                      </div>
                      <p class="m-0 chatreceive_time">10:30 AM</p>
                    </div>
                  </div>
                </div>

                <div class="type_message clearfix mt-4">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      class="form-control"
                      placeholder={translation.TYPE_HERE}
                      aria-label="Type Here"
                      aria-describedby="basic-addon2 "
                    />
                    <span className="input-group-text" id="basic-addon2">
                      <a href="#">
                        <img src={attachment} alt="" />
                      </a>{" "}
                    </span>
                    <span className="input-group-text" id="basic-addon2">
                      <a href="#">
                        <img src={send} alt="" />
                      </a>{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Profile Videos Modal Start --> */}

      <div
        class="modal fade"
        id="ProfileVideosModal"
        tabIndex="-1"
        aria-labelledby="ProfileVideosModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div class="modal-body">
              <div class="row">
                <div class="col-lg-8 col-md-8 offset-lg-2">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/AZF1odfd_p8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                  <div class="video_controls">
                    <a href="#">
                      <img src={delete_video} alt="" />
                    </a>
                    <a href="#">
                      <img src={edit_video} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Profile Videos Modal End --> */}
    </div>
  );
}

export default RecruiterChat;
