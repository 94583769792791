import React from "react";
import { Link, useLocation } from "react-router-dom";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import profile_banner_bg from "../../assets/img/profile_banner_bg.svg";
import nvidia_logo_big from "../../assets/img/nvidia_logo_big.png";
import block_company from "../../assets/img/block_company.svg";
import chat from "../../assets/img/chat.svg";
import thumbs_up from "../../assets/img/thumbs_up.svg";
import play_video from "../../assets/img/play_video.svg";
import useTranslation from "../../customHooks/translations";
import ApiConfig from "../../api/ApiConfig";
import { postWithAuthCallWithErrorResponse } from "../../api/ApiServices";

function RecruiterJobDetails() {
  const state = useLocation().state;
  const translation = useTranslation();
  const showLevel = (level) => {
    return (
      <div className="row skills_progress">

        {level === "Beginner" &&
          <div className="col-lg-3 col-3 g-0">
            <div className="filled_skill"></div>
          </div>
        }
        {level === "Intermediate" && <>
          <div className="col-lg-3 col-3 g-0">
            <div className="filled_skill"></div>
          </div>
          <div className="col-lg-3 col-3 g-0">
            <div className="filled_skill"></div>
          </div>
        </>

        }
        {level === "Proficient" && <>
          <div className="col-lg-3 col-3 g-0">
            <div className="filled_skill"></div>
          </div>
          <div className="col-lg-3 col-3 g-0">
            <div className="filled_skill"></div>
          </div>
          <div className="col-lg-3 col-3 g-0">
            <div className="filled_skill"></div>
          </div>
        </>

        }
        {level === "Expert" && <>
          <div className="col-lg-3 col-3 g-0">
            <div className="filled_skill"></div>
          </div>
          <div className="col-lg-3 col-3 g-0">
            <div className="filled_skill"></div>
          </div>
          <div className="col-lg-3 col-3 g-0">
            <div className="filled_skill"></div>
          </div>
          <div className="col-lg-3 col-3 g-0">
            <div className="filled_skill"></div>
          </div>
          <div className="col-lg-3 col-3 g-0">
            <div className="filled_skill"></div>
          </div>
        </>
        }
      </div>
    )
  }

  return (
    <>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="/recruiter-profile">{translation.PROFILE}</a>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/recruiter-ad-listing">
                      {translation.JOB_ADS}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {state.job_title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="profile" className="profile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12 g-0">
              <img src={state.photo_link || profile_banner_bg} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card profile_lfcard">
                <div className="card-body">
                  <div className="profile_cardimg text-center">
                    <img src={state.photo || nvidia_logo_big} alt="" />
                  </div>
                  <div className="text-center mt-4">
                    <h3 className="fw-600 fs-18">{state.job_title}</h3>
                    <p className="fs-16">{state.company_name}</p>
                  </div>
                  {/* <div className="profilejobmatch_percent">
                    <p className="m-0">90% {translation.MATCH}</p>
                  </div> */}
                  <div className="profile_actionbtn text-center py-3">
                    {/* <button type="button" className="btn rounded-circle" >
                      <img src={block_company} alt="" />
                    </button> */}

                    {/* <div className="btn-group profilebtn_block jobprofile_block">
                      <button
                        type="button"
                        className="btn dropdown-toggle rounded-circle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={thumbs_up} alt="" />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li className="profileaction_bg">
                          <button className="dropdown-item" type="button">
                            {translation.INTERESTED}
                          </button>
                        </li>
                      </ul>
                    </div> */}

                    <div className="btn-group profilebtn_block jobprofile_block">
                      {/* <button
                        type="button"
                        className="btn dropdown-toggle rounded-circle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={chat} alt="" />
                      </button> */}
                      {/* <ul className="dropdown-menu dropdown-menu-end">
                        <li className="profileaction_bg">
                          <Link to="/Chat">
                            {" "}
                            <button className="dropdown-item" type="button">
                              {translation.CHAT}
                            </button>
                          </Link>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                  <hr className="mt-2 mb-4" />
                  {(state.video) && (
                    <div className="d-flex justify-content-between">
                      <h4 className="fs-16 m-0">
                        {translation.PLAY_INTRO_VIDEO}{" "}
                        {/* <span className="fs-14 ms-2">{document.getElementById("videoPlayer") && document.getElementById("videoPlayer").duration}</span> */}
                      </h4>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#HomeUpgrade"
                      >
                        <img src={play_video} alt="" />
                      </a>
                    </div>
                  )}
                </div>
                <div
                  class="modal fade"
                  id="HomeUpgrade"
                  tabIndex="-1"
                  aria-labelledby="HomeUpgradeLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-body text-center">
                        <video width={"100%"} controls src={state.video} id="videoPlayer" />
                      </div>
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-dismiss="modal"
                      >
                        {translation.CLOSE}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="profile_rfcard pt-5 px-4">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="BasicInfo-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#BasicInfo"
                      type="button"
                      role="tab"
                      aria-controls="BasicInfo"
                      aria-selected="true"
                    >
                      {translation.COMPANY_INFO}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Education-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Education"
                      type="button"
                      role="tab"
                      aria-controls="Education"
                      aria-selected="false"
                    >
                      {translation.ABOUT_THE_JOB}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Jobs-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Jobs"
                      type="button"
                      role="tab"
                      aria-controls="Jobs"
                      aria-selected="false"
                    >
                      {translation.PREREQUISITES}
                    </button>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="Prerequisite-tab" data-bs-toggle="tab" data-bs-target="#Prerequisite" type="button" role="tab" aria-controls="Prerequisite" aria-selected="false">About the Company</button>
                                    </li> */}
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="BasicInfo"
                    role="tabpanel"
                    aria-labelledby="BasicInfo-tab"
                  >
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.INDUSTRY}</p>
                      <p className="m-0 profile_role">{state.industry}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.EXPERIANCE}</p>
                      <p className="m-0 profile_role">{state.required_experience.map(exp => exp.level).join(",")}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.JOB_TYPE}</p>
                      <p className="m-0 profile_role">{state.job_type}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.WORK_LOCATION}</p>
                      <p className="m-0 profile_role">{state.work_location.join(",")}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.REQUIRED_LANGUAGE}</p>
                      <p className="m-0 profile_role">{state.language_required.map(lng => lng.language).join(",")}</p>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Education"
                    role="tabpanel"
                    aria-labelledby="Education-tab"
                  >
                    {state.job_description}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Jobs"
                    role="tabpanel"
                    aria-labelledby="Jobs-tab"
                  >
                    <div className="skills_jobs">
                      <h4 className="mb-4">{translation.SKILLS_REQUIRED}</h4>

                      <div className="row mb-5">
                        <div className="col-lg-3 col-md-6 g-5 mt-0 pt-0">

                          {state.job_skill.map(skill => {
                            return (<>
                              <p className="m-0" key={skill.id}>
                                {skill.skill} <span>{skill.level}</span>
                              </p>
                              {showLevel(skill.level)}

                            </>)
                          })}

                        </div>
                      </div>

                      <h4 className="mb-4">{translation.TOOLS_REQUIRED}</h4>

                      <div className="row mb-5">
                        <div className="col-lg-3 col-md-6 g-5 mt-0 pt-0">

                          {state.job_tool.map(tool => {
                            return (<>
                              <p className="m-0" key={tool.id}>
                                {tool.tool} <span>{tool.level}</span>
                              </p>
                              {showLevel(tool.level)}
                            </>)
                          })}
                        </div>
                      </div>

                      <h4 className="mb-4">{translation.EXPERIANCE}</h4>

                      <div className="row mb-5">
                        <div className="col-lg-3 col-md-6 g-5 mt-0 pt-0">

                          {state.required_experience.map(exp => {
                            return (<>
                              <p className="m-0" key={exp.id}>
                                <span>{exp.level}</span>
                              </p>
                              {showLevel(exp.level)}
                            </>)
                          })}
                        </div>
                      </div>
                      <h4 className="mb-2">{translation.CERTIFICATES}</h4>
                      <p className="m-0">{state.job_certificate.map(cer => cer.job_certificates).join(",")}</p>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Prerequisite"
                    role="tabpanel"
                    aria-labelledby="Prerequisite-tab"
                  >
                    {translation.PREREQUISITES}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RecruiterJobDetails;
