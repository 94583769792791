import React, { useState, useEffect, useRef } from "react";
import Eye from "../assets/img/eye.svg";
import eye_closed from "../assets/img/eye_closed.svg";
import upload from "../assets/img/upload.svg";
import Chevron_right_colored from "../assets/img/chevron_right_colored.svg";
import { NavLink } from "react-router-dom";
import { multipartPostCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import Select from 'react-select';
import Country from '../assets/CountrtiesData/Country';
import listContryCity from '../assets/CountrtiesData/CoutrycityList';
import countryCodes from "country-codes-list"
import moment from "moment";
import useTranslation from "../customHooks/translations";
import PopoverElement from "../sharedComponents/Popover";
import { toast } from "react-toastify";
import Employer from "../assets/img/employer.svg";
import Job_seeker from "../assets/img/job_seeker.svg";


function Register() {
  const translation = useTranslation();
  const [countryCodesArray, setCountryCodesArray] = useState([]);
  const [errorMsg, setErrorMsg] = useState({});
  const FullName = useRef(null);
  const email = useRef(null);
  const dob = useRef(null);
  const countryCode = useRef(null)
  const phone_number = useRef(null)
  const countryName = useRef(null);
  const city = useRef(null)
  const password = useRef(null)
  const confirm_password = useRef(null);
  const [state, setState] = useState({
    isProcessing: false,
    country_code: "+91",
    phone_number: "",
    full_name: "",
    city: "",
    country: "",
    email: "",
    password: "",
    confirm_password: "",
    role: "Job_seeker",
    profile_pic: "",
    profile_pic_name: "",
    device_type: "",
    device_token: "",
    showPassword: false,
    showConfirmPassword: false,
    alreadyRegisteredMsg: "",
    selected_Country: {},
    selected_City: {},
    about: "",
    dob: ""
    // dob: moment().subtract(20, "years").format("YYYY-MM-DD"),
  });

  const [countriesState, setCountriesState] = useState([]);
  const [citiesState, setCitiesState] = useState();

  const registerUser = async () => {
    let formData = new FormData();
    setErrorMsg({})
    formData.append("phone_number", state.phone_number);
    formData.append("full_name", state.full_name);
    formData.append("city", state.city);
    formData.append("country", state.country);
    formData.append("email", state.email);
    formData.append("password", state.password);
    formData.append("confirm_password", state.confirm_password);
    formData.append("role", state.role);
    formData.append("profile_pic", state.profile_pic);
    formData.append("about", state.about);
    formData.append("dob", state.dob);
    formData.append("device_type", "");
    formData.append("device_token", "");
    if (
      state.full_name == "" ||
      state.email == "" ||
      state.dob == "" ||
      state.country_code == "" ||
      state.country == "" ||
      state.phone_number == "" ||
      state.city == "" ||
      state.country == "" ||
      state.password == "" ||
      state.confirm_password == ""
    ) {
      if (state.full_name == "") {
        setErrorMsg({ full_name: translation.ENTER_FULL_NAME });
        return;

      } else if (state.email == "") {
        setErrorMsg({ email: translation.ENTER_EMAIL });
        return;

      } else if (state.dob == "") {
        setErrorMsg({ dob: translation.ENTER_DATE_OF_BIRTH });
        return;
      } else if (state.country_code == "") {
        setErrorMsg({ phone_number: translation.SELECT_COUNTRY_CODE });
        return;
      } else if (state.phone_number == "") {
        setErrorMsg({ phone_number: translation.ENTER_PHONE_NUMBER });
        return;
      }
      else if (state.country == "") {
        setErrorMsg({ country: translation.SELECT_COUNTRY });
        return;
      }
      else if (state.city == "") {
        setErrorMsg({ city: translation.SELECT_CITY });
        return;
      }
      else if (state.password == "") {
        setErrorMsg({ password: translation.ENTER_PASSWORD });
        return;
      }


      else if (state.confirm_password == "") {
        setErrorMsg({ confirm_password: translation.CONFIRM_PASSWORD });
        return;
      }
    } else {
      setErrorMsg({});
      if (!validateEmail(state.email)) {
        setErrorMsg({ email: translation.ENTER_EMAIL_IN_CURRECT_FORMAT },);
        return;
      }
      else if (state.password.length < 6) {
        setErrorMsg({ password: "Password must be greater than 6 digit" });
        return;
      }
      else if (!validatepassword(state.password)) {
        setErrorMsg({ password: "Password must contains combination alphabets and numbers" });
        return
      }
      else if (state.password !== state.confirm_password) {
        setErrorMsg({ confirm_password: translation.PASSWORDS_DOES_NOT_MATCH });
        return;
      }
    }
    if (
      state.full_name.length &&
      state.email.length &&
      state.dob.length &&
      state.country_code.length &&
      state.phone_number.length &&
      state.country.length &&
      state.city.length &&
      state.password.length &&
      state.confirm_password.length
    ) {
      multipartPostCallWithErrorResponse(ApiConfig.REGISTER_API, formData)
        .then((data) => {

          if (data.error == false) {
            if (data.json.otp_verify == false) {
              localStorage.setItem(
                "MESSAGE",
                data.json.message + " " + state.phone_number
              );
              localStorage.setItem("PhNo", state.phone_number);
              localStorage.setItem("OTP", data.json.otp);
              window.location.href = "/otp-register";
            } else {
              setErrorMsg({ alreadyRegisteredMsg: data.json.message });
              toast.success(data.json.message, { autoClose: 1000 })
            }
          } else {
            // setErrorMsg({ ...errorMsg, errorMsg: data.json.message });
          }
        })
        .catch((error) => {
          console.log("api response", error);
        });
    }
  };

  const showPassword = async (field_id) => {
    if (field_id == "password_field")
      setState({ ...state, showPassword: !state.showPassword });
    else
      setState({ ...state, showConfirmPassword: !state.showConfirmPassword });
  };

  const changeUserRole = async (user_role) => {
    setState({ ...state, role: user_role });
  };

  const submitForm = async () => {
    document.registerForm.submit();
  };

  const handleImageChange = async (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      /*this.setState({
          image: file,
          imagePreviewUrl: reader.result
      });*/
      setState({ ...state, profile_pic: file, profile_pic_name: file.name });
    };

    reader.readAsDataURL(file);
  };

  const onchangeImg = async (e) => { };

  /* city section starts */
  function getCitiesList(selected_country) {
    Object.keys(listContryCity.listContryCity).forEach(function (key, index) {
      var newJson = listContryCity.listContryCity[key];
      if (newJson.country == selected_country) {
        updateCity(newJson.city);
      }
    });
  }

  function updateCity(data) {
    let temp_cities = [];
    data.map((item, index) => {
      temp_cities.push({ label: item, value: item });
    });
    setCitiesState(temp_cities);
  }

  function onCityChange(e) {
    setErrorMsg({})
    setState({ ...state, city: e.target.value });
  }
  /* city section ends */

  /* country section starts */
  const selectStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: 'transparent', borderRadius: "8px", border: "1px solid #b3c2c9"
    }),
    menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)', backgroundColor: 'transparent', zIndex: "5000" }),


  };

  function getCountriesList() {
    setCountriesState(Country.Country);
  }

  function onCountryChange(e) {
    setErrorMsg({})
    setState({ ...state, country: e.target.value });
    getCitiesList(e.target.value);
  }

  /* country section ends */
  function onCountrycodesChange(e) {
    setErrorMsg({})
    setState({ ...state, country_code: e.target.value });
    // let country = countryCodesArray.filter(countrycode => countrycode.value == e.target.value && { label: countrycode, value: countrycode.label })
    // console.log("country ", country);
    // onCountryChange(country)
  }
  useEffect(() => {
    let codes = countryCodes.all().map(code => {
      return { value: "+" + code.countryCallingCode, label: code.countryNameEn }
    })
    window.onload = function () {
      document.getElementById("FullName").focus();
    }
    setCountryCodesArray(codes);
    getCountriesList();
  }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatepassword = (password) => {
    return String(password)
      .toLowerCase()
      .match(
        /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{6,50}$/
      );
  }
  return (
    <section id="login" className="login">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-12 g-0 p-0">
            {/* <!-- <div className="login_ltblock text-center">
                      <img src="img/logo.png" alt="">
                      <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p>
                    </div> --> */}

            <div className="doctor_leftimg">
              <div className="overlay_content text-center">
                <h2 className="mb-3 text-white">{translation.REGISTER}</h2>
                <p className="m-t-25 text-white">
                  {translation.REGISTER_INTO}
                </p>
              </div>
              {/* <!-- <div className="overlay_logo">
                            <img src="img/logo.png">
                        </div> --> */}
            </div>
          </div>

          <div className="col-lg-6 col-md-12 right-register">
            <div className="login_rtblock position-relative pt-4">
              <div className="d-lg-flex d-md-flex justify-content-end me-4">
                <p>
                  {translation.ALREADY_HAVE_A_ACCOUNT_LABLE + " "}
                  <NavLink to="/login" className="fw-600">
                    {translation.LOGIN}
                  </NavLink>
                </p>
              </div>

              <div className="form_block w-100 pt-4 px-3 register-form">
                <form
                  name="registerForm"
                  className="row g-3 text-start"
                  encType="multipart/form-data"
                >
                  <div className="col-md-6 regsiterrf">
                    <label className="form-label fs-14">
                      {translation.FULLNAME}
                    </label>
                    <input
                      ref={FullName}
                      id="FullName"
                      type="text"
                      className="form-control"
                      placeholder={translation.FULLNAME}
                      value={state.full_name && state.full_name}
                      onChange={(e) => {
                        setErrorMsg({})
                        setState({ ...state, full_name: e.target.value });
                      }}
                    />

                    {
                      errorMsg.full_name &&
                      <PopoverElement references={FullName} msg={errorMsg.full_name} Id={"FullName"} />
                    }

                    {/* <span className="fs-14" style={{ color: "red" }}>
                      {errorMsg.full_name ? errorMsg.full_name : ""}
                    </span>*/}
                  </div>

                  <div className="col-md-6 regsiterrf">
                    <label
                      htmlFor="inputPassword4"
                      className="form-label fs-14"
                    >
                      {translation.EMAIL}
                    </label>
                    <input
                      ref={email}
                      id="emailid"
                      type="text"
                      className="form-control "
                      placeholder={translation.EMAIL}
                      value={state.email || ""}
                      onChange={(e) => {
                        setErrorMsg({})
                        setState({ ...state, email: e.target.value });
                      }}
                    />
                    {errorMsg.email &&
                      <PopoverElement references={email} msg={errorMsg.email} Id={"emailid"} />
                    }
                    {/* <span className="fs-14" style={{ color: "red" }}>
                      {errorMsg.email ? errorMsg.email : ""}
                    </span> */}
                  </div >

                  <div className="col-md-6 regsiterrf">
                    <label htmlFor="DOB" className="form-label fs-14">
                      {translation.DATE_OF_BIRTH}
                    </label>
                    <input
                      ref={dob}
                      type="date"
                      className="form-control"
                      id="DOB"
                      value={state.dob || ""}
                      onChange={(e) => {
                        setErrorMsg({})
                        setState({ ...state, dob: e.target.value });
                      }}
                    />
                    {
                      errorMsg.dob &&
                      <PopoverElement references={dob} msg={errorMsg.dob} Id={"DOB"} />
                    }

                    {/* <span className="fs-14" style={{ color: "red" }}>
                      {errorMsg.dob ? errorMsg.dob : ""}
                    </span> */}
                  </div >

                  <div className="col-md-6 regsiterrf">
                    <label
                      htmlFor="inputPassword4"
                      className="form-label fs-14"
                    >
                      {translation.MOBILE_NUMBER}
                    </label>
                    <div className="input-group country-code-select">
                      <input list="brow" id="countryCode" className="form-control" onChange={onCountrycodesChange} style={{ maxWidth: "83px" }} value={state.country_code} />
                      <datalist id="brow" name={"service-city"} style={{ color: "red" }}>
                        {countryCodesArray.map(codes => {
                          return (< option value={codes.value} label={codes.label} />)
                        })}
                      </datalist>
                      {
                        errorMsg.country_code &&
                        <PopoverElement references={countryCode} msg={errorMsg.country_code} ID={"countryCode"} />
                      }
                      <input
                        ref={phone_number}
                        id="phone_number"
                        type="text"
                        className="form-control"
                        placeholder={translation.MOBILE_NUMBER}
                        value={state.phone_number || ""}
                        maxLength="10"
                        onChange={(e) => {
                          setErrorMsg({})
                          const re = /^[0-9\b]+$/;
                          if (
                            e.target.value === "" ||
                            re.test(e.target.value)
                          ) {
                            setState({
                              ...state,
                              phone_number: e.target.value,
                            });
                          }
                        }}
                      />
                      {
                        errorMsg.phone_number &&
                        <PopoverElement references={phone_number} msg={errorMsg.phone_number} Id={"phone_number"} />
                      }
                    </div >
                  </div >

                  <div className="col-md-12 upload-btn-wrapper">
                    <label
                      htmlFor="inputPassword4"
                      className="form-label fs-14"
                    >
                      {translation.UPLOAD_PROFILE_PICTURE}
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={translation.UPLOAD_PROFILE_PICTURE}
                        value={state.profile_pic_name || ""}
                        onChange={onchangeImg}
                        style={{
                          border: "1px solid #B3C2C9",
                          borderRadius: "8px",
                        }}
                      />
                      <span className="input-group-text">
                        <img src={upload} alt="" />
                      </span>
                    </div>

                    <input
                      id="file-input"
                      type="file"
                      name="myfile"
                      accept="image/*"
                      onChange={(e) => handleImageChange(e)}
                    />
                  </div>
                  <div className="col-md-6 regsiterrf">
                    <label
                      htmlFor="inputPassword4"
                      className="form-label fs-14"
                    >
                      {translation.COUNTRY}
                    </label>
                    <input list="countryLIst" ref={countryName} id="countryName" className="form-control" onChange={onCountryChange} value={state.country || ""} />
                    <datalist id="countryLIst" name={"service-cityselected"} style={{ color: "red" }}>
                      {countriesState && countriesState.length && countriesState.map(country => {
                        return (<option value={country.value} />)
                      })}
                    </datalist>


                    {/* 
                    <Select
                      menuPlacement="top"
                      ref={countryName}
                      id="countryName"
                      isClearable={false}
                      onChange={onCountryChange}
                      options={countriesState}
                      placeholder={translation.SEARCH}
                      name="country"
                      styles={selectStyles}
                      //tabSelectsValue={false}
                      value={state.selected_Country || ""}
                    /> */}
                    {
                      errorMsg.country &&
                      <PopoverElement references={countryName} msg={errorMsg.country} Id={"countryName"} />
                    }
                  </div>

                  <div className="col-md-6 regsiterrf">
                    <label className="form-label fs-14">
                      {translation.CITY}
                    </label>

                    <input list="cityList" ref={city} id="city" className="form-control" onChange={onCityChange} value={state.city || ""} />
                    <datalist id="cityList" name={"service-cityselected"} style={{ color: "red" }}>
                      {citiesState && citiesState.length && citiesState.map(city => {
                        return (<option value={city.value} />)
                      })}
                    </datalist>

                    {/* <Select
                      id="city"
                      ref={city}
                      isClearable={false}
                      onChange={onCityChange}
                      options={citiesState}
                      placeholder={translation.SEARCH}
                      styles={selectStyles}
                      //tabSelectsValue={false}
                      value={state.selected_City || ""}
                    /> */}
                    {
                      errorMsg.city &&
                      <PopoverElement references={city} msg={errorMsg.city} Id={"city"} />
                    }

                    {/* <span className="fs-14" style={{ color: "red" }}>
                      {errorMsg.city ? errorMsg.city : ""}
                      </span> */}

                  </div >
                  <div className="col-md-12 regsiterrf">
                    <label
                      htmlFor="inputPassword4"
                      className="form-label fs-14"
                    >
                      {translation.SHORT_DESCRIPTION}
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      placeholder={translation.SHORT_DESCRIPTION}
                      onChange={(e) => {
                        setState({ ...state, about: e.target.value });
                      }}
                      name="shortDesc"
                      value={state.about}
                      rows="5"
                      style={{ height: "69px" }}
                    ></textarea>
                  </div>
                  <div className="col-md-6 ">
                    <label
                      htmlFor="inputPassword4"
                      className="form-label fs-14"
                    >
                      {translation.PASSWORD}
                    </label>
                    <div className="input-group">
                      <input
                        ref={password}
                        minLength="6"
                        id="password"
                        type={state.showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder={translation.PASSWORD}
                        value={state.password}
                        onChange={(e) => {
                          setErrorMsg({})
                          setState({ ...state, password: e.target.value });
                        }}
                      />
                      <span className="input-group-text">
                        <img
                          src={state.showPassword ? eye_closed : Eye}
                          alt=""
                          onClick={() => showPassword("password_field")}
                        />
                      </span>
                    </div>
                    {
                      errorMsg.password &&
                      <PopoverElement references={password} msg={errorMsg.password} Id={"password"} />
                    }
                  </div >

                  <div className="col-md-6 ">
                    <label
                      htmlFor="inputPassword4"
                      className="form-label fs-14"
                    >
                      {translation.CONFIRM_PASSWORD}
                    </label>
                    <div className="input-group">
                      <input
                        ref={confirm_password}
                        type={state.showConfirmPassword ? "text" : "password"}
                        id="confirm_password"
                        className="form-control"
                        placeholder={translation.CONFIRM_PASSWORD}
                        value={state.confirm_password}
                        onChange={(e) => {
                          setErrorMsg({})
                          setState({
                            ...state,
                            confirm_password: e.target.value,
                          });
                        }}
                      />
                      <span className="input-group-text">
                        <img
                          src={state.showConfirmPassword ? eye_closed : Eye}
                          alt=""
                          onClick={() => showPassword("confirm_password_field")}
                        />
                      </span>
                    </div>
                    {
                      errorMsg.confirm_password &&
                      <PopoverElement references={confirm_password} msg={errorMsg.confirm_password} Id={"confirm_password"} />
                    }

                  </div >

                  <div className="col-md-12">
                    <label className="form-label fs-14">Register As</label>
                    <div className="row registeras">
                      <div
                        className="col-md-6"
                        onClick={() => changeUserRole("Recruiter")}
                      >
                        <div
                          className={
                            state.role == "Recruiter" ? "card active" : "card"
                          }
                        >
                          <div className="card-body text-center">
                            <p className="text-body-light fw-500 m-0">
                              <img src={Employer} className="me-3" alt="" />
                              Recruiter
                            </p>
                            <a className="stretched-link"></a>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-6 mt-lg-0 mt-md-0 mt-4"
                        onClick={() => changeUserRole("Job_seeker")}
                      >
                        <div
                          className={
                            state.role == "Job_seeker" ? "card active" : "card"
                          }
                        >
                          <div className="card-body text-center">
                            <p className="text-primary fw-500 m-0">
                              <img src={Job_seeker} className="me-3" alt="" />
                              Job Seeker
                            </p>
                            <a className="stretched-link"></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="loginwithlinks common-registration mobile-display-none">
                    <div className="d-lg-flex d-md-flex justify-content-end">
                      <div className="loginwithbtn">
                        <p className="fs-22 fw-600 text-primary">
                          {translation.REGISTER}
                          {/*<NavLink to='/otp_register' className="btn btn-outline-primary rounded-circle ms-2"><img src={Chevron_right_colored} alt="" /></NavLink>  */}
                          <a
                            className="btn btn-outline-primary rounded-circle ms-2"
                            onClick={registerUser}
                          >
                            <img src={Chevron_right_colored} alt="" />
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* responsive btn */}
                  <div
                    className="login-nativeBtn-wrapper desktop-display-none "
                    id="registerBtn"
                  >
                    <button
                      type="button"
                      className="login-nativeBtn "
                      id="Register-Btn"
                      onClick={registerUser}
                    >
                      {translation.REGISTER}
                    </button>
                  </div>
                </form>
              </div >
            </div >
          </div>
        </div >
      </div>
    </section >
  );
}

export default Register;
