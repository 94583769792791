import React from "react";
import ReactDOM from "react-dom";
import "../src/assets/css/style.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import LanguageContextProvider from "./contexts/LanguageContext";
import NotificationContextProvider from "./contexts/NotificationContext";
import UserContextProvider from "./contexts/userContext";

ReactDOM.render(
  <BrowserRouter>
    <UserContextProvider>
      <NotificationContextProvider>
        <LanguageContextProvider>
          <App />
        </LanguageContextProvider>
      </NotificationContextProvider>
    </UserContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
