import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import chevron_right_colored from "../assets/img/chevron_right_colored.svg";
import eye from "../assets/img/eye.svg";
import { simplePostCallWithErrorResponse } from "../api/ApiServices";
import ApiConfig from "../api/ApiConfig";
import useTranslation from "../customHooks/translations";

const ForgotPasswordNew = (props) => {
  const translations = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    showPassword: false,
    showConfirmPassword: false,
    password: "",
    confirm_password: "",
    errorMsg: "",
  });

  const showPassword = async (field_id) => {
    if (field_id == "password_field") {
      if (state.showPassword) {
        setState({ ...state, showPassword: false });
      } else {
        setState({ ...state, showPassword: true });
      }
    } else {
      if (state.showConfirmPassword) {
        setState({ ...state, showConfirmPassword: false });
      } else {
        setState({ ...state, showConfirmPassword: true });
      }
    }
  };
  const validatepassword = (password) => {
    return String(password)
      .toLowerCase()
      .match(
        /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{6,50}$/
      );
  }
  const resetPword = async () => {
    if (state.password == "" || state.confirm_password == "") {
      setState({ ...state, errorMsg: "Please fill both fileds" });

    } else {
      if (!validatepassword(state.password)) {
        setState({ ...state, errorMsg: "Password must contains combination alphabets and numbers" });
      }
      else if (state.password == state.confirm_password) {
        let requestBody = JSON.stringify({
          user_id: localStorage.getItem("forgetPword_UserID"),
          otp: localStorage.getItem("forgotPwordOTP"),
          password: state.password,
        });

        simplePostCallWithErrorResponse(ApiConfig.RESET_PWORD_API, requestBody)
          .then((data) => {
            setState({ ...state, isProcessing: true });
            if (data.error == true) {
              setState({ ...state, isProcessing: false });
              localStorage.setItem("resetPwordErrMsg", data.json.message);
              window.location.href = "/forgot-password-otp";
            } else {
              const lang = localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "IN_en";
              localStorage.clear();
              localStorage.setItem("language", lang);
              localStorage.setItem("successMSG", data.json.message);
              window.location.href = "/login";
            }
          })
          .catch((error) => {
            setState({ ...state, isProcessing: false });
          });
      } else {
        setState({ ...state, errorMsg: translations.PASSWORD_DOES_NOT_MATCH });
      }
    }
  };

  return (
    <>
      <section id="login" class="login">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 col-md-12 g-0 p-0">
              {/* <div class="login_ltblock text-center">
                                <img src="img/logo.png" alt="">
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p>
                            </div> */}

              <div class="doctor_leftimg">
                <div class="overlay_content text-center">
                  <img src={logo} class="mb-3" />
                  <p class="m-t-25 text-white">
                    {translations.PRODUCT_INTRO}
                  </p>
                </div>
                {/* <div class="overlay_logo">
                                    <img src="img/logo.png">
                                </div> */}
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div class="login_rtblock position-relative pt-4">
                <div class="d-flex justify-content-end">
                  <Link to="/forgot-password-otp" class="fw-600">
                    {translations.Go_Back}
                  </Link>
                </div>

                <div class="form_block forgot_pass">
                  <div class="text-center">
                    <h2>{translations.FORGOT_PASSWORD_HEADING}</h2>
                    {/* <p class="fs-14 mt-3 mb-4">Enter your mobile number or email id to continue</p> */}
                  </div>

                  <form action="action" class="form_block text-start pt-4">
                    <label for="" class="fs-14 mb-2">
                      {translations.ENTER_NEW_PASSWORD}
                    </label>
                    <div class="input-group mb-4">
                      <input
                        type={state.showPassword ? "text" : "password"}
                        class="form-control"
                        aria-label="EmailPhoneNumber"
                        aria-describedby="basic-addon1"
                        value={state.password}
                        onChange={(e) => {
                          setState({ ...state, password: e.target.value });
                        }}
                      />
                      <span
                        class="input-group-text"
                        id="basic-addon1"
                        onClick={() => showPassword("password_field")}
                      >
                        <img src={eye} alt="" />
                      </span>
                    </div>
                    <label for="" class="fs-14 mb-2">
                      {translations.CONFIRM_NEW_PASSWORD}
                    </label>
                    <div class="input-group mb-4">
                      <input
                        type={state.showConfirmPassword ? "text" : "password"}
                        class="form-control"
                        aria-label="EmailPhoneNumber"
                        aria-describedby="basic-addon1"
                        value={state.confirm_password}
                        onChange={(e) => {
                          setState({
                            ...state,
                            confirm_password: e.target.value,
                          });
                        }}
                      />
                      <span
                        class="input-group-text"
                        id="basic-addon1"
                        onClick={() => showPassword("confirm_password_field")}
                      >
                        <img src={eye} alt="" />
                      </span>
                    </div>
                  </form>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <span
                      className="fs-14"
                      style={{ float: "left", color: "red", width: "100%" }}
                      id="error_msg"
                    >
                      {state.errorMsg}
                    </span>
                  </div>
                </div>
              </div>

              <div class="loginwithlinks">
                <div class="d-lg-flex d-md-flex justify-content-end">
                  <div class="loginwithbtn">
                    <p class="fs-22 fw-600 text-primary">
                      {translations.CONTINUE}
                      {/* to="/login" */}
                      <a onClick={resetPword}>
                        <button
                          type="button"
                          class="btn btn-outline-primary rounded-circle ms-2"
                        >
                          <img src={chevron_right_colored} alt="" />
                        </button>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ForgotPasswordNew;
