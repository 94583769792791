import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../../sharedComponents/Header";
import chevron_right from "../../../assets/img/chevron_right_colored.svg";
import camera from "../../../assets/img/camera.svg";
import profile_pic from "../../../assets/img/profile_pic.svg";
import ApiConfig from "../../../api/ApiConfig";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../../api/ApiServices.js";
import default_profile_pic from "../../../assets/img/profile_pic.svg";
import moment from "moment";
import {
  postMultipartWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../../api/ApiServices";
import Country from "../../../assets/CountrtiesData/Country";
import listContryCity from "../../../assets/CountrtiesData/CoutrycityList";
import Select from "react-select";
import { Multiselect } from "multiselect-react-dropdown";
import useTranslation from "../../../customHooks/translations";
import { UserContext } from "../../../contexts/userContext";

export default function Edit_preferences() {
  const translation = useTranslation();
  const [industries, setIndustries] = useState([]);
  const [jobRole, setJobRole] = useState([]);
  const [jobType, setJobType] = useState([]);
  const [languageState, setLanguageState] = useState();
  const [countriesState, setCountriesState] = useState();
  const [citiesState, setCitiesState] = useState();
  const { UserData, getUserData } = useContext(UserContext)
  console.log("UserData", UserData);
  const [state, setState] = useState({
    isProcessing: false,
    country_code: "",
    errMsg: {},
    full_name: "",
    profile_pic: "",
    city: UserData.preferences && UserData.preferences.length && UserData.preferences[0].city || "",
    country: UserData.preferences && UserData.preferences.length && UserData.preferences[0].country || "",
    email: "",
    phone_number: "",
    dob: "",
    about: "",
    user_profile_pic: default_profile_pic,
    profile_pic_name: "",
    jobTitle: "",
    shortDesc: "",
    profile_pic: "",
    profile_pic_name: "",
    device_type: "",
    device_token: "",
    current_date: moment().format("YYYY-MM-DD"),
    selected_Country: {},
    selected_City: {},
    errorMsg: {},
    selectedLanguagesWithLevel: UserData.preferences && UserData.preferences.length && UserData.preferences[0].languages ? UserData.preferences[0].languages : [],
    new_industry: "",
    new_industries_array: [],
    selectedIndustries: UserData.preferences && UserData.preferences.length && UserData.preferences[0].industries ? UserData.preferences[0].industries : [],
    selectedJobRole: UserData.preferences && UserData.preferences.length && UserData.preferences[0].job_role ? UserData.preferences[0].job_role : [],
    selectedJobType: UserData.preferences && UserData.preferences.length && UserData.preferences[0].job_type ? UserData.preferences[0].job_type : [],
    new_jobRole: "",
    new_jobRole_array: [],
    new_jobType: "",
    new_jobType_array: [],
    salary: UserData.preferences && UserData.preferences.length && UserData.preferences[0].monthly_salary ? UserData.preferences[0].monthly_salary : "",
    percentage: UserData.preferences && UserData.preferences.length && UserData.preferences[0].match_percentage ? UserData.preferences[0].match_percentage.length && UserData.preferences[0].match_percentage[0] : "",
    work_location: UserData.preferences && UserData.preferences.length && UserData.preferences[0].work_location ? UserData.preferences[0].work_location.map(location => location.name) : ["OnSite"],
  });




  //INDUSTRY START
  const getIndustry = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.INDUSTRY_LISTING_API)
      .then((data) => {
        setIndustries(data.json);
        parseIndustryList(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const parseIndustryList = (data) => {
    let temp_tools = [];
    data.map((item, index) => {
      temp_tools.push({ name: item.name, id: item.id });
    });

    setIndustries(temp_tools);
  };

  function onIndustryChange(selectedList, selectedItem) {
    let temp_selectedIndustry = [];

    // selectedList.map((item, index) => {
    //   temp_selectedIndustry.push({ job_certificates: item.name });
    // });

    setState({
      ...state,
      selectedIndustries: selectedList,
      // industries: temp_selectedIndustry,
    });
  }

  const handleKeyDown_Industry = (e) => {
    if (e.key === "Enter") {
      var new_industries_array = state.new_industries_array;
      if (state.new_industry == "" || state.new_industry == null) {
        // show error message
      } else {
        new_industries_array.push(state.new_industry);
      }
      setState({
        ...state,
        new_industries_array: new_industries_array,
        new_industry: "",
      });
    }
  };

  function removeIndustry(industryToRemove) {
    let filteredIndustryArray = state.new_industries_array.filter(
      (item) => item !== industryToRemove
    );
    setState({ ...state, new_industries_array: filteredIndustryArray });
  }
  /* INDUSTRIY END */

  //START JOB TITLE
  const getJobRole = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.JOBROLE_LISTING_API)
      .then((data) => {
        setJobRole(data.json);
        // parseJobList(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const parseJobList = (data) => {
    let temp_job = [];
    data.map((item, index) => {
      temp_job.push({ name: item.name, id: item.id });
    });

    setJobRole(temp_job);
  };

  function onJobeRoleChange(selectedList, selectedItem) {
    let temp_selectedJobRole = [];

    selectedList.map((item, index) => {
      temp_selectedJobRole.push({ job_certificates: item.name });
    });

    setState({
      ...state,
      selectedJobRole: selectedList,
      jobs: temp_selectedJobRole,
    });
  }

  const handleKeyDown_JobRole = (e) => {
    if (e.key === "Enter") {
      var new_jobRole_array = state.new_jobRole_array;
      if (state.new_jobRole == "" || state.new_jobRole == null) {
        // show error message
      } else {
        new_jobRole_array.push(state.new_jobRole);
      }
      setState({
        ...state,
        new_jobRole_array: new_jobRole_array,
        new_jobRole: "",
      });
    }
  };

  function removeJobRole(jobRoleToRemove) {
    let filteredJobRoleArray = state.new_jobRole_array.filter(
      (item) => item !== jobRoleToRemove
    );
    setState({ ...state, new_jobRole_array: filteredJobRoleArray });
  }

  //END JOB TITLE

  //START JOB TYPE
  const getJobType = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.JOBTYPE_LISTING_API)
      .then((data) => {
        setJobType(data.json);
        parseJobTypeList(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const parseJobTypeList = (data) => {
    let temp_jobType = [];
    data.map((item, index) => {
      temp_jobType.push({ name: item.name, id: item.id });
    });

    // setJobRole(temp_jobType);
  };

  function onJobeTypeChange(selectedList, selectedItem) {
    let temp_selectedJobType = [];

    selectedList.map((item, index) => {
      temp_selectedJobType.push({ job_certificates: item.name });
    });

    setState({
      ...state,
      selectedJobType: selectedList,
      // jobTypes: temp_selectedJobType,
    });
  }

  const handleKeyDown_JobType = (e) => {
    if (e.key === "Enter") {
      var new_jobType_array = state.new_jobType_array;
      if (state.new_jobType == "" || state.new_jobType == null) {
        // show error message
      } else {
        new_jobType_array.push(state.new_jobRole);
      }
      setState({
        ...state,
        new_jobType_array: new_jobType_array,
        new_jobType: "",
      });
    }
  };

  function removeJobType(jobTypeToRemove) {
    let filteredJobTypeArray = state.new_jobType_array.filter(
      (item) => item !== jobTypeToRemove
    );
    setState({ ...state, new_jobType_array: filteredJobTypeArray });
  }
  //END JOB TYPE

  //START CITY
  function getCitiesList(selected_country) {
    Object.keys(listContryCity.listContryCity).forEach(function (key, index) {
      var newJson = listContryCity.listContryCity[key];
      if (newJson.country == selected_country) {
        updateCity(newJson.city);
      }
    });
  }

  function updateCity(data) {
    let temp_cities = [];
    data.map((item, index) => {
      temp_cities.push({ label: item, value: item });
    });
    setCitiesState(temp_cities);
  }

  function onCityChange(e) {
    setState({ ...state, city: e.target.value });
  }
  /* city section ends */

  /* country section starts */
  const selectStyles = {
    control: (provided) => ({ ...provided, backgroundColor: 'transparent', borderRadius: "8px", border: "1px solid #b3c2c9", fontSize: "13px" }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)", backgroundColor: 'transparent' }),
  };

  function getCountriesList() {
    setCountriesState(Country.Country);
  }
  function onCountryChange(e) {
    setState({ ...state, country: e.target.value });
    getCitiesList(e.target.value);
  }
  /* country section ends */
  /* languages list section starts */
  const getLanguageList = () => {
    setState({ ...state, isProcessing: true });
    simpleGetCallWithErrorResponse(ApiConfig.LANGUAGE_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseLanguagelList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseLanguagelList = (data) => {
    let temp_lang = [];
    data.map((item, index) => {
      temp_lang.push({ name: item.name, id: item.id });
    });
    setLanguageState(temp_lang);
  };

  function onRequiredLanguageChange(selectedList, selectedItem) {
    let temp_required_language = [];
    let temp_selectedLanguagesWithLevel = [];

    selectedList.map((item, index) => {
      temp_required_language.push(item.name);
      temp_selectedLanguagesWithLevel.push({
        language: item.name,
        level: "Beginner",
      });
    });

    setState({
      ...state,
      selectedLanguages: selectedList,
      selectedLanguagesWithLevel: temp_selectedLanguagesWithLevel,
    });
  }

  function onChangeLanguageLevel(language, level) {
    let temp_selectedLanguagesWithLevel = [];
    state.selectedLanguagesWithLevel.map((item, index) => {
      temp_selectedLanguagesWithLevel.push({
        language: item.language,
        level: item.language == language ? level : item.level,
      });
    });
    setState({
      ...state,
      selectedLanguagesWithLevel: temp_selectedLanguagesWithLevel,
    });
  }
  /* languages list section end */
  //Work location
  function changeWorkLocation(work_location) {
    var temp_work_location = state.work_location;
    if (temp_work_location.indexOf(work_location) > -1) {
      temp_work_location.splice(temp_work_location.indexOf(work_location), 1);
    } else {
      temp_work_location.push(work_location);
    }
    setState({ ...state, work_location: temp_work_location });
  }
  //end work location
  //START ADD PREFERENCES
  const addPreferences = () => {
    let formData = new FormData();
    formData.append("industries", state.selectedIndustries);
    formData.append("job_role", state.job_role);
    formData.append("job_type", state.job_type);
    formData.append(
      "selectedLanguagesWithLevel",
      state.selectedLanguagesWithLevel
    );
    formData.append("city", state.city);
    formData.append("country", state.country);
    formData.append("industries", state.selectedIndustries);
    formData.append("monthly_salary", state.monthly_salary);

    formData.append("work_location", state.work_location);
    formData.append("match_percentage", state.match_percentage);

    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.CREATE_PREFERENCES,
      FormData
    )
      .then((data) => {
        if (data.error == false) {
          // window.location.href = "/Seeker_Profile";
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  const savePreferences = () => {
    let industries = state.selectedIndustries.map((industry) => {
      return { name: industry.name };
    });
    let JobRoles = state.selectedJobRole.map((role) => {
      return { name: role.name };
    });
    let jobTypes = state.selectedJobType.map((jobType) => {
      return { name: jobType.name };
    });
    let data = {
      industries: industries,
      job_role: JobRoles,
      job_type: jobTypes,
      languages: state.selectedLanguagesWithLevel,
      city: state.city,
      country: state.country,
      monthly_salary: state.salary,
      work_location: state.work_location,
      match_percentage: [state.percentage],
    };
    postWithAuthCallWithErrorResponse(
      ApiConfig.Add_PREFERENCE_JOB_SEEKER,
      JSON.stringify(data)
    )
      .then((res) => {
        getUserData();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //END ADD PREFERENCES
  useEffect(() => {
    getIndustry();
    getJobRole();
    getJobType();
    getCountriesList();
    getLanguageList();
  }, []);

  return (
    <div>
      <Header />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/Seeker_Profile">{translation.PROFILE}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.ADD_PREFERENCES}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div
                className="card login settings_card"
                style={{ marginTop: "1.5em" }}
              >
                <div className="card-body">
                  <form className="row g-3 text-start">
                    <div class="col-md-6 regsiterrf ">
                      <label for="" class="form-label fs-14">
                        {translation.LANGAUGES}
                      </label>
                      <Multiselect
                        className="form-select bg-transparent"
                        aria-label="Default select example"
                        showCheckbox={true} // To show checkbox in the dropdown
                        options={languageState} // Options to display in the dropdown
                        selectedValues={state.selectedLanguagesWithLevel.map((lang) => {
                          return { name: lang.language, level: lang.level }
                        })}
                        // selectedValues={state.selectedLanguages} // Preselected value to persist in dropdown
                        displayValue="name" // Property name to display in the dropdown options
                        onSelect={onRequiredLanguageChange}
                        onRemove={onRequiredLanguageChange}
                      />

                      <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3">
                        {/* <!-- Skill 1 --> */}
                        {state.selectedLanguagesWithLevel.map(
                          (selected_language, index) => {
                            return (
                              <div className="skill_col me-3" key={index}>
                                <div className="d-flex justify-content-between">
                                  <p className="m-0 fs-14">
                                    {selected_language.language}
                                  </p>
                                  {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                                </div>

                                <div className="col-md-12 regsiterrf">
                                  <select
                                    className="form-select fs-14 fw-600 border-primary bg-transparent"
                                    defaultValue={selected_language.level}
                                    onChange={(e) =>
                                      onChangeLanguageLevel(
                                        selected_language.language,
                                        e.target.value
                                      )
                                    }
                                    aria-label="Default select example"
                                  >
                                    <option value="Beginner">
                                      {translation.BEGINNER}
                                    </option>
                                    <option value="Competent">
                                      {translation.COMPETENT}
                                    </option>
                                    <option value="Competent">
                                      {translation.PROFICIENT}
                                    </option>
                                    <option value="Expert">
                                      {translation.EXPERT}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                    {/* <!-- First Skill --> */}
                    <div class="col-md-6 regsiterrf ">
                      <label for="" class="fs-14 mb-2">
                        {translation.INDUSTRIES}
                      </label>
                      {/* <select
                        type="text"
                        placeholder="Add Industry"
                        class="form-control border border-primary"
                      >
                        <option value="">Select Industries </option>
                        {industries && industries.length > 0
                          ? industries.map((result, index) => {
                            return <option>{result.name}</option>;
                          })
                          : null}
                      </select> */}
                      <Multiselect
                        className="form-select bg-transparent"
                        aria-label="Default select example"
                        avoidHighlightFirstOption={true}
                        showCheckbox={true} // To show checkbox in the dropdown
                        options={industries} // Options to display in the dropdown
                        selectedValues={state.selectedIndustries} // Preselected value to persist in dropdown
                        displayValue="name" // Property name to display in the dropdown options
                        onSelect={onIndustryChange}
                        onRemove={onIndustryChange}
                      />
                      <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3">
                        {/* <!-- Skill 1 --> */}
                        {/* {state.selectedIndustries.map(
                          (selectedIndustry, index) => (
                            <div class="skill_col me-3">
                              <div class="single_skills border border-primary d-flex justify-content-between">
                                <p class="m-0 fs-14 me-3">
                                  {selectedIndustry.name}
                                </p>
                              </div>
                            </div>
                          )
                        )} */}
                        {state.new_industries_array.map(
                          (new_industries_data, index) => {
                            return (
                              <div className="skill_col me-3">
                                <div className="d-flex justify-content-between">
                                  <p className="m-0 fs-14">
                                    {new_industries_data}
                                  </p>
                                  &nbsp;&nbsp;&nbsp;
                                  <button
                                    type="button"
                                    className="btn-close"
                                    aria-label="Close"
                                    onClick={() =>
                                      removeIndustry(new_industries_data)
                                    }
                                  ></button>
                                </div>
                                <div className="col-md-12 regsiterrf"></div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>

                    {/* <!-- Second Skill --> */}
                    <div class="col-md-6 regsiterrf ">
                      <label for="" class="fs-14 mb-2">
                        {translation.JOB_TITLE}
                      </label>
                      {/* <select
                        type="text"
                        placeholder="Add Role"
                        class="form-control border border-primary"
                      >
                        <option value="">Select Roles </option>
                        {jobRole && jobRole.length > 0
                          ? jobRole.map((result, index) => {
                            debugger;
                            return <option>{result.name}</option>;
                          })
                          : null}
                      </select> */}
                      <Multiselect
                        className="form-select bg-transparent"
                        aria-label="Default select example"
                        showCheckbox={true} // To show checkbox in the dropdown
                        options={jobRole} // Options to display in the dropdown
                        selectedValues={state.selectedJobRole} // Preselected value to persist in dropdown
                        displayValue="name" // Property name to display in the dropdown options
                        onSelect={onJobeRoleChange}
                        onRemove={onJobeRoleChange}
                      />
                      <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3">
                        {/* <!-- Skill 1 --> */}

                        {/* <!-- Skill 2 --> */}
                        {/* <div class="skill_col me-3">
                          <div
                            class="single_skills border border-primary d-flex justify-content-between">
                            <p class="m-0 fs-14 me-3">UI Designer</p>
                            <button
                              type="button"
                              class="btn-close"
                              aria-label="Close"
                            ></button>
                          </div>
                        </div> */}
                      </div>
                    </div>

                    {/* <!-- Third Skill --> */}
                    <div class="col-md-6 regsiterrf ">
                      <label for="" class="fs-14 mb-2">
                        {translation.JOB_TYPE}
                      </label>
                      {/* <select
                        type="text"
                        placeholder="Add Job Type"
                        class="form-control border border-primary"
                      >
                        <option value="">Select Job Type </option>
                        {jobType && jobType.length > 0
                          ? jobType.map((result, index) => {
                              debugger;
                              return <option>{result.name}</option>;
                            })
                          : null}
                      </select> */}
                      <Multiselect
                        className="form-select bg-transparent"
                        aria-label="Default select example"
                        showCheckbox={true} // To show checkbox in the dropdown
                        options={jobType} // Options to display in the dropdown
                        selectedValues={state.selectedJobType} // Preselected value to persist in dropdown
                        displayValue="name" // Property name to display in the dropdown options
                        onSelect={onJobeTypeChange}
                        onRemove={onJobeTypeChange}
                      />
                      <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3">
                        {/* <!-- Skill 1 --> */}
                      </div>
                    </div>

                    {/* <!-- Fourth Skill --> */}

                    <div class="col-md-6 regsiterrf ">
                      <label for="inputEmail4" class="form-label fs-14">
                        {translation.MONTHLY_SALARY}
                      </label>
                      <select
                        class="form-select bg-transparent border-primary"
                        aria-label="Default select example"
                        value={state.salary}
                        onChange={(e) => {
                          setState({ ...state, salary: e.target.value });
                        }}
                      >
                        <option >$3000 - $5000</option>
                        <option value="3000-5000">$3000 - $5000</option>
                        <option value="5000-10000">$5000 - $10000</option>
                        <option value="10000-15000">$10000 - $15000</option>
                      </select>
                    </div>
                    <div class="col-md-6 regsiterrf">
                      <label for="inputEmail4" class="form-label fs-14">
                        {translation.PERCENTAGE}
                      </label>
                      <select
                        class="form-select bg-transparent border-primary"
                        aria-label="Default select example"
                        value={state.percentage}
                        onChange={(e) => {
                          setState({ ...state, percentage: e.target.value });
                        }}
                      >
                        <option >Greater than</option>
                        <option value="0-49%">0-49%</option>
                        <option value="50-74%">50-74%</option>
                        <option value="75-100%">75-100%</option>
                      </select>
                    </div>
                    <div className="col-md-6 regsiterrf">
                      <label for="inputEmail4" className="form-label fs-14">
                        {translation.COUNTRY}
                      </label>
                      {/* <input
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                          onChange={onInputHandle}
                          name="city"
                          value={state.city}
                        /> */}

                      <input list="countryLIst" id="countryName" className="form-control" onChange={onCountryChange} value={state.country || ""} />
                      <datalist id="countryLIst" name={"service-cityselected"} style={{ color: "red" }}>
                        {countriesState && countriesState.length && countriesState.map(country => {
                          return (<option value={country.value} />)
                        })}
                      </datalist>

                      <span className="fs-14" style={{ color: "red" }}>
                        {state.errorMsg.country ? state.errorMsg.country : ""}
                      </span>
                    </div>
                    <div className="col-md-6 regsiterrf">
                      <label for="inputPassword4" className="form-label fs-14">
                        {translation.CITY}
                      </label>
                      <input list="cityList" id="city" className="form-control" onChange={onCityChange} value={state.city || ""} />
                      <datalist id="cityList" name={"service-cityselected"} style={{ color: "red" }}>
                        {citiesState && citiesState.length && citiesState.map(city => {
                          return (<option value={city.value} />)
                        })}
                      </datalist>
                      {/* <Select
                        isClearable={false}
                        onChange={onCityChange}
                        options={citiesState}
                        placeholder={translation.SEARCH}
                        styles={selectStyles}
                        //tabSelectsValue={false}
                        value={state.selected_City}
                      /> */}
                      <span className="fs-14" style={{ color: "red" }}>
                        {state.errorMsg.city ? state.errorMsg.city : ""}
                      </span>
                      {/* <input
                          type="email"
                          className="form-control"
                          id="inputPassword4"
                          onChange={onInputHandle}
                          name="country"
                          value={state.country}
                        /> */}
                    </div>

                    <div class="col-md-12 regsiterrf mb-4">
                      <div>
                        <label class="form-label fs-14">{translation.WORK_LOCATION}</label>
                      </div>
                      <div className="swith_btn btn-group btn-toggle">
                        <button
                          className={
                            state.work_location.indexOf("OnSite") > -1
                              ? `btn btn-lg btn-primary active`
                              : `btn btn-lg btn-default`
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            changeWorkLocation("OnSite");
                          }}
                        >
                          {translation.ONSITE}
                        </button>
                        <button
                          className={
                            state.work_location.indexOf("Hybrid") > -1
                              ? `btn btn-lg btn-primary active`
                              : `btn btn-lg btn-default`
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            changeWorkLocation("Hybrid");
                          }}
                        >
                          {translation.HYBRID}
                        </button>
                        <button
                          className={
                            state.work_location.indexOf("Remote") > -1
                              ? `btn btn-lg btn-primary active`
                              : `btn btn-lg btn-default`
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            changeWorkLocation("Remote");
                          }}
                        >
                          {translation.REMOTE}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="loginwithlinks editprofile_block">
                <div className="d-lg-flex d-md-flex justify-content-end">
                  <div className="loginwithbtn">
                    <p className="fs-22 fw-600 text-primary">
                      {translation.UPDATE + " "}
                      <Link to="/Seeker_Profile">
                        <button
                          type="button"
                          className="btn btn-outline-primary rounded-circle ms-2"
                          onClick={savePreferences}
                        >
                          <img src={chevron_right} alt="" />
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
