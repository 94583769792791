import React, { useEffect, useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import ApiConfig from "../api/ApiConfig";
import {
  getWithAuthCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
} from "../api/ApiServices.js";
import { Link } from "react-router-dom";
import useTranslation from "../customHooks/translations";

const Filter_JobseekerHome = () => {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    new_skill: "",
    new_skills_array: [],
    new_tool: "",
    new_tools_array: [],
    new_certificate: "",
    new_certificates_array: [],
    selectedToolsWithLevel: [],
    selectedSkillsWithLevel: [],
    skill_set: "",
    tool_set: "",
    percent: "25",
  });
  const [tool, setTool] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const options = [
    { id: 1, value: "Hybrid", label: "Hybrid" },
    { id: 2, value: "Static", label: "Static" },
    { id: 3, value: "Fixed", label: "Fixed" },
    { id: 4, value: "Flexible", label: "Flexible" },
  ];
  const [skillsState, setSkillsState] = useState();
  const [toolsState, setToolsState] = useState();
  const [workplace, setWorkplace] = useState(options);
  const [countriesState, setCountriesState] = useState();
  const [citiesState, setCitiesState] = useState();

  // ****TOOLS****
  const getToolsList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.TOOLS_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseToolsList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseToolsList = (data) => {
    let temp_tools = [];
    data.map((item, index) => {
      temp_tools.push({ name: item.name, id: item.id });
    });
    setState({ ...state, tool_set: [temp_tools[0]] });
    setToolsState(temp_tools);
  };

  function onToolChange(selectedList, selectedItem) {
    let temp_tool = [];
    let temp_selectedToolsWithLevel = [];

    selectedList.map((item, index) => {
      temp_tool.push(item.name);
      temp_selectedToolsWithLevel.push({ tool: item.name, level: "Beginner" });
    });

    setState({
      ...state,
      selectedTools: selectedList,
      selectedToolsWithLevel: temp_selectedToolsWithLevel,
    });
  }

  function onChangeToolLevel(tool, level) {
    let temp_selectedToolsWithLevel = [];
    state.selectedToolsWithLevel.map((item, index) => {
      temp_selectedToolsWithLevel.push({
        tool: item.tool,
        level: item.tool == tool ? level : item.level,
      });
    });
    setState({ ...state, selectedToolsWithLevel: temp_selectedToolsWithLevel });
  }
  // *** TOOLS ***

  /* skills section starts */
  const getSkillsList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.SKILLS_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseSkillsList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseSkillsList = (data) => {
    let temp_skills = [];
    data.map((item, index) => {
      temp_skills.push({ name: item.name, id: item.id });
    });
    setState({ ...state, skill_set: [temp_skills[0]] });
    setSkillsState(temp_skills);
  };

  function onSkillChange(selectedList, selectedItem) {
    let temp_skill = [];
    let temp_selectedSkillsWithLevel = [];

    selectedList.map((item, index) => {
      temp_skill.push(item.name);
      temp_selectedSkillsWithLevel.push({
        skill: item.name,
        level: "Beginner",
      });
    });

    setState({
      ...state,
      selectedSkills: selectedList,
      selectedSkillsWithLevel: temp_selectedSkillsWithLevel,
    });
  }

  function onChangeSkillLevel(skill, level) {
    let temp_selectedSkillsWithLevel = [];
    state.selectedSkillsWithLevel.map((item, index) => {
      temp_selectedSkillsWithLevel.push({
        skill: item.skill,
        level: item.skill == skill ? level : item.level,
      });
    });
    setState({
      ...state,
      selectedSkillsWithLevel: temp_selectedSkillsWithLevel,
    });
  }
  function addNewSkill(value) {
    let temp_selectedSkillsWithLevel = skillsState;
    temp_selectedSkillsWithLevel.push({ name: value, id: value });
    setSkillsState(temp_selectedSkillsWithLevel);
  }
  /* skills section ends */
  //***JOB FILTER ***/
  //FILTER_JOB_SEEKER
  const Filter = () => {
    let formData = new FormData();
    formData.append("skill_set", state.selectedSkills);
    formData.append("tool_set", state.selectedTools);
    formData.append("workplace", workplace);
    formData.append("percent", state.percent);

    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.FILTER_JOB_SEEKER,
      formData
    )
      .then((data) => {
        if (data.error == false) {
          //window.location.href = "/Seeker_Home";
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  //**END JOB FILTER */
  useEffect(() => {
    getToolsList();
    getSkillsList();
  }, []);
  return (
    <div>
      {/* <!-- Search Filter Offcanvas Start --> */}
      <div
        class="SearchFilterCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasSearchFilter"
        aria-labelledby="offcanvasSearchFilterLabel"
      >
        <div class="offcanvas-header">
          <h5
            class="offcanvas-title fs-18 fw-600"
            id="offcanvasSearchFilterLabel"
          >
            {translation.FILTERS}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <form class="row g-3 text-start">
            {/* <div class="col-md-12 regsiterrf mb-3">
              <label class="skl_block fw-700 mb-3">JOB TITLE</label>
              <select class="form-select border-primary"
                aria-label="Default select example" >
              <option value="">Please select job title</option>
                {jobTitle && jobTitle.length > 0
                  ? jobTitle.map((result, index) => {
                      // debugger;
                      return <option>{result.name}</option>;
                    })
                  : null}
              </select>
            </div> */}
            {/* <div class="col-md-6 regsiterrf mb-3">
              <label class="skl_block fw-700 mb-3">LOCATION</label>
              <input type="text" class="form-control border border-primary" />
            </div> */}

            {/* <div class="col-md-6 regsiterrf mb-3">
              <label class="skl_block fw-700 mb-3">Country</label>
              <Select
                isClearable={false}
                onChange={onCountryChange}
                options={countriesState}
                placeholder={translation.SEARCH}
                styles={selectStyles}
                //tabSelectsValue={false}
                value={state.selected_Country}
              />
            </div>

            <div class="col-md-6 regsiterrf mb-3">
              <label class="skl_block fw-700 mb-3">City</label>
              <Select
                isClearable={false}
                onChange={onCityChange}
                options={citiesState}
                placeholder={translation.SEARCH}
                styles={selectStyles}
                //tabSelectsValue={false}
                value={state.selected_City}
              />
            </div> */}

            <div class="col-md-12 regsiterrf mb-3">
              <label class="skl_block fw-700 mb-3">
                {translation.MATCH_PRECENTAGE.toUpperCase()}
              </label>
              <select
                class="form-select border-primary"
                aria-label="Default select example"
                value={state.percent}
                onChange={(e) => {
                  setState({ ...state, percent: e.target.value });
                }}
              >
                <option value="25">25%</option>
                <option value="50">50%</option>
                <option value="75">75%</option>
                <option value="100">100%</option>
              </select>
            </div>
            {/* <div class="col-md-6 regsiterrf mb-3">
              <label class="skl_block fw-700 mb-3">MONTHLY SALARY</label>
              <select
                class="form-select border-primary"
                aria-label="Default select example"
              >
                <option selected>$3000 - $5000</option>
                <option value="1">$3000 - $5000</option>
                <option value="2">$5000 - $10000</option>
                <option value="3">Above $10000</option>
              </select>
            </div> */}

            <div class="col-md-12 regsiterrf mb-3">
              <label class="skl_block fw-700 mb-3">
                {translation.WORKPLACE.toUpperCase()}
              </label>
              <Multiselect
                className="form-select bg-transparent"
                aria-label="Default select example"
                avoidHighlightFirstOption={true}
                // onSearch={addNewSkill}

                showCheckbox={true} // To show checkbox in the dropdown
                options={options} // Options to display in the dropdown
                // Preselected value to persist in dropdown
                displayValue="label" // Property name to display in the dropdown options
                //onChange={setWorkplace}
                onSelect={(selectedList, selectedItem) => {
                  setWorkplace(selectedList);
                }}
                onRemove={(selectedList, selectedItem) => {
                  setWorkplace(selectedList);
                }}
              ></Multiselect>
              <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                {/* <!-- Skill 1 --> */}
              </div>
            </div>

            {/* <!-- First Skill --> */}
            <div class="col-md-12 regsiterrf mb-3">
              <label class="skl_block mb-3">
                {translation.SKILLS.toUpperCase()}
              </label>
              {/* <select
                class="form-select border-primary"
                aria-label="Default select example"
              >
                <option value="">Select Skills</option>
                {state && state.length > 0
                  ? state.map((result, index) => {
                      // debugger;
                      return <option>{result.name}</option>;
                    })
                  : null}
              </select> */}
              <Multiselect
                className="form-select bg-transparent"
                aria-label="Default select example"
                avoidHighlightFirstOption={true}
                onSearch={addNewSkill}
                showCheckbox={true} // To show checkbox in the dropdown
                options={skillsState} // Options to display in the dropdown
                selectedValues={state.selectedSkills} // Preselected value to persist in dropdown
                displayValue="name" // Property name to display in the dropdown options
                onSelect={onSkillChange}
                onRemove={onSkillChange}
              />
              <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                {/* <!-- Skill 1 --> */}
                {state.selectedSkillsWithLevel.map((selected_skill, index) => {
                  return (
                    <div className="skill_col me-3" key={index}>
                      <div className="d-flex justify-content-between">
                        <p className="m-0 fs-14">{selected_skill.skill}</p>
                        {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                      </div>
                      <div className="col-md-12 regsiterrf">
                        <select
                          className="form-select fs-14 fw-600 border-primary bg-transparent"
                          value={selected_skill.level}
                          onChange={(e) =>
                            onChangeSkillLevel(
                              selected_skill.skill,
                              e.target.value
                            )
                          }
                          aria-label="Default select example"
                        >
                          <option value="Beginner">
                            {translation.BEGINNER}
                          </option>
                          <option value="Intermediate">
                            {translation.COMPETENT}
                          </option>
                          <option value="Competent">
                            {translation.PROFICIENT}
                          </option>
                          <option value="Expert">{translation.EXPERT}</option>
                        </select>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* <!-- Second Skill --> */}
            <div class="col-md-12 regsiterrf mb-3">
              <label class="skl_block mb-3">{translation.TOOLS}</label>
              {/* <select
                class="form-select border-primary"
                aria-label="Default select example"
              >
                <option value="">Select Tool</option>
                {tool && tool.length > 0
                  ? tool.map((result, index) => {
                      // debugger;
                      return <option>{result.name}</option>;
                    })
                  : null}
              </select> */}
              <Multiselect
                className="form-select bg-transparent"
                aria-label="Default select example"
                avoidHighlightFirstOption={true}
                showCheckbox={true} // To show checkbox in the dropdown
                options={toolsState} // Options to display in the dropdown
                selectedValues={state.selectedTools} // Preselected value to persist in dropdown
                displayValue="name" // Property name to display in the dropdown options
                onSelect={onToolChange}
                onRemove={onToolChange}
              />
              <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                {/* <!-- Skill 1 --> */}
                {state.selectedToolsWithLevel.map((selected_tool, index) => {
                  return (
                    <div className="skill_col me-3" key={index}>
                      <div className="d-flex justify-content-between">
                        <p className="m-0 fs-14">{selected_tool.tool}</p>
                        {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                      </div>

                      <div className="col-md-12 regsiterrf">
                        <select
                          className="form-select fs-14 fw-600 border-primary bg-transparent"
                          value={selected_tool.level}
                          onChange={(e) =>
                            onChangeToolLevel(
                              selected_tool.tool,
                              e.target.value
                            )
                          }
                          aria-label="Default select example"
                        >
                          <option value="Beginner">
                            {translation.BEGINNER}
                          </option>
                          <option value="Intermediate">
                            {translation.COMPETENT}
                          </option>
                          <option value="Competent">
                            {translation.PROFICIENT}
                          </option>
                          <option value="Expert">{translation.EXPERT}</option>
                        </select>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </form>
        </div>
        <div class="oddcanvas-footer">
          <hr />
          <div class="d-flex justify-content-end">
            <Link
              class="fs-16 fw-600 me-3 pb-3"
              //data-bs-dismiss="offcanvas"
              //aria-label="Close"
              to={
                window.location.pathname === "/Seeker_Home" ?
                  window.location.pathname === "/Seeker_Home" ?
                    window.location.pathname === "/Seeker_Home"
                      ? "/Seeker_Home"
                      : window.location.pathname === "/Explore"
                        ? "/Explore"
                        : "#"
                    : "#"
                  :
                  window.location.pathname === "/Recuiter_Home" ?
                    window.location.pathname === "/Recuiter_Home"
                      ? "/Recuiter_Home"
                      : window.location.pathname === "/recruiter-explore-jobs"
                        ? "/recruiter-explore-jobs"
                        : "#"
                    : "#"
              }
              // onClick={Filter}
              state={{
                skill_set: state.selectedSkills
                  ? state.selectedSkills.map((a) => a.name)
                  : [""],
                tool_set: state.selectedTools
                  ? state.selectedTools.map((a) => a.name)
                  : [""],
                location_set: workplace ? workplace.map((a) => a.value) : [""],
                percent: state.percent ? [state.percent] : [""],
                industry: "",
                isFrom: "Explore_Jobs",
              }}
            >
              {translation.APPLY}
            </Link>
          </div>
        </div>
      </div>
      {/* <!-- Search Filter Offcanvas End --> */}
    </div>
  );
};

export default Filter_JobseekerHome;
