import { useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';

function PopoverElement(props) {
  const [show, setShow] = useState(true)
  let msg = props.msg
  document.getElementById(props.Id).classList.add("border")
  document.getElementById(props.Id).classList.add("border-danger")
  setTimeout(() => {
    document.getElementById(props.Id).classList.remove("border")
    document.getElementById(props.Id).classList.remove("border-danger")
  }, 3000);
  return (
    <>
      {/* <Button ref={target} onClick={() => setShow(!show)}>
        Click me!
      </Button> */}
      <Overlay target={props.references.current.controlRef || props.references.current} show={show} placement="bottom">

        {(props) => (
          <Tooltip id={"overlay-example " + props.msg}   {...props}>
            {msg}
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}

export default PopoverElement;