import React from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import logoColor from "../assets/img/logo_color.png";
import Privacy from './Privacy';
import useTranslation from '../customHooks/translations';


export default function PrivcayPolicy() {
    const translation = useTranslation();
    return (
        <div>
            <section class="breadcrumbs_block">
                <div class="container">
                    
                    <div class="offcanvas-header">
                    <a class="navbar-brand" href="#">
              <img src={logoColor} alt="" />
            </a>
                        <h5 class="offcanvas-title fs -16 fw-700" id="offcanvasAddEducationLabel">{translation.PRIVACY_POLICY}</h5>
                        <Link to="/Login"><button type="button" class="btn-close text-reset" aria-label="Close"></button></Link>
                    </div>
                </div>
            </section>

            <section id="faq" class="faq">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="accordion mt-5" id="accordionPanelsStayOpenExample">
                                <div class="accordion-item">

                                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                        <div class="accordion-body">
                                            <Privacy/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </div>
    )
}
