import React from "react";
import notification from "../../assets/img/notification.svg";
import menu from "../../assets/img/menu.svg";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import { Link } from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import NotificationComponent from "../Job_Seeker/NotificationComponent";

function RecruiterNotification() {
  const translation = useTranslation();
  return (
    <>
      <RecruiterHeader />
      <div>
        <section className="breadcrumbs_block">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/Seeker_Profile">{translation.PROFILE}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {translation.NOTIFICATIONS}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </section>

        <section id="settings" className="settings">
          <div className="container">
            <div className="row">
              <NotificationComponent />
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default RecruiterNotification;
