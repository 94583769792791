const Country=[
  {label: 'Afghanistan', value: 'Afghanistan'},
  {label: 'Albania', value: 'Albania'},
  {label: 'Algeria', value: 'Algeria'},
  {label: 'Andorra', value: 'Andorra'},
  {label: 'Angola', value: 'Angola'},
  {label: 'Antigua and Barbuda', value: 'Antigua and Barbuda'},
  {label: 'Argentina', value: 'Argentina'},
  {label: 'Armenia', value: 'Armenia'},
  {label: 'Aruba', value: 'Aruba'},
  {label: 'Australia', value: 'Australia'},
  {label: 'Austria', value: 'Austria'},
  {label: 'Azerbaijan', value: 'Azerbaijan'},
  {label: 'Bahamas', value: 'Bahamas'},
  {label: 'Bahrain', value: 'Bahrain'},
  {label: 'Bangladesh', value: 'Bangladesh'},
  {label: 'Barbados', value: 'Barbados'},
  {label: 'Belarus', value: 'Belarus'},
  {label: 'Belgium', value: 'Belgium'},
  {label: 'Belize', value: 'Belize'},
  {label: 'Bolivia', value: 'Bolivia'},
  {label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina'},
  {label: 'Botswana', value: 'Botswana'},
  {label: 'Brazil', value: 'Brazil'},
  {label: 'Brunei', value: 'Brunei'},
  {label: 'Bulgaria', value: 'Bulgaria'},
  {label: 'Cambodia', value: 'Cambodia'},
  {label: 'Cameroon', value: 'Cameroon'},
  {label: 'Canada', value: 'Canada'},
  {label: 'Cayman Islands', value: 'Cayman Islands'},
  {label: 'Chile', value: 'Chile'},
  {label: 'China', value: 'China'},
  {label: 'Colombia', value: 'Colombia'},
  {label: 'Congo', value: 'Congo'},
  {label: 'Costa Rica', value: 'Costa Rica'},
  {label: 'Croatia', value: 'Croatia'},
  {label: 'Cuba', value: 'Cuba'},
  {label: 'Cyprus', value: 'Cyprus'},
  {label: 'Czech Republic', value: 'Czech Republic'},
  {label: 'Denmark', value: 'Denmark'},
  {label: 'Dominican Republic', value: 'Dominican Republic'},
  {label: 'Ecuador', value: 'Ecuador'},
  {label: 'Egypt', value: 'Egypt'},
  {label: 'El Salvador', value: 'El Salvador'},
  {label: 'Estonia', value: 'Estonia'},
  {label: 'Faroe Islands', value: 'Faroe Islands'},
  {label: 'Finland', value: 'Finland'},
  {label: 'France', value: 'France'},
  {label: 'French Polynesia', value: 'French Polynesia'},
  {label: 'Gabon', value: 'Gabon'},
  {label: 'Georgia', value: 'Georgia'},
  {label: 'Germany', value: 'Germany'},
  {label: 'Ghana', value: 'Ghana'},
  {label: 'Greece', value: 'Greece'},
  {label: 'Greenland', value: 'Greenland'},
  {label: 'Guadeloupe', value: 'Guadeloupe'},
  {label: 'Guam', value: 'Guam'},
  {label: 'Guatemala', value: 'Guatemala'},
  {label: 'Guinea', value: 'Guinea'},
  {label: 'Haiti', value: 'Haiti'},
  {label: 'Hashemite Kingdom of Jordan', value: 'Hashemite Kingdom of Jordan'},
  {label: 'Honduras', value: 'Honduras'},
  {label: 'Hong Kong', value: 'Hong Kong'},
  {label: 'Hungary', value: 'Hungary'},
  {label: 'Iceland', value: 'Iceland'},
  {label: 'India', value: 'India'},
  {label: 'Indonesia', value: 'Indonesia'},
  {label: 'Iran', value: 'Iran'},
  {label: 'Iraq', value: 'Iraq'},
  {label: 'Ireland', value: 'Ireland'},
  {label: 'Isle of Man', value: 'Isle of Man'},
  {label: 'Israel', value: 'Israel'},
  {label: 'Italy', value: 'Italy'},
  {label: 'Jamaica', value: 'Jamaica'},
  {label: 'Japan', value: 'Japan'},
  {label: 'Kazakhstan', value: 'Kazakhstan'},
  {label: 'Kenya', value: 'Kenya'},
  {label: 'Kosovo', value: 'Kosovo'},
  {label: 'Kuwait', value: 'Kuwait'},
  {label: 'Latvia', value: 'Latvia'},
  {label: 'Lebanon', value: 'Lebanon'},
  {label: 'Libya', value: 'Libya'},
  {label: 'Liechtenstein', value: 'Liechtenstein'},
  {label: 'Luxembourg', value: 'Luxembourg'},
  {label: 'Macedonia', value: 'Macedonia'},
  {label: 'Madagascar', value: 'Madagascar'},
  {label: 'Malaysia', value: 'Malaysia'},
  {label: 'Malta', value: 'Malta'},
  {label: 'Martinique', value: 'Martinique'},
  {label: 'Mauritius', value: 'Mauritius'},
  {label: 'Mayotte', value: 'Mayotte'},
  {label: 'Mexico', value: 'Mexico'},
  {label: 'Mongolia', value: 'Mongolia'},
  {label: 'Montenegro', value: 'Montenegro'},
  {label: 'Morocco', value: 'Morocco'},
  {label: 'Mozambique', value: 'Mozambique'},
  {label: 'Myanmar [Burma]', value: 'Myanmar [Burma]'},
  {label: 'Namibia', value: 'Namibia'},
  {label: 'Nepal', value: 'Nepal'},
  {label: 'Netherlands', value: 'Netherlands'},
  {label: 'New Caledonia', value: 'New Caledonia'},
  {label: 'New Zealand', value: 'New Zealand'},
  {label: 'Nicaragua', value: 'Nicaragua'},
  {label: 'Nigeria', value: 'Nigeria'},
  {label: 'Norway', value: 'Norway'},
  {label: 'Oman', value: 'Oman'},
  {label: 'Pakistan', value: 'Pakistan'},
  {label: 'Palestine', value: 'Palestine'},
  {label: 'Panama', value: 'Panama'},
  {label: 'Papua New Guinea', value: 'Papua New Guinea'},
  {label: 'Paraguay', value: 'Paraguay'},
  {label: 'Peru', value: 'Peru'},
  {label: 'Philippines', value: 'Philippines'},
  {label: 'Poland', value: 'Poland'},
  {label: 'Portugal', value: 'Portugal'},
  {label: 'Puerto Rico', value: 'Puerto Rico'},
  {label: 'Republic of Korea', value: 'Republic of Korea'},
  {label: 'Republic of Lithuania', value: 'Republic of Lithuania'},
  {label: 'Republic of Moldova', value: 'Republic of Moldova'},
  {label: 'Romania', value: 'Romania'},
  {label: 'Russia', value: 'Russia'},
  {label: 'Saint Lucia', value: 'Saint Lucia'},
  {label: 'San Marino', value: 'San Marino'},
  {label: 'Saudi Arabia', value: 'Saudi Arabia'},
  {label: 'Senegal', value: 'Senegal'},
  {label: 'Serbia', value: 'Serbia'},
  {label: 'Singapore', value: 'Singapore'},
  {label: 'Slovakia', value: 'Slovakia'},
  {label: 'Slovenia', value: 'Slovenia'},
  {label: 'South Africa', value: 'South Africa'},
  {label: 'Spain', value: 'Spain'},
  {label: 'Sri Lanka', value: 'Sri Lanka'},
  {label: 'Sudan', value: 'Sudan'},
  {label: 'Suriname', value: 'Suriname'},
  {label: 'Swaziland', value: 'Swaziland'},
  {label: 'Sweden', value: 'Sweden'},
  {label: 'Switzerland', value: 'Switzerland'},
  {label: 'Taiwan', value: 'Taiwan'},
  {label: 'Tanzania', value: 'Tanzania'},
  {label: 'Thailand', value: 'Thailand'},
  {label: 'Trinidad and Tobago', value: 'Trinidad and Tobago'},
  {label: 'Tunisia', value: 'Tunisia'},
  {label: 'Turkey', value: 'Turkey'},
  {label: 'U.S. Virgin Islands', value: 'U.S. Virgin Islands'},
  {label: 'Ukraine', value: 'Ukraine'},
  {label: 'United Arab Emirates', value: 'United Arab Emirates'},
  {label: 'United Kingdom', value: 'United Kingdom'},
  {label: 'United States', value: 'United States'},
  {label: 'Uruguay', value: 'Uruguay'},
  {label: 'Venezuela', value: 'Venezuela'},
  {label: 'Vietnam', value: 'Vietnam'},
  {label: 'Zambia', value: 'Zambia'},
  {label: 'Zimbabwe', value: 'Zimbabwe'},
];
export default {Country}