import React, { useState } from "react";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import { Link } from "react-router-dom";
import default_profile_pic from "../../assets/img/profile_pic.svg";
import camera from "../../assets/img/camera.svg";
import tick_timeline from "../../assets/img/tick_timeline.svg";
import upload from "../../assets/img/upload.svg";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";
import useTranslation from "../../customHooks/translations";

const CompanyInfo = (props) => {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    profile_pic: localStorage.getItem("profilePic")
      ? localStorage.getItem("profilePic")
      : "",
    profile_pic_name: "",
    user_profile_pic: localStorage.getItem("userProfilePic")
      ? localStorage.getItem("userProfilePic")
      : default_profile_pic,
    company_name: localStorage.getItem("companyName")
      ? localStorage.getItem("companyName")
      : "",
    industry: localStorage.getItem("Industry")
      ? localStorage.getItem("Industry")
      : "",
    about_company: localStorage.getItem("aboutCompany")
      ? localStorage.getItem("aboutCompany")
      : "",
    errMsg: {},
  });

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleImageChange = async (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      /*this.setState({
          image: file,
          imagePreviewUrl: reader.result
      });*/
      setState({
        ...state,
        profile_pic: file,
        profile_pic_name: file.name,
        user_profile_pic: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  const goToJobInfo1 = async (data) => {
    if (
      state.company_name == "" ||
      state.company_name == null ||
      state.industry == "" ||
      state.industry == null ||
      state.about_company == "" ||
      state.about_company == null
    ) {
      if (state.company_name == "" || state.company_name == null) {
        setState({
          ...state,
          errMsg: { company_name: translation.PLEASE_ENTER_COMPANY_NAME },
        });
      } else if (state.industry == "" || state.industry == null) {
        setState({
          ...state,
          errMsg: { industry: translation.PLEASE_ENTER_INDUSTRY },
        });
      } else if (state.about_company == "" || state.about_company == null) {
        setState({
          ...state,
          errMsg: { about_company: translation.PLEASE_ENTER_ABOUT_COMPANY },
        });
      }
    } else {
      props.goToJobInfo(data);
    }
  };
  return (
    <>
      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative" style={{ marginTop: "7em" }}>
            <div className="col-lg-4 col-md-12">
              <h2 className="fs-18 fw-600">{translation.CREATE_JOB_AD}</h2>

              <div className="create_job_tracking trackingstep">
                <div className="step step-active">
                  <div>
                    <div className="circle"></div>
                  </div>
                  <div>
                    <div className="title">{translation.COMPANY_INFO}</div>
                  </div>
                </div>
                <div className="step inactive">
                  <div>
                    <div className="circle">2</div>
                  </div>
                  <div>
                    <div className="title">{translation.JOBS}</div>
                  </div>
                </div>
                <div className="step inactive">
                  <div>
                    <div className="circle">3</div>
                  </div>
                  <div>
                    <div className="title">{translation.PREREQUISITES}</div>
                    {/* <div className="caption">Some text about Third step. </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="card login settings_card create_job_card_profile">
                <div className="card-body">
                  <div className="form_block w-100 pt-0">
                    <form className="row g-3 text-start">
                      <div className="col-md-12">
                        <div className="upload_profileimg mb-4">
                          <div className="profle_circle">
                            <div className="profle_upload">
                              <img
                                src={state.user_profile_pic}
                                alt=""
                                style={{
                                  borderRadius: "50%",
                                  width: "94px",
                                  height: "94px",
                                }}
                              />
                              <a href="#" onClick={handleClick}>
                                <img
                                  src={camera}
                                  className="upload-button"
                                  alt=""
                                />
                              </a>
                              <input
                                type="file"
                                ref={hiddenFileInput}
                                style={{ display: "none" }}
                                accept="image/*"
                                id="profilePicFile"
                                onChange={(e) => handleImageChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="company_name"
                          className="form-label fs-14"
                        >
                          {translation.COMPANY_NAME}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="company_name"
                          value={state.company_name}
                          onChange={(e) => {
                            setState({
                              ...state,
                              company_name: e.target.value,
                            });
                          }}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.company_name
                            ? state.errMsg.company_name
                            : ""}
                        </span>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label htmlFor="industry" className="form-label fs-14">
                          {translation.INDUSTRY}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="industry"
                          value={state.industry}
                          onChange={(e) => {
                            setState({ ...state, industry: e.target.value });
                          }}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.industry ? state.errMsg.industry : ""}
                        </span>
                      </div>
                      <div className="col-md-12 regsiterrf mb-4">
                        <label
                          htmlFor="about_company"
                          className="form-label fs-14"
                        >
                          {translation.ABOUT_THE_COMPANY}
                        </label>
                        <textarea
                          className="form-control"
                          id="about_company"
                          rows="5"
                          style={{ height: "69px" }}
                          value={state.about_company}
                          onChange={(e) => {
                            setState({
                              ...state,
                              about_company: e.target.value,
                            });
                          }}
                        ></textarea>
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.about_company
                            ? state.errMsg.about_company
                            : ""}
                        </span>
                      </div>

                      <hr className="mt-4" />
                      <div className="card_footer_btn d-flex justify-content-between pt-0 mt-0">
                        <p
                          className="fw-600 fs-18"
                          style={{ color: "#fcfeff" }}
                        >
                          {translation.PREV}
                        </p>
                        {/* <a href="#" className="fw-600 fs-18" onClick={() => { props.handleNextPage() }}>Next</a> */}
                        {/* <a href="#" className="fw-600 fs-18" onClick={() => { props.goToJobInfo(state) }}>Next</a> */}
                        <a
                          href="#"
                          className="fw-600 fs-18"
                          onClick={() => goToJobInfo1(state)}
                        >
                          {translation.NEXT}
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const JobInfo = (props) => {
  const translation = useTranslation();
  return (
    <>
      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative" style={{ marginTop: "4em" }}>
            <div className="col-lg-4 col-md-12">
              <h2 className="fs-18 fw-600">{translation.CREATE_JOB_AD}</h2>

              <div className="create_job_tracking trackingstep">
                <div className="step step-complete">
                  <div>
                    <div className="circle">
                      <img src={tick_timeline} alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="title">{translation.COMPANY_INFO}</div>
                  </div>
                </div>
                <div className="step step-active">
                  <div>
                    <div className="circle">2</div>
                  </div>
                  <div>
                    <div className="title">{translation.JOBS}</div>
                  </div>
                </div>
                <div className="step inactive">
                  <div>
                    <div className="circle">3</div>
                  </div>
                  <div>
                    <div className="title">{translation.PREREQUISITES}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="card login settings_card create_job_card">
                <div className="card-body">
                  <div className="form_block w-100 pt-0">
                    <form className="row g-3 text-start">
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.JOB_TITLE}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="inputEmail4"
                        />
                      </div>
                      <div className="col-md-6 upload-btn-wrapper">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.ADD_VIDEO}
                        </label>
                        <div className="input-group">
                          <input type="text" className="form-control" />
                          <span
                            className="input-group-text"
                            style={{
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px",
                            }}
                          >
                            <img src={upload} alt="" />
                          </span>
                          <input type="file" name="myfile" />
                        </div>
                      </div>
                      <div className="col-md-12 regsiterrf mb-4">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.JOB_DESCRIPTION}
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ height: "69px" }}
                        ></textarea>
                      </div>

                      <div className="col-md-6 regsiterrf mb-4">
                        <div>
                          <label className="form-label fs-14">{translation.JOB_TYPE}</label>
                        </div>
                        <div className="swith_btn btn-group btn-toggle">
                          <button className="btn btn-lg btn-default">
                            {translation.PART_TIME}
                          </button>
                          <button className="btn btn-lg btn-primary active">
                            {translation.FULL_TIME}
                          </button>
                        </div>
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.REQUIRED_EXPERIENCE}
                        </label>
                        <select
                          className="form-select bg-transparent"
                          aria-label="Default select example"
                        >
                          <option></option>
                          <option value="1">1 Year</option>
                          <option value="2">2 Year</option>
                          <option value="3">3 Year</option>
                        </select>
                      </div>

                      <div className="col-md-6 regsiterrf mb-4">
                        <div>
                          <label className="form-label fs-14">
                            {translation.WORK_LOCATION}
                          </label>
                        </div>
                        <div className="swith_btn btn-group btn-toggle">
                          <button className="btn btn-lg btn-default">
                            {translation.ONSITE}
                          </button>
                          <button className="btn btn-lg btn-primary active">
                            {translation.HYBRID}
                          </button>
                          <button className="btn btn-lg btn-lobster">
                            {translation.REMOTE}
                          </button>
                        </div>
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.REQUIRED_LANGUAGE}
                        </label>
                        <select
                          className="form-select bg-transparent"
                          aria-label="Default select example"
                        >
                          <option></option>
                          <option value="1">English</option>
                          <option value="2">Hindi</option>
                          <option value="3">Marathi</option>
                        </select>
                      </div>

                      <div className="col-md-6 regsiterrf mb-4">
                        <div>
                          <label className="form-label fs-14">
                            {translation.JOB_POSTING_LANGUAGE}
                          </label>
                        </div>
                        <div className="swith_btn btn-group btn-toggle">
                          <button className="btn btn-lg btn-default">
                            English
                          </button>
                          <button className="btn btn-lg btn-primary active">
                            Swedish
                          </button>
                          <button className="btn btn-lg btn-lobster">
                            German
                          </button>
                        </div>
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.AD_DURATION}
                        </label>
                        <select
                          className="form-select bg-transparent"
                          aria-label="Default select example"
                        >
                          <option></option>
                          <option value="1">2 Days</option>
                          <option value="2">9 Days</option>
                          <option value="3">15 Days</option>
                        </select>
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="inputEmail4"
                          className="form-label fs-14"
                        >
                          {translation.MONTHLY_SALARY}
                        </label>
                        <select
                          className="form-select bg-transparent"
                          aria-label="Default select example"
                        >
                          <option></option>
                          <option value="1">$1000 - $5000</option>
                          <option value="2">$5000 - $10000</option>
                          <option value="3">$10000 - $15000</option>
                        </select>
                      </div>

                      <hr className="mt-4" />
                      <div className="card_footer_btn d-flex justify-content-between pt-0 mt-0">
                        <a
                          href="#"
                          className="fw-600 fs-18"
                          onClick={() => {
                            props.handlePrevPage();
                          }}
                        >
                          {translation.PREV}
                        </a>
                        <a
                          href="#"
                          className="fw-600 fs-18"
                          onClick={() => {
                            props.handleNextPage();
                          }}
                        >
                          {translation.NEXT}
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const PrerequisiteInfo = (props) => {
  const translation = useTranslation();
  return (
    <>
      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative" style={{ marginTop: "4em" }}>
            <div className="col-lg-4 col-md-12">
              <h2 className="fs-18 fw-600">{translation.CREATE_JOB_AD}</h2>

              <div className="create_job_tracking trackingstep">
                <div className="step step-complete">
                  <div>
                    <div className="circle">
                      <img src={tick_timeline} alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="title">{translation.COMPANY_INFO}</div>
                  </div>
                </div>
                <div className="step step-complete">
                  <div>
                    <div className="circle">
                      <img src={tick_timeline} alt="" />
                    </div>
                  </div>
                  <div>
                    <div className="title">{translation.JOBS}</div>
                  </div>
                </div>
                <div className="step step-active">
                  <div>
                    <div className="circle">3</div>
                  </div>
                  <div>
                    <div className="title">{translation.PREREQUISITES}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="card login settings_card create_job_card">
                <div className="card-body">
                  {/* First Skill */}
                  <div className="col-md-12 regsiterrf mb-3">
                    <label className="skl_block mb-3">
                      {translation.SKILLS}
                    </label>
                    <input
                      type="text"
                      placeholder={translation.ADD_SKILLS}
                      className="form-control border border-primary"
                    />
                    <div className="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                      {/* Skill 1 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">HTML</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 2 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">Javascript</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 3 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">CSS</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 4 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">Bootstrap</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Second Skill */}
                  <div className="col-md-12 regsiterrf mb-3 mt-5">
                    <label className="skl_block mb-3">
                      {translation.TOOLS}
                    </label>
                    <input
                      type="text"
                      placeholder={translation.ADD_TOOLS}
                      className="form-control border border-primary"
                    />
                    <div className="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                      {/* Skill 1 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">Adobe XD</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 2 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">Photoshop</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 3 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">illustrator</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>

                      {/* Skill 4 */}
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">Bootstrap</p>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf">
                          <select
                            className="form-select fs-14 fw-600 border-primary bg-transparent"
                            aria-label="Default select example"
                          >
                            <option>Beginner</option>
                            <option value="1">Beginner</option>
                            <option value="2">Professional</option>
                            <option value="3">Advanced</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className="mt-4" />
                  <div className="card_footer_btn d-flex justify-content-between pt-0 mt-0">
                    <a
                      href="#"
                      className="fw-600 fs-18"
                      onClick={() => {
                        props.handlePrevPage();
                      }}
                    >
                      {translation.PREV}
                    </a>
                    <a href="#" className="fw-600 fs-18">
                      {translation.NEXT}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

function CreateJob() {
  const translation = useTranslation();
  const [state, setState] = useState({
    pageno: 0,
    isProcessing: false,
    companyName: "",
    profilePic: "",
    Industry: "",
    aboutCompany: "",
  });
  const myData = [1];
  function handleNextPage(num) {
    setState({ ...state, pageno: state.pageno + 1 });
  }
  function handlePrevPage() {
    setState({ ...state, pageno: state.pageno - 1 });
  }

  function goToJobInfo(data) {
    var companyName = data.company_name;
    var profilePic = data.profile_pic;
    var Industry = data.industry;
    var aboutCompany = data.about_company;
    var userProfilePic = data.user_profile_pic;

    localStorage.setItem("profilePic", profilePic);
    localStorage.setItem("companyName", companyName);
    localStorage.setItem("Industry", Industry);
    localStorage.setItem("aboutCompany", aboutCompany);
    localStorage.setItem("userProfilePic", userProfilePic);

    setState({
      ...state,
      companyName: companyName,
      profilePic: profilePic,
      Industry: Industry,
      aboutCompany: aboutCompany,
    });
    handleNextPage();
  }
  return (
    <>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="#">{translation.PROFILE}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.CREATE_JOB_AD + " "}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <SpinnerCmp loading={state.isProcessing} />
      <div style={{ display: state.isProcessing ? "none" : "" }}>
        {state.pageno == 0 ? (
          <CompanyInfo
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            goToJobInfo={goToJobInfo}
          />
        ) : null}
        {state.pageno == 1 ? (
          <JobInfo
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
          />
        ) : null}
        {state.pageno == 2 ? (
          <PrerequisiteInfo
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
          />
        ) : null}
      </div>
    </>
  );
}

export default CreateJob;
