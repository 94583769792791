import React, { useState, useContext } from "react";
import english_lang from "../assets/img/english_lang.png";

const LanguageContext = React.createContext();
export const useLanguageContext = () => useContext(LanguageContext);

export default function LanguageContextProvider({ children }) {
  const defaultLanguage = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "IN_en";
  //
  const [language, changeLanguage] = useState(defaultLanguage);
  localStorage.setItem("language", language);
  const [languageIcon, changeLanguageIcon] = useState(english_lang);
  return (
    <LanguageContext.Provider
      value={{ language, changeLanguage, languageIcon, changeLanguageIcon }}
    >
      {children}
    </LanguageContext.Provider>
  );
}
