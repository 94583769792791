import React from 'react'
import { Link } from 'react-router-dom'
import Header from './Header'
import logoColor from "../assets/img/logo_color.png";
import TermsAndCondtion from './TermsAndCondtion';
import useTranslation from '../customHooks/translations';


export default function Terms() {
    const translation = useTranslation();
    return (
        <div>
            <section class="breadcrumbs_block">
                <div class="container">
                    <div class="row">
                        <div class="offcanvas-header">
                            <a class="navbar-brand" href="#">
                                <img src={logoColor} alt="" />
                            </a>
                            <h5 class="offcanvas-title fs -16 fw-700" id="offcanvasAddEducationLabel">{translation.TERMS_CONDITIONS}</h5>
                        <Link to="/Login"><button type="button" class="btn-close text-reset" aria-label="Close"></button></Link>
                    </div>
                    </div>
                </div>
            </section>

            <section id="faq" class="faq">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="accordion mt-5" id="accordionPanelsStayOpenExample">
                                <div class="accordion-item">

                                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                        <div class="accordion-body">
                                            <TermsAndCondtion/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </div>
    )
}
