import React, { useState, useEffect } from "react";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import { Link, useLocation } from "react-router-dom";
import Bookmark from "../../assets/img/bookmark.svg";
import profile_pic from "../../assets/img/profile_image.png";
import close_job from "../../assets/img/close_job.svg";
import check_job from "../../assets/img/check_job.svg";
import { Buffer } from "buffer";
import TinderCard from "react-tinder-card";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import { toast } from "react-toastify";
import useTranslation from "../../customHooks/translations";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";
const JobCard = (props) => {
  const translation = useTranslation();
  const [jobData, setProfileData] = useState(props.jobData);

  const onSwipe = (direction) => {
    if (direction == "right") {
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_RECRUITER_TINDERL,
        JSON.stringify({
          action: "accept",
          job_id: jobData.job_id,
          user_id: jobData.user.user.id,
        })
      )
        .then((res) => {
          toast.success(res.json.message, { autoClose: 1000 });
        })
        .catch((err) => console.log(err));
    } else if (direction == "left")
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_RECRUITER_TINDERL,
        JSON.stringify({
          action: "reject",
          job_id: jobData.job_id,
          user_id: jobData.user.user.id,
        })
      )
        .then((res) => {
          toast.success(res.json.message, { autoClose: 1000 });
        })
        .catch((err) => console.log(err));
  };
  return (
    <TinderCard className="card-body text-center" onSwipe={onSwipe}>
      <div className="jobmatch_percent">
        <p className="m-0">{jobData.percent} {translation.MATCH}</p>
      </div>

      <div
        className="job_bookmark"
        style={{ cursor: "pointer" }}
        onClick={() => {
          props.saveJob(jobData.user.id);
        }}
      >
        <img src={Bookmark} alt="" />
      </div>

      <img
        style={{ height: 100, width: 100, borderRadius: "50%" }}
        src={
          jobData.user.user.profile_pic !== null
            ? jobData.user.user.profile_pic
            : profile_pic
        }
        alt=""
      />

      <h3 className="fs-16 fw-600 mt-4">{jobData.user.user.designation}</h3>
      {/* <p className="m-0 fs-14">{jobData.job.company_name}</p> */}
      <p className="m-0 fs-14">{jobData.user.user.full_name}</p>
      <p className="m-0 fs-14">
        {jobData.user.user.city + "," + jobData.user.user.country}
        {/* {jobData.job.work_location.map((item) => {
            return <> {item}</>;
          })} */}
      </p>
      <p className="m-0 fs-14">
        {/* {jobData.job.job_type} */}
        {jobData.user.user.user_experience_level}
        {/* {jobData.job.required_experience.map((item) => {
            return <> {item.level}</>;
          })} */}
      </p>

      {/* <p className="fs-14 text-truncate">{jobData.job.about_company}</p> */}

      <hr />

      <div className="row">
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.SKILLS}</h4>
          <p className="fs-16 ls-4 m-0">
            {jobData.user.skill &&
              jobData.user.skill.length &&
              jobData.user.skill.map((item, index) => {
                return <> {item.skill}</>;
              })}
          </p>
        </div>
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.TOOLS}</h4>
          <p className="fs-16 ls-4 m-0">
            {jobData.user.tools &&
              jobData.user.tools.length &&
              jobData.user.tools.map((item, index) => {
                return <> {item.tool}</>;
              })}
          </p>
        </div>
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.CERTIFICATES}</h4>
          <p className="fs-16 ls-4 m-0 text-truncate ">
            {jobData.user.certificate &&
              jobData.user.certificate.length &&
              jobData.user.certificate.map((item, index) => {
                return <> {item.job_certificates}</>;
              })}
          </p>
        </div>
      </div>

    </TinderCard>
  );
};
function RecruiterExploreJobs() {
  const filterData = useLocation().state;
  const translation = useTranslation();
  const [jobRoleState, setJobRoleState] = useState({
    name: "",
    id: 0,
  });
  const [jobsData, setJobsData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [state, setState] = useState({
    isProcessing: false,
  });


  function getPropsData() {
    const currentURL = window.location.href;
    const urlArray =
      currentURL != "" ? currentURL.split("?designation=") : [];
    const designation = urlArray[1];

    getWithAuthCallWithErrorResponse(
      ApiConfig.PROFILE_LISTING_BY_JOB_TITLE_API + designation
    )
      .then((res) => {
        setJobsData(res.json.list);
      })
      .catch((err) => console.log(err));
  }


  const saveJob = (jobId) => {
    let requestBody = JSON.stringify({
      user_id: jobId,
    });
    postWithAuthCallWithErrorResponse(
      ApiConfig.FAVOURITE_JOB_API_RECRUITER,
      requestBody
    ).then((data) => {
      if (data.error !== true) {
        toast.success(translation.JOB_NOT_SAVED_SUCCESSFULLY, { autoClose: 1000 });
        // if (count < jobList.length - 1) {
        //   setCount(count + 1);
        // }
      } else {
        alert(data.json.message);
      }
    });
  };
  useEffect(() => {
    const currentURL = window.location.href;
    const urlArray =
      currentURL != "" ? currentURL.split("?designation=") : [];
    const designation = urlArray[1];
    if (filterData) {
      filterData.competency = "";
      filterData.industry = designation
      let uri;
      if (filterData.isFrom === "search") {
        uri = ApiConfig.PROFILE_SEARCH_API
      } else {
        uri = ApiConfig.PROFILE_FILTER;
      }
      let requestBody = JSON.stringify(filterData);

      setState({ ...state, isProcessing: true });
      postWithAuthCallWithErrorResponse(uri, requestBody).then((data) => {
        setState({ ...state, isProcessing: false });
        if (data.error !== true) {
          setJobsData(data.json.list);
        } else {
          alert(data.json.message);
        }
      });
    }
  }, [filterData]);
  useEffect(() => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.GET_JOB_SEEKER_PROFILE).then(
      (data) => {
        setState({ ...state, isProcessing: false });
        if (data.error !== true) {
          setUserData(data.json);
        } else {
          alert(data.json.message);
        }
      }
    );
    getPropsData();
  }, []);

  const onSwipe = (direction, profileData) => {
    let profileListFiltered = jobsData.filter(
      (profile) => profile.user.id !== profileData.user.id
    );
    setJobsData(profileListFiltered);
    if (direction == "right") {
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_RECRUITER_TINDERL,
        JSON.stringify({
          action: "accept",
          job_id: profileData.job_id,
          user_id: profileData.user.id,
        })
      )
        .then((res) => {
          let profileListFiltered = jobsData.filter(
            (profile) => profile.user.id !== profileData.user.id
          );
          setJobsData(profileListFiltered);
          toast.success(res.json.message, { autoClose: 1000 });
        })
        .catch((err) => console.log(err));
    } else if (direction == "left")
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_SEEKER_TINDERL,
        JSON.stringify({
          action: "reject",
          job_id: profileData.job_id,
          user_id: profileData.user.id,
        })
      )
        .then((res) => {
          let profileListFiltered = jobsData.filter(
            (profile) => profile.user.id !== profileData.user.id
          );
          toast.success(res.json.message, { autoClose: 1000 });
          setJobsData(profileListFiltered);
        })
        .catch((err) => console.log(err));
  };
  return (
    <>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/recruiter-explore">{translation.EXPLORE}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {jobRoleState.name}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {jobsData && jobsData.length ? (
            jobsData.map((job, index) => {
              return (
                <div class="job_card overlap_card card">
                  <JobCard
                    jobsData={jobsData}
                    id={userData.id}
                    jobData={job}
                    index={index}
                    saveJob={saveJob}
                  />
                </div>
              );
            })
          ) : (
            state.isProcessing ? <SpinnerCmp loading={state.isProcessing} /> :
              <div className="row">
                <div className="col-lg-8 col-md-12 offset-lg-2">
                  <div className="card-body text-center no-record">
                    <p className="m-0 fs-50 no-record-style">
                      {" "}
                      {translation.NO_RECORDS_FOUND}
                    </p>
                  </div>
                </div>
              </div>
          )}
          {userData.actively_hiring && jobsData && jobsData.length > 0 && (
            <div className="job_actions Card-button" style={{ zIndex: "1000" }}>
              <button

                href=""
                type="button"
                // disabled={
                // count < profileList.length - 1 ? false : true
                // }
                className="btn "
                data-tip={translation.REJECT}
                onClick={() => {
                  onSwipe("left", jobsData[jobsData.length - 1]);
                }}
                style={{ marginRight: "10rem" }}
              >
                <img src={close_job} alt="" />
              </button>

              <button
                href=""
                type="button"
                className="btn "
                data-tip={translation.ACCEPT}
                // data-bs-toggle="modal"
                // data-bs-target="#HomeUpgrade"
                onClick={() =>
                  onSwipe("right", jobsData[jobsData.length - 1])
                }
              >
                <img src={check_job} alt="" />
              </button>
            </div>
          )}
          {/*
                            
                            <div className="job_card card">
                                <div className="card-body text-center">
                                    <div className="jobmatch_percent">
                                        <p className="m-0">90% Match</p>
                                    </div>

                                    <div className="job_bookmark">
                                        <a href="#"><img src={bookmark} alt="" /></a>
                                    </div>

                                    <img src={job_logo} alt="" />

                                    <h3 className="fs-16 fw-600 mt-4">Android Developer</h3>
                                    <p className="m-0 fs-14">Redbytes</p>
                                    <p className="m-0 fs-14 pb-4">Full Time . 3+ Yrs Experience. Onsite</p>

                                    <p className="fs-14">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat…</p>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-4">
                                            <h4 className="fs-14 fw-700 ls-3">SKILLS</h4>
                                            <p className="fs-16 ls-4 m-0">Communication, Leadership, Team Work</p>
                                        </div>
                                        <div className="col-md-4">
                                            <h4 className="fs-14 fw-700 ls-3">TOOLS</h4>
                                            <p className="fs-16 ls-4 m-0">Github, Jenkins, JIRA</p>
                                        </div>
                                        <div className="col-md-4">
                                            <h4 className="fs-14 fw-700 ls-3">CERTIFICATES</h4>
                                            <p className="fs-16 ls-4 m-0">IREB, ISQEB</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            
                            <div className="job_card overlap_card card" style={{ top: "131px" }}>
                                <div className="card-body text-center">
                                    <div className="jobmatch_percent">
                                        <p className="m-0">90% Match</p>
                                    </div>

                                    <div className="job_bookmark">
                                        <a href="#"><img src={bookmark} alt="" /></a>
                                    </div>

                                    <img src={job_logo} alt="" />

                                    <h3 className="fs-16 fw-600 mt-4">Android Developer</h3>
                                    <p className="m-0 fs-14">Redbytes</p>
                                    <p className="m-0 fs-14 pb-4">Full Time . 3+ Yrs Experience. Onsite</p>

                                    <p className="fs-14">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat…</p>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-4">
                                            <h4 className="fs-14 fw-700 ls-3">SKILLS</h4>
                                            <p className="fs-16 ls-4 m-0">Communication, Leadership, Team Work</p>
                                        </div>
                                        <div className="col-md-4">
                                            <h4 className="fs-14 fw-700 ls-3">TOOLS</h4>
                                            <p className="fs-16 ls-4 m-0">Github, Jenkins, JIRA</p>
                                        </div>
                                        <div className="col-md-4">
                                            <h4 className="fs-14 fw-700 ls-3">CERTIFICATES</h4>
                                            <p className="fs-16 ls-4 m-0">IREB, ISQEB</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                           
                            <div className="job_card overlap_card third_card card" style={{ top: "147px" }}>
                                <div className="card-body text-center">
                                    <div className="jobmatch_percent">
                                        <p className="m-0">90% Match</p>
                                    </div>

                                    <div className="job_bookmark">
                                        <a href="#"><img src={bookmark} alt="" /></a>
                                    </div>

                                    <img src={job_logo} alt="" />

                                    <h3 className="fs-16 fw-600 mt-4">Android Developer</h3>
                                    <p className="m-0 fs-14">Redbytes</p>
                                    <p className="m-0 fs-14 pb-4">Full Time . 3+ Yrs Experience. Onsite</p>

                                    <p className="fs-14">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat…</p>

                                    <hr />

                                    <div className="row">
                                        <div className="col-md-4">
                                            <h4 className="fs-14 fw-700 ls-3">SKILLS</h4>
                                            <p className="fs-16 ls-4 m-0">Communication, Leadership, Team Work</p>
                                        </div>
                                        <div className="col-md-4">
                                            <h4 className="fs-14 fw-700 ls-3">TOOLS</h4>
                                            <p className="fs-16 ls-4 m-0">Github, Jenkins, JIRA</p>
                                        </div>
                                        <div className="col-md-4">
                                            <h4 className="fs-14 fw-700 ls-3">CERTIFICATES</h4>
                                            <p className="fs-16 ls-4 m-0">IREB, ISQEB</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="loginwithlinks job_actions text-center">
                                <button type="button" className="btn btn-outline-primary rounded-circle"><img src={close_job} alt="" /></button>
                                <Link to="/recruiter-job-details"> <button type="button" className="btn btn-outline-primary rounded-circle"><img src={check_job} alt="" /></button> </Link>
                            </div>
*/}
        </div>
      </section>
    </>
  );
}

export default RecruiterExploreJobs;
