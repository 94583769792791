import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Header from "../../../sharedComponents/Header";
import chevron_right from "../../../assets/img/chevron_right_colored.svg";
import camera from "../../../assets/img/camera.svg";
import profile_pic from "../../../assets/img/profile_pic.svg";
import ApiConfig from "../../../api/ApiConfig";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../../api/ApiServices.js";
import { Multiselect } from "multiselect-react-dropdown";
import useTranslation from "../../../customHooks/translations";
import { UserContext } from "../../../contexts/userContext";

function Edit_Advanced_Skills() {
  const translation = useTranslation();
  const { UserData, getUserData } = useContext(UserContext)
  const [state, setState] = useState({
    profile_pic: "",
    profile_pic_name: "",
    isProcessing: false,
    looking_for_job: false,
    new_skill: "",
    new_skills_array: [],
    new_tool: "",
    new_tools_array: [],
    new_certificate: "",
    new_certificates_array: [],
    designation: UserData.user && UserData.user.designation ? UserData.user.designation : "",
    selectedCertificates: UserData.certificate && UserData.certificate ? UserData.certificate : [],
    selectedToolsWithLevel: UserData.tools && UserData.tools ? UserData.tools : [],
    selectedSkillsWithLevel: UserData.skill && UserData.skill ? UserData.skill : [],
    user_experience_level: UserData.user && UserData.user.user_experience_level ? UserData.user.user_experience_level : "",
    driving_license_available: UserData.user && UserData.user.driving_license_available ? UserData.user.driving_license_available : "no",
    level: "",
  });
  const [skillsState, setSkillsState] = useState();
  const [roleList, setRoleList] = useState([]);
  const [toolsState, setToolsState] = useState();
  const [workplace, setWorkplace] = useState([]);
  const [certificatesState, setCertificatesState] = useState();
  const [experianceList, setExperianceList] = useState([]);
  const options = [
    { placelist: "Hybrid" },
    { placelist: "Static" },
    { placelist: "Fixed" },
    { placelist: "Flexible" },
  ];
  // ****TOOLS****
  const getToolsList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.TOOLS_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseToolsList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseToolsList = (data) => {
    let temp_tools = [];
    data.map((item, index) => {
      temp_tools.push({ name: item.name, id: item.id });
    });

    setToolsState(temp_tools);
  };

  function onToolChange(selectedList, selectedItem) {
    let temp_tool = [];
    let temp_selectedToolsWithLevel = [];

    selectedList.map((item, index) => {
      temp_tool.push(item.name);
      temp_selectedToolsWithLevel.push({ tool: item.name, level: "Beginner" });
    });

    setState({
      ...state,
      selectedTools: selectedList,
      selectedToolsWithLevel: temp_selectedToolsWithLevel,
    });
  }

  function onChangeToolLevel(tool, level) {
    let temp_selectedToolsWithLevel = [];
    state.selectedToolsWithLevel.map((item, index) => {
      temp_selectedToolsWithLevel.push({
        tool: item.tool,
        level: item.tool == tool ? level : item.level,
      });
    });
    setState({ ...state, selectedToolsWithLevel: temp_selectedToolsWithLevel });
  }
  // *** TOOLS ***

  /* skills section starts */
  const getSkillsList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.SKILLS_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseSkillsList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseSkillsList = (data) => {
    let temp_skills = [];
    data.map((item, index) => {
      temp_skills.push({ name: item.name, id: item.id });
    });

    setSkillsState(temp_skills);
  };

  function onSkillChange(selectedList, selectedItem) {
    let temp_skill = [];
    let temp_selectedSkillsWithLevel = [];

    selectedList.map((item, index) => {
      temp_skill.push(item.name);
      temp_selectedSkillsWithLevel.push({
        skill: item.name,
        level: "Beginner",
      });
    });

    setState({
      ...state,
      selectedSkills: selectedList,
      selectedSkillsWithLevel: temp_selectedSkillsWithLevel,
    });
  }

  function onChangeSkillLevel(skill, level) {
    let temp_selectedSkillsWithLevel = [];
    state.selectedSkillsWithLevel.map((item, index) => {
      temp_selectedSkillsWithLevel.push({
        skill: item.skill,
        level: item.skill == skill ? level : item.level,
      });
    });
    setState({
      ...state,
      selectedSkillsWithLevel: temp_selectedSkillsWithLevel,
    });
  }
  function addNewSkill(value) {
    let temp_selectedSkillsWithLevel = skillsState;
    temp_selectedSkillsWithLevel.push({ name: value, id: value });
    setSkillsState(temp_selectedSkillsWithLevel);
  }
  /* skills section ends */
  /* certificates section starts */
  const getCertificatesList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.CERTIFICATES_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseCertificatesList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseCertificatesList = (data) => {
    let temp_tools = [];
    data.map((item, index) => {
      temp_tools.push({ name: item.name, id: item.id });
    });

    setCertificatesState(temp_tools);
  };

  function onCertificateChange(selectedList, selectedItem) {
    let temp_selectedCertificates = [];
    selectedList.map((item, index) => {
      temp_selectedCertificates.push({ name: item.name });
    });

    setState({
      ...state,
      selectedCertificates: selectedList,
      certificates: temp_selectedCertificates,
    });
  }

  const savePrerequesites = async () => {
    let certificates = state.selectedCertificates && state.selectedCertificates.length &&
      state.selectedCertificates.map((cer) => {
        return { user_certificates: cer.name || cer.user_certificates };
      });

    let data = {
      skills: state.selectedSkillsWithLevel,
      tools: state.selectedToolsWithLevel,
      certificates: certificates,
      user_experience_level: state.user_experience_level,
      designation: state.designation,
      driving_license_available: state.driving_license_available,
    };

    postWithAuthCallWithErrorResponse(
      ApiConfig.ADD_PREREUESITE_JOB_SEEKER,
      JSON.stringify(data)
    )
      .then((res) => {
        if (!res.error) {
          getUserData()
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleKeyDown_Certificates = (e) => {
    if (e.key === "Enter") {
      var new_certificates_array = state.new_certificates_array;
      if (state.new_certificate == "" || state.new_certificate == null) {
        // show error message
      } else {
        new_certificates_array.push(state.new_certificate);
      }
      setState({
        ...state,
        new_certificates_array: new_certificates_array,
        new_certificate: "",
      });
    }
  };

  function removeCertificate(certificateToRemove) {
    let filteredCertificateArray = state.new_certificates_array.filter(
      (item) => item !== certificateToRemove
    );
    setState({ ...state, new_certificates_array: filteredCertificateArray });
  }

  /* certificates section ends */

  // experiance list
  const getExperienceList = () => {
    setExperianceList({ ...experianceList, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.EXPERIENCE_LISTING_API)
      .then((data) => {
        if (data.error == true) {
          setExperianceList({ ...experianceList, isProcessing: false });
        } else {
          setExperianceList(data.json);
        }
      })
      .catch((error) => {
        setExperianceList({ ...experianceList, isProcessing: false });
      });
  };
  // end experiance list
  //Current role List
  const getRoleList = () => {
    setRoleList({ ...roleList, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.JOB_ROLE_LIST)
      .then((data) => {
        if (data.error == true) {
          setRoleList({ ...roleList, isProcessing: false });
        } else {
          setRoleList(data.json);
        }
      })
      .catch((error) => {
        setRoleList({ ...roleList, isProcessing: false });
      });
  };
  //end cuurent role list
  useEffect(() => {
    getToolsList();
    getSkillsList();
    getCertificatesList();
    getExperienceList();
    getRoleList();
  }, []);
  return (
    <div>
      <Header />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/Seeker_Profile">{translation.PROFILE}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.ADVANCED_SKILLS}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div
                className="card login settings_card"
                style={{ marginTop: "1.5em" }}
              >
                <div className="card-body">
                  <div className="form_block w-100 pt-0">
                    <form className="row g-3 text-start">
                      {/* <!-- First Skill --> */}
                      <div class="col-md-12 regsiterrf ">
                        <label for="" class="form-label fs-14">
                          {translation.SKILLS}
                        </label>
                        <Multiselect
                          className="form-select bg-transparent"
                          aria-label="Default select example"
                          avoidHighlightFirstOption={true}
                          onSearch={addNewSkill}
                          showCheckbox={true} // To show checkbox in the dropdown
                          options={skillsState} // Options to display in the dropdown
                          selectedValues={state.selectedSkillsWithLevel.map((skl, index) => { return { name: skl.skill, id: skl.id } })} // Preselected value to persist in dropdown
                          displayValue="name" // Property name to display in the dropdown options
                          onSelect={onSkillChange}
                          onRemove={onSkillChange}
                        />
                        <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3">
                          {/* <!-- Skill 1 --> */}

                          {state.selectedSkillsWithLevel.map(
                            (selected_skill, index) => {
                              return (
                                <div className="skill_col me-3" key={index}>
                                  <div className="d-flex justify-content-between">
                                    <p className="m-0 fs-14">
                                      {selected_skill.skill}
                                    </p>
                                    {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                                  </div>
                                  <div className="col-md-12 regsiterrf">
                                    <select
                                      className="form-select fs-14 fw-600 border-primary bg-transparent"
                                      value={selected_skill.level}
                                      onChange={(e) =>
                                        onChangeSkillLevel(
                                          selected_skill.skill,
                                          e.target.value
                                        )
                                      }
                                      aria-label="Default select example"
                                    >
                                      <option value="Beginner">{translation.BEGINNER}</option>
                                      <option value="Intermediate">
                                        {translation.INTERMEDIATE}
                                      </option>
                                      <option value="Competent">{translation.COMPETENT}</option>
                                      <option value="Expert">{translation.EXPERT}</option>
                                    </select>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>

                      {/* <!-- Second Skill --> */}
                      <div class="col-md-12 regsiterrf ">
                        <label for="" class="form-label fs-14">
                          {translation.TOOLS}
                        </label>
                        <Multiselect
                          className="form-select bg-transparent"
                          aria-label="Default select example"
                          avoidHighlightFirstOption={true}
                          showCheckbox={true} // To show checkbox in the dropdown
                          options={toolsState} // Options to display in the dropdown
                          selectedValues={state.selectedToolsWithLevel.map(tool => { return { name: tool.tool, id: tool.id } })} // Preselected value to persist in dropdown
                          displayValue="name" // Property name to display in the dropdown options
                          onSelect={onToolChange}
                          onRemove={onToolChange}
                        />
                        <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3">
                          {/* <!-- Skill 1 --> */}
                          {state.selectedToolsWithLevel.map(
                            (selected_tool, index) => {
                              return (
                                <div className="skill_col me-3" key={index}>
                                  <div className="d-flex justify-content-between">
                                    <p className="m-0 fs-14">
                                      {selected_tool.tool}
                                    </p>
                                    {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                                  </div>

                                  <div className="col-md-12 regsiterrf">
                                    <select
                                      className="form-select fs-14 fw-600 border-primary bg-transparent"
                                      value={selected_tool.level}
                                      onChange={(e) =>
                                        onChangeToolLevel(
                                          selected_tool.tool,
                                          e.target.value
                                        )
                                      }
                                      aria-label="Default select example"
                                    >
                                      <option value="Beginner">{translation.BEGINNER}</option>
                                      <option value="Intermediate">
                                        {translation.INTERMEDIATE}
                                      </option>
                                      <option value="Competent">{translation.COMPETENT}</option>
                                      <option value="Expert">{translation.EXPERT}</option>
                                    </select>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>

                      {/* <!-- Third Skill --> */}
                      <div class="col-md-12 regsiterrf ">
                        <label for="" class="form-label fs-14">
                          {translation.CERTIFICATES}
                        </label>
                        <Multiselect
                          className="form-select bg-transparent"
                          aria-label="Default select example"
                          avoidHighlightFirstOption={true}
                          showCheckbox={true} // To show checkbox in the dropdown
                          options={certificatesState} // Options to display in the dropdown
                          selectedValues={state.selectedCertificates.map(cer => {
                            return { name: cer.name || cer.user_certificates }
                          })} // Preselected value to persist in dropdown
                          displayValue="name" // Property name to display in the dropdown options
                          onSelect={onCertificateChange}
                          onRemove={onCertificateChange}
                        />

                        <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3">
                          {/* <!-- Skill 1 --> */}

                          {state.new_certificates_array.map(
                            (new_certificates_data, index) => {
                              return (
                                <div className="skill_col me-3">
                                  <div className="d-flex justify-content-between">
                                    <p className="m-0 fs-14">
                                      {new_certificates_data}
                                    </p>
                                    &nbsp;&nbsp;&nbsp;
                                    <button
                                      type="button"
                                      className="btn-close"
                                      aria-label="Close"
                                      onClick={() =>
                                        removeCertificate(new_certificates_data)
                                      }
                                    ></button>
                                  </div>
                                  <div className="col-md-12 regsiterrf"></div>
                                </div>
                              );
                            }
                          )}
                        </div>
                        <div className="col-md-12 regsiterrf mb-3">
                          <label
                            htmlFor="inputEmail4"
                            className="form-label fs-14"
                          >
                            {translation.DO_YOU_HAVE_DRIVING_LICENSE}
                          </label>
                          <select
                            className="form-control border border-primary"
                            aria-label="Default select example"
                            value={state.driving_license_available}
                            onChange={(e) => { setState({ ...state, driving_license_available: e.target.value }) }}
                          >
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>

                        <div class="col-md-12 regsiterrfv mb-3">
                          <label for="inputEmail4" class="form-label fs-14">
                            {translation.CURRENT_EXPERIANCE_LEVEL}
                          </label>
                          <select
                            class="form-select border-primary bg-transparent"
                            aria-label="Default select example"
                            value={state.user_experience_level}
                            onChange={(e) => { setState({ ...state, user_experience_level: e.target.value }) }}
                          >
                            <option >Select Exprerience Level</option>
                            {experianceList.length > 0
                              ? experianceList.map((list) => (
                                <option>{list.level}</option>
                              ))
                              : ""}
                          </select>
                        </div>
                        <div class="col-md-12 regsiterrf">
                          <label for="inputEmail4" class="form-label fs-14">
                            {translation.CURRENT_ROLE}
                          </label>
                          <select
                            class="form-select border-primary bg-transparent"
                            aria-label="Default select example"
                            value={state.designation}
                            onChange={(e) => { setState({ ...state, designation: e.target.value }) }}
                          >
                            <option >Select Role</option>
                            {roleList.length > 0
                              ? roleList.map((list) => (
                                <option value={list.name} >{list.name}</option>
                              ))
                              : ""}
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="loginwithlinks editprofile_block">
                <div className="d-lg-flex d-md-flex justify-content-end">
                  <div className="loginwithbtn">
                    <p className="fs-22 fw-600 text-primary">
                      {translation.UPDATE}{" "}
                      <Link to="/Seeker_Profile">
                        <button
                          type="button"
                          className="btn btn-outline-primary rounded-circle ms-2"
                          onClick={savePrerequesites}
                        >
                          <img src={chevron_right} alt="" />
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Edit_Advanced_Skills;
