import React, { useState, useEffect } from "react";
import logoColor from "../assets/img/logo_color.png";
import notification from "../assets/img/notification.svg";
import search from "../assets/img/search.svg";
import sweden from "../assets/img/sweden.png";
import check_job from "../assets/img/check_job.svg";
import menu from "../assets/img/menu.svg";
import swedish_lang from "../assets/img/swedish_lang.png";
import english_lang from "../assets/img/english_lang.png";
import Hindi from "../assets/img/hindi.png";
import Marathi from "../assets/img/ic_marathi.svg";
import german_lang from "../assets/img/german_lang.png";
import tutorial from "../assets/img/tutorial.svg";
import right_arrow from "../assets/img/right_arrow.svg";
import change_language from "../assets/img/change_language.svg";
import contact_us from "../assets/img/contact_us.svg";
import settings from "../assets/img/settings.svg";
import legal from "../assets/img/legal.svg";
import s_logout from "../assets/img/s_logout.svg";
import faq from "../assets/img/faq.svg";
import filter from "../assets/img/filter.svg";
import thumbs_up from "../assets/img/thumbs_up.svg";
import manage_subscription from "../assets/img/manage_subscription.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ApiConfig from "../api/ApiConfig";
import { getWithAuthCallWithErrorResponse } from "../api/ApiServices.js";
import Country from "../assets/CountrtiesData/Country";
import listContryCity from "../assets/CountrtiesData/CoutrycityList";
import Select from "react-select";
import { Multiselect } from "multiselect-react-dropdown";
import Filter_JobseekerHome from "./Filter_JobseekerHome";
import SelectLanguage from "./SelectLanguage";
import { useLanguageContext } from "../contexts/LanguageContext";
import useTranslation from "../customHooks/translations";
import NotificationComponent from "../pages/Job_Seeker/NotificationComponent";

function Header() {
  const navigate = useNavigate()
  const [state, setState] = useState([]);
  const { languageIcon } = useLanguageContext();
  const translation = useTranslation();
  const location = useLocation();
  const [CurrentRoute, setCurrentRoute] = useState(location.pathname);
  const [tool, setTool] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const options = [
    { id: 1, value: "Hybrid", label: "Hybrid" },
    { id: 2, value: "Static", label: "Static" },
    { id: 3, value: "Fixed", label: "Fixed" },
    { id: 4, value: "Flexible", label: "Flexible" },
  ];
  const [skillsState, setSkillsState] = useState();
  const [toolsState, setToolsState] = useState();
  const [workplace, setWorkplace] = useState(options);
  const [countriesState, setCountriesState] = useState();
  const [citiesState, setCitiesState] = useState();
  const [searchText, setSearchText] = useState();

  // ****TOOLS****
  const getToolsList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.TOOLS_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseToolsList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseToolsList = (data) => {
    let temp_tools = [];
    data.map((item, index) => {
      temp_tools.push({ name: item.name, id: item.id });
    });

    setToolsState(temp_tools);
  };

  function onToolChange(selectedList, selectedItem) {
    let temp_tool = [];
    let temp_selectedToolsWithLevel = [];

    selectedList.map((item, index) => {
      temp_tool.push(item.name);
      temp_selectedToolsWithLevel.push({ tool: item.name, level: "Beginner" });
    });

    setState({
      ...state,
      selectedTools: selectedList,
      selectedToolsWithLevel: temp_selectedToolsWithLevel,
    });
  }
  function onChangeToolLevel(tool, level) {
    let temp_selectedToolsWithLevel = [];
    state.selectedToolsWithLevel.map((item, index) => {
      temp_selectedToolsWithLevel.push({
        tool: item.tool,
        level: item.tool == tool ? level : item.level,
      });
    });
    setState({ ...state, selectedToolsWithLevel: temp_selectedToolsWithLevel });
  }
  // *** TOOLS ***

  //SEARCH
  const searchProfile = (e) => {
    // console.log(e.target.value
  };
  // job title list
  const jobtitle = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.JOB_ROLE_LIST)
      .then((data) => {
        setJobTitle(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //  skills list
  const getskills = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.SKILLS_LISTING_API)
      .then((data) => {
        setState(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // tools
  const gettools = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.TOOLS_LISTING_API)
      .then((data) => {
        setTool(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getCitiesList(selected_country) {
    Object.keys(listContryCity.listContryCity).forEach(function (key, index) {
      var newJson = listContryCity.listContryCity[key];
      if (newJson.country == selected_country) {
        updateCity(newJson.city);
      }
    });
  }

  function updateCity(data) {
    let temp_cities = [];
    data.map((item, index) => {
      temp_cities.push({ label: item, value: item });
    });
    setCitiesState(temp_cities);
  }

  // function onCityChange(value) {
  //   setState({ ...state, selected_City: value, city: value.label });
  //
  /* city section ends */

  /* country section starts */
  const selectStyles = {
    control: (provided) => ({ ...provided, backgroundColor: 'transparent' }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)", backgroundColor: 'transparent' }),
  };

  function getCountriesList() {
    setCountriesState(Country.Country);
  }
  function onCityChange(value) {
    setState({ ...state, selected_City: value, city: value.label });
  }
  function onCountryChange(value) {
    setState({ ...state, selected_Country: value, country: value.label });
    getCitiesList(value.label);
  }
  /* country section ends */

  const checkLogin = async () => {
    if (localStorage.getItem("is_logged_in") == "true") {
      if (localStorage.getItem("USER_ROLE") != "Job_seeker") {
        Logout();
      }
    } else {
      Logout();
    }
  };
  const onLangChange = (value) => {
    let selectedLangIcon = english_lang;
    switch (value) {
      case "English":
        selectedLangIcon = english_lang;
        break;
      case "Hindi":
        selectedLangIcon = Hindi;
        break;
      case "Marathi":
        selectedLangIcon = Hindi;
        break;
    }
    setState({ ...state, language: value, selectedLangIcon: selectedLangIcon });
  };
  const Logout = async () => {
    const lang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "IN_en";
    localStorage.clear();
    localStorage.setItem("language", lang);
    navigate("/login")
    // window.location.href = "/login";
  };
  useEffect(() => {
    setCurrentRoute(location.pathname);
    getCountriesList();
    checkLogin();
    getskills();
    gettools();
    jobtitle();
  }, []);
  return (
    <>
      <ReactTooltip />
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container">
          <button
            class="navbar-toggler"
            style={{ width: "5rem" }}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
            <a class="navbar-brand" href="#">
              <img src={logoColor} alt="" />
            </a>
            <ul class="lfmenu navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <Link to="/Seeker_Home" class="nav-link" href="#">
                  {translation.HOME}
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/Explore" class="nav-link" href="#">
                  {translation.EXPLORE}
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/Seeker_Profile" class="nav-link" href="#">
                  {translation.PROFILE}
                </Link>
              </li>
              <li class="nav-item">
                <Link to="/Match_Job" class="nav-link" href="#">
                  {translation.MATCHING_JOBS}
                </Link>
              </li>
              {/* <li class="nav-item">
                <Link to="/Chat" class="nav-link" href="#">
                  Chat
                </Link>
              </li> */}
            </ul>
            <ul class="rlmenu navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasNotification"
                  aria-controls="offcanvasNotification"
                >
                  <img src={notification} alt="" />
                </a>
              </li>
              {(CurrentRoute === "/Seeker_Home" || CurrentRoute == "/Explore_Jobs")
                &&
                (<>
                  <li class="nav-item">
                    <a
                      class="nav-link search_modal"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#SearchModal"
                      data-bs-backdrop="false"
                    >
                      <img src={search} alt="" />
                    </a>
                  </li>



                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-bs-toggle="offcanvas"
                      href="#offcanvasSearchFilter"
                      aria-controls="offcanvasSearchFilter"
                    >
                      <img src={filter} alt="" />
                    </a>
                  </li>

                </>)}

              <li class="nav-item lang_dropdown">
                <div class="btn-group">
                  <a
                    class="dropdown-toggle nav-link lang_flag"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    href="#"
                  >
                    <img
                      src={languageIcon}
                      alt=""
                      data-tip={translation.SELECT_LANGUAGE}
                    />
                  </a>
                  <SelectLanguage />
                </div>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="offcanvas"
                  href="#offcanvasSidebarMenu"
                  aria-controls="offcanvasSidebarMenu"
                >
                  <img src={menu} alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Menu Modal */}
      {/* <!-- Mobile Menu Actions --> */}
      <ul class="rlmenu rlmenu_mobile navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a
            class="nav-link"
            data-bs-toggle="offcanvas"
            href="#offcanvasNotification"
            aria-controls="offcanvasNotification"
          >
            <img src={notification} alt="" />
          </a>
        </li>
        {CurrentRoute == ("/Explore_Jobs" || "/Seeker_Home") && (
          <li class="nav-item">
            <a
              class="nav-link"
              data-bs-toggle="offcanvas"
              href="#offcanvasSearchFilter"
              aria-controls="offcanvasSearchFilter"
            >
              <img src={filter} alt="" />
            </a>
          </li>
        )}
        <li class="nav-item">
          <a
            class="nav-link search_modal"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#SearchModal"
            data-bs-backdrop="false"
          >
            <img src={search} alt="search" />
          </a>
        </li>
        <li class="nav-item lang_dropdown">
          <div class="btn-group">
            <a
              class="dropdown-toggle nav-link lang_flag"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              href="#"
            >
              <img
                src={languageIcon}
                alt=""
                data-tip={translation.SELECT_LANGUAGE}
              />
            </a>
            <SelectLanguage />
          </div>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            data-bs-toggle="offcanvas"
            href="#offcanvasSidebarMenu"
            aria-controls="offcanvasSidebarMenu"
          >
            <img src={menu} alt="" />
          </a>
        </li>
      </ul>

      <div
        class="SidebarMenuCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasSidebarMenu"
        aria-labelledby="offcanvasSidebarMenuLabel"
      >
        <div class="offcanvas-header">
          <h5
            class="offcanvas-title fs-16 fw-600"
            id="offcanvasSidebarMenuLabel"
          >
            {translation.MENU}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div class="d-flex justify-content-between">
            <Link to="/tutorial" className="menu_title">
              <img src={tutorial} alt="" />
              {translation.TUTORIAL}
            </Link>
            <Link to="/tutorial">
              <img src={right_arrow} alt="" />
            </Link>
          </div>
          {/* <hr class="my-4" /> */}
          {/* <div class="d-flex justify-content-between">
            <Link to="/Subscription" class="menu_title">
              <img src={manage_subscription} alt="" />
              Subscription Plans
            </Link>
            <a href="#">
              <img src={right_arrow} alt="" />
            </a>
          </div> */}
          {/* <hr class="my-4" /> */}
          {/* <div class="d-flex justify-content-between">
            <a href="#" class="menu_title">
              <img src={change_language} alt="" />
              Change Language
            </a>
            <a href="#">
              <img src={right_arrow} alt="" />
            </a>
          </div> */}
          {/* <div class="d-flex justify-content-between">
            <Link to="/Faq" class="menu_title">
              <img src={faq} alt="" />
              {translation.FAQ}
            </Link>
            <a href="#">
              <img src={right_arrow} alt="" />
            </a>
          </div>
          <hr class="my-4" /> */}
          {/* <div class="d-flex justify-content-between">
            <Link to="/Feedback" class="menu_title">
              <img src={thumbs_up} alt="" />
              Feedback & Suggetion
            </Link>
            <a href="#">
              <img src={right_arrow} alt="" />
            </a>
          </div> */}

          <hr class="my-4" />
          <div class="d-flex justify-content-between">
            <Link to="/Setting" class="menu_title">
              <img src={settings} alt="" />
              {translation.SETTINGS}
            </Link>
            <Link to="/Setting">
              <img src={right_arrow} alt="" />
            </Link>
          </div>
          <hr class="my-4" />
          <div class="d-flex justify-content-between">
            <Link to="/Legal" class="menu_title">
              <img src={legal} alt="" />
              {translation.LEGAL}
            </Link>
            <Link to="/Legal">
              <img src={right_arrow} alt="" />
            </Link>
          </div>
          <hr class="my-4" />
          <div class="d-flex justify-content-between">
            <Link to="/Contact_Us" class="menu_title">
              <img src={contact_us} alt="" />
              {translation.CONTACT_US}
            </Link>
            <Link to="/Contact_Us">
              <img src={right_arrow} alt="" />
            </Link>
          </div>
          <hr class="my-4" />
          <div class="d-flex justify-content-between">
            <Link to="/login" class="menu_title" onClick={() => Logout()} >
              <img src={s_logout} alt="" />
              {translation.LOGOUT}
            </Link>
            <Link to="/login">
              <img src={right_arrow} alt="" />
            </Link>
          </div>
        </div>
      </div>
      {/* Menu modal */}

      {/* <!-- Search Modal Start --> */}
      <div class="modal fade" id="SearchModal" tabIndex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div class="input-group mb-3">
                <span class="input-group-text">
                  <Link
                    to={
                      window.location.pathname === "Seeker_Home"
                        ? "/Seeker_Home"
                        : window.location.pathname === "Explore"
                          ? "/Explore"
                          : "#"
                    }
                    state={{
                      keyword: searchText,
                      industry: "",
                      isFrom: "search",
                    }}
                  >
                    <img src={search} alt="" />
                  </Link>
                </span>
                <input
                  type="text"
                  class="form-control"
                  placeholder={translation.SEARCH_HERE}
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>{" "}
                <span class="divide_line"></span>
                {/* <a class="btn" data-bs-toggle="offcanvas" href="#offcanvasSearchFilter" aria-controls="offcanvasSearchFilter"><img src={filter} alt="" /></a> */}
              </div>
              <p class="text-center fs-14 m-0">{translation.NO_RECENT_SEARCHES}</p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Search Modal End --> */}
      {/* <!-- Notifications Offcanvas Start --> */}
      <div
        class="NotificationCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasNotification"
        aria-labelledby="offcanvasNotificationLabel"
      >
        <div class="offcanvas-header">
          <h5
            class="offcanvas-title fs-16 fw-600"
            id="offcanvasNotificationLabel"
          >
            {translation.NOTIFICATIONS}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div class="d-flex justify-content-end p-2">
            <Link to="/Notification">{translation.SEE_ALL}</Link>
          </div>
          <NotificationComponent />
        </div>
      </div>
      {/* <!-- Notifications Offcanvas End --> */}
      {/* <!-- Search Filter Offcanvas Start --> */}
      <Filter_JobseekerHome />
      {/* <!-- Search Filter Offcanvas End --> */}
    </>
  );
}

export default Header;
