import React, { useState, useEffect, Component } from "react";
import TinderCard from "react-tinder-card";
import ReactTooltip from "react-tooltip";

import Bookmark from "../../assets/img/bookmark.svg";
import close_job from "../../assets/img/cross_white_bg.svg";
import check_job from "../../assets/img/tick_white_bg.svg";
import job_logo from "../../assets/img/job_logo.png";
import Header from "../../sharedComponents/Header";
import { Link, useLocation } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import {
  getWithAuthCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  simpleGetCallWithErrorResponse,
} from "../../api/ApiServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTranslation from "../../customHooks/translations";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";
toast.configure();

const JobCard = (props) => {
  const translation = useTranslation();
  const [jobData, setProfileData] = useState(props.jobData);
  const onSwipe = (direction) => {
    if (direction == "right") {
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_SEEKER_TINDERL,
        JSON.stringify({
          action: "accept",
          job_id: jobData.job.id,
          user_id: props.id,
        })
      )
        .then((res) => {
          toast.success(res.message, { autoClose: 1000 });
          //   if(res.json.subscription_required)
          //     window.$("#HomeUpgrade").modal("show")
          //   // let jobData=jobData.slice(props.index,1)
          //   // props.setProfileList(profileList)
        })
        .catch((err) => console.log(err));
    } else if (direction == "left")
      postWithAuthCallWithErrorResponse(
        ApiConfig.JOB_SEEKER_TINDERL,
        JSON.stringify({
          action: "reject",
          job_id: jobData.job.id,
          user_id: props.id,
        })
      )
        .then((res) => {
          toast.success(res.message, { autoClose: 1000 });
          // console.log(res)

          // let profileList=props.profileList.slice(props.index,1)
          // console.log("profilelist",profileList)
          // props.setProfileList(profileList)
        })
        .catch((err) => console.log(err));
  };
  return (
    <TinderCard className="card-body text-center" onSwipe={onSwipe}>
      <ReactTooltip />
      <div className="jobmatch_percent">
        <p className="m-0">
          {jobData.percent} {translation.MATCH}
        </p>
      </div>

      <div
        className="job_bookmark"
        style={{ cursor: "pointer" }}
        onClick={() => {
          props.saveJob(jobData.job.id);
        }}
      >
        <img src={Bookmark} alt="" />
      </div>

      <img
        style={{ height: 100, width: 100, borderRadius: "50%" }}
        src={
          jobData.job.photo_link !== null ? jobData.job.photo_link : job_logo
        }
        alt=""
      />

      <h3 className="fs-16 fw-600 mt-4">{jobData.job.job_title}</h3>
      <p className="m-0 fs-14">{jobData.job.company_name}</p>
      <p className="m-0 fs-14">
        {jobData.job.work_location.map((item) => {
          return <> {item}</>;
        })}
      </p>
      <p className="m-0 fs-14">
        {jobData.job.job_type}
        {jobData.job.required_experience.map((item) => {
          return <> {item.level}</>;
        })}
      </p>

      <p className="fs-14 text-truncate">{jobData.job.about_company}</p>

      <hr />

      <div className="row">
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.SKILLS}</h4>
          <p className="fs-16 ls-4 m-0">
            {jobData.job.job_skill.map((item, index) => {
              return <> {item.skill},</>;
            })}
          </p>
        </div>
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.TOOLS}</h4>
          <p className="fs-16 ls-4 m-0">
            {jobData.job.job_tool.map((item, index) => {
              return <> {item.tool},</>;
            })}
          </p>
        </div>
        <div className="col-md-4">
          <h4 className="fs-14 fw-700 ls-3">{translation.CERTIFICATES}</h4>
          <p className="fs-16 ls-4 m-0 text-truncate ">
            {jobData.job.job_certificate.map((item, index) => {
              return <> {item.job_certificates},</>;
            })}
          </p>
        </div>
      </div>
      {/* <div className="links job_actions text-center">
        <button
          type="button"
          // disabled={
          // count < profileList.length - 1 ? false : true
          // }
          className="btn btn-outline-primary rounded-circle"
          onClick={() => {
            onSwipe("left");
          }}
          style={{ marginRight: "5rem" }}
        >
          <img src={close_job} alt="" />
        </button>

        <button
          type="button"
          className="btn btn-outline-primary rounded-circle"
          data-bs-toggle="modal"
          // data-bs-target="#HomeUpgrade"
          onClick={() => onSwipe("right")}
        >
          <img src={check_job} alt="" />
        </button>
      </div> */}
    </TinderCard>
  );
};

function Seeker_Home() {
  const translation = useTranslation();
  const filterData = useLocation().state;
  const [state, setState] = useState({
    isProcessing: false,
  });
  const [count, setCount] = useState(0);
  const [jobList, setJobList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [upgradeRequired, setUpgradeRequired] = useState(false);
  useEffect(() => {
    if (!filterData) {
      setState({ ...state, isProcessing: true });
      getWithAuthCallWithErrorResponse(ApiConfig.MATCHING_JOB_API).then(
        (data) => {
          setState({ ...state, isProcessing: false });
          if (data.error !== true) {
            setJobList(data.json.list);
          } else {
            alert(data.json.detail);
          }
        }
      );
    }
  }, []);
  useEffect(() => {
    if (filterData) {
      let requestBody = JSON.stringify(filterData);
      let uri;
      if (filterData.isFrom === "Explore_Jobs") {
        uri = ApiConfig.FILTER_JOB_SEEKER;
      } else {
        uri = ApiConfig.JOB_SEARCH_SEEKER;
      }

      postWithAuthCallWithErrorResponse(uri, requestBody).then((data) => {
        setState({ ...state, isProcessing: false });
        if (data.error !== true) {
          setJobList(data.json.list);
        } else {
          alert(data.json.message);
        }
      });
    }
  }, [filterData]);

  useEffect(() => {
    getWithAuthCallWithErrorResponse(ApiConfig.GET_JOB_SEEKER_PROFILE).then(
      (data) => {
        if (data.error !== true) {
          setUserData(data.json);
          if (!data.json.preferences.length || !data.json.certificate.length || !data.json.education.length || !data.json.preferences.length || !data.json.skill.length || !data.json.tools.length) {
            toast.error("Please Upadate the profile to get desired job.", { autoClose: 1000 })
          }
        } else {
          alert(data.json.message);
        }
      }
    );
  }, []);
  const saveJob = (jobId) => {
    let requestBody = JSON.stringify({
      job_id: jobId,
    });
    postWithAuthCallWithErrorResponse(
      ApiConfig.FAVOURITE_JOB_API,
      requestBody
    ).then((data) => {
      if (data.error !== true) {
        toast.success(translation.JOB_SAVED_SUCCESSFULLY, { autoClose: 1000 });
        if (count < jobList.length - 1) {
          setCount(count + 1);
        }
      } else {
        alert(data.json.message);
      }
    });
  };

  const onSwipe = (direction, id) => {
    let jobListFiltered = jobList.filter((job) => job.job.id !== id);
    let formData = new FormData();
    formData.append("action", "accept");
    formData.append("job_id", id);
    if (direction == "right") {
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.JOB_SEEKER_TINDERL,
        formData
        // JSON.stringify({
        //   action: "accept",
        //   job_id: id,
        //   // user_id: userData.user.id,
        // })
      )
        .then((res) => {
          let jobListFiltered = jobList.filter((job) => job.job.id !== id);
          toast.success(res.json.message, { autoClose: 1000 });
          setJobList(jobListFiltered);
        })
        .catch((err) => console.log(err));
    } else if (direction == "left")
      //   formData.append("action", "reject");
      // formData.append("job_id", id);
      postMultipartWithAuthCallWithErrorResponse(
        ApiConfig.JOB_SEEKER_TINDERL,
        formData
        // JSON.stringify({
        //   action: "reject",
        //   job_id: id,
        //   // user_id: userData.user.id,
        // })
      )
        .then((res) => {
          let jobListFiltered = jobList.filter((job) => job.job.id !== id);
          toast.success(res.json.message, { autoClose: 1000 });
          setJobList(jobListFiltered);
        })
        .catch((err) => console.log(err));
  };
  return (
    <div>
      <Header />
      <section id="home" className="home">
        <div className="container-fluid">
          <div className="">
            <div className="cardContainer">
              {userData.user && userData.user.looking_for_job && jobList && jobList.length > 0 ? (
                jobList.map((item, index) => {
                  return (
                    <>
                      <div className="row" key={index}>
                        <div className="col-lg-8 col-md-12 offset-lg-2">
                          {/* <!-- Job Card One --> */}
                          <div className="job_card overlap_card card">
                            <JobCard
                              jobList={jobList}
                              id={userData.id}
                              setUpgradeRequired={setUpgradeRequired}
                              jobData={item}
                              index={index}
                              key={userData.id}
                              saveJob={saveJob}
                            />
                          </div>
                          {/* <!-- Job Card Two --> */}
                          {/* <div className="job_card overlap_card card">
                          <JobCard jobData={item} saveJob={saveJob} />
                        </div> */}
                          {/* <!-- Job Card Three --> */}
                          {/* <div className="job_card overlap_card third_card card">
                          <JobCard jobData={item} saveJob={saveJob} />
                        </div> */}
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (state.isProcessing ? <SpinnerCmp loading={state.isProcessing} /> :
                <div className="row">
                  <div className="col-lg-8 col-md-12 offset-lg-2">
                    <div className="card-body text-center no-record">
                      <p className="m-0 fs-50 no-record-style" id="no-record">
                        {" "}
                        {translation.CHECKOUT_MORE_PROFILES}
                      </p>
                    </div>
                  </div>
                </div>
              )}

            </div>
            {userData.user && userData.user.looking_for_job && jobList && jobList.length > 0 && (
              <div className="job_actions Card-button">
                <button
                  style={{ marginRight: "10px" }}
                  href=""
                  type="button"
                  // disabled={
                  // count < profileList.length - 1 ? false : true
                  // }
                  className="btn "
                  data-tip={translation.REJECT}
                  onClick={() => {
                    onSwipe("left", jobList[jobList.length - 1].job.id);
                  }}
                >
                  <img src={close_job} alt="" />
                </button>

                <button
                  href=""
                  type="button"
                  className="btn "
                  data-tip={translation.ACCEPT}
                  // data-bs-toggle="modal"
                  // data-bs-target="#HomeUpgrade"
                  onClick={() =>
                    onSwipe("right", jobList[jobList.length - 1].job.id)
                  }
                >
                  <img src={check_job} alt="" />
                </button>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* <!-- Home Upgrade Modal Start --> */}

      <div
        class="modal fade"
        id="HomeUpgrade"
        tabIndex="-1"
        aria-labelledby="HomeUpgradeLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body text-center">
              <p>{translation.UPGRADE_TO_FULL_ACCESS_MSG}</p>

              <button
                type="button"
                class="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                {translation.CANCEL}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
              >
                {translation.UPGRADE}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Home Upgrade Modal End --> */}
    </div>
  );
}

export default Seeker_Home;
