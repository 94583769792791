import React from "react";
import { Link } from "react-router-dom";
import useTranslation from "../../../customHooks/translations";
import RecruiterHeader from "../../../sharedComponents/RecruiterHeader";

function Subscription_Premium() {
  const translation = useTranslation();
  return (
    <div>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="#">{translation.PROFILE}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.SUBSCRIPTION_PLANS}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div className="row pricing_block">
                <div className="col-lg-4">
                  <div className="card pricing_lf settings_card mt-5 ">
                    <div className="card-body text-center">
                      <div className="plan_title">
                        <h4 className="fs-18 m-0 fw-600">
                          {translation.OUR_PLANS}
                        </h4>
                      </div>
                      <hr />
                      <div className="plan_feature mt-4">
                        <p className="m-0 fw-600">
                          {translation.MAXIMUM_JOBS_TO_APPLY}
                        </p>
                      </div>
                      <div className="plan_feature">
                        <p className="m-0 fw-600">
                          {translation.NO_OF_JOB_SAVES}
                        </p>
                      </div>
                      <div className="plan_feature">
                        <p className="m-0 fw-600">{translation.VALIDITY}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="card pricing_header mt-5">
                    <div className="card-body text-center">
                      <p className="text-white">
                        {translation.WANT_TO_TRY_OUR_PLATFORM}
                      </p>
                      <p className="text-white m-0">
                        {translation.CLICK_HERE_FOR_3_DAYS_FREE_TRIAL}
                      </p>
                    </div>
                  </div>

                  <div className="row pricing_plans">
                    <div className="col-lg-6 col-md-6">
                      <div className="card settings_card ">
                        <div className="card-body text-center">
                          <div className="plan_title">
                            <h4 className="fs-18 m-0 fw-600">Free</h4>
                          </div>
                          <div className="plan_feature">
                            <p className="m-0 fw-600">Free</p>
                          </div>
                          <div className="light_feature">
                            <p className="m-0 fw-600">3 per 2 weeks</p>
                          </div>
                          <div className="light_feature">
                            <p className="m-0 fw-600">1</p>
                          </div>
                          <div className="light_feature">
                            <p className="m-0 fw-600">-</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="card settings_card ">
                        <div className="card-body text-center">
                          <div className="plan_title">
                            <h4 className="fs-18 m-0 fw-600">Full Access</h4>
                          </div>
                          <div className="plan_feature">
                            <p className="m-0 fw-600">199 SEK</p>
                          </div>
                          <div className="light_feature">
                            <p className="m-0 fw-600">Unlimited</p>
                          </div>
                          <div className="light_feature">
                            <p className="m-0 fw-600">Unlimited</p>
                          </div>
                          <div className="light_feature">
                            <p className="m-0 fw-600">1 month</p>
                          </div>
                          <div className="light_feature text-center">
                            <button className="btn btn-outline-primary">
                              {translation.UPGRADE}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Subscription_Premium;
