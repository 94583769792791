import React from 'react'
import Logo from '../assets/img/logo.png'
import BackgroundImg from '../assets/img/background.png'
// import NavLink from 'react-router-dom'
import { NavLink } from "react-router-dom";
import useTranslation from '../customHooks/translations';

function Welcome() {
  const translation = useTranslation();
  return (
    <section id="welcome" className="welcome">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">



            <div id="carouselExampleIndicators" className="carousel " data-bs-ride="carousel">
              {/* <div className="carousel-indicators">
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div> */}
              <div className="carousel-inner">

                {/* <!-- Banner One --> */}
                <div className="carousel-item active">
                  {/* <!-- Banner Content --> */}
                  <div className="overlay"></div>
                  <div className="overlay-line"></div>
                  <img src={BackgroundImg} className="banner_img d-block w-100" alt="..." />
                  <div className="carousel-caption d-md-block">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="top_logo">
                          <img src={Logo} alt="" />
                        </div>
                        <div className="banner_block">
                          <h2>{translation.WELCOME_LABEL} <br /> proW</h2>
                          <p className="pt-2 text-white">{translation.PRODUCT_INTRO}</p>

                          <NavLink to='login' className='btn btn-primary banner_btn mt-4'>{translation.GET_STARTED}</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Banner Content End --> */}
                </div>

                {/* <!-- Banner Two --> */}
                <div className="carousel-item">
                  {/* <!-- Banner Content --> */}
                  <div className="overlay"></div>
                  <div className="overlay-line"></div>
                  <img src={BackgroundImg} className="banner_img d-block w-100" alt="..." />
                  <div className="carousel-caption d-md-block">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="top_logo">
                          <img src={Logo} alt="" />
                        </div>
                        <div className="banner_block">
                          <h2>{translation.WELCOME_LABEL}<br /> workr</h2>
                          <p className="pt-2 text-white">{translation.PRODUCT_INTRO}</p>
                          <NavLink to='login' className='btn btn-primary banner_btn mt-4'>{translation.GET_STARTED}</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Banner Content End --> */}
                </div>

                {/* <!-- Banner Three --> */}
                <div className="carousel-item">
                  {/* <!-- Banner Content --> */}
                  <div className="overlay"></div>
                  <div className="overlay-line"></div>
                  <img src={BackgroundImg} className="banner_img d-block w-100" alt="..." />
                  <div className="carousel-caption d-md-block">
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="top_logo">
                          <img src={Logo} alt="" />
                        </div>
                        <div className="banner_block">
                          <h2>{translation.WELCOME_LABEL} <br /> proW</h2>
                          <p className="pt-2 text-white">{translation.PRODUCT_INTRO}</p>
                          <NavLink to='login' className='btn btn-primary banner_btn mt-4'>{translation.GET_STARTED}</NavLink>



                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- Banner Content End --> */}
                </div>
              </div>
              {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button> */}
            </div>


          </div>
        </div>
      </div>
    </section>
  )
}

export default Welcome
