import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../../sharedComponents/Header";
import chevron_right_colored from "../../../assets/img/chevron_right_colored.svg";
import useTranslation from "../../../customHooks/translations";
import { postWithAuthCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { toast } from "react-toastify";

function Delete_Profile() {
  const [state, setState] = useState([])
  const [text, setText] = useState("")
  let resonArray = []
  const translation = useTranslation();

  const deleteProfile = () => {
    let requestBody = JSON.stringify({ reason: [...state, text] })
    postWithAuthCallWithErrorResponse(
      ApiConfig.DELETE_ACCOUNT_API,
      requestBody
    ).then((data) => {
      if (data.error !== true) {
        toast.success(data.json.message, { autoClose: 1000 });
        localStorage.clear();
        window.location.href = "/login"
      } else {
        toast.error(data.json.message, { autoClose: 1000 });
      }
    });
  }
  return (
    <div>
      <Header />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item">
                    <Link to="/Setting">{translation.SETTINGS}</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {translation.DELETE_PROFILE}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" class="settings">
        <div class="container">
          <div class="row position-relative">
            <div class="col-lg-6 col-md-12 offset-lg-3">
              <div
                class="card delete_profile login settings_card"
                style={{ marginTop: "4em" }}
              >
                <div class="card-body">
                  <p class="text-muted mb-4">
                    {translation.SELECT_A_REASON_FOR_DELETING_THE_ACCOUNT}
                  </p>

                  <div class="d-flex justify-content-between">
                    <p>{translation.THIS_IS_TEMPORARY_I_WILL_BE_BACK}</p>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        onChange={(e) => {
                          if (Array.isArray(state)) {
                            resonArray = state;
                            if (resonArray.indexOf("This is temporary, I will be back.") > -1) {
                              resonArray.splice(resonArray.indexOf("This is temporary, I will be back."), 1);
                            } else {
                              resonArray.push("This is temporary, I will be back.");
                            }
                          }
                          setState(resonArray)
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <p>{translation.I_GET_TOO_MANY_NOTIFICATIONS}</p>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        onChange={(e) => {
                          if (Array.isArray(state)) {
                            resonArray = state;
                            if (resonArray.indexOf("I get too many notifications.") > -1) {
                              resonArray.splice(resonArray.indexOf("I get too many notifications."), 1);
                            } else {
                              resonArray.push("I get too many notifications.");
                            }
                          }
                          setState(resonArray)
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <p>{translation.I_SPEND_TOO_MUCH_TIME_IN_PROW}</p>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        onChange={(e) => {
                          if (Array.isArray(state)) {
                            resonArray = state;
                            if (resonArray.indexOf("I spend too much time in ProW.") > -1) {
                              resonArray.splice(resonArray.indexOf("I spend too much time in ProW."), 1);
                            } else {
                              resonArray.push("I spend too much time in ProW.");
                            }
                          }
                          setState(resonArray)
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <p>{translation.MY_ACCOUNT_WAS_HACKED}</p>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        onChange={(e) => {
                          if (Array.isArray(state)) {
                            resonArray = state;
                            if (resonArray.indexOf("My account was hacked.") > -1) {
                              resonArray.splice(resonArray.indexOf("My account was hacked."), 1);
                            } else {
                              resonArray.push("My account was hacked.");
                            }
                          }
                          setState(resonArray)
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <p>{translation.I_DIDNT_FIND_PROW_USEFUL}</p>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        onChange={(e) => {
                          if (Array.isArray(state)) {
                            resonArray = state;
                            if (resonArray.indexOf("I didn't find ProW useful.") > -1) {
                              resonArray.splice(resonArray.indexOf("I didn't find ProW useful."), 1);
                            } else {
                              resonArray.push("I didn't find ProW useful.");
                            }
                          }
                          setState(resonArray)
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <p>{translation.I_HAVE_A_PRIVATE_CONCERN}</p>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckChecked"
                        onChange={(e) => {
                          if (Array.isArray(state)) {
                            resonArray = state;
                            if (resonArray.indexOf("I have a private concern.") > -1) {
                              resonArray.splice(resonArray.indexOf("This is temporary, I will be back."), 1);
                            } else {
                              resonArray.push("I have a private concern.");
                            }
                          }
                          setState(resonArray)
                        }}
                      />
                    </div>
                  </div>
                  <hr />

                  <div class="form_block w-100 pt-0">
                    <form class="row g-3 text-start">
                      <div class="col-md-12 regsiterrf">
                        <label for="inputPassword4" class="form-label fs-14">
                          {translation.OTHER}
                        </label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ height: "69px" }}
                          onChange={(e) => setText(e.target.value)}
                        ></textarea>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="loginwithlinks editprofile_block">
                <div class="d-lg-flex d-md-flex justify-content-end">
                  <div class="loginwithbtn">
                    <p class="fs-22 fw-600 text-primary">
                      {translation.CONTINUE}{" "}
                      <button
                        type="button"
                        class="btn btn-outline-primary rounded-circle ms-2"
                        data-bs-toggle="modal"
                        data-bs-target="#HomeUpgrade"

                      >
                        <img src={chevron_right_colored} alt="" />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Home Upgrade Modal Start --> */}

      <div
        class="modal fade"
        id="HomeUpgrade"
        tabIndex="-1"
        aria-labelledby="HomeUpgradeLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body text-center">
              <p>{translation.DELETE_YOUR_ACCOUNT_DO_YOU_WISH_TO_CONTINUE}</p>

              <button
                type="button"
                class="btn btn-outline-primary"
                data-bs-dismiss="modal"
              >
                {translation.NO}
              </button>
              {/* <Link to="/Setting"> */}
              <button
                type="button"
                data-bs-dismiss="modal"
                class="btn btn-primary"
                onClick={deleteProfile}
              >
                {translation.YES}
              </button>
              {/* </Link> */}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Home Upgrade Modal End --> */}
    </div>
  );
}

export default Delete_Profile;
