import React, { useState } from "react";
import RecruiterHeader from "../../../sharedComponents/RecruiterHeader";
import { Link, useLocation } from "react-router-dom";
import default_profile_pic from "../../../assets/img/profile_pic.svg";
import tick_timeline from "../../../assets/img/tick_timeline.svg";
import upload from "../../../assets/img/upload.svg";
import SpinnerCmp from "../../../sharedComponents/SpinnerCmp";
import { useNavigate } from "react-router-dom";
import CompanyInfo from "./CompanyInfo";
import JobsInfo from "./JobsInfo";
import PrerequisiteInfo from "./PrerequisiteInfo";
import ReactTooltip from "react-tooltip";
import useTranslation from "../../../customHooks/translations";

export default function CreateJobNew() {
  const translation = useTranslation();
  const [state, setState] = useState(0);
  const [jobDetails, setJobDetails] = useState({
    user_profile_pic: default_profile_pic,
    profile_pic: "",
    company_name: "",
    about_company: "",
    industry: "",
    selected_industry: [],
    company_linkedin: "",
    job_title: "",
    selected_jobTitle: [],
    job_description: "",
    job_type: "Full Time",
    work_location: ["OnSite"],
    job_posting_language: "English",
    salary: "",
    ad_duration: "",
    required_experience: [],
    selectedExperiences: [],
    //languages_required: [],
    selectedLanguages: [],
    selectedLanguagesWithLevel: [],
    //skills: [],
    selectedSkills: [],
    selectedSkillsWithLevel: [],
    selectedTools: [],
    selectedToolsWithLevel: [],
    selectedCertificates: [],
    //tools: [],
    video: "",
    video_name: "",
    certificates: [],
  });
  function handleNext() {
    if (state !== 2) {
      setState(state + 1);
    } else {
      return;
    }
  }
  function handlePrevious() {
    if (state !== 0) {
      setState(state - 1);
    } else {
      return;
    }
  }
  function saveCompanyInfo(data) {
    setJobDetails({
      ...jobDetails,
      user_profile_pic: data.user_profile_pic,
      profile_pic: data.profile_pic,
      company_name: data.company_name,
      about_company: data.about_company,
      industry: data.industry,
      selected_industry: data.selected_industry,
      company_linkedin: data.company_linkedin,
    });
  }

  function saveJobsInfo(data) {
    setJobDetails({
      ...jobDetails,
      job_title: data.job_title,
      selected_jobTitle: data.selected_jobTitle,
      job_description: data.job_description,
      job_type: data.job_type,
      work_location: data.work_location,
      job_posting_language: data.job_posting_language,
      salary: data.salary,
      ad_duration: data.ad_duration,
      required_experience: data.required_experience,
      selectedExperiences: data.selectedExperiences,
      selectedLanguages: data.selectedLanguages,
      selectedLanguagesWithLevel: data.selectedLanguagesWithLevel,
      selectedSkills: data.selectedSkills,
      selectedSkillsWithLevel: data.selectedSkillsWithLevel,
      selectedTools: data.selectedTools,
      selectedToolsWithLevel: data.selectedToolsWithLevel,
      selectedCertificates: data.selectedCertificates,
      video: data.video,
      video_name: data.video_name,
      certificates: data.certificates,
    });
  }

  return (
    <>
      <RecruiterHeader />
      <ReactTooltip />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <a href="/recruiter-profile">{translation.PROFILE}</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.CREATE_JOB_AD}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section id="settings" className="settings">
        <div className="container">
          <div className="row position-relative" style={{ marginTop: "7em" }}>
            <div className="col-lg-4 col-md-12">
              <h2 className="fs-18 fw-600">{translation.CREATE_JOB_AD}</h2>

              <div className="create_job_tracking trackingstep">
                <div
                  className={
                    state > 0 ? "step step-complete" : "step step-active"
                  }
                >
                  <div>
                    <div className="circle">
                      {state > 0 ? <img src={tick_timeline} alt="" /> : null}
                    </div>
                  </div>
                  <div>
                    <div className="title">{translation.COMPANY_INFO}</div>
                  </div>
                </div>
                <div
                  className={
                    state > 1
                      ? "step step-complete"
                      : state === 1
                      ? "step step-active"
                      : "step inactive"
                  }
                >
                  <div>
                    <div className="circle">
                      {state > 1 ? <img src={tick_timeline} alt="" /> : null}
                    </div>
                  </div>
                  <div>
                    <div className="title">{translation.ADD_JOB_INFO}</div>
                  </div>
                </div>
                <div
                  className={state === 2 ? "step step-active" : "step inactive"}
                >
                  <div>
                    <div className="circle">3</div>
                  </div>
                  <div>
                    <div className="title">{translation.PREREQUISITES}</div>
                  </div>
                </div>
              </div>
            </div>

            {state === 0 ? (
              <CompanyInfo
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                saveCompanyInfo={saveCompanyInfo}
                jobDetails={jobDetails}
              />
            ) : state === 1 ? (
              <JobsInfo
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                saveJobsInfo={saveJobsInfo}
                jobDetails={jobDetails}
              />
            ) : state === 2 ? (
              <PrerequisiteInfo
                handlePrevious={handlePrevious}
                saveJobsInfo={saveJobsInfo}
                jobDetails={jobDetails}
              />
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
}
