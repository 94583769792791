import React, { useState, useEffect } from "react";
import { Plus } from "react-feather";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import Country from "../../../assets/CountrtiesData/Country";
import listContryCity from "../../../assets/CountrtiesData/CoutrycityList";
import Select from "react-select";
import moment from "moment";
import { toast } from "react-toastify";
import useTranslation from "../../../customHooks/translations";
import { useLocation, useNavigate } from "react-router-dom";
const Add_Experiance = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    isProcessing: false,
    errMsg: { company_name: "" },
    company_name: "",
    role: "",
    job_type: "",
    experience_level: "",
    start_year: "",
    end_year: "",
    is_present: "",
    selected_Country: {},
    selected_City: {},
    errorMsg: {},
    TodayDate_yyyy_mm_dd: moment().format("YYYY-MM-DD"),
    selected_start_year: "",
    selected_end_year: "",

  });
  const translation = useTranslation();
  const route = useLocation().pathname
  const [countriesState, setCountriesState] = useState();
  const [citiesState, setCitiesState] = useState();
  const [jobtype, setJobType] = useState();
  const [joblist, setJobList] = useState();
  const [expList, setExpList] = useState();
  const [experiance, setExperiance] = useState({
    item: [],
  });
  // Job Type start
  const getJobType = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.JOBTYPE_LISTING_API)
      .then((data) => {
        setJobType(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Job Type End
  // Job List start
  const getJobList = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.JOBROLE_LISTING_API)
      .then((data) => {
        setJobList(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Job List End
  // Experiance List start
  const getExperianceList = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.EXPERIENCE_LISTING_API)
      .then((data) => {
        setExpList(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* city section starts */
  function getCitiesList(selected_country) {
    Object.keys(listContryCity.listContryCity).forEach(function (key, index) {
      var newJson = listContryCity.listContryCity[key];
      if (newJson.country == selected_country) {
        updateCity(newJson.city);
      }
    });
  }

  function updateCity(data) {
    let temp_cities = [];
    data.map((item, index) => {
      temp_cities.push({ label: item, value: item });
    });
    setCitiesState(temp_cities);
  }

  function onCityChange(value) {
    setState({ ...state, selected_City: value, city: value.label });
  }
  /* city section ends */

  /* country section starts */
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent",
      borderRadius: "8px",
      border: "1px solid #b3c2c9",
      fontSize: "13px",
    }),
    menu: () => ({
      boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: "transparent",
    }),
  };

  function getCountriesList() {
    setCountriesState(Country.Country);
  }

  function onCountryChange(value) {
    setState({ ...state, selected_Country: value, country: value.label });
    getCitiesList(value.label);
  }

  function onCityChange(e) {
    setState({ ...state, city: e.target.value });
  }
  //   ADD EXPERIANCE
  const addExperianceDetails = async () => {

    if (
      state.company_name == "" ||
      state.role == "" ||
      state.job_type == "" ||
      state.experience_level == "" ||
      state.country == "" ||
      state.city == "" ||
      state.start_year == "" ||
      state.end_year == "" ||
      state.company_name == null ||
      state.role == null ||
      state.job_type == null ||
      state.experience_level == null ||
      state.country == null ||
      state.city == null ||
      state.start_year == null ||
      state.end_year == null
    ) {
      if (state.company_name == "" || state.company_name == null) {
        setState({
          ...state,
          errMsg: { company_name: translation.PLEASE_ENTER_COMPANY_NAME },
        });
        return;
      } else if (state.role == "" || state.role == null) {
        setState({
          ...state,
          errMsg: { role: translation.PLEASE_SELECT_JOB_TITLE },
        });
        return;
      } else if (state.job_type == "" || state.job_type == null) {
        setState({
          ...state,
          errMsg: { job_type: translation.PLEASE_SELECT_JOB_TYPE },
        });
        return;
      } else if (
        state.experience_level == "" ||
        state.experience_level == null
      ) {
        setState({
          ...state,
          errMsg: { experience_level: translation.SELECT_EXPERIENCE },
        });
        return;
      } else if (state.country == "" || state.country == null) {
        setState({
          ...state,
          errMsg: { country: translation.SELECT_COUNTRY },
        }); return;
      } else if (state.city == "" || state.city == null) {
        setState({
          ...state,
          errMsg: { city: translation.SELECT_CITY },
        }); return;
      } else if (state.start_year == "" || state.start_year == null) {
        setState({
          ...state,
          errMsg: { start_year: translation.SELECT_START_YEAR },
        }); return;
      } else if (state.end_year == "" || state.end_year == null) {
        setState({
          ...state,
          errMsg: { end_year: translation.SELECT_END_YEAR },
        }); return;
      }
    } else {
      let requestBody = JSON.stringify({
        company_name: state.company_name,
        role: state.role,
        city: state.city,
        country: state.country,
        role: state.role,
        start_year: state.start_year,
        end_year: state.end_year,
        experience_level: state.experience_level,
        job_type: state.job_type,
        experiance: []
        // location: state.location
      });
      postWithAuthCallWithErrorResponse(ApiConfig.EXPERIENCE_API, requestBody)
        .then((data) => {
          setState({ ...state, isProcessing: true });
          if (data.error == true) {
            setState({ ...state, isProcessing: false });
          } else {
            toast.success("Experience added successfully", { autoClose: 1000 })
            setState({ ...state, ...data.json })
            props.GetExperiance()
            // window.location.href = "/Edit_Experiance";
            navigate("/Edit_Experiance");
          }
        })
        .catch((error) => {
          setState({ ...state, isProcessing: false });
        });
    }
  };

  // END EXPERIANCE
  /* country section ends */
  useEffect(() => {
    getCountriesList();
    getJobType();
    getJobList();
    getExperianceList();

  }, []);
  function onCountryChange(e) {
    setState({ ...state, country: e.target.value });
    getCitiesList(e.target.value);
  }

  return (
    <div>
      {route === "/profile" &&
        <div class="AddEducationLevel">
          <div class="d-flex justify-content-end">
            <p class="text-primary">
              Add Experience Levels{" "}
              <a
                class="addlevel ms-2"
                data-bs-toggle="offcanvas"
                href="#offcanvasAddEducationa"
                role="button"
                aria-controls="offcanvasAddEducation"
              >
                <Plus />
              </a>
            </p>
          </div>

          <div class="edulevels">
            <div class="levelcircle active d-flex justify-content-between mt-4">
              <div>
                <h3 class="fs-16">{state.role}</h3>
                <p class="fs-14 m-0">{state.company_name}</p>
                <p class="fs-14 m-0">{state.start_year && moment(state.start_year).format("MMM-YYYY") + " to " + moment(state.end_year).format("MMM-YYYY")}</p>
              </div>
              <a href="#">
                <img src="img/edit_cert.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      }


      <div
        class="AddLevelCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasAddEducationa"
        aria-labelledby="offcanvasAddEducationLabel"
      >
        {/* <div class="offcanvas-header">
            <h5
              class="offcanvas-title fs-16 fw-700"
              id="offcanvasAddEducationLabel"
            >
              {translation.ADD_EXPERIENCE}
            </h5>
            <button
              type="button"
              class="btn-close text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div> */}
        <div class="offcanvas-body">
          <form class="row g-3 text-start">
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.COMPANY_NAME}
              </label>
              <input
                type="text"
                class="form-control border-primary"
                id="inputEmail4"
                value={state.company_name}
                onChange={(e) => {
                  setState({ ...state, company_name: e.target.value });
                }}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.company_name ? state.errMsg.company_name : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.JOB_TITLE}
              </label>
              <select
                type="text"
                placeholder={translation.ADD_SKILLS}
                value={state.role}
                onChange={(e) => {
                  setState({ ...state, role: e.target.value });
                }}
                class="form-select border-primary"
                aria-label="Default select example"
              >
                <option value="">{translation.PLEASE_SELECT_JOB_TITLE}</option>
                {joblist && joblist.length > 0
                  ? joblist.map((result, index) => {
                    return <option>{result.name}</option>;
                  })
                  : null}
              </select>
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.role ? state.errMsg.role : ""}
              </span>
            </div>

            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.JOB_TYPE}
              </label>
              <select
                type="text"
                placeholder={translation.ADD_SKILLS}
                value={state.job_type}
                class="form-select border-primary"
                aria-label="Default select example"
                onChange={(e) => {
                  setState({ ...state, job_type: e.target.value });
                }}
              >
                <option value="">Please select job type</option>
                {jobtype && jobtype.length > 0
                  ? jobtype.map((result, index) => {
                    return <option>{result.name}</option>;
                  })
                  : null}
              </select>
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.job_type ? state.errMsg.job_type : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.EXPERIANCE}
              </label>
              <select
                type="text"
                value={state.experience_level}
                onChange={(e) => {
                  setState({ ...state, experience_level: e.target.value });
                }}
                class="form-select border-primary"
                aria-label="Default select example"
              >
                <option value="">Please select job experiance</option>
                {expList && expList.length > 0
                  ? expList.map((result, index) => {
                    return <option>{result.level}</option>;
                  })
                  : null}
              </select>
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.experience_level
                  ? state.errMsg.experience_level
                  : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.COUNTRY}
              </label>

              <input list="countryLIst" id="countryName" className="form-control" onChange={onCountryChange} value={state.country || ""} />
              <datalist id="countryLIst" name={"service-cityselected"} style={{ color: "red" }}>
                {countriesState && countriesState.length && countriesState.map(country => {
                  return (<option value={country.value} />)
                })}
              </datalist>

              {/* <Select
                isClearable={false}
                onChange={onCountryChange}
                options={countriesState}
                placeholder={translation.SEARCH}
                styles={selectStyles}
                //tabSelectsValue={false}
                value={state.selected_Country}
              /> */}
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.country ? state.errMsg.country : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.CITY}
              </label>
              <input list="cityList" id="city" className="form-control" onChange={onCityChange} value={state.city || ""} />
              <datalist id="cityList" name={"service-cityselected"} style={{ color: "red" }}>
                {citiesState && citiesState.length && citiesState.map(city => {
                  return (<option value={city.value} />)
                })}
              </datalist>
              {/* <Select
                isClearable={false}
                onChange={onCityChange}
                options={citiesState}
                placeholder={translation.SEARCH}
                styles={selectStyles}
                //tabSelectsValue={false}
                value={state.selected_City}
              /> */}
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.city ? state.errMsg.city : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.START_YEAR}
              </label>
              <input
                type="date"
                className="form-control"
                id="StartYear"
                max={state.TodayDate_yyyy_mm_dd}
                value={state.selected_start_year}
                onChange={(e) => {
                  setState({
                    ...state,
                    start_year: e.target.value,
                    selected_start_year: e.target.value,
                  });
                }}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.start_year ? state.errMsg.start_year : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.END_YEAR}
              </label>
              <input
                type="date"
                class="form-control border-primary"
                id="inputPassword4"
                value={state.end_year}
                onChange={(e) => {
                  setState({ ...state, end_year: e.target.value });
                }}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.end_year ? state.errMsg.end_year : ""}
              </span>
            </div>
          </form>
        </div>
        <div class="educationcanvas-footer">
          <div class="d-flex justify-content-end">
            <a
              href="#"
              class=" fs-16 fw-600 me-3 p-2"
              onClick={addExperianceDetails}
            >
              {translation.APPLY}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add_Experiance;
