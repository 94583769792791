export default {
  FULLNAME: "पूरा नाम",
  EMAIL: "ईमेल",
  MOBILE_NUMBER: "मोबाईल नंबर",
  COUNTRY: "देश",
  CITY: "शहर",
  DATE_OF_BIRTH: "जन्म की तारीख",
  LINKEDIN_PROFILE: "लिंक्डइन प्रोफ़ाइल",
  SHORT_DESCRIPTION: "अपने बारे में संक्षिप्त विवरण",
  UPDATE_BASIC_DETAILS: "बुनियादी विवरण अपडेट करें",
  UPDATE: "अद्यतन",
  CONFIRMATION_FOR_DELETE: "क्या आप वाकई हटाना चाहते हैं?",
  MATCHED_JOBS: "मेल खाने वाली नौकरियां",
  SAVED_JOBS: "सहेजी गई नौकरियां",
  SET_STATUS: "स्थिति सेट करें",
  ADD_INTRO_VIDEO: "परिचय वीडियो जोड़ें",
  BASIC_PROFILE: "मूल प्रोफ़ाइल",
  EDUCATION: "शिक्षा",
  EXPERIANCE: "अनुभव",
  PREFERNCES: "पसंद",
  LANGUAGE: "भाषा",
  INDUSTRY: "उद्योग",
  MATCHING_PERCENTAGE_PREFERENCE: "मिलान प्रतिशत वरीयता",
  JOB_TITLE: "नौकरी का नाम",
  MONTHLY_SALARY: "मासिक वेतन",
  DESIRED_LOCATION: "इच्छित स्थान",
  WORK_LOCATION: "कार्य स्थल",
  SKILLS: "कौशल",
  TOOLS: "उपकरण",
  CERTIFICATES: "प्रमाण पत्र",
  CURRENT_EXPERIANCE_LEVEL: "वर्तमान अनुभव स्तर",
  CURRENT_ROLE: "वर्तमान भूमिका",
  TUTORIAL: "ट्यूटोरियल",
  CHANGE_LANGAUAGE: "भाषा बदलें",
  SUBSCRIPTION_PLANS: "सदस्यता योजनाएं",
  FAQ: "सामान्य प्रश्न",
  FEEDBACK_SUGGESTION: "प्रतिक्रिया और सुझाव",
  CONTACT_US: "संपर्क करें",
  SETTINGS: "समायोजन",
  LEGAL: "कानूनी",
  LOGOUT: "लॉग आउट",
  SELECT_LANGUAGE: "भाषा चुने",
  SELECT_YOUR_PREFERRED_LANGUAGE_TO_CONTINUE:
    "जारी रखने के लिए अपनी पसंदीदा भाषा चुनें",
  CONTINUE: "जारी रखें",
  UPGRADE: "अपग्रेड",
  GIVE_US_FEEDBACK_ABOUT_A_FEATURE_LET_US_KNOW_HOW_WE_CAN_IMPROVE_YOUR_EXPERIANCE:
    "किसी सुविधा के बारे में हमें फ़ीडबैक दें. हमें बताएं कि हम आपके अनुभव को कैसे बेहतर बना सकते हैं।",
  SELECT_FEATURE: "फ़ीचर का चयन करें",
  ENTER_YOUR_FEEDBACK_BELOW: "अपनी प्रतिक्रिया नीचे दर्ज करें",
  GET_IN_TOUCH_WITH_US: "हमारे साथ संपर्क में जाओ",
  MESSAGE_US: "संदेश हमें",
  SUBJECT: "विषय",
  PLEASE_SELECT_SUBJECT: "कृपया विषय चुनें",
  MESSAGE: "संदेश",
  ACCOUNT_SETTINGS: "अकाउंट सेटिंग",
  HIDE_STATUS_FOR_EMPLOYERS: "नियोक्ताओं के लिए स्थिति छुपाएं",
  ENABLE_READ_RECEIPT: "पठन रसीद सक्षम करें",
  BLOCKING: "ब्लॉक कर रहा है",
  CHANGE_PASSWORD: "पासवर्ड बदलें",
  DELETE_PROFILE: "प्रोफ़ाइल हटाएं",
  NOTIFICATIONS_SETTINGS: "अधिसूचना सेटिंग",
  ENABLE_NOTIFICATIONS_FOR_MATCHES: "मैचों के लिए सूचनाएं सक्षम करें",
  WANT_TO_TRY_OUR_PLATFORM: "हमारे मंच को आजमाना चाहते हैं?",
  CLICK_HERE_FOR_3_DAYS_FREE_TRIAL:
    "3 दिनों के नि:शुल्क परीक्षण के लिए यहां क्लिक करें",
  MAXIMUM_JOBS_TO_APPLY: "आवेदन करने के लिए अधिकतम नौकरियां",
  NUMBER_OF_JOBS_SAVES: "नौकरियों की संख्या बचाता है",
  VALIDITY: "वैधता",
  PRICE: "कीमत",
  PRIVACY_POLICY: "गोपनीयता नीति",
  TERMS_CONDITIONS: "नियम एवं शर्तें",
  PRIVACY_PREFERENCES: "गोपनीयता वरीयताएँ",
  NO_NEW_NOTIFICATION: "कोई नई सूचना नहीं!",
  ADD_EXPERIENCE: "अनुभव जोड़ें",
  COMPANY_NAME: "कंपनी का नाम",
  JOB_TYPE: "कार्य का प्रकार",
  PLEASE_SELECT_JOB_TYPE: "कृपया जॉब प्रकार का चयन करें",
  PLEASE_SELECT_JOB_TITLE: "कृपया नौकरी का शीर्षक चुनें",
  PLEASE_SELECT_JOB_EXPERIENCE: "कृपया नौकरी का अनुभव चुनें",
  START_YEAR: "शुरुवाती साल",
  SELECT_DATE: "तारीख़ चुनें",
  END_YEAR: "अंत वर्ष",
  I_AM_CURRENTLY_WORK_HERE: "मैं वर्तमान में यहां काम कर रहा हूं",
  SAVE: "सहेजें",
  EDUCATION_TYPE: "शिक्षा प्रकार",
  PLEASE_SELECT_EDUCATION_TYPE: "कृपया शिक्षा प्रकार का चयन करें",
  INSTITUTE_NAME: "संस्थान का नाम",
  AREA_OF_STUDY: "अध्ययन का क्षेत्र",
  YEAR_OF_GRADUATION: "स्नातक स्तर की पढ़ाई का साल",
  PLEASE_ENTER_EDUCATION_TYPE: "कृपया शिक्षा प्रकार दर्ज करें",
  PLEASE_ENTER_INSTITUTE_NAME: "कृपया संस्थान का नाम दर्ज करें",
  PLEASE_ENTER_AREA_OF_STUDY: "कृपया अध्ययन का क्षेत्र दर्ज करें",
  PLEASE_ENTER_GRADUATION_YEAR: "कृपया स्नातक वर्ष दर्ज करें",
  PLEASE_ENTER_VALID_GRADUATION_YEAR: "कृपया मान्य स्नातक वर्ष दर्ज करें",
  PLEASE_ENTER_COMPANY_NAME: "कृपया कंपनी का नाम दर्ज करें!",
  PLEASE_CHOOSE_ROLE: "कृपया भूमिका चुनें!",
  PLEASE_CHOOSE_JOB_TYPE: "कृपया नौकरी का प्रकार चुनें!",
  PLEASE_ADD_EXPERIENCE: "कृपया अनुभव जोड़ें!",
  PLEASE_ADD_CITY: "कृपया शहर जोड़ें!",
  PLEASE_ADD_COUNTRY: "कृपया देश जोड़ें!",
  PLEASE_CHOOSE_START_YEAR: "कृपया प्रारंभ वर्ष चुनें!",
  BEGINNER: "शुरुआती",
  COMPETENT: "सक्षम",
  PROFICIENT: "कुशल",
  EXPERT: "विशेषज्ञ",
  ADD_CERTIFICATE: "प्रमाणपत्र जोड़ें",
  ADD_LINKEDIN_PROFILE: "LinkedIn प्रोफ़ाइल जोड़ें",
  PLEASE_SELECT_LANGUAGE: "कृपया भाषा का चयन करें",
  PLEASE_SELECT_INDUSTRIES: "कृपया उद्योगों का चयन करें",
  DESIRED_COUNTRY: "वांछित देश",
  DESIRED_CITY: "वांछित शहर",
  PLEASE_SELECT_YOUR_SALARY_RANGE: "कृपया अपनी वेतन सीमा का चयन करें",
  MATCH_PRECENTAGE: "मिलान प्रतिशत",
  PLEASE_SELECT_MATCH_PRECENTAG: "कृपया मैच प्रतिशत चुनें",
  ONSITE: "साइट पर",
  HYBRID: "हाइब्रिड",
  REMOTE: "रिमोट",
  UPGRADE_TO_FULL_ACCESS_MSG:
    "आपको या तो अधिक नौकरियों को बचाने / लागू करने के लिए पूर्ण पहुंच में अपग्रेड करने की आवश्यकता है या अपने मुफ्त पहुंच पुनः लोड होने तक प्रतीक्षा करें।",
  CANCEL: "रद्द करना",
  RECENT_SEARCHES: "हाल की खोजें",
  PLEASE_ENTER_YOUR_EMAIL_PHONE_NUMBER: "कृपया अपना ईमेल / फोन नंबर दर्ज करें!",
  PLEASE_ENTER_YOUR_PASSWORD: "अपना पासवर्ड दर्ज करें!",
  PLEASE_SELECT_USER_TYPE: "कृपया उपयोगकर्ता प्रकार का चयन करें",
  JOB_SEEKER: "नौकरी खोजने वाला",
  RECRUITER: "भर्तीकर्ता",
  LOGIN: "लॉग इन करें",
  LOGIN_INSTRUCTION_LABEL_MSG:
    "अपने खाते में लॉगिन करने के लिए अपना सत्यापित ईमेल / फ़ोन नंबर दर्ज करें",
  FORGOT_PASSWORD: "पासवर्ड भूल गए?",
  LOGIN_AGREE_LABEL_MSG: '"लॉगिन" पर क्लिक करके, आप हमारे साथ सहमत हैं ',
  TERMS: "शर्तें। ",
  LOGIN_PRIVACY_COOKIES_POLICY_LABEL_MSG:
    " जानें कि हम आपके डेटा को अपने में कैसे संसाधित करते हैं ",
  PRIVACY_POLICY: "गोपनीयता नीति.",
  LOGIN_WITH: "के साथ लॉगिन करें",
  OR_LOGIN_WITH: "या लॉगिन के साथ",
  DONT_HAVE_AN_ACCOUNT: "खाता नहीं है?",
  REGISTER: "रजिस्टर करें",
  PLEASE_ENTER_CORRECT_OTP: "कृपया सही ओटीपी दर्ज करें!",
  DIDNT_RECEIVE_CODE: "कोड प्राप्त नहीं किया?",
  RESEND: "पुन: भेजें",
  PLEASE_ENTER_YOUR_NAME: "अपना नाम दर्ज करें।",
  PLEASE_ENTER_YOUR_EMAIL: "अपना ईमेल दर्ज करें।",
  PLEASE_ENTER_VALID_EMAIL_ADDRESS: "कृपया सही ईमेल पता दें!",
  PLEASE_ENTER_YOUR_MOBILE_NUMBER: "अपना मोबाइल नंबर दर्ज करें।",
  PLEASE_ENTER_YOUR_CITY: "कृपया अपना शहर दर्ज करें।",
  PLEASE_ENTER_YOUR_COUNTRY: "अपना मोबाइल नंबर दर्ज करें।",
  PLEASE_SELECT_YOUR_DATE_OF_BIRTH: "कृपया अपनी जन्मतिथि का चयन करें!",
  PLEASE_DESCRIBE_YOUR_SELF: "कृपया अपने आप का वर्णन करें!",
  PLEASE_ENTER_YOUR_PASSWORD: "अपना पासवर्ड दर्ज करें।",
  VALID_PASSWORD_MSG:
    "पासवर्ड कम से कम एक अपरकेस, लोअरकेस, संख्यात्मक और विशेष चरित्र का मिश्रण होना चाहिए।",
  PLEASE_ENTER_YOUR_CONFIRM_PASSWORD: "कृपया अपनी पुष्टि पासवर्ड दर्ज करें।",
  PASSWORD_DO_NOT_MATCH: "पासवर्ड नही मिल रहा!",
  UPLOAD_PROFILE_PICTURE: "प्रोफ़ाइल चित्र अपलोड करें",
  UPLOAD_FROM_STORAGE: "भंडारण से अपलोड",
  CAMERA: "कैमरा",
  CONFIRM: "पुष्टि करना",
  PLEASE_ENTER_YOUR_DEPARTMENT: "कृपया अपना विभाग दर्ज करें!",
  PLEASE_SELECT_YOUR_ROLE: "कृपया अपनी भूमिका का चयन करें!",
  PLEASE_ENTER_YOUR_COMPANY_NAME: "कृपया अपनी कंपनी का नाम दर्ज करें!",
  PLEASE_SELECT_YOUR_COMPANY_SIZE: "कृपया अपनी कंपनी के आकार का चयन करें!",
  PLEASE_ENTER_YOUR_VAT_COMPANY_REGISTRATION_NUMBER:
    "कृपया अपनी वैट / कंपनी पंजीकरण संख्या दर्ज करें!",
  PLEASE_ADD_COMPANY_LINKEDIN_PROFILE: "कृपया कंपनी लिंक्डइन प्रोफाइल जोड़ें!",
  SUCCESSFULLY_REGISTER: "सफलतापूर्वक पंजीकरण करें।",
  PLEASE_SELECT_INDUSTRY: "कृपया उद्योग का चयन करें",
  DEPARTMENT: "विभाग",
  PLEASE_SELECT_SIZE: "कृपया आकार का चयन करें",
  VAT_COMPANY_REGISTRATION_NUMBER: "वैट / कंपनी पंजीकरण संख्या",
  ADD_COMPANY_LINKEDIN_PROFILE: "कंपनी लिंक्डइन प्रोफाइल जोड़ें",
  YOUR_PASSWORD_HAS_BEEN_CHANGED_SUCCESSFULLY:
    "आपका पासवर्ड सफलतापूर्वक बदल दिया गया है!",
  PLEASE_ENTER_CURRENT_PASSWORD: "कृपया वर्तमान पासवर्ड दर्ज करें।",
  PLEASE_ENTER_NEW_PASSWORD: "कृपया नया पासवर्ड दर्ज करें।",
  PLEASE_ENTER_CONFIRM_NEW_PASSWORD: "कृपया नया पासवर्ड की पुष्टि करें।",
  CURRENT_PASSWORD_AND_NEW_PASSWORD_SAME: "वर्तमान पासवर्ड और नया पासवर्ड वही!",
  PASSWORD_DOES_NOT_MATCH: "पासवर्ड मैच नहीं कर रहा है!",
  ENTER_CURRENT_PASSWORD: "वर्तमान पासवर्ड दर्ज करो",
  ENTER_NEW_PASSWORD: "नया पासवर्ड दर्ज करें",
  CONFIRM_NEW_PASSWORD: "नए पासवर्ड की पुष्टि करें",
  SUBMIT: "प्रस्तुत",
  PLEASE_ADD_YOUR__PHOTO: "कृपया अपनी तस्वीर जोड़ें!",
  PLEASE_ENTER_ABOUT_COMPANY: "कृपया कंपनी के बारे में दर्ज करें!",
  PLEASE_ENTER_INDUSTRY_NAME: "कृपया उद्योग का नाम दर्ज करें!",
  COPY_JOB_AD: "कॉपी जॉब विज्ञापन",
  COMPANY_INFO: "कंपनी की जानकारी",
  ABOUT_THE_COMPANY: "कंपनी के बारे में",
  VIDEO_LENGTH_SHOULD_NOT_BE_MORE_THAN_45_SEC:
    "वीडियो की लंबाई 45 सेकंड से अधिक नहीं होनी चाहिए।",
  PLEASE_ENTER_JOB_DESCRIPTION: "कृपया नौकरी विवरण दर्ज करें!",
  PLEASE_SELECT_JOB_TYPE: "कृपया नौकरी का प्रकार चुनें!",
  PLEASE_SELECT_REQUIRE_EXPERIENCE: "कृपया अनुभव की आवश्यकता है!",
  PLEASE_SELECT_WORK_LOCATION: "कृपया कार्य स्थान का चयन करें!",
  PLEASE_SELECT_LANGUAGE_REQUIRE: "कृपया भाषा की आवश्यकता का चयन करें!",
  PLEASE_SELECT_LANGUAGE_FOR_JOB_POSTING:
    "कृपया नौकरी पोस्टिंग के लिए भाषा का चयन करें!",
  PLEASE_ADD_DURATION_OF_ADV: "कृपया एड की अवधि जोड़ें!",
  JOB_INFO: "नौकरी की जानकारी",
  ADD_VIDEO: "वीडियो जोड़ें",
  PART_TIME: "पार्ट टाईम",
  FULL_TIME: "पूरा समय",
  EXPERIENCE_LEVEL: "अनुभव स्तर",
  LIST_OF_LANGUAGES: "भाषाओं की सूची",
  JOB_POSTING_LANGUAGE: "नौकरी पोस्टिंग भाषा",
  AD_DURATION: "विज्ञापन अवधि",
  JOB_COPIED_SUCCESSFULLY: "नौकरी सफलतापूर्वक कॉपी की गई।",
  PREREQUISITES: "पूर्वापेक्षाएँ",
  ADD_SKILLS: "कौशल जोड़ें",
  ADD_TOOLS: "उपकरण जोड़ें",
  CREATE_JOB_AD: "नौकरी विज्ञापन बनाएँ",
  JOB_CREATED_SUCCESSFULLY: "नौकरी सफलतापूर्वक बनाई गई",
  OPPS_NO_JOBS_FOUND: "Opps! कोई नौकरी नहीं मिली!",
  OPPS_NO_PROFILES_FOUND: "Opps! कोई प्रोफ़ाइल नहीं मिला!",
  LOADING: "लोड हो रहा है...",
  THIS_IS_TEMPORARY_I_WILL_BE_BACK: "यह अस्थायी है, मैं वापस आऊंगा।",
  I_GET_TOO_MANY_NOTIFICATIONS: "मुझे बहुत अधिक सूचनाएं मिलती हैं।",
  I_SPEND_TOO_MUCH_TIME_IN_PROW: "मैं प्रो में बहुत अधिक समय बिताता हूं।",
  MY_ACCOUNT_WAS_HACKED: "मेरा खाता हैक किया गया।",
  I_DIDNT_FIND_PROW_USEFUL: "मुझे फॉरो उपयोगी नहीं मिला।",
  I_HAVE_A_PRIVATE_CONCERN: "मुझे एक निजी चिंता है।",
  DELETE_ACCOUNT: "खाता हटा दो",
  SELECT_A_REASON_FOR_DELETING_THE_ACCOUNT: "खाता हटाने का एक कारण चुनें",
  OTHER: "अन्य",
  DELETE_YOUR_ACCOUNT_DO_YOU_WISH_TO_CONTINUE:
    "एक बार जब आप अपना खाता हटाते हैं, तो आपके सभी डेटा खो जाएंगे और अब आप अपने खाते तक पहुंचने में सक्षम नहीं होंगे। क्या आप जारी रखना चाहते हैं?",
  NO: "ना",
  YES: "हां",
  YOUR_ACCOUNT_HAS_BEEN_DELETED_SUCCESSFULLY_CLICK_CONTINUE_TO_LOGOUT:
    "आपका खाता सफलतापूर्वक हटा दिया गया है! लॉगआउट के लिए जारी रखें पर क्लिक करें।",
  EDIT_JOB_AD: "नौकरी विज्ञापन संपादित करें",
  JOB_UPDATED_SUCCESSFULLY: "नौकरी सफलतापूर्वक अद्यतन",
  SEARCH: "खोज...",
  SAVED_PROFILES: "सहेजे गए प्रोफाइल",
  MATCHED_PROFILES: "मिलान प्रोफाइल",
  OPEN: "खुला हुआ",
  PAUSED: "रोके गए",
  PAUSE: "रोकें",
  CLOSED: "बंद किया हुआ",
  CLOSE: "बंद करे",
  RESUME: "फिर शुरू करना",
  JOB_ADS: "नौकरी के विज्ञापन",
  REQUIRED_LANGUAGE: "आवश्यक भाषा",
  NO_VIDEO_FOUND: "कोई वीडियो नहीं मिला!",
  ARE_YOU_SURE_WANT_TO_MAKE_DUPLICATE_JOB:
    "क्या आप निश्चित रूप से डुप्लिकेट बनाना चाहते हैं?, कृपया ध्यान दें कि यह क्रिया इस नौकरी का डुप्लिकेट बनाएगी।",
  NO_PROFILES_SAVED: "कोई प्रोफ़ाइल सहेजी नहीं गई।",
  ABOUT_THE_JOB: "नौकरी के बारे में",
  AD_DETAILS: "विज्ञापन विवरण",
  ARE_YOU_SURE_WANT_TO_LOGOUT: "क्या आप निश्चित रूप से लॉगआउट करना चाहते हैं?",
  SELECT_YEAR: "वर्ष का चयन करें",
  PLEASE_ENTER_INDUSTRY_PREFERENCE: "कृपया उद्योग की प्राथमिकता दर्ज करें!",
  PLEASE_ENTER_ROLE_PREFERENCE: "कृपया भूमिका वरीयता दर्ज करें!",
  PLEASE_ENTER_JOB_TYPE_PREFERENCE: "कृपया नौकरी प्रकार की वरीयता दर्ज करें!",
  PLEASE_SELECT_YOUR_PREFERENCE_COUNTRY: "कृपया अपने वरीयता देश का चयन करें!",
  PLEASE_SELECT_YOUR_PREFERENCE_CITY: "कृपया अपनी प्राथमिकता शहर का चयन करें!",
  PLEASE_SELECT_MONTHLY_SALARY_PREFERENCE:
    "कृपया मासिक वेतन वरीयता का चयन करें!",
  INDUSTRIES: "उद्योग",
  PERCENTAGE: "प्रतिशत",
  PLEASE_SELECT_PERCENTAGE: "कृपया प्रतिशत का चयन करें",
  PLEASE_ADD_YOUR_CURRENT_EXPERIENCE_LEVEL:
    "कृपया अपना वर्तमान अनुभव स्तर जोड़ें!",
  PLEASE_ADD_YOUR_CUURENT_DESIGNATION: "कृपया अपना वर्तमान पदनाम जोड़ें!",
  PLEASE_SELECT_YOUR_SKILLS: "कृपया अपने कौशल का चयन करें",
  PLEASE_SELECT_YOUR_TOOLS: "कृपया अपने उपकरण का चयन करें",
  DO_YOU_HAVE_DRIVING_LICENSE: "क्या आपके पास ड्राइविंग लाइसेंस है",
  CURRENT_EXPERIENCE_LEVEL: "वर्तमान अनुभव स्तर",
  PHONE_NUMBER_VERIFIED_SUCESSFULLY: "फोन नंबर ने सफलतापूर्वक सत्यापित किया",
  EMAIL_VERIFIED_SUCESSFULLY: "ईमेल ने सफलतापूर्वक सत्यापित किया",
  PLEASE_ENTER_PHONE_NUMBER: "कृपया फोन नंबर दर्ज करें!",
  OTP_VERIFICATION: "ओटीपी सत्यापन",
  PLEASE_ENTER_THE_4_DIGIT_CODE_SENT_TO: "कृपया 4 अंकों का कोड दर्ज करें",
  YOU_NEED_TO_VERIFIY_YOUR_EMAIL_PLEASE_VERIFIY:
    "आपको अपने ईमेल को सत्यापित करने की आवश्यकता है, कृपया सत्यापित करें",
  OK: "ठीक",
  YOU_ARE_ALREADY_USING_THIS_EMAIL_NO_NEED_OF_VERIFICATION:
    "आप पहले से ही इस ईमेल का उपयोग कर रहे हैं, सत्यापन की कोई आवश्यकता नहीं है",
  YOU_NEED_TO_VERIFIY_YOUR_PHONE_NUMBER_PLEASE_VERIFIY:
    "आपको अपने फोन नंबर को सत्यापित करने की आवश्यकता है, कृपया सत्यापित करें",
  YOU_ARE_ALREADY_USING_THIS_PHONE_NUMBER_NO_NEED_OF_VERIFICATION:
    "आप पहले से ही इस फोन नंबर का उपयोग कर रहे हैं, सत्यापन की कोई आवश्यकता नहीं है",
  PLEASE_ENTER_INDUSTRY: "कृपया उद्योग दर्ज करें!",
  UPDATE_COMPANY_INFO: "अद्यतन कंपनी की जानकारी",
  PRESENT: "वर्तमान",
  ARE_YOU_ACTIVELY_HIRING: "क्या आप सक्रिय रूप से भर्ती कर रहे हैं?",
  ACTIVELY_HIRING_FOR: "सक्रिय रूप से काम पर रखने",
  ACTIVELY_LOOKING_FOR: "सक्रिय रूप से नौकरी की तलाश",
  MATCHES: "मैच",
  CREATE_NEW_JOB_AD: "नया नौकरी विज्ञापन बनाएं",
  SEE_ALL_ADS: "सभी विज्ञापन देखें",
  BASIC_INFO: "बुनियादी जानकारी",
  ARE_YOU_LOOKING_FOR_A_JOB: "क्या आप नौकरी की तलाश में हैं?",
  TUTORIALS: "ट्यूटोरियल",
  SKIP: "छोड़ें",
  TOOLBAR: "टूलबार",
  TYPE_HERE: "यहाँ टाइप करें",
  GET_STARTED: "शुरुआत करें",
  PLEASE_ADD_YOUR_LINKEDIN_PROFILE: "कृपया अपनी लिंक्डइन प्रोफ़ाइल जोड़ें",
  PLEASE_SELECT_DID_YOU_HAVE_DRIVING_LICENSE:
    "कृपया चुनें कि आपके पास ड्राइविंग लाइसेंस था!",
  SOMTHING_WENT_TO_WRONG: "नौकरी के साथ मैच",
  MATCH_WITH_JOB: "नौकरी के साथ मैच",
  TOOLS_REQUIRED: "उपकरण की आवश्यकता",
  DRIVING_LICENSE: "ड्राइविंग लाइसेंस",
  PROFILE: "प्रोफ़ाइल",
  ADVANCED_SKILLS: "उन्नत कौशल",
  UPLOAD_VIDEO: "विडियो को अॅॅपलोड करें",
  YOUR_PREFERRED_INDUSTRY: "अपने पसंदीदा उद्योग का चयन करें",
  LOOKING_FOR_A_JOB: "एक नौकरी के लिए देख रहे हैं?",
  FILTERS: "फ़िल्टर",
  PLAY_INTRO_VIDEO: "परिचय वीडियो चलाएं",
  WORKPLACE: "कार्यस्थल",
  APPLY: "लागू",
  MENU: "मेन्यू",
  NOTIFICATIONS: "सूचनाएं",
  SEE_ALL: "सभी देखें",
  SEND: "भेजें",
  HIDE_STATUS_FOR_RECRUITERS: "भर्ती करने वालों के लिए स्थिति छुपाएं",
  HOME: "घर",
  CHAT: "बातचीत",
  NOT_INTERESTED: "रुचि नहीं",
  BLOCK_COMPANY: "ब्लॉक कंपनी",
  EXPLORE: "अन्वेषण करना",
  MATCHING_JOBS: "मैचिंग जॉब",
  MATCHING_PERCENTAGE: "मिलान प्रतिशत",
  MATCH: "मिलान",
  LOOKING_TO_HIRE_PEOPLE: "लोगों को किराए पर लेना चाहते हैं?",
  SELECT_YOUR_PREFERRED_ROLE: "अपनी पसंदीदा भूमिका का चयन करें ",
  ACTIVELY_HIRING: "सक्रिय रूप से भर्ती",
  COMPANY: "कंपनी",
  LOCATION: "स्थान",
  COMPANY_SIZE: "संग का आकार",
  COMPANY_LINKEDIN_PROFILE: "कंपनी लिंक्डइन प्रोफाइल",
  JOBS: "नौकरियां",
  EDIT_BASIC_INFO: "मूल जानकारी संपादित करें",
  EDIT_COMPANY_INFO: "कंपनी की जानकारी संपादित करें",
  BLOCK: "खंड",
  NO_OF_JOB_SAVES: "नौकरी बचाने की संख्या",
  OUR_PLANS: "हमारी योजनाएँ",
  FEEDBACK_AND_SUGGESTIONS: "प्रतिक्रिया और सुझाव",
  ACCOUNT: "खाता",
  SEARCH_HERE: "यहाँ ढूँढे",
  TOP_MATCHES: " शीर्ष मैच",
  RECENT_CHATS: "हाल की बातचीत",
  BLOCK_COMPANY_LABEL_MSG:
    "एक बार जब आप एक कंपनी को अवरुद्ध कर लेंगे तो आप नौकरी विज्ञापन के पोस्ट को देखने में सक्षम नहीं होंगे उन्हें।",
  BLOCKED_COMPANY_LIST: "अवरुद्ध कंपनी सूची",
  ACTION: "Action",
  UNBLOCK: "अनवरोधित",
  RECRUITER_INFO: "भर्ती जानकारी",
  TYPE_NAME_HERE: "यहां का नाम",
  COMPANY_BLOCKED_SUCESSFULLY: "कंपनी ने सफलतापूर्वक अवरुद्ध किया",
  PLEASE_ENTER_YOUR_MESSAGE: "कृपया अपना संदेश लिखें!",
  MEASSAGE_SENT_SUCCESSFULLY: "Meassage सफलतापूर्वक भेजा गया",
  PLEASE_CREATE_JOB_FIRST: "कृपया पहले नौकरी बनाएं!",
  MATCHED_JOBS_NOT_FOUND: "मिलान वाली नौकरियां नहीं मिलीं!",
  MATCHED_PROFILES_NOT_FOUND: "मिलान प्रोफाइल नहीं मिला!",
  NO_NEW_RECORDS_FOUND: "कोई नया रिकॉर्ड नहीं मिला",
  PLEASE_SELECT_MATCH_FOR: "कृपया मैच का चयन करें",
  PLEASE_ENTER_YOUR_MOBILE_NUMBER_OR_EMAIL:
    "कृपया अपना मोबाइल नंबर या ईमेल दर्ज करें",
  ENTER_YOUR_MOBILE_NUMBER_OR_EMAIL_ID_TO_CONTINUE:
    "जारी रखने के लिए अपना मोबाइल नंबर या ईमेल आईडी दर्ज करें",
  MOBILE_NUMBER_OR_EMAIL_ID: "मोबाइल नंबर या ईमेल आईडी",
  EMAIL_OR_PHONE: "ईमेल या फोन",
  ENTER_THE_CODE_SENT_YOUR_REGISTERED_MOBILE_NUMBER_EMAIL_ID_TO_CONTINUE:
    "अपने पंजीकृत मोबाइल नंबर / ईमेल आईडी को जारी रखने के लिए कोड दर्ज करें",
  AD_ID: "विज्ञापन आईडी",
  VALID_UNTIL: "तब तक वैध",
  COMPANY_INFO: "कंपनी की जानकारी",
  ARE_YOU_SURE_YOU_WANT_TO: " क्या आप निस्चय ही यह कार्य करना चाहते हैं",
  THE_JOB: "काम?",
  SELECT_JOB: "नौकरी का चयन करें",
  NO_RECORDS_FOUND: "कोई रिकॉर्ड नहीं मिला",
  NO_RECENT_SEARCHES: "कोई हाल की खोज नहीं",
  REJECT: "अस्वीकार करें",
  ACCEPT: "स्वीकार करें",
  EDIT_EDUCATION_INFO: "शिक्षा जानकारी संपादित करें",
  EDIT_EXPRIENCE_INFO: "अवधि की जानकारी संपादित करें",
  EDIT_ADVANCED_SKILLS: "उन्नत कौशल संपादित करें",
  EDIT_PREFERENCES: "वरीयताएँ संपादित करें",
  PREV: "पिछला",
  NEXT: "अगला",
  ADD_JOB_INFO: "नौकरी की जानकारी जोड़ें",
  WELCOME_LABEL: "इसमें आपका स्वागत है",
  PRODUCT_INTRO:
    "नौकरी चाहने वालों और भर्ती करने वालों के लिए डिज़ाइन किया गया। अपने काम पर रखने के निर्णय तेजी से, आसान करें हम आपको नौकरी और प्रोफ़ाइल का सटीक मिलान प्रतिशत बताते हैं।",
  PH_EMAIL_PHONE_NUMBER: "ईमेल/फ़ोन नंबर",
  PASSWORD: "पासवर्ड",
  ERROR_MSG_INVALID_CREDENTIALS: "अमान्य क्रेडेंशियल",
  REGISTER_AS: "इस रूप में पंजीकृत करें",
  CONFIRM_PASSWORD: "पासवर्ड की पुष्टि करें",
  REGISTER_INTO: "प्लेटफ़ॉर्म में पंजीकरण करने के लिए विवरण दर्ज करें",
  ALERT_OTP_NOT_VERIFIED: "OTP सत्यापित नहीं है",
  ALREADY_HAVE_A_ACCOUNT_LABLE: "पहले से खाता है?",
  SETUP_YOUR_PROFILE: "अपना प्रोफ़ाइल सेटअप करने के लिए विवरण दर्ज करें",
  ROLE: "भूमिका",
  ADD_YOUR_COMPANY_LINKEDIN_PROFILE: "अपनी कंपनी लिंक्डइन प्रोफ़ाइल जोड़ें",
  FORGOT_PASSWORD_HEADING: "पासवर्ड भूल गए",
  DATA_UPDATE_SUCCESSFULLY: "डेटा अपडेट सफलतापूर्वक",
  PLEASE_SELECT_JOB_TITLE: "कृपया कार्य शीर्षक चुनें",
  SELECT_EXPERIENCE: "अनुभव चुनें",
  SELECT_COUNTRY: "देश चुनें",
  SELECT_CITY: "शहर चुनें",
  SELECT_START_YEAR: "प्रारंभ वर्ष चुनें",
  SELECT_END_YEAR: "समाप्ति वर्ष चुनें",
  DELETE: "हटाएं",
  EDIT: "संपादित करें",
  EDIT_EXPERIENCE: "अनुभव संपादित करें",
  COMPANY_UNBLOCK_SUCESSFULLY: "कंपनी सफलतापूर्वक अनब्लॉक करें",
  ADD_EXPERIENCE: "अनुभव जोड़ें",
  SETUP_YOUR_PROFILE: "अपना प्रोफाइल सेटअप करें",
  INTERMEDIATE: "इंटरमीडिएट",
  DESIGNATION: "पदनाम",
  ROLES: "भूमिकाएं",
  ENTER_FULL_NAME: "पूरा नाम दर्ज करें",
  ENTER_EMAIL: "ईमेल दर्ज करें",
  ENTER_DATE_OF_BIRTH: "जन्म तिथि दर्ज करें",
  ENTER_PHONE_NUMBER: "फ़ोन नंबर दर्ज करें",
  SELECT_COUNTRY_CODE: "देश कोड चुनें",
  ENTER_CITY: "शहर दर्ज करें",
  ENTER_ABOUT_YOU: "अपने बारे में दर्ज करें",
  ENTER_COUNTRY: "देश दर्ज करें",
  JOB_DESCRIPTION: "नौकरी विवरण",
  REQUIRED_EXPERIENCE: "आवश्यक अनुभव",
  ADD_PREFERENCES: "वरीयताएँ जोड़ें",
  LANGAUGES: "भाषाएँ",
  SELECT_SALARY: "वेतन चुनें",
  SELECT: "चुनें",
  ENTER_COMPANY_COUNTRY: "कंपनी देश दर्ज करें",
  ENTER_YOUR_JOB_TITLE: "अपना कार्य शीर्षक दर्ज करें",
  ENTER_COMPANY_SIZE: "कंपनी का आकार दर्ज करें",
  ENTER_COMPANY_CITY: "कंपनी शहर दर्ज करें",
  ENTER_DEPARTMENT: "विभाग दर्ज करें",
  ENTER_INDUSTRY: "उद्योग में प्रवेश करें",
  SELECT_JOB_TITLE: "नौकरी का शीर्षक चुनें",
  SELECT_EDUCATION_TYPE: "शिक्षा प्रकार चुनें",
  ENTER_INTITUTE_NAME: "इंस्टीट्यूट का नाम दर्ज करें",
  ENTER_AREA_OF_STUDY: "अध्ययन का क्षेत्र दर्ज करें",
  ENTER_YEAR_OF_GRADUATION: "स्नातक वर्ष दर्ज करें",
  EDIT_EDUCATION: "शिक्षा संपादित करें",
  INTERESTED: "इच्छुक",
  SKILLS_REQUIRED: "कौशल आवश्यक",
  MATCH_PERCENTAGE: "मैच प्रतिशत",
  VIDEO_RECORD_TIME_MSG: "वीडियो केवल 45 सेकंड के लिए रिकॉर्ड किया जाएगा",
  START_RECORDING: "रिकॉर्डिंग प्रारंभ करें",
  STOP_RECORDING: "रिकॉर्डिंग बंद करो",
  ENTER_SUBJECT: "विषय दर्ज करें",
  ENTER_MESSAGE: "संदेश दर्ज करें",
  MESSAGE_SENT_SUCCESSFULLY: "संदेश सफलतापूर्वक भेजा गया",
  JOB_SAVED_SUCCESSFULLY: "नौकरी सफलतापूर्वक सहेजी गई",
  JOB_NOT_SAVED_SUCCESSFULLY: "नौकरी सफलतापूर्वक सहेजी नहीं गई",
  SELECT_AN_ITEM: "एक आइटम चुनें",
  ERROR_TRY_AFTER_SOMETIME: "कुछ त्रुटि हुई। कृपया कुछ समय बाद प्रयास करें",
  REASON_NEEDED_PROCEED: "आगे बढ़ने के लिए कारण आवश्यक है",
  S_No: "S.No",
  PERSONAL_BANKED_TO_LOGIN:
    "लॉगिन करने के लिए अपना व्यक्तिगत बैंकिंग दर्ज करें।",
  VALID_DIGIT_OTP: "कृपया 4 अंकों का एक मान्य ओटीपी दर्ज करें",
  GO_BACK: "वापस जाओ",
  SETUP_CAREER_PROFILE: "अपना अनुभव जोड़कर अपना करियर प्रोफ़ाइल सेट करें",
  ADD_EDUCATION_LEVELS: "शिक्षा स्तर जोड़ें",
  SELECT_EDUCATION_TYPE: "शिक्षा प्रकार चुनें",
  ENTER_EMAIL_IN_CURRECT_FORMAT: "ईमेल को सही प्रारूप में दर्ज करें",
  ENTER_PASSWORD: "पासवर्ड दर्ज करें",
  PASSWORDS_DOES_NOT_MATCH: "पासवर्ड मेल नहीं खाता",
  COOKIES_POLICY: "कुकी नीति",
  PERSONAL_ID: "व्यक्तिगत आईडी",
  UPLOAD_PICTURE: "चित्र अपलोड करें",
  ADD_EDUCATION: "शिक्षा जोड़ें",
  CHECKOUT_MORE_PROFILES:"एक्सप्लोर अनुभाग पर अधिक प्रोफ़ाइल चेकआउट करें",
};
