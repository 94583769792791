import Welcome from "../src/pages/Welcome";
import Login from "./pages/login";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import Notification from "./pages/Job_Seeker/Notification";
import { Routes, Route } from "react-router-dom";
import Otp_register from "./pages/Otp_register";
import Bankid from "./pages/Bankid";
// import Header from './pages/component/Header';
import Explore from "./pages/Job_Seeker/Explore";
import Chat from "./pages/Job_Seeker/Chat";
import Seeker_Profile from "./pages/Job_Seeker/Seeker_profile/Seeker_Profile";
import Seeker_Home from "./pages/Job_Seeker/Seeker_Home";
import Recruiter_Home from "./pages/Recruiter/Recruiter_Home";
import RecruiterExplore from "./pages/Recruiter/RecruiterExplore";
import RecuiterProfile from "./pages/Recruiter/RecruiterProfile";
import RecruiterExploreJobs from "./pages/Recruiter/RecruiterExploreJobs";
import RecruiterJobDetails from "./pages/Recruiter/RecruiterJobDetails";
import RecruiterAdListing from "./pages/Recruiter/RecruiterAdListing";
import CreateJob from "./pages/Recruiter/CreateJob";
import CreateJobInfo from "./pages/Recruiter/CreateJobInfo";
import CreateJobAd from "./pages/Recruiter/CreateJobAd";
import RecruiterChat from "./pages/Recruiter/RecruiterChat";
import Recuiter_Home from "../src/pages/Recruiter/Recruiter_Home";
import Explore_Jobs from "./pages/Job_Seeker/Explore_Jobs";
import Edit_Basic_Info from "./pages/Job_Seeker/Seeker_profile/Edit_Basic_Info";
import Edit_Advanced_Skills from "./pages/Job_Seeker/Seeker_profile/Edit_Advanced_Skills";
import Edit_Education from "./pages/Job_Seeker/Seeker_profile/Edit_Education";
import Match_Job from "./pages/Job_Seeker/Seeker_profile/Match_Job";
import Job_Details from "./pages/Job_Seeker/Seeker_profile/Job_Details";
import Setting from "./pages/Job_Seeker/Setting/Setting";
import Setting_BlockUser from "./pages/Job_Seeker/Setting/Setting_BlockUser";
import Setting_ChangePass from "./pages/Job_Seeker/Setting/Setting_ChangePass";
import Pass_Suceess from "./pages/Job_Seeker/Setting/Pass_Suceess";
import Subscription_Premium from "./pages/Job_Seeker/Setting/Subscription_Premium";
import Delete_Profile from "./pages/Job_Seeker/Setting/Delete_Profile";
import Faq from "./pages/Job_Seeker/Faq";
import Contact_Us from "./pages/Job_Seeker/Contact_Us";
import Legal from "./pages/Job_Seeker/Legal";
import RecruiterNotification from "./pages/Recruiter/RecruiterNotification";
import RecruiterFaq from "./pages/Recruiter/RecruiterFaq";
import RecruiterContactUs from "./pages/Recruiter/RecruiterContactUs";
import RecruiterSetting from "./pages/Recruiter/RecruiterSetting/Setting";
import RecruiterSetting_BlockUser from "./pages/Recruiter/RecruiterSetting/Setting_BlockUser";
import RecruiterSetting_ChangePass from "./pages/Recruiter/RecruiterSetting/Setting_ChangePass";
import RecruiterPass_Suceess from "./pages/Recruiter/RecruiterSetting/Pass_Suceess";
import RecruiterSubscription_Premium from "./pages/Recruiter/RecruiterSetting/Subscription_Premium";
import RecruiterDelete_Profile from "./pages/Recruiter/RecruiterSetting/Delete_Profile";
import RecruiterLegal from "./pages/Recruiter/RecruiterLegal";
import RecruiterProfile from "./pages/RecruiterProfile";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotPasswordOTP from "./pages/ForgotPasswordOTP";
import ForgotPasswordNew from "./pages/ForgotPasswordNew";
import EditBasicProfileInfo from "./pages/Recruiter/EditBasicProfileInfo";
import EditCompanyInfo from "./pages/Recruiter/EditCompanyInfo";
import CreateJobNew from "./pages/Recruiter/createJob/CreateJobNew";
import Feedback from "./pages/Job_Seeker/Seeker_profile/Feedback";
import Recruiter_Feedback from "./pages/Recruiter/Recruiter_Feedback";
import RecruiterMatchingProfilesList from "./pages/Recruiter/RecruiterMatchingProfilesList";
import Edit_preferences from "./pages/Job_Seeker/Seeker_profile/Edit_preferences";
import Edit_Experiance from "./pages/Job_Seeker/Seeker_profile/Edit_Experiance";
import Terms from "./sharedComponents/Terms";
import PrivcayPolicy from "./sharedComponents/PrivcayPolicy";
import CookiesPolicy from "./sharedComponents/CookiesPolicy";
import Tutorial from "./sharedComponents/Tutorial";
import TutorialRecruiter from "./sharedComponents/TutorialRecruiter";
import Match_Profile from "./pages/Recruiter/Match_Profile";
import Preference from "./pages/Job_Seeker/Seeker_profile/Preference";
import { LinkedInCallback } from "react-linkedin-login-oauth2";

function App() {
  return (
    <>
      <Routes>
      <Route path="/linkedin" element={<LinkedInCallback/>} />
        <Route path="/" element={<Welcome />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="otp-register" element={<Otp_register />} />
        <Route path="bankid" element={<Bankid />} />
        <Route path="Profile" element={<Profile />} />
        <Route path="RecruiterProfile" element={<RecruiterProfile />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="forgot-password-otp" element={<ForgotPasswordOTP />} />
        <Route path="forgot-password-new" element={<ForgotPasswordNew />} />
        <Route path="Terms" element={<Terms />} />
        <Route path="PrivcayPolicy" element={<PrivcayPolicy />} />
        <Route path="CookiesPolicy" element={<CookiesPolicy />} />
        <Route path="tutorial" element={<Tutorial />} />
        <Route path="tutorial-recruiter" element={<TutorialRecruiter />} />

        {/* for Recuiter Routing */}
        <Route path="Match_Profile" element={<Match_Profile />} />
        <Route path="Seeker_Home" element={<Seeker_Home />} />
        <Route path="Explore" element={<Explore />} />
        <Route path="Explore_Jobs" element={<Explore_Jobs />} />
        <Route path="Chat" element={<Chat />} />
        <Route path="Seeker_Profile" element={<Seeker_Profile />} />
        <Route path="Edit_Basic_Info" element={<Edit_Basic_Info />} />
        <Route path="Edit_Advanced_Skills" element={<Edit_Advanced_Skills />} />
        <Route path="Edit_Experiance" element={<Edit_Experiance />} />
        <Route path="Preference" element={<Preference />} />

        <Route path="Edit_preferences" element={<Edit_preferences />} />

        <Route path="Edit_Education" element={<Edit_Education />} />
        <Route path="Match_Job" element={<Match_Job />} />
        <Route path="Saved_Jobs" element={<Match_Job />} />
        <Route path="Notification" element={<Notification />} />
        <Route path="Job_Details" element={<Job_Details />} />
        <Route path="Setting" element={<Setting />} />
        <Route path="Setting_BlockUser" element={<Setting_BlockUser />} />
        <Route path="Setting_ChangePass" element={<Setting_ChangePass />} />
        <Route path="Pass_Suceess" element={<Pass_Suceess />} />
        <Route path="Subscription" element={<Subscription_Premium />} />
        <Route path="Delete_Profile" element={<Delete_Profile />} />
        <Route path="Faq" element={<Faq />} />
        <Route path="Contact_Us" element={<Contact_Us />} />
        <Route path="Legal" element={<Legal />} />
        <Route path="Feedback" element={<Feedback />} />

        {/* End for Recuiter Routing */}

        {/* for Recuiter Routing */}
        <Route path="Recuiter_Home" element={<Recruiter_Home />} />
        <Route path="recruiter-explore" element={<RecruiterExplore />} />
        <Route
          path="recruiter-explore-jobs"
          element={<RecruiterExploreJobs />}
        />
        <Route path="recruiter-job-details" element={<RecruiterJobDetails />} />
        <Route path="recruiter-profile" element={<RecuiterProfile />} />
        <Route path="recruiter-ad-listing" element={<RecruiterAdListing />} />
        <Route path="create-job" element={<CreateJob />} />
        <Route path="create-job-info" element={<CreateJobInfo />} />
        <Route path="create-job-ad" element={<CreateJobAd />} />
        <Route path="recruiter-chat" element={<RecruiterChat />} />
        <Route
          path="recruiter-notification"
          element={<RecruiterNotification />}
        />
        <Route path="recruiter-faq" element={<RecruiterFaq />} />
        <Route path="recruiter-contact-us" element={<RecruiterContactUs />} />
        <Route path="recruiter-setting" element={<RecruiterSetting />} />
        <Route
          path="recruiter-block-user"
          element={<RecruiterSetting_BlockUser />}
        />
        <Route
          path="recruiter-change-password"
          element={<RecruiterSetting_ChangePass />}
        />
        <Route
          path="recruiter-password-success"
          element={<RecruiterPass_Suceess />}
        />
        <Route
          path="recruiter-subscription"
          element={<RecruiterSubscription_Premium />}
        />
        <Route
          path="recruiter-delete-profile"
          element={<RecruiterDelete_Profile />}
        />
        <Route path="recruiter-legal" element={<RecruiterLegal />} />
        <Route
          path="recruiter-edit-basic-info"
          element={<EditBasicProfileInfo />}
        />
        <Route
          path="recruiter-edit-company-info"
          element={<EditCompanyInfo />}
        />
        <Route path="create-new-job" element={<CreateJobNew />} />
        <Route path="Recruiter_Feedback" element={<Recruiter_Feedback />} />
        <Route
          path="recruiter-matching-profiles"
          element={<RecruiterMatchingProfilesList />}
        />

        <Route
          path="recruiter-saved-profiles"
          element={<RecruiterMatchingProfilesList />}
        />
        {/* End for Recuiter Routing */}

  
      </Routes>
      {/* <Welcome /> */}
    </>
  );
}

export default App;
