import React, { useState, useEffect, useRef } from "react";
import {
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
} from "../../../api/ApiServices.js";
import ApiConfig from "../../../api/ApiConfig";
import { Multiselect } from "multiselect-react-dropdown";
import Select from "react-select";
import listContryCity from "../../../assets/CountrtiesData/CoutrycityList";
import useTranslation from "../../../customHooks/translations.js";
import Country from "../../../assets/CountrtiesData/Country.js";
import { toast } from "react-toastify";
const Preference = () => {
  const translation = useTranslation();
  const [jobRole, setJobRole] = useState([]);
  const [countriesState, setCountriesState] = useState();
  const [citiesState, setCitiesState] = useState();
  const [state, setState] = useState({
    profile_pic: "",
    profile_pic_name: "",
    isProcessing: false,
    looking_for_job: false,
    new_skill: "",
    new_skills_array: [],
    new_tool: "",
    new_tools_array: [],
    new_certificate: "",
    new_certificates_array: [],
    selectedToolsWithLevel: [],
    selectedSkillsWithLevel: [],

  });
  function getCountriesList() {
    setCountriesState(Country.Country);
  }
  const options = [
    { id: 1, value: "Hybrid", label: "Hybrid" },
    { id: 2, value: "Static", label: "Static" },
    { id: 3, value: "Fixed", label: "Fixed" },
    { id: 4, value: "Flexible", label: "Flexible" },
  ]
  const [skillsState, setSkillsState] = useState();
  const [toolsState, setToolsState] = useState();
  const [workplace, setWorkplace] = useState(options);

  const savePreferences = () => {
    // let industries = state.selectedIndustries.map((industry) => {
    //   return { name: industry.name };
    // });
    // let JobRoles = state.selectedJobRole.map((role) => {
    //   return { name: role.name };
    // });
    // let jobTypes = state.selectedJobType.map((jobType) => {
    //   return { name: jobType.name };
    // });
    let data = {
      industries: [],
      job_role: [],
      job_type: [],
      languages: "",
      city: state.selected_City.value,
      country: state.selected_Country.value,
      monthly_salary: state.salary,
      work_location: [],
      match_percentage: [state.percentage],
    };
    postWithAuthCallWithErrorResponse(
      ApiConfig.Add_PREFERENCE_JOB_SEEKER,
      JSON.stringify(data)
    )
      .then((res) => {
        toast.success("Preference Added Successfully", { autoClose: 1000 })
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //START JOB TITLE
  const getJobRole = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.JOBROLE_LISTING_API)
      .then((data) => {
        setJobRole(data.json);
        // parseJobList(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function updateCity(data) {
    let temp_cities = [];
    data.map((item, index) => {
      temp_cities.push({ label: item, value: item });
    });
    setCitiesState(temp_cities);
  }

  // ****TOOLS****
  const getToolsList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.TOOLS_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseToolsList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseToolsList = (data) => {
    let temp_tools = [];
    data.map((item, index) => {
      temp_tools.push({ name: item.name, id: item.id });
    });

    setToolsState(temp_tools);
  };

  function onToolChange(selectedList, selectedItem) {
    let temp_tool = [];
    let temp_selectedToolsWithLevel = [];

    selectedList.map((item, index) => {
      temp_tool.push(item.name);
      temp_selectedToolsWithLevel.push({ tool: item.name, level: "Beginner" })
    });

    setState({ ...state, selectedTools: selectedList, selectedToolsWithLevel: temp_selectedToolsWithLevel });
  }

  function onChangeToolLevel(tool, level) {
    let temp_selectedToolsWithLevel = [];
    state.selectedToolsWithLevel.map((item, index) => {
      temp_selectedToolsWithLevel.push({ tool: item.tool, level: item.tool == tool ? level : item.level })
    });
    setState({ ...state, selectedToolsWithLevel: temp_selectedToolsWithLevel });
  }
  // *** TOOLS ***


  /* skills section starts */
  const getSkillsList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.SKILLS_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseSkillsList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseSkillsList = (data) => {
    let temp_skills = [];
    data.map((item, index) => {
      temp_skills.push({ name: item.name, id: item.id });
    });

    setSkillsState(temp_skills);
  };

  function onSkillChange(selectedList, selectedItem) {
    let temp_skill = [];
    let temp_selectedSkillsWithLevel = [];

    selectedList.map((item, index) => {
      temp_skill.push(item.name);
      temp_selectedSkillsWithLevel.push({ skill: item.name, level: "Beginner" })
    });

    setState({ ...state, selectedSkills: selectedList, selectedSkillsWithLevel: temp_selectedSkillsWithLevel });
  }

  function onChangeSkillLevel(skill, level) {
    let temp_selectedSkillsWithLevel = [];
    state.selectedSkillsWithLevel.map((item, index) => {
      temp_selectedSkillsWithLevel.push({ skill: item.skill, level: item.skill == skill ? level : item.level })
    });
    setState({ ...state, selectedSkillsWithLevel: temp_selectedSkillsWithLevel });
  }
  function addNewSkill(value) {
    let temp_selectedSkillsWithLevel = skillsState;
    temp_selectedSkillsWithLevel.push({ name: value, id: value })
    setSkillsState(temp_selectedSkillsWithLevel);
  }
  /* skills section ends */
  useEffect(() => {
    getJobRole();
    getToolsList();
    getSkillsList();
    getCountriesList();
  }, [])
  function onJobeRoleChange(selectedList, selectedItem) {
    let temp_selectedJobRole = [];

    selectedList.map((item, index) => {
      temp_selectedJobRole.push({ job_certificates: item.name });
    });

    setState({
      ...state,
      selectedJobRole: selectedList,
      jobs: temp_selectedJobRole,
    });
  }
  /* country section starts */
  const selectStyles = {
    control: (provided) => ({ ...provided, backgroundColor: 'transparent', borderRadius: "8px", border: "1px solid #b3c2c9", fontSize: "13px" }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)", backgroundColor: 'transparent' }),
  };

  function onCountryChange(value) {
    setState({ ...state, selected_Country: value, country: value.label });
    getCitiesList(value.label);
  }
  function onCityChange(value) {
    setState({ ...state, selected_City: value, city: value.label });
  }
  //START CITY
  function getCitiesList(selected_country) {
    Object.keys(listContryCity.listContryCity).forEach(function (key, index) {
      var newJson = listContryCity.listContryCity[key];
      if (newJson.country == selected_country) {
        updateCity(newJson.city);
      }
    });
  }
  return (
    <div>
      <div
        className="SearchFilterCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasPreference"
        aria-labelledby="offcanvasSearchFilterLabel"
      >
        <div className="offcanvas-header">
          <h5
            className="offcanvas-title fs-18 fw-600"
            id="offcanvasSearchFilterLabel"
          >
            {translation.PREFERNCES}
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <form className="row g-3 text-start">
            <div className="col-md-6 regsiterrf mb-3">
              <label className="skl_block fw-700 mb-3">{translation.JOB_TITLE}</label>
              <Multiselect
                className="form-select bg-transparent"
                aria-label="Default select example"
                showCheckbox={true} // To show checkbox in the dropdown
                options={jobRole} // Options to display in the dropdown
                selectedValues={state.selectedJobRole} // Preselected value to persist in dropdown
                displayValue="name" // Property name to display in the dropdown options
                onSelect={onJobeRoleChange}
                onRemove={onJobeRoleChange}
              />
            </div>
            <div className="col-md-6 regsiterrf mb-3">
              <label className="skl_block fw-700 mb-3">{translation.LOCATION}</label>
              <Select
                isClearable={false}
                onChange={onCountryChange}
                options={countriesState}
                placeholder="Search..."
                styles={selectStyles}
                //tabSelectsValue={false}
                value={state.selected_Country}
              />
            </div>
            <div className="col-md-6 regsiterrf">
              <label className="skl_block fw-700 mb-3">{translation.CITY}</label>

              <Select
                isClearable={false}
                onChange={onCityChange}
                options={citiesState}
                placeholder="Search..."
                styles={selectStyles}
                //tabSelectsValue={false}
                value={state.selected_City}
              />
            </div>
            <div className="col-md-6 regsiterrf mb-3">
              <label className="skl_block fw-700 mb-3">{translation.MATCH_PERCENTAGE}</label>
              <select
                className="form-select border-primary"
                aria-label="Default select example"
                defaultValue={"49"}
                onChange={(e) => {
                  setState({ ...state, percentage: [e.target.value] });

                }}
              >
                <option selected>Select percentage</option>
                <option value="49">0-49%</option>
                <option value="74">50-74%</option>
                <option value="100">75-100%</option>
              </select>
            </div>
            <div className="col-md-12 regsiterrf mb-3">
              <label className="skl_block fw-700 mb-3">{translation.WORKPLACE}</label>
              <Multiselect
                className="form-select bg-transparent"
                aria-label="Default select example"
                avoidHighlightFirstOption={true}
                // onSearch={addNewSkill}
                showCheckbox={true} // To show checkbox in the dropdown
                options={options} // Options to display in the dropdown
                // Preselected value to persist in dropdown
                displayValue="label" // Property name to display in the dropdown options
                onChange={setWorkplace}
              >
              </Multiselect>
              {/* <select
                className="form-select border-primary"
                aria-label="Default select example"
              >
                <option selected>Hybrid</option>
                <option value="1">Static</option>
                <option value="2">Fixed</option>
                <option value="3">Flexible </option>
              </select> */}
            </div>

            {/* <!-- First Skill --> */}
            <div className="col-md-12 regsiterrf mb-3">
              <label className="skl_block mb-3">{translation.SKILLS}</label>
              <Multiselect
                className="form-select bg-transparent"
                aria-label="Default select example"
                avoidHighlightFirstOption={true}
                onSearch={addNewSkill}
                showCheckbox={true} // To show checkbox in the dropdown
                options={skillsState} // Options to display in the dropdown
                selectedValues={state.selectedSkills} // Preselected value to persist in dropdown
                displayValue="name" // Property name to display in the dropdown options
                onSelect={onSkillChange}
                onRemove={onSkillChange}
              />
              <div className="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                {state.selectedSkillsWithLevel.map((selected_skill, index) => {
                  return (
                    <div className="skill_col me-3" key={index}>
                      <div className="d-flex justify-content-between">
                        <p className="m-0 fs-14">{selected_skill.skill}</p>
                        {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                      </div>
                      <div className="col-md-12 regsiterrf">
                        <select className="form-select fs-14 fw-600 border-primary bg-transparent" value={selected_skill.level} onChange={(e) => onChangeSkillLevel(selected_skill.skill, e.target.value)} aria-label="Default select example">
                          <option value="Beginner">{translation.BEGINNER}</option>
                          <option value="Intermediate">{translation.INTERMEDIATE}</option>
                          <option value="Competent">{translation.PROFICIENT}</option>
                          <option value="Expert">{translation.EXPERT}</option>
                        </select>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* <!-- Second Skill --> */}
            <div className="col-md-12 regsiterrf mb-3">
              <label className="skl_block mb-3">{translation.TOOLS}</label>
              {/* <input
                type="text"
                placeholder="Add Tools"
                className="form-control border border-primary"
              /> */}

              <Multiselect
                className="form-select bg-transparent"
                aria-label="Default select example"
                avoidHighlightFirstOption={true}
                showCheckbox={true} // To show checkbox in the dropdown
                options={toolsState} // Options to display in the dropdown
                selectedValues={state.selectedTools} // Preselected value to persist in dropdown
                displayValue="name" // Property name to display in the dropdown options
                onSelect={onToolChange}
                onRemove={onToolChange}
              />

              <div className="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                {/* <!-- Skill 1 --> */}

                {

                  state.selectedToolsWithLevel.map((selected_tool, index) => {
                    return (
                      <div className="skill_col me-3" key={index}>
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">{selected_tool.tool}</p>
                          {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                        </div>

                        <div className="col-md-12 regsiterrf">
                          <select className="form-select fs-14 fw-600 border-primary bg-transparent" value={selected_tool.level} onChange={(e) => onChangeToolLevel(selected_tool.tool, e.target.value)} aria-label="Default select example">
                            <option value="Beginner">{translation.BEGINNER}</option>
                            <option value="Intermediate">{translation.INTERMEDIATE}</option>
                            <option value="Competent">{translation.PROFICIENT}</option>
                            <option value="Expert">{translation.EXPERT}</option>
                          </select>
                        </div>
                      </div>
                    );
                  })}

              </div>
            </div >

            <div className="col-md-6 regsiterrf mb-3">
              <label className="skl_block fw-700 mb-3">{translation.MONTHLY_SALARY}</label>
              <select
                className="form-select border-primary"
                aria-label="Default select example"
                value={state.salary}
                onChange={(e) => {
                  setState({ ...state, salary: e.target.value });
                }}
              >
                <option selected>$3000 - $5000</option>
                <option value="5000">$3000 - $5000</option>
                <option value="10000">$5000 - $10000</option>
                <option value="15000">$10000 - $15000</option>
              </select>
            </div>
          </form >
        </div >
        <div className="oddcanvas-footer">
          <hr />
          <div className="d-flex justify-content-end">
            <a className="fs-16 fw-600 me-3 pb-3" style={{ cursor: "pointer" }} onClick={savePreferences} >{translation.APPLY}</a>
          </div >
        </div >
      </div >

    </div >
  )
}

export default Preference