import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import RecruiterHeader from "../../../sharedComponents/RecruiterHeader";
import chevron_right from "../../../assets/img/chevron_right_colored.svg";
import chevron_left from "../../../assets/img/chevron_right_colored.svg";
import camera from "../../../assets/img/camera.svg";
import default_profile_pic from "../../../assets/img/profile_pic.svg";
import countryCodes from "country-codes-list"
import moment from "moment";
import { toast } from "react-toastify";
import SpinnerCmp from "../../../sharedComponents/SpinnerCmp";

import {
  multipartPostCallWithErrorResponse,
  getWithAuthCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import ReactTooltip from "react-tooltip";

import Select from "react-select";
import Country from "../../../assets/CountrtiesData/Country";
import listContryCity from "../../../assets/CountrtiesData/CoutrycityList";
import Header from "../../../sharedComponents/Header";
import "react-toastify/dist/ReactToastify.css";
import useTranslation from "../../../customHooks/translations";
import PopoverElement from "../../../sharedComponents/Popover";
toast.configure();

function EditBasicProfileInfo() {
  const translation = useTranslation();
  const [errorMsg, setErrorMsg] = useState({});
  const [state, setState] = useState({
    isProcessing: false,
    errMsg: { full_name: "" },
    full_name: "",
    profile_pic: "",
    city: "",
    country: "",
    email: "",
    phone_number: "",
    country_code: "+91",
    dob: "",
    selected_dob: "",
    about: "",
    user_profile_pic: default_profile_pic,
    profile_pic_name: "",
    TodayDate_yyyy_mm_dd: moment().format("YYYY-MM-DD"),
    selected_Country: {},
    selected_City: {},
  });

  const [countriesState, setCountriesState] = useState();
  const [citiesState, setCitiesState] = useState();
  const [countryCodesArray, setCountryCodesArray] = useState([]);
  const FullName = useRef(null);
  const email = useRef(null);
  const dob = useRef(null);
  const countryCode = useRef(null)
  const phone_number = useRef(null)
  const countryName = useRef(null);
  const city = useRef(null)
  const about = useRef(null)
  const getSeekerDetails = async () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.GET_JOB_SEEKER_PROFILE)
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
        } else {
          var full_name = data.json.user.full_name;
          var profile_pic = data.json.user.profile_pic;
          var city = data.json.user.city;
          var country = data.json.user.country;
          var email = data.json.user.email;
          var phone_number = data.json.user.phone_number;
          var dob = data.json.user.dob;
          var about = data.json.user.about;
          var country_code = data.json.user.country_code;
          var selected_dob = dob.split("-").reverse().join("-");

          setState({
            ...state,
            full_name: full_name,
            city: city,
            country: country,
            selected_Country: { label: country, value: country },
            selected_City: { label: city, value: city },
            email: email,
            phone_number: phone_number,
            dob: dob,
            selected_dob: selected_dob,
            about: about,
            user_profile_pic: profile_pic ? profile_pic : default_profile_pic,
            country_code: country_code,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };
  const notify = () => {
    // Calling toast method by passing string
    toast.success(translation.DATA_UPDATE_SUCCESSFULLY, { autoClose: 1000 });
  };

  const onInputHandle = (e) => {
    const { name, value } = e.target;

    if (name === "phone_number") {
      const re = /^[0-9\b]+$/;
      if (!(e.target.value === "" || re.test(e.target.value))) return false;
    }
    setState({ ...state, [name]: value });
  };
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleImageChange = async (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      /*this.setState({
          image: file,
          imagePreviewUrl: reader.result
      });*/
      setState({
        ...state,
        profile_pic: file,
        profile_pic_name: file.name,
        user_profile_pic: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  const updateProfile = async () => {

    if (
      state.phone_number == "" ||
      state.full_name == "" ||
      state.city == "" ||
      state.country == "" ||
      state.email == "" ||
      state.dob == "" ||
      state.about == "" ||
      state.country_code == ""
    ) {

      if (state.full_name == "") {
        setErrorMsg({ full_name: translation.ENTER_FULL_NAME });
        return;

      } else if (state.email == "") {
        setErrorMsg({ email: translation.ENTER_EMAIL });
        return;

      } else if (state.dob == "") {
        setErrorMsg({ dob: translation.ENTER_DATE_OF_BIRTH });
        return;
      } else if (state.country_code == "") {
        setErrorMsg({ phone_number: translation.SELECT_COUNTRY_CODE });
        return;
      } else if (state.phone_number == "") {
        setErrorMsg({ phone_number: translation.ENTER_PHONE_NUMBER });
        return;
      }
      else if (state.country == "") {
        setErrorMsg({ country: translation.SELECT_COUNTRY });
        return;
      }
      else if (state.city == "") {
        setErrorMsg({ city: translation.SELECT_CITY });
        return;
      }
    } else {
      if (!validateEmail(state.email)) {
        setErrorMsg({ email: translation.ENTER_EMAIL_IN_CURRECT_FORMAT },);
        return;
      }
      let formData = new FormData();
      formData.append("phone_number", state.phone_number);
      formData.append("full_name", state.full_name);
      formData.append("city", state.city);
      formData.append("country", state.country);
      formData.append("email", state.email);
      formData.append("dob", state.dob);
      formData.append("about", state.about);
      formData.append("profile_pic", state.profile_pic);
      formData.append("country_code", state.country_code);
      putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.UPDATE_PROFILE_API,
        formData
      )
        .then((data) => {
          if (data.error == false) {
            notify();
            // window.location.href = "/Seeker_Profile";
          } else {
            setState({ ...state, errorMsg: data });
            toast.error(data.json.message, { autoClose: 1000 });
          }
        })
        .catch((error) => {
          console.log("api response", error);
        });
    }
  };
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  /* city section starts */
  function getCitiesList(selected_country) {
    Object.keys(listContryCity.listContryCity).forEach(function (key, index) {
      var newJson = listContryCity.listContryCity[key];
      if (newJson.country == selected_country) {
        updateCity(newJson.city);
      }
    });
  }

  function updateCity(data) {
    let temp_cities = [];
    data.map((item, index) => {
      temp_cities.push({ label: item, value: item });
    });
    setCitiesState(temp_cities);
  }

  function onCityChange(value) {
    setState({ ...state, selected_City: value, city: value.label });
  }
  /* city section ends */

  /* country section starts */
  const selectStyles = {
    control: (provided) => ({
      ...provided, backgroundColor: 'transparent', borderRadius: "8px", border: "1px solid #b3c2c9", fontSize: "13px"
    }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)", backgroundColor: 'transparent' }),
  };

  function getCountriesList() {
    setCountriesState(Country.Country);
  }

  function onCountryChange(value) {
    setState({ ...state, selected_Country: value, country: value.label });
    getCitiesList(value.label);
  }
  /* country section ends */

  useEffect(() => {
    let codes = countryCodes.all().map(code => {
      return { value: "+" + code.countryCallingCode, label: code.countryNameEn }
    })
    window.onload = function () {
      document.getElementById("FullName") && document.getElementById("FullName").focus();
    }
    setCountryCodesArray(codes);
    getCountriesList();
    getSeekerDetails();
  }, []);
  /* country section ends */
  function onCountrycodesChange(e) {
    setState({ ...state, country_code: e.target.value });
    // let country = countryCodesArray.filter(countrycode => countrycode.value == e.target.value && { label: countrycode, value: countrycode.label })
    // console.log("country ", country);
    // onCountryChange(country)
  }
  return (
    <div>
      <Header />
      <ReactTooltip />

      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/recruiter-profile">{translation.PROFILE}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.EDIT_BASIC_INFO}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <SpinnerCmp loading={state.isProcessing} />
      <section
        id="settings"
        className="settings"
        style={{ display: state.isProcessing ? "none" : "" }}
      >
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div
                className="card login settings_card "
                style={{ marginTop: "5em" }}
              >
                <div className="card-body">
                  <div className="form_block w-100 pt-0">
                    <form
                      className="row g-3 text-start"
                      encType="multipart/form-data"
                    >
                      <div className="col-md-12">
                        <div className="upload_profileimg mb-4">
                          <div className="profle_circle">
                            <div className="profle_upload">
                              <img
                                src={state.user_profile_pic}
                                alt=""
                                style={{
                                  borderRadius: "50%",
                                  width: "94px",
                                  height: "94px",
                                }}
                              />
                              <a
                                href="#"
                                onClick={handleClick}
                                data-tip={translation.UPLOAD_PROFILE_PICTURE}
                              >
                                <img
                                  src={camera}
                                  className="upload-button"
                                  alt=""
                                />
                              </a>
                              <input
                                type="file"
                                ref={hiddenFileInput}
                                style={{ display: "none" }}
                                accept="image/*"
                                id="profilePicFile"
                                onChange={(e) => handleImageChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label htmlFor="full_name" className="form-label fs-14">
                          {translation.FULLNAME}
                        </label>
                        <input
                          ref={FullName}
                          type="text"
                          className="form-control"
                          id="FullName"
                          value={state.full_name}
                          onChange={(e) => {
                            setState({ ...state, full_name: e.target.value });
                            // onInputHandle(e);
                          }}
                        />
                        {
                          errorMsg.full_name &&
                          <PopoverElement references={FullName} msg={errorMsg.full_name} Id={"full_name"} />
                        }
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label htmlFor="email" className="form-label fs-14">
                          {translation.EMAIL}
                        </label>
                        <input
                          ref={email}
                          type="email"
                          className="form-control"
                          id="email"
                          value={state.email}
                          onChange={(e) => {
                            setState({ ...state, email: e.target.value });
                          }}
                        />
                        {errorMsg.email &&
                          <PopoverElement references={email} msg={errorMsg.email} Id={"email"} />
                        }
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label htmlFor="DOB" className="form-label fs-14">
                          {translation.DATE_OF_BIRTH}
                        </label>
                        <input
                          ref={dob}
                          type="date"
                          className="form-control"
                          id="DOB"
                          max={state.TodayDate_yyyy_mm_dd}
                          value={state.selected_dob}
                          onChange={(e) => {
                            setState({
                              ...state,
                              dob: e.target.value,
                              selected_dob: e.target.value,
                            });
                          }}
                        />
                        {
                          errorMsg.dob &&
                          <PopoverElement references={dob} msg={errorMsg.dob} Id={"DOB"} />
                        }
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="phone_number"
                          className="form-label fs-14"
                        >
                          {translation.MOBILE_NUMBER}
                        </label>
                        <div className="input-group country-code-select">
                          <input list="brow" className="form-control" onChange={onCountrycodesChange} style={{ maxWidth: "83px" }} value={state.country_code} />
                          <datalist id="brow" name={"service-city"} style={{ color: "red" }}>
                            {countryCodesArray.map(codes => {
                              return (< option className="d-flex" value={codes.value} label={codes.label} />)
                            })}
                          </datalist>
                          {
                            errorMsg.country_code &&
                            <PopoverElement references={countryCode} msg={errorMsg.country_code} ID={"countryCode"} />
                          }
                          <input
                            ref={phone_number}
                            type="text"
                            className="form-control"
                            id="phone_number"
                            value={state.phone_number}
                            maxLength="10"
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setState({
                                  ...state,
                                  phone_number: e.target.value,
                                });
                              }
                            }}
                          />
                        </div>
                        {
                          errorMsg.phone_number &&
                          <PopoverElement references={phone_number} msg={errorMsg.phone_number} Id={"phone_number"} />
                        }
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label htmlFor="country" className="form-label fs-14">
                          {translation.COUNTRY}
                        </label>

                        <Select
                          ref={countryName}
                          isClearable={false}
                          onChange={onCountryChange}
                          options={countriesState}
                          placeholder={translation.SEARCH}
                          styles={selectStyles}
                          //tabSelectsValue={false}
                          value={state.selected_Country}
                        />
                        {
                          errorMsg.country &&
                          <PopoverElement references={countryName} msg={errorMsg.country} Id={"countryName"} />
                        }
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label htmlFor="city" className="form-label fs-14">
                          {translation.CITY}
                        </label>
                        <Select
                          ref={city}
                          isClearable={false}
                          onChange={onCityChange}
                          options={citiesState}
                          placeholder={translation.SEARCH}
                          styles={selectStyles}
                          //tabSelectsValue={false}
                          value={state.selected_City}
                        />
                        {
                          errorMsg.city &&
                          <PopoverElement references={city} msg={errorMsg.city} Id={"city"} />
                        }
                      </div>

                      <div className="col-md-12 regsiterrf">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.SHORT_DESCRIPTION}
                        </label>
                        <textarea
                          ref={about}
                          className="form-control"
                          id="about"
                          rows="5"
                          style={{ height: "69px" }}
                          value={state.about}
                          onChange={(e) => {
                            setState({ ...state, about: e.target.value });
                          }}
                        ></textarea>
                        {
                          errorMsg.about &&
                          <PopoverElement references={about} msg={errorMsg.city} Id={"about"} />
                        }
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* cancel button */}
              <div
                className="loginwithlinks editprofile_block1 left-btn-basicInfo"
                id="left-btn-basicInfo"
              >
                <div className="d-lg-flex d-md-flex justify-content-end">
                  <div className="loginwithbtn cancel">
                    <p className="fs-22 fw-600 text-primary">
                      {translation.CANCEL + " "}
                      <Link to="/Seeker_Profile">
                        <button
                          type="button"
                          className="btn btn-outline-primary rounded-circle ms-2"
                        >
                          <img src={chevron_right} alt="" />
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              {/* update button */}
              <div className="loginwithlinks editprofile_block left-btn-basicInfo">
                <div className="d-lg-flex d-md-flex justify-content-end">
                  <div className="loginwithbtn">
                    <p className="fs-22 fw-600 text-primary">
                      {translation.UPDATE + " "}
                      <button
                        onClick={updateProfile}
                        type="button"
                        className="btn btn-outline-primary rounded-circle ms-2"
                      >
                        <img src={chevron_right} alt="" />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EditBasicProfileInfo;
