import React, { useState, useEffect } from "react";
import chevron_right_colored from "../assets/img/chevron_right_colored.svg";
import setup_profile_image from "../assets/img/setup_profile_image.svg";
import tick from "../assets/img/tick.svg";
import { Plus } from "react-feather";
import { Link } from "react-router-dom";
import ApiConfig from "../api/ApiConfig";
import {
  updateProfile,
  getWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
} from "../api/ApiServices";
import default_profile_pic from "../assets/img/profile_pic.svg";
import moment from "moment";
// import  from "../api/ApiServices";
import Country from "../assets/CountrtiesData/Country";
import listContryCity from "../assets/CountrtiesData/CoutrycityList";
import Select from "react-select";
import SpinnerCmp from "../sharedComponents/SpinnerCmp";
import Add_Experiance from "./Job_Seeker/Seeker_profile/Add_Experiance";
import Multiselect from "multiselect-react-dropdown";
import useTranslation from "../customHooks/translations";
import { toast } from "react-toastify";

const SetProfile = (props) => {
  const translation = useTranslation();
  return (
    <>
      <section id="login" class="login">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 col-md-12 g-0 p-0">
              <div class="doctor_leftimg setup_profile">
                <div class="overlay_content text-start">
                  <h2 class="text-white fs-28 fw-500">{translation.SETUP_YOUR_PROFILE}</h2>

                  <div class="trackingstep">
                    <div class="step step-active">
                      <div>
                        <div class="circle"></div>
                      </div>
                      <div>
                        <div class="title">{translation.EDUCATION}</div>
                        {/* <!-- <div class="caption">Optional</div> --> */}
                      </div>
                    </div>
                    <div class="step">
                      <div>
                        <div class="circle">2</div>
                      </div>
                      <div>
                        <div class="title">{translation.JOBS}</div>
                        {/* <!-- <div class="caption">This is description of second step.</div> --> */}
                      </div>
                    </div>
                    <div class="step">
                      <div>
                        <div class="circle">3</div>
                      </div>
                      <div>
                        <div class="title">{translation.SKILLS}</div>
                        {/* <!-- <div class="caption">Some text about Third step. </div> --> */}
                      </div>
                    </div>

                    <div class="step">
                      <div>
                        <div class="circle">4</div>
                      </div>
                      <div>
                        <div class="title">{translation.PREFERNCES}</div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Stepper HTML --> */}
                </div>
              </div>

              {/*<div class="loginlt">
                                <p class="fs-18" style={{ color: "#ffffff6e" }}>Already have an account? &nbsp; <Link to="/Login" href="#" class="fw-600 text-white">Login</Link></p>
                            </div>*/}
            </div>

            <div class="col-lg-6 col-md-12" style={{ height: "100vh", overflow: "auto" }}>
              <div class="login_rtblock position-relative pt-4">
                <div class="d-flex justify-content-end">
                  <p>
                    <Link to="/Seeker_Home" class="fw-600">
                      {translation.SKIP}
                    </Link>
                  </p>
                </div>

                <div class="profileinfo_block text-center">
                  <img src={setup_profile_image} alt="" />
                  <div class="card edu_card mt-5">
                    <div class="card-body">
                      <p>
                        {translation.SETUP_CAREER_PROFILE}
                      </p>
                    </div>
                  </div>
                  <div class="text-center mt-4">
                    <a href="#">
                      {" "}
                      <button
                        type="button"
                        onClick={() => {
                          props.handleNextPage();
                        }}
                        class="btn btn-primary bg-white banner_btn text-uppercase fs-16 fw-700 mt-4"
                        style={{ boxShadow: "0px 6px 20px #4190b729" }}
                      >
                        {translation.GET_STARTED}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const SetEducation = (props) => {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    errMsg: {},
    education_type: "",
    institute_name: "",
    area_of_study: "",
    year_of_graduation: "",
    selected_Country: {},
    selected_City: {},
    selected_CompanySize: {},
  });

  // Add education start
  const AddEducation = async (e) => {
    e.preventDefault();
    if (
      state.education_type == "" ||
      state.institute_name == "" ||
      state.area_of_study == "" ||
      state.year_of_graduation == "" ||
      state.education_type == null ||
      state.institute_name == null ||
      state.area_of_study == null ||
      state.year_of_graduation == null
    ) {
      if (state.education_type == "" || state.education_type == null) {
        setState({
          ...state,
          errMsg: { education_type: translation.SELECT_EDUCATION_TYPE },
        });
      } else if (state.institute_name == "" || state.institute_name == null) {
        setState({
          ...state,
          errMsg: { institute_name: translation.ENTER_INTITUTE_NAME },
        });
      } else if (state.area_of_study == "" || state.area_of_study == null) {
        setState({
          ...state,
          errMsg: { area_of_study: translation.ENTER_AREA_OF_STUDY },
        });
      } else if (
        state.year_of_graduation == "" ||
        state.year_of_graduation == null
      ) {
        setState({
          ...state,
          errMsg: { year_of_graduation: translation.ENTER_YEAR_OF_GRADUATION },
        });
      }
    } else {
      let requestBody = JSON.stringify({
        education_type: state.education_type,
        institute_name: state.institute_name,
        area_of_study: state.area_of_study,
        year_of_graduation: state.year_of_graduation,
      });

      postWithAuthCallWithErrorResponse(ApiConfig.ADD_EDUCATION, requestBody)
        .then((data) => {
          setState({ ...state, isProcessing: true });
          // e.preventDefault()
          console.log(data);
          toast.success(data.json.message, { autoClose: 1000 })
          if (data.error == true) {
            props.handleNextPage();
            setState({ ...state, isProcessing: false });
          } else {
            props.handleNextPage();
            setState({ ...data.education })
            // window.location.href = "/profile";
          }
        })
        .catch((error) => {
          setState({ ...state, isProcessing: false });
        });
    }
  };
  useEffect(() => {
  }, []);

  // end add education

  const [educationTypesState, seteducationTypesState] = useState([
    { label: "Elementary /Primary", value: "Elementary /Primary" },
    { label: "Middle School", value: "Middle School" },
    { label: "Home School", value: "Home School" },
    { label: "Private School", value: "Private School" },
    { label: "Junior High School", value: "Junior High School" },
    { label: "High School", value: "High School" },
    { label: "Undergraduate", value: "Undergraduate" },
    { label: "Professional Certificate", value: "Professional Certificate" },
    { label: "Transfer", value: "Transfer" },
    { label: "Associate", value: "Associate" },
    { label: "Bachelor", value: "Bachelor" },
    { label: "Graduate", value: "Graduate" },
    { label: "Master", value: "Master" },
    { label: "Doctoral", value: "Doctoral" },
    { label: "Professional", value: "Professional" },
    { label: "Specialist", value: "Specialist" },
  ]);

  const selectStyles = {
    control: (provided) => ({ ...provided, backgroundColor: 'transparent', borderRadius: "8px", border: "1px solid #b3c2c9", fontSize: "13px" }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)", backgroundColor: 'transparent' }),
  };

  /* Education Type section starts */
  function onEducationTypeChange() { }
  /* Education Type section ends */
  return (
    <>
      <section id="login" class="login">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 col-md-12 g-0 p-0">
              <div class="doctor_leftimg setup_profile">
                <div class="overlay_content text-start">
                  <h2 class="text-white fs-28 fw-500">{translation.SETUP_YOUR_PROFILE}</h2>

                  <div class="trackingstep">
                    <div class="step step-active">
                      <div>
                        <div class="circle"></div>
                      </div>
                      <div>
                        <div class="title">{translation.EDUCATION}</div>
                        {/* <!-- <div class="caption">Optional</div> --> */}
                      </div>
                    </div>
                    <div class="step">
                      <div>
                        <div class="circle">2</div>
                      </div>
                      <div>
                        <div class="title">{translation.JOBS}</div>
                        {/* <!-- <div class="caption">This is description of second step.</div> --> */}
                      </div>
                    </div>
                    <div class="step">
                      <div>
                        <div class="circle">3</div>
                      </div>
                      <div>
                        <div class="title">{translation.SKILLS}</div>
                        {/* <!-- <div class="caption">Some text about Third step. </div> --> */}
                      </div>
                    </div>

                    <div class="step">
                      <div>
                        <div class="circle">4</div>
                      </div>
                      <div>
                        <div class="title">{translation.PREFERNCES}</div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Stepper HTML --> */}
                </div>
              </div>

              {/*<div class="loginlt">
                                <p class="fs-18" style={{ color: "#ffffff6e" }}>Already have an account? &nbsp; <Link to="/Login" href="#" class="fw-600 text-white">Login</Link></p>
    </div>*/}
            </div>

            <div class="col-lg-6 col-md-12" style={{ height: "100vh", overflow: "auto" }}>
              <div class="login_rtblock position-relative pt-4">
                <div class="d-flex justify-content-end">
                  <p>
                    <Link to="/Seeker_Home" class="fw-600">
                      {translation.SKIP}
                    </Link>
                  </p>
                </div>

                <div class="AddEducationLevel show">
                  <div class="d-flex justify-content-end">
                    <p class="text-primary">
                      {translation.ADD_EDUCATION_LEVELS + " "}
                      <a
                        class="addlevel ms-2"
                        data-bs-toggle="offcanvas"
                        href="#offcanvasAddEducation"
                        role="button"
                        aria-controls="offcanvasAddEducation"
                      >
                        <Plus />
                      </a>
                    </p>
                  </div>

                  <div class="edulevels">
                    <div class="levelcircle active d-flex justify-content-between mt-4">
                      <div>
                        <h3 class="fs-16">{state.education_type}</h3>
                        <p class="fs-14 m-0">
                          {state.institute_name}
                        </p>
                        <p class="fs-14 m-0">
                          {state.area_of_study}
                        </p>
                      </div>
                      <a href="#">
                        <img src="img/edit_cert.svg" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="newloginwithlinks" style={{ paddingRight: "8rem" }}>
                <div class="d-lg-flex d-md-flex justify-content-end">
                  <div class="loginwithbtn">
                    <p class="fs-22 fw-600 text-primary">
                      {translation.CONTINUE + " "}
                      <button
                        type="button"
                        onClick={(e) => {
                          props.handleNextPage()
                          e.preventDefault()
                          // AddEducation(e);
                        }}
                        class="btn btn-outline-primary rounded-circle ms-2"
                      >
                        <img src={chevron_right_colored} alt="" />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Add Education Level Offcanvas --> */}

      <div
        class="AddLevelCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasAddEducation"
        aria-labelledby="offcanvasAddEducationLabel"
      >
        <div class="offcanvas-header">
          <h5
            class="offcanvas-title fs-16 fw-700"
            id="offcanvasAddEducationLabel"
          >
            {translation.ADD_EDUCATION}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <form class="row g-3 text-start">
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.EDUCATION_TYPE}
              </label>
              <select
                class="form-select border-primary"
                aria-label="Default select example"
                onChange={(e) => {
                  setState({ ...state, education_type: e.target.value });
                }}
                value={state.education_type}
              >
                <option selected>{translation.SELECT_EDUCATION_TYPE}</option>
                {educationTypesState.map((edutype) => (
                  <option>{edutype.label}</option>
                ))}
              </select>
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.education_type ? state.errMsg.education_type : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.INSTITUTE_NAME}
              </label>
              <input
                type="text"
                class="form-control border-primary"
                id="inputEmail4"
                value={state.institute_name}
                onChange={(e) => {
                  setState({ ...state, institute_name: e.target.value });
                }}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.institute_name ? state.errMsg.institute_name : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.AREA_OF_STUDY}
              </label>
              <input
                type="text"
                class="form-control border-primary"
                id="inputPassword4"
                value={state.area_of_study}
                onChange={(e) => {
                  setState({ ...state, area_of_study: e.target.value });
                }}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.area_of_study ? state.errMsg.area_of_study : ""}
              </span>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.YEAR_OF_GRADUATION}
              </label>
              <input
                type="text"
                class="form-control border-primary"
                id="inputPassword4"
                value={state.year_of_graduation}
                onChange={(e) => {
                  setState({ ...state, year_of_graduation: e.target.value });
                }}
              />
              <span className="fs-14" style={{ color: "red" }}>
                {state.errMsg.year_of_graduation
                  ? state.errMsg.year_of_graduation
                  : ""}
              </span>
            </div>
          </form>
          <div
            class="educationcanvas-footer"
            style={{ position: "absolute", bottom: "10px", right: "10px" }}
          >
            <div class="d-flex justify-content-end">
              <a
                href="#"
                class=" fs-16 fw-600 me-3 pb-3"
                onClick={AddEducation}
              >
                {translation.APPLY}
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* end add education */}
    </>
  );
};

const Setexperience = (props) => {
  const translation = useTranslation();
  return (
    <section id="login" class="login">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 col-md-12 g-0 p-0">
            <div class="doctor_leftimg setup_profile">
              <div class="overlay_content text-start">
                <h2 class="text-white fs-28 fw-500">{translation.SETUP_YOUR_PROFILE}</h2>

                <div class="trackingstep">
                  <div class="step step-complete">
                    <div>
                      <div class="circle">
                        <img src={tick} alt="" />
                      </div>
                    </div>
                    <div>
                      <div
                        class="title"
                        onClick={() => {
                          props.handlePrevPage();
                        }}
                      >
                        {translation.EDUCATION}
                      </div>
                      {/* <!-- <div class="caption">Optional</div> --> */}
                    </div>
                  </div>
                  <div class="step step-active">
                    <div>
                      <div class="circle">2</div>
                    </div>
                    <div>
                      <div class="title">{translation.JOBS}</div>
                      {/* <!-- <div class="caption">This is description of second step.</div> --> */}
                    </div>
                  </div>
                  <div class="step">
                    <div>
                      <div class="circle">3</div>
                    </div>
                    <div>
                      <div class="title">{translation.SKILLS}</div>
                      {/* <!-- <div class="caption">Some text about Third step. </div> --> */}
                    </div>
                  </div>

                  <div class="step">
                    <div>
                      <div class="circle">4</div>
                    </div>
                    <div>
                      <div class="title">{translation.PREFERNCES}</div>
                    </div>
                  </div>
                </div>
                {/* <!-- End Stepper HTML --> */}
              </div>
            </div>

            {/*<div class="loginlt">
                            <p class="fs-18" style={{ color: "#ffffff6e" }}>Already have an account? &nbsp; <Link to="/Login" href="#" class="fw-600 text-white">Login</Link></p>
                        </div>*/}
          </div>

          <div class="col-lg-6 col-md-12" style={{ height: "100vh", overflow: "auto" }}>
            <div class="login_rtblock position-relative pt-4">
              <div class="d-flex justify-content-end">
                <p>
                  <Link to="/Seeker_Home" class="fw-600">
                    {translation.SKIP}
                  </Link>
                </p>
              </div>

              <div class="profileinfo_block text-center">
                <img src={setup_profile_image} alt="" />
                <div class="card edu_card mt-5">
                  <div class="card-body">
                    <p>{translation.SETUP_CAREER_PROFILE}</p>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <a href="#">
                    {" "}
                    <button
                      type="button"
                      onClick={() => {
                        props.handleNextPage();
                      }}
                      class="btn btn-primary bg-white banner_btn text-uppercase fs-16 fw-700 mt-4"
                      style={{ boxShadow: "0px 6px 20px #4190b729" }}
                    >
                      {translation.GET_STARTED}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const Experience = (props) => {
  const translation = useTranslation();
  return (
    <>
      <section id="login" class="login">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 col-md-12 g-0 p-0">
              <div class="doctor_leftimg setup_profile">
                <div class="overlay_content text-start">
                  <h2 class="text-white fs-28 fw-500">{translation.SETUP_YOUR_PROFILE}</h2>

                  <div class="trackingstep">
                    <div class="step step-complete">
                      <div>
                        <div class="circle">
                          <img src={tick} alt="" />
                        </div>
                      </div>
                      <div>
                        <div class="title">{translation.EDUCATION}</div>
                        {/* <!-- <div class="caption">Optional</div> --> */}
                      </div>
                    </div>
                    <div class="step step-active">
                      <div>
                        <div class="circle">2</div>
                      </div>
                      <div>
                        <div
                          class="title"
                          onClick={() => {
                            props.handlePrevPage();
                          }}
                        >
                          {translation.JOBS + " "}
                        </div>
                        {/* <!-- <div class="caption">This is description of second step.</div> --> */}
                      </div>
                    </div>
                    <div class="step">
                      <div>
                        <div class="circle">3</div>
                      </div>
                      <div>
                        <div class="title">{translation.SKILLS}</div>
                        {/* <!-- <div class="caption">Some text about Third step. </div> --> */}
                      </div>
                    </div>

                    <div class="step">
                      <div>
                        <div class="circle">4</div>
                      </div>
                      <div>
                        <div class="title">{translation.PREFERNCES}</div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Stepper HTML --> */}
                </div>
              </div>

              {/*<div class="loginlt">
                                <p class="fs-18" style={{ color: "#ffffff6e" }}>Already have an account? &nbsp; <Link to="/Login" href="#" class="fw-600 text-white">Login</Link></p>
    </div>*/}
            </div>

            <div class="col-lg-6 col-md-12" style={{ height: "100vh", overflow: "auto" }}>
              <div class="login_rtblock position-relative pt-4">
                <div class="d-flex justify-content-end">
                  <p>
                    <Link to="/Seeker_Home" class="fw-600">
                      {translation.SKIP}
                    </Link>
                  </p>
                </div>
                <Add_Experiance />
                {/* <div class="AddEducationLevel">
                                    <div class="d-flex justify-content-end">
                                        <p class="text-primary">Add Experience Levels <a class="addlevel ms-2" data-bs-toggle="offcanvas" href="#offcanvasAddEducationa" role="button" aria-controls="offcanvasAddEducation"><Plus /></a></p>
                                    </div>

                                    <div class="edulevels">
                                        <div class="levelcircle active d-flex justify-content-between mt-4">
                                            <div>
                                                <h3 class="fs-16">Android Developer</h3>
                                                <p class="fs-14 m-0">TCS . Junior</p>
                                                <p class="fs-14 m-0">Jan 2015 - June 2017</p>
                                            </div>
                                            <a href="#"><img src="img/edit_cert.svg" alt="" /></a>
                                        </div>
                                        <div class="levelcircle d-flex justify-content-between mt-4">
                                            <div>
                                                <h3 class="fs-16">Android Developer</h3>
                                                <p class="fs-14 m-0">Natwest Group . Senior</p>
                                                <p class="fs-14 m-0">Jul 2017 - Present</p>
                                            </div>
                                            <a href="#"><img src="img/edit_cert.svg" alt="" /></a>
                                        </div>
                                    </div>
                                </div> */}
                <div class="newloginwithlinks" style={{ paddingRight: "8rem" }}>
                  <div class="d-lg-flex d-md-flex justify-content-end">
                    <div class="loginwithbtn">
                      <p class="fs-22 fw-600 text-primary">
                        {translation.CONTINUE + " "}
                        <a href="#">
                          {" "}
                          <button
                            type="button"
                            onClick={async () => {
                              props.handleNextPage();
                            }}
                            class="btn btn-outline-primary rounded-circle ms-2"
                          >
                            <img src={chevron_right_colored} alt="" />
                          </button>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
      {/* //   <!-- Add Education Level Offcanvas --> */}
      {/* <div class="AddLevelCanvas offcanvas offcanvas-end" tabIndex="-1" id="offcanvasAddEducationa" aria-labelledby="offcanvasAddEducationLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title fs-16 fw-700" id="offcanvasAddEducationLabel">ADD EXPERIENCE</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <form class="row g-3 text-start">
                        <div class="col-md-12 regsiterrf">
                            <label for="inputEmail4" class="form-label fs-14">Company Name</label>
                            <input type="text" class="form-control border-primary" id="inputEmail4" />
                        </div>
                        <div class="col-md-12 regsiterrf">
                            <label for="inputEmail4" class="form-label fs-14">Role</label>
                            <select class="form-select border-primary" aria-label="Default select example">
                                <option selected></option>
                                <option value="1">BSC</option>
                                <option value="2">MSC</option>
                                <option value="3">B.E</option>
                            </select>
                        </div>
                        <div class="col-md-12 regsiterrf">
                            <label for="inputEmail4" class="form-label fs-14">Designation</label>
                            <select class="form-select border-primary" aria-label="Default select example">
                                <option selected></option>
                                <option value="1">BSC</option>
                                <option value="2">MSC</option>
                                <option value="3">B.E</option>
                            </select>
                        </div>
                        <div class="col-md-12 regsiterrf">
                            <label for="inputPassword4" class="form-label fs-14">Start Year</label>
                            <input type="date" class="form-control border-primary" id="inputPassword4" />
                        </div>
                        <div class="col-md-12 regsiterrf">
                            <label for="inputPassword4" class="form-label fs-14">End Year</label>
                            <input type="date" class="form-control border-primary" id="inputPassword4" />
                        </div>
                    </form>
                </div>
            </div> */}
    </>
  );
};
const Prerequisite = (props) => {
  const translation = useTranslation();
  const [skill, setSkill] = useState([]);
  const [tool, setTool] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [skillsState, setSkillsState] = useState();
  const [toolsState, setToolsState] = useState();
  const [certificatesState, setCertificatesState] = useState();
  const [userId, setUserId] = useState();
  const [state, setState] = useState({
    profile_pic: "",
    profile_pic_name: "",
    isProcessing: false,
    looking_for_job: false,
    new_skill: "",
    new_skills_array: [],
    new_tool: "",
    new_tools_array: [],
    new_certificate: "",
    new_certificates_array: [],
    selectedToolsWithLevel: [],
    selectedSkillsWithLevel: [],
    level: "",
    driving_license_available: "no",
  });
  const getUserid = () => {
    getWithAuthCallWithErrorResponse(ApiConfig.GET_JOB_SEEKER_PROFILE).then(
      (res) => {
        setUserId(res.json.id);
      }
    );
  };
  function onCertificateChange(selectedList, selectedItem) {
    let temp_selectedCertificates = [];

    selectedList.map((item, index) => {
      temp_selectedCertificates.push({ job_certificates: item.name });
    });

    setState({
      ...state,
      selectedCertificates: selectedList,
      certificates: temp_selectedCertificates,
    });
  }

  function removeCertificate(certificateToRemove) {
    let filteredCertificateArray = state.new_certificates_array.filter(
      (item) => item !== certificateToRemove
    );
    setState({ ...state, new_certificates_array: filteredCertificateArray });
  }
  function onToolChange(selectedList, selectedItem) {
    let temp_tool = [];
    let temp_selectedToolsWithLevel = [];

    selectedList.map((item, index) => {
      temp_tool.push(item.name);
      temp_selectedToolsWithLevel.push({ tool: item.name, level: "Beginner" });
    });

    setState({
      ...state,
      selectedTools: selectedList,
      selectedToolsWithLevel: temp_selectedToolsWithLevel,
    });
  }
  function onChangeToolLevel(tool, level) {
    let temp_selectedToolsWithLevel = [];
    state.selectedToolsWithLevel.map((item, index) => {
      temp_selectedToolsWithLevel.push({
        tool: item.tool,
        level: item.tool == tool ? level : item.level,
      });
    });
    setState({ ...state, selectedToolsWithLevel: temp_selectedToolsWithLevel });
  }
  // *** TOOLS ***
  function onSkillChange(selectedList, selectedItem) {
    let temp_skill = [];
    let temp_selectedSkillsWithLevel = [];

    selectedList.map((item, index) => {
      temp_skill.push(item.name);
      temp_selectedSkillsWithLevel.push({
        skill: item.name,
        level: "Beginner",
      });
    });

    setState({
      ...state,
      selectedSkills: selectedList,
      selectedSkillsWithLevel: temp_selectedSkillsWithLevel,
    });
  }
  function onChangeSkillLevel(skill, level) {
    let temp_selectedSkillsWithLevel = [];
    state.selectedSkillsWithLevel.map((item, index) => {
      temp_selectedSkillsWithLevel.push({
        skill: item.skill,
        level: item.skill == skill ? level : item.level,
      });
    });
    setState({
      ...state,
      selectedSkillsWithLevel: temp_selectedSkillsWithLevel,
    });
  }
  const getskills = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.SKILLS_LISTING_API)
      .then((data) => {
        setSkillsState(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const gettools = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.TOOLS_LISTING_API)
      .then((data) => {
        setToolsState(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCertificate = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.CERTIFICATES_LISTING_API)
      .then((data) => {
        setCertificatesState(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function addNewSkill(value) {
    let temp_selectedSkillsWithLevel = skillsState;
    temp_selectedSkillsWithLevel.push({ name: value, id: value });
    setSkillsState(temp_selectedSkillsWithLevel);
  }
  const savePrerequesites = async () => {
    let certificates =
      state.selectedCertificates &&
      state.selectedCertificates.map((cer) => {
        return { user_certificates: cer.name };
      });

    let data = {
      skills: state.selectedSkillsWithLevel,
      tools: state.selectedToolsWithLevel,
      certificates: certificates,
      driving_license_available: state.driving_license_available,
    };

    postWithAuthCallWithErrorResponse(
      ApiConfig.ADD_PREREUESITE_JOB_SEEKER,
      JSON.stringify(data)
    )
      .then((res) => {
        props.handleNextPage()
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getskills();
    gettools();
    getCertificate();
    getUserid();
  }, []);
  return (
    <>
      <section id="login" class="login">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 col-md-12 g-0 p-0">
              <div class="doctor_leftimg setup_profile">
                <div class="overlay_content text-start">
                  <h2 class="text-white fs-28 fw-500">{translation.SETUP_YOUR_PROFILE}</h2>

                  <div class="trackingstep">
                    <div class="step step-complete">
                      <div>
                        <div class="circle">
                          <img src={tick} alt="" />
                        </div>
                      </div>
                      <div>
                        <div class="title">{translation.EDUCATION}</div>
                        {/* <!-- <div class="caption">Optional</div> --> */}
                      </div>
                    </div>
                    <div class="step step-complete">
                      <div>
                        <div class="circle">
                          <img src={tick} alt="" />
                        </div>
                      </div>
                      <div>
                        <div
                          class="title"
                          onClick={() => props.handlePrevPage()}
                        >
                          {translation.JOBS}
                        </div>
                        {/* <!-- <div class="caption">This is description of second step.</div> --> */}
                      </div>
                    </div>
                    <div class="step step-active">
                      <div>
                        <div class="circle">3</div>
                      </div>
                      <div>
                        <div class="title">{translation.SKILLS}</div>
                        {/* <!-- <div class="caption">Some text about Third step. </div> --> */}
                      </div>
                    </div>

                    <div class="step">
                      <div>
                        <div class="circle">4</div>
                      </div>
                      <div>
                        <div class="title">{translation.PREFERNCES}</div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Stepper HTML --> */}
                </div>
              </div>

              {/* <div class="loginlt">
                                <p class="fs-18" style={{ color: "#ffffff6e" }}>Already have an account? &nbsp; <Link to="Login" href="#" class="fw-600 text-white">Login</Link></p>
    </div> */}
            </div>

            <div class="col-lg-6 col-md-12" style={{ height: "100vh", overflow: "auto" }}>
              <div class="login_rtblock position-relative pt-4">
                <div class="d-flex justify-content-end">
                  <p>
                    <Link to="/Seeker_Home" class="fw-600">
                      {translation.SKIP}
                    </Link>
                  </p>
                </div>

                <div class="PrerequisiteLevel">
                  {/* <!-- First Skill --> */}
                  <div class="col-md-12 regsiterrf mb-3">
                    <label for="" class="skl_block mb-3">
                      {translation.SKILLS}
                    </label>
                    <Multiselect
                      className="form-select bg-transparent"
                      aria-label="Default select example"
                      avoidHighlightFirstOption={true}
                      onSearch={addNewSkill}
                      showCheckbox={true} // To show checkbox in the dropdown
                      options={skillsState} // Options to display in the dropdown
                      selectedValues={state.selectedSkills} // Preselected value to persist in dropdown
                      displayValue="name" // Property name to display in the dropdown options
                      onSelect={onSkillChange}
                      onRemove={onSkillChange}
                    />
                    <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3 row">
                      {/* <!-- Skill 1 --> */}
                      {state.selectedSkillsWithLevel.map(
                        (selected_skill, index) => {
                          return (
                            <div className="skill_col col-3 me-3" key={index}>
                              <div className="d-flex justify-content-between">
                                <p className="m-0 fs-14">
                                  {selected_skill.skill}
                                </p>
                                {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                              </div>
                              <div className="col-md-12 regsiterrf">
                                <select
                                  className="form-select fs-14 fw-600 border-primary bg-transparent"
                                  value={selected_skill.level}
                                  onChange={(e) =>
                                    onChangeSkillLevel(
                                      selected_skill.skill,
                                      e.target.value
                                    )
                                  }
                                  aria-label="Default select example"
                                >
                                  <option value="Beginner">{translation.BEGINNER}</option>
                                  <option value="Intermediate">
                                    {translation.INTERMEDIATE}
                                  </option>
                                  <option value="Competent">{translation.COMPETENT}</option>
                                  <option value="Expert">{translation.EXPERT}</option>
                                </select>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>

                  {/* <!-- Second Skill --> */}
                  <div class="col-md-12 regsiterrf mb-3">
                    <label for="" class="skl_block mb-3">
                      {translation.TOOLS}
                    </label>
                    <Multiselect
                      className="form-select bg-transparent"
                      aria-label="Default select example"
                      avoidHighlightFirstOption={true}
                      showCheckbox={true} // To show checkbox in the dropdown
                      options={toolsState} // Options to display in the dropdown
                      selectedValues={state.selectedTools} // Preselected value to persist in dropdown
                      displayValue="name" // Property name to display in the dropdown options
                      onSelect={onToolChange}
                      onRemove={onToolChange}
                    />
                    <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3">
                      {/* <!-- Skill 1 --> */}

                      {state.selectedToolsWithLevel.map(
                        (selected_tool, index) => {
                          return (
                            <div className="skill_col me-3" key={index}>
                              <div className="d-flex justify-content-between">
                                <p className="m-0 fs-14">
                                  {selected_tool.tool}
                                </p>
                                {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                              </div>

                              <div className="col-md-12 regsiterrf">
                                <select
                                  className="form-select fs-14 fw-600 border-primary bg-transparent"
                                  value={selected_tool.level}
                                  onChange={(e) =>
                                    onChangeToolLevel(
                                      selected_tool.tool,
                                      e.target.value
                                    )
                                  }
                                  aria-label="Default select example"
                                >
                                  <option value="Beginner">{translation.BEGINNER}</option>
                                  <option value="Intermediate">
                                    {translation.INTERMEDIATE}
                                  </option>
                                  <option value="Competent">{translation.COMPETENT}</option>
                                  <option value="Expert">{translation.EXPERT}</option>
                                </select>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>

                  {/* <!-- Third Skill --> */}
                  <div class="col-md-12 regsiterrf mb-3">
                    <label for="" class="skl_block mb-3">
                      {translation.CERTIFICATES}
                    </label>
                    <Multiselect
                      className="form-select bg-transparent"
                      aria-label="Default select example"
                      avoidHighlightFirstOption={true}
                      showCheckbox={true} // To show checkbox in the dropdown
                      options={certificatesState} // Options to display in the dropdown
                      selectedValues={state.selectedCertificates} // Preselected value to persist in dropdown
                      displayValue="name" // Property name to display in the dropdown options
                      onSelect={onCertificateChange}
                      onRemove={onCertificateChange}
                    />

                    <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3">
                      {/* <!-- Skill 1 --> */}
                      {state.new_certificates_array.map(
                        (new_certificates_data, index) => {
                          return (
                            <div className="skill_col me-3">
                              <div className="d-flex justify-content-between">
                                <p className="m-0 fs-14">
                                  {new_certificates_data}
                                </p>
                                &nbsp;&nbsp;&nbsp;
                                <button
                                  type="button"
                                  className="btn-close"
                                  aria-label="Close"
                                  onClick={() =>
                                    removeCertificate(new_certificates_data)
                                  }
                                ></button>
                              </div>
                              <div className="col-md-12 regsiterrf"></div>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="col-md-12 regsiterrf mb-3">
                      <label htmlFor="inputEmail4" className="skl_block mb-3">
                        {translation.DRIVING_LICENSE}
                      </label>
                      <select
                        className="form-control border border-primary"
                        aria-label="Default select example"
                        value={state.driving_license_available}
                        onChange={(e) =>
                          setState({
                            ...state,
                            driving_license_available: e.target.value,
                          })
                        }
                      >
                        <option value="yes">{translation.YES}</option>
                        <option value="no">{translation.NO}</option>
                      </select>
                    </div>

                  </div>
                  <div class="newloginwithlinks">
                    <div class="d-lg-flex d-md-flex justify-content-end">
                      <div class="loginwithbtn">
                        <p class="fs-22 fw-600 text-primary">
                          {translation.CONTINUE + " "}
                          <a href="#">
                            {" "}
                            <button
                              type="button"
                              onClick={() => {
                                savePrerequesites();
                                // props.handleNextPage();
                              }}
                              class="btn btn-outline-primary rounded-circle ms-2"
                            >
                              <img src={chevron_right_colored} alt="" />
                            </button>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>
      {/* <!-- Add Education Level Offcanvas --> */}

      <div
        class="AddLevelCanvas offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasAddEducation"
        aria-labelledby="offcanvasAddEducationLabel"
      >
        <div class="offcanvas-header">
          <h5
            class="offcanvas-title fs-16 fw-700"
            id="offcanvasAddEducationLabel"
          >
            {translation.ADD_EDUCATION}
          </h5>
          <button
            type="button"
            class="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <form class="row g-3 text-start">
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.COMPANY_NAME}
              </label>
              <input
                type="text"
                class="form-control border-primary"
                id="inputEmail4"
              />
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.ROLE}
              </label>
              <select
                class="form-select border-primary"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">BSC</option>
                <option value="2">MSC</option>
                <option value="3">B.E</option>
              </select>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputEmail4" class="form-label fs-14">
                {translation.DESIGNATION}
              </label>
              <select
                class="form-select border-primary"
                aria-label="Default select example"
              >
                <option selected></option>
                <option value="1">BSC</option>
                <option value="2">MSC</option>
                <option value="3">B.E</option>
              </select>
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.START_YEAR}
              </label>
              <input
                type="date"
                class="form-control border-primary"
                id="inputPassword4"
              />
            </div>
            <div class="col-md-12 regsiterrf">
              <label for="inputPassword4" class="form-label fs-14">
                {translation.END_YEAR}
              </label>
              <input
                type="date"
                class="form-control border-primary"
                id="inputPassword4"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
const SetPrerequisite = (props) => {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    country_code: "",
    errMsg: {},
    full_name: "",
    profile_pic: "",
    city: "",
    country: "",
    email: "",
    phone_number: "",
    dob: "",
    about: "",
    user_profile_pic: default_profile_pic,
    profile_pic_name: "",
    jobTitle: "",
    shortDesc: "",
    profile_pic: "",
    profile_pic_name: "",
    device_type: "",
    device_token: "",
    current_date: moment().format("YYYY-MM-DD"),
    selected_Country: {},
    selected_City: {},
    errorMsg: {},
    selectedIndustries: [],
    selectedJobRole: [],
    new_industries_array: [],
    work_location: ["OnSite"],
  });
  const [selectedIndustries, useSelectedIndustries] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [jobRole, setJobRole] = useState([]);
  const [jobType, setJobType] = useState([]);

  const [countriesState, setCountriesState] = useState();
  const [citiesState, setCitiesState] = useState();
  const getIndustry = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.INDUSTRY_LISTING_API)
      .then((data) => {
        setIndustries(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getJobRole = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.JOBROLE_LISTING_API)
      .then((data) => {
        setJobRole(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getJobType = async () => {
    getWithAuthCallWithErrorResponse(ApiConfig.JOBTYPE_LISTING_API)
      .then((data) => {
        setJobType(data.json);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function getCitiesList(selected_country) {
    Object.keys(listContryCity.listContryCity).forEach(function (key, index) {
      var newJson = listContryCity.listContryCity[key];
      if (newJson.country == selected_country) {
        updateCity(newJson.city);
      }
    });
  }

  function updateCity(data) {
    let temp_cities = [];
    data.map((item, index) => {
      temp_cities.push({ label: item, value: item });
    });
    setCitiesState(temp_cities);
  }

  function onCityChange(value) {
    setState({ ...state, selected_City: value, city: value.label });
  }
  /* city section ends */
  // Setting
  /* country section starts */
  const selectStyles = {
    control: (provided) => ({ ...provided, backgroundColor: 'transparent', borderRadius: "8px", border: "1px solid #b3c2c9", fontSize: "13px" }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)", backgroundColor: 'transparent' }),
  };

  function getCountriesList() {
    setCountriesState(Country.Country);
  }


  function onCountryChange(e) {
    setState({ ...state, country: e.target.value });
    getCitiesList(e.target.value);
  }
  function onCityChange(e) {
    setState({ ...state, city: e.target.value });
  }

  /* country section ends */

  function onIndustryChange(selectedList, selectedItem) {
    let temp_selectedIndustry = [];

    // selectedList.map((item, index) => {
    //   temp_selectedIndustry.push({ job_certificates: item.name });
    // });

    setState({
      ...state,
      selectedIndustries: selectedList,
      // industries: temp_selectedIndustry,
    });
  }
  function onJobeRoleChange(selectedList, selectedItem) {
    let temp_selectedJobRole = [];

    selectedList.map((item, index) => {
      temp_selectedJobRole.push({ job_certificates: item.name });
    });

    setState({
      ...state,
      selectedJobRole: selectedList,
      jobs: temp_selectedJobRole,
    });
  }
  function onJobeTypeChange(selectedList, selectedItem) {
    let temp_selectedJobType = [];

    selectedList.map((item, index) => {
      temp_selectedJobType.push({ job_certificates: item.name });
    });

    setState({
      ...state,
      selectedJobType: selectedList,
      // jobTypes: temp_selectedJobType,
    });
  }
  function changeWorkLocation(work_location) {
    var temp_work_location = state.work_location;
    console.log("temp_work_location", temp_work_location);
    if (temp_work_location.indexOf(work_location) > -1) {
      temp_work_location.splice(temp_work_location.indexOf(work_location), 1);
    } else {
      temp_work_location.push(work_location);
    }
    setState({ ...state, work_location: temp_work_location });
  }

  const savePreferences = () => {
    let industries = state.selectedIndustries.map((industry) => {
      return { name: industry.name };
    });
    let JobRoles = state.selectedJobRole.map((role) => {
      return { name: role.name };
    });
    let jobTypes = state.selectedJobType.map((jobType) => {
      return { name: jobType.name };
    });

    let data = {
      industries: industries,
      job_role: JobRoles,
      job_type: jobTypes,
      languages: [],
      city: state.selected_City.value,
      country: state.selected_Country.value,
      monthly_salary: state.salary,
      work_location: state.work_location,
      match_percentage: state.percentage,
    };
    postWithAuthCallWithErrorResponse(
      ApiConfig.Add_PREFERENCE_JOB_SEEKER,
      JSON.stringify(data)
    )
      .then((res) => {
        window.location.href = "/Seeker_Home"
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  /* INDUSTRIY END */
  /* INDUSTRIY END */
  useEffect(() => {
    getIndustry();
    getJobRole();
    getJobType();
    getCountriesList();
  }, []);
  return (
    <>
      <section id="login" class="login">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 col-md-12 g-0 p-0">
              <div class="doctor_leftimg setup_profile">
                <div class="overlay_content text-start">
                  <h2 class="text-white fs-28 fw-500">{translation.SETUP_YOUR_PROFILE}</h2>

                  <div class="trackingstep">
                    <div class="step step-complete">
                      <div>
                        <div class="circle">
                          <img src={tick} alt="" />
                        </div>
                      </div>
                      <div>
                        <div class="title">{translation.EDUCATION}</div>
                        {/* <!-- <div class="caption">Optional</div> --> */}
                      </div>
                    </div>
                    <div class="step step-complete">
                      <div>
                        <div class="circle">
                          <img src={tick} alt="" />
                        </div>
                      </div>
                      <div>
                        <div class="title">{translation.JOBS}</div>
                        {/* <!-- <div class="caption">This is description of second step.</div> --> */}
                      </div>
                    </div>
                    <div class="step step-complete">
                      <div>
                        <div class="circle">
                          <img src={tick} alt="" />
                        </div>
                      </div>
                      <div>
                        <div
                          class="title"
                          onClick={() => props.handlePrevPage()}
                        >
                          {translation.SKILLS}
                        </div>
                        {/* <!-- <div class="caption">Some text about Third step. </div> --> */}
                      </div>
                    </div>

                    <div class="step step-active">
                      <div>
                        <div class="circle">4</div>
                      </div>
                      <div>
                        <div class="title">{translation.PREFERNCES}</div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Stepper HTML --> */}
                </div>
              </div>

              {/*<div class="loginlt">
                                <p class="fs-18" style={{ color: "#ffffff6e" }}>Already have an
                                    account? &nbsp; <Link to="/Login" href="#" class="fw-600 text-white">Login</Link></p>
    </div>*/}
            </div>

            <div class="col-lg-6 col-md-12" style={{ height: "100vh", overflow: "auto" }}>
              <div class="login_rtblock position-relative pt-4">
                <div class="d-flex justify-content-end">
                  <p>
                    <Link to="/Seeker_Home" class="fw-600">
                      {translation.SKIP}
                    </Link>
                  </p>
                </div>
                <div class="PreferenceLevel row">
                  {/* <!-- First Skill --> */}
                  <div class="col-md-6 regsiterrf mb-3">
                    <label for="" class="fs-14 mb-2">
                      {translation.INDUSTRIES}
                    </label>

                    <Multiselect
                      className="form-select bg-transparent"
                      aria-label="Default select example"
                      avoidHighlightFirstOption={true}
                      showCheckbox={true} // To show checkbox in the dropdown
                      options={industries} // Options to display in the dropdown
                      selectedValues={state.selectedIndustries} // Preselected value to persist in dropdown
                      displayValue="name" // Property name to display in the dropdown options
                      onSelect={onIndustryChange}
                      onRemove={onIndustryChange}
                    />

                    {/* <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3"> */}
                    {/* <!-- Skill 1 --> */}
                    {

                      // state.selectedIndustries.map((industry) => {
                      //   return (
                      //     <div class="skill_col me-3">
                      //       <div
                      //         class="single_skills border border-primary d-flex
                      //   justify-content-between"
                      //       >
                      //         <p class="m-0 fs-14 me-3">{industry.name}</p>
                      //       </div>
                      //     </div>
                      //   );
                      // })


                    }
                    {/* </div> */}
                  </div>

                  {/* <!-- Second Skill --> */}
                  <div class="col-md-6 regsiterrf mb-3">
                    <label for="" class="fs-14 mb-2">
                      {translation.ROLES}
                    </label>
                    <Multiselect
                      className="form-select bg-transparent"
                      aria-label="Default select example"
                      showCheckbox={true} // To show checkbox in the dropdown
                      options={jobRole} // Options to display in the dropdown
                      selectedValues={state.selectedJobRole} // Preselected value to persist in dropdown
                      displayValue="name" // Property name to display in the dropdown options
                      onSelect={onJobeRoleChange}
                      onRemove={onJobeRoleChange}
                    />
                    {/* <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3"> */}
                    {/* <!-- Skill 1 --> */}
                    {
                      // state.selectedJobRole.map((job) => {
                      //   return (
                      //     <div class="skill_col me-3">
                      //       <div
                      //         class="single_skills border border-primary d-flex
                      //   justify-content-between"
                      //       >
                      //         <p class="m-0 fs-14 me-3">{job.name}</p>
                      //       </div>
                      //     </div>
                      //   );
                      // })

                    }
                    {/* </div> */}
                  </div>

                  {/* <!-- Third Skill --> */}
                  <div class="col-md-6 regsiterrf mb-3">
                    <label for="" class="fs-14 mb-2">
                      {translation.JOB_TYPE}
                    </label>
                    <Multiselect
                      className="form-select bg-transparent"
                      aria-label="Default select example"
                      showCheckbox={true} // To show checkbox in the dropdown
                      options={jobType} // Options to display in the dropdown
                      selectedValues={state.selectedJobType} // Preselected value to persist in dropdown
                      displayValue="name" // Property name to display in the dropdown options
                      onSelect={onJobeTypeChange}
                      onRemove={onJobeTypeChange}
                    />
                    <div class="AddSkillsBlock d-lg-flex d-md-flex mt-3">
                      {/* <!-- Skill 1 --> */}

                      {
                        //   state.selectedJobType &&
                        //     state.selectedJobType.length &&
                        //     state.selectedJobType.map((job) => {
                        //       return (
                        //         <div class="skill_col me-3">
                        //           <div
                        //             class="single_skills border border-primary d-flex
                        // justify-content-between"
                        //           >
                        //             <p class="m-0 fs-14 me-3">{job.name}</p>
                        //           </div>
                        //         </div>
                        //       );
                        //     })

                      }
                    </div>
                  </div>

                  {/* <!-- Fourth Skill --> */}
                  <div class="col-md-6 regsiterrf mb-3">
                    <label for="inputEmail4" class="form-label fs-14">
                      {translation.MONTHLY_SALARY}
                    </label>
                    <select
                      class="form-select bg-transparent border-primary"
                      aria-label="Default select example"
                      value={state.salary}
                      onChange={(e) => {
                        setState({ ...state, salary: e.target.value });
                      }}
                    >
                      <option selected value="5000">
                        $3000 - $5000
                      </option>
                      <option value="10000">$5000 - $10000</option>
                      <option value="15000">$10000 - $15000</option>
                    </select>
                  </div>
                  <div className="col-md-6 regsiterrf">
                    <label for="inputEmail4" className="form-label fs-14">
                      {translation.COUNTRY}
                    </label>
                    <input list="countryLIst" id="countryName" className="form-control" onChange={onCountryChange} value={state.country || ""} />
                    <datalist id="countryLIst" name={"service-cityselected"} style={{ color: "red" }}>
                      {countriesState && countriesState.length && countriesState.map(country => {
                        return (<option value={country.value} />)
                      })}
                    </datalist>
                    {/* <Select
                      isClearable={false}
                      onChange={onCountryChange}
                      options={countriesState}
                      placeholder={translation.SEARCH}
                      styles={selectStyles}
                      style={{ borderRadius: "20px" }}
                      //tabSelectsValue={false}
                      value={state.selected_Country}
                    /> */}
                    <span className="fs-14" style={{ color: "red" }}>
                      {state.errorMsg.country ? state.errorMsg.country : ""}
                    </span>
                  </div>
                  <div className="col-md-6 regsiterrf">
                    <label for="inputPassword4" className="form-label fs-14">
                      {translation.CITY}
                    </label>

                    <input list="cityList" id="city" className="form-control" onChange={onCityChange} value={state.city || ""} />
                    <datalist id="cityList" name={"service-cityselected"} style={{ color: "red" }}>
                      {citiesState && citiesState.length && citiesState.map(city => {
                        return (<option value={city.value} />)
                      })}
                    </datalist>


                    {/* 
                    <Select
                      isClearable={false}
                      onChange={onCityChange}
                      options={citiesState}
                      placeholder={translation.SEARCH}
                      styles={selectStyles}
                      //tabSelectsValue={false}
                      value={state.selected_City}
                    /> */}
                    <span className="fs-14" style={{ color: "red" }}>
                      {state.errorMsg.city ? state.errorMsg.city : ""}
                    </span>
                    {/* <input
                          type="email"
                          className="form-control"
                          id="inputPassword4"
                          onChange={onInputHandle}
                          name="country"
                          value={state.country}
                        /> */}
                  </div>
                  <div class="col-md-6 regsiterrf">
                    <label for="inputEmail4" class="form-label fs-14">
                      {translation.PERCENTAGE}
                    </label>
                    <select
                      class="form-select border-primary"
                      aria-label="Default select example"
                      onChange={(e) => {
                        setState({ ...state, percentage: [e.target.value] });
                      }}
                    >
                      <option selected>Greater Than</option>
                      <option value="49">0-49%</option>
                      <option value="74">50-74%</option>
                      <option value="100">75-100%</option>
                    </select>
                  </div>
                  <div class="col-md-6 regsiterrf mb-4">
                    <div>
                      <label class="form-label fs-14">Work Location</label>
                    </div>
                    <div class="swith_btn btn-group btn-toggle">
                      <button

                        className={
                          state.work_location.indexOf("OnSite") > -1
                            ? `btn btn-lg btn-primary active`
                            : `btn btn-lg btn-default`
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          changeWorkLocation("OnSite");
                        }}
                      >
                        {translation.ONSITE}
                      </button>
                      <button

                        className={
                          state.work_location.indexOf("Hybrid") > -1
                            ? `btn btn-lg btn-primary active`
                            : `btn btn-lg btn-default`
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          changeWorkLocation("Hybrid");
                        }}
                      >
                        {translation.HYBRID}
                      </button>
                      <button

                        className={
                          state.work_location.indexOf("Remote") > -1
                            ? `btn btn-lg btn-primary active`
                            : `btn btn-lg btn-default`
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          changeWorkLocation("Remote");
                        }}
                      >
                        {translation.REMOTE}
                      </button>
                    </div>
                  </div>
                  <div class="newloginwithlinks">
                    <div class="d-lg-flex d-md-flex justify-content-end">
                      <div class="loginwithbtn">
                        <p class="fs-22 fw-600 text-primary">
                          {translation.CONTINUE + " "}
                          <a href="#" onClick={savePreferences}>
                            {" "}
                            {/* <Link to="/Seeker_Home"> */}
                            <button
                              type="button"
                              class="btn btn-outline-primary rounded-circle ms-2"
                            >
                              <img src={chevron_right_colored} alt="" />
                            </button>
                            {/* </Link> */}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};
function Profile() {
  const [state, setState] = useState(0);

  const myData = [1];
  function handleNextPage(num) {
    setState(state + 1);
  }
  function handlePrevPage() {
    setState(state - 1);
  }
  function handlePage(num) {
    setState(num);
  }

  /* check if user authentication starts */
  const checkLogin = async () => {
    if (localStorage.getItem("is_logged_in") == "true") {
      if (localStorage.getItem("USER_ROLE") != "Job_seeker") {
        Logout();
      }
    } else {
      Logout();
    }
  };
  const Logout = async () => {
    const lang = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "IN_en";
    localStorage.clear();
    localStorage.setItem("language", lang);
    window.location.href = "/login";
  };
  /* check if user have authentication ends */

  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <body class="logn">
      <form className="form-wizard" id="regForm" action="#" method="POST">
        <div>
          {myData.map((item, index) => {
            return (
              <>
                {state == 0 ? (
                  <SetProfile
                    handleNextPage={handleNextPage}
                    handlePrevPage={handlePrevPage}
                    tabsData={item}
                  />
                ) : null}
                {state == 1 ? (
                  <SetEducation
                    handleNextPage={handleNextPage}
                    handlePrevPage={handlePrevPage}
                    tabsData={item}
                  />
                ) : null}
                {state == 2 ? (
                  <Setexperience
                    handleNextPage={handleNextPage}
                    handlePrevPage={handlePrevPage}
                    tabsData={item}
                  />
                ) : null}
                {state == 3 ? (
                  <Experience
                    handleNextPage={handleNextPage}
                    handlePrevPage={handlePrevPage}
                    tabsData={item}
                  />
                ) : null}
                {state == 4 ? (
                  <Prerequisite
                    handleNextPage={handleNextPage}
                    handlePrevPage={handlePrevPage}
                    tabsData={item}
                  />
                ) : null}
                {state == 5 ? (
                  <SetPrerequisite
                    handleNextPage={handleNextPage}
                    handlePrevPage={handlePrevPage}
                    tabsData={item}
                  />
                ) : null}
              </>
            );
          })}
        </div>
      </form>
    </body>
  );
}
export default Profile;
