import React from "react";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import chevron_right_colored from "../../assets/img/chevron_right_colored.svg";
import { Link } from "react-router-dom";
import useTranslation from "../../customHooks/translations";

function Recruiter_Feedback() {
  const translation = useTranslation();
  return (
    <div>
      <RecruiterHeader />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item">
                    <a href="#">{translation.ACCOUNT}</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {translation.FEEDBACK_AND_SUGGESTIONS}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" class="settings">
        <div class="container">
          <div class="row position-relative">
            <div class="col-lg-4 col-md-12 offset-lg-4">
              <div
                class="card delete_profile login settings_card"
                style={{ marginTop: "4em" }}
              >
                <div class="card-body">
                  <h3 class="text-center fs-20">
                    {translation.GET_IN_TOUCH_WITH_US}
                  </h3>
                  <p
                    class="mb-4"
                    style={{
                      fontSize: "14px",
                      color: " #000 !important",
                      textAlign: "center",
                    }}
                  >
                    {
                      translation.GIVE_US_FEEDBACK_ABOUT_A_FEATURE_LET_US_KNOW_HOW_WE_CAN_IMPROVE_YOUR_EXPERIANCE
                    }
                  </p>

                  <div class="form_block w-100 pt-0">
                    <form class="row g-3 text-start">
                      <div class="col-md-12 regsiterrf">
                        <label for="inputPassword4" class="form-label fs-14">
                          {translation.SELECT_FEATURE}
                        </label>
                        <select
                          class="form-select bg-transparent"
                          aria-label="Default select example"
                          placeholder={translation.SELECT_AN_ITEM}
                        >
                          <option selected>{translation.SELECT_AN_ITEM}</option>
                          <option value="1">City List</option>
                          <option value="2">Company List</option>
                          <option value="3">City List</option>
                        </select>
                      </div>
                      <div class="col-md-12 regsiterrf">
                        <label for="inputPassword4" class="form-label fs-14">
                          {translation.ENTER_YOUR_FEEDBACK_BELOW}
                        </label>
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ height: "132px" }}
                        ></textarea>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="loginwithlinks editprofile_block">
                <div class="d-lg-flex d-md-flex justify-content-end">
                  <div class="loginwithbtn">
                    <p class="fs-22 fw-600 text-primary">
                      {translation.SUBMIT}{" "}
                      <Link
                        to="/RecruiterProfile"
                        type="button"
                        class="btn btn-outline-primary rounded-circle ms-2"
                      >
                        <img src={chevron_right_colored} alt="" />
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Recruiter_Feedback;
