import React, { useState, useEffect, useRef } from "react";
import proile_banner from "../../../assets/img/profile_banner_bg.svg";
import matched_jobs from "../../../assets/img/matched_jobs.svg";
import saved_jobs from "../../../assets/img/saved_jobs.svg";
import upload_from_storage from "../../../assets/img/upload_from_storage.svg";
import set_status from "../../../assets/img/set_status.svg";
import edit_profile from "../../../assets/img/edit_profile.svg";
import default_profile_pic from "../../../assets/img/profile_pic.svg";
import edit_cert from "../../../assets/img/edit_cert.svg";
import play_video from "../../../assets/img/play_video.svg";
import add_video from "../../../assets/img/add_video.svg";
import take_picture from "../../../assets/img/take_picture.svg";
import edit_video from "../../../assets/img/edit_video.svg";
import delete_video from "../../../assets/img/delete_video.svg";
import Header from "../../../sharedComponents/Header";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Preference from "./Preference";
import {
  getWithAuthCallWithErrorResponse,
  postMultipartWithAuthCallWithErrorResponse,
  postWithAuthCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
  uploadImtroVideo,
} from "../../../api/ApiServices.js";
import ApiConfig from "../../../api/ApiConfig";
import SpinnerCmp from "../../../sharedComponents/SpinnerCmp";

import { Multiselect } from "multiselect-react-dropdown";
import { ReactMediaRecorder } from "react-media-recorder";
import useTranslation from "../../../customHooks/translations";
import { toast } from "react-toastify";

function Seeker_Profile() {

  const VideoPreview = ({ stream }) => {
    const videoRef = useRef();

    useEffect(() => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    }, [stream]);
    if (!stream) {
      return null;
    }
    return <video ref={videoRef} width={400} height={400} autoPlay controls />;
  };
  const [introVideo, setIntroVideo] = useState("")
  const translation = useTranslation();
  const [state, setState] = useState({
    profile_pic: "",
    profile_pic_name: "",
    isProcessing: false,
    checkData: true,
    looking_for_job: false,
    video_url: "",
    intro_video: "",
    new_skill: "",
    new_skills_array: [],
    new_tool: "",
    new_tools_array: [],
    new_certificate: "",
    new_certificates_array: [],
    selectedToolsWithLevel: [],
    selectedSkillsWithLevel: [],
    match_percentage: "",
    city: "",
    work_location: "",
    certificate: [],
    job_role: [],
    job_type: [],
    jobList: [],
    languages: "",
    industries: [],
    monthly_salary: "",
    about: ""
  });

  const [experiance, setExperiance] = useState({
    // profile_pic: "",
    item: [],
    // profile_pic_name: "",
  });
  const [tempVideo, setTempVideo] = useState();
  const [showVideo, setShowVideo] = useState(false);
  const [videoBtn, setVideoBtn] = useState(false);
  const [getEducation, setGetEducation] = useState([]);
  const getSeekerDetails = async () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.GET_JOB_SEEKER_PROFILE)
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
        } else {
          var full_name = data.json.user.full_name != null ? data.json.user.full_name : "";
          var profile_pic = data.json.user.profile_pic != null ? data.json.user.profile_pic : "";
          var city = data.json.user.city != null ? data.json.user.city : "";
          var country = data.json.user.country != null ? data.json.user.country : "";
          var about = data.json.user.about != null ? data.json.user.about : "";
          var email = data.json.user.email;
          var phone_number = data.json.user.phone_number;
          var dob = data.json.user.dob != null ? data.json.user.dob : "";
          // var country_code = data.json.user.country_code;
          var preferences = data.json.preferences.length ? data.json.preferences : "";
          var country_code = data.json.user.country_code != null ? data.json.user.country_code : "";
          var selected_dob = dob.split("-").reverse().join("-");
          var user_experience_level = data.json.user.user_experience_level != null ? data.json.user.user_experience_level : "";
          var looking_for_job = data.json.user.looking_for_job != null ? data.json.user.looking_for_job : false;
          var industries = preferences.length && preferences[0].industries
            .map((res) => res.name)
            .join(",");
          var match_percentage = preferences &&
            preferences[0].match_percentage &&
            preferences[0].match_percentage.join(",");
          var job_role = preferences && preferences[0].job_role
            .map((res) => res.name)
            .join(",");
          var monthly_salary = preferences.length && preferences[0].monthly_salary;
          var job_type = preferences.length && preferences[0].job_type
            .map((res) => res.name)
            .join(",");
          var city = preferences.length && preferences[0].city;
          var work_location = preferences.length && preferences[0].work_location
            .map((res) => res.name)
            .join(",");
          var skill = data.json.skill;
          var tools = data.json.tools;
          var languages = preferences.length && preferences[0].languages;
          var certificate = data.json.certificate;
          var driving_license_available = data.json.user.driving_license_available
          var jobList = preferences.length && preferences[0].job_role.map(job => job.name).join(",")
          var linkedin_profile = data.json.user.linkedin_profile ? data.json.user.linkedin_profile : ""
          setState({
            ...state,
            full_name: full_name,
            city: city,
            country: country,
            selected_Country: { label: country, value: country },
            selected_City: { label: city, value: city },
            email: email,
            phone_number: phone_number,
            dob: dob,
            selected_dob: selected_dob,
            user_experience_level: user_experience_level,
            about: about,
            looking_for_job: looking_for_job,
            video_url: data.json.user.video,
            industries: industries,
            match_percentage: match_percentage,
            linkedin_profile: linkedin_profile,
            // user_profile_pic: profile_pic ? profile_pic : default_profile_pic,
            user_profile_pic: profile_pic ? profile_pic : default_profile_pic,
            preferences: data.json.preferences,
            job_role: job_role,
            monthly_salary: monthly_salary,
            job_type: job_type,
            city: city,
            work_location: work_location,
            languages: languages,
            skill: skill,
            tools: tools,
            certificate: certificate,
            driving_license_available: driving_license_available,
            jobList: jobList,
            country_code: country_code
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });

  };

  // EXPERIANCE START
  const GetExperiance = () => {
    setExperiance({ ...experiance, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.EXPERIENCE_API)
      .then((data) => {
        if (!data.error) {
          let item = data.json;
          setExperiance({
            ...experiance,
            item: item,
          });
        }
      })
      .catch((error) => {
        setExperiance({ ...experiance, isProcessing: false });
      });
  };

  // EDUCATION START

  const getEducationList = async () => {
    setGetEducation({ ...getEducation, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.ADD_EDUCATION)
      .then((data) => {
        if (data.error == true) {
          setGetEducation({ ...getEducation, isProcessing: false });
        } else {
          // var education_type = data.json.education_type;
          // var institute_name = data.json.institute_name;
          // var area_of_study = data.json.area_of_study;
          // var year_of_graduation = data.json.year_of_graduation;
          setGetEducation(data.json);
          // setGetEducation({
          //   ...getEducation,
          //   education_type: education_type,
          //   institute_name: institute_name,
          //   area_of_study: area_of_study,
          //   year_of_graduation: year_of_graduation,

          // });
        }
      })
      .catch((error) => {
        setGetEducation({ ...getEducation, isProcessing: false });
      });
  };

  //END EDUCATION

  // Actively looking for  job
  const activelyLooking = async (option_value) => {
    setState({ ...state, isProcessing: true, checkData: false });
    let requestBody = JSON.stringify({
      looking_for_job: option_value,
    });
    postWithAuthCallWithErrorResponse(ApiConfig.LOOKING_FOR_JOB_API, requestBody)
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
        } else {
          setState({
            ...state,
            isProcessing: false,
            looking_for_job: option_value == "no" ? false : true,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const langaugelevel = (level) => {
    if (level == "Beginner") {
      return (
        <>
          <div class="row skills_progress">
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
          </div>
        </>
      );
    } else if (level == "Competent")
      return (
        <>
          <div class="row skills_progress">
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
          </div>
        </>
      );
    else if (level == "Proficient")
      return (
        <>
          <div class="row skills_progress">
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
          </div>
        </>
      );
    else
      return (
        <>
          <div class="row skills_progress">
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
            <div class="col-lg-3 col-3 g-0">
              <div class="filled_skill"></div>
            </div>
          </div>
        </>
      );
  };
  // video upload

  const handleVideoChange = async (e) => {
    saveVideo(e.target.files[0]);
  };

  useEffect(() => {
    getSeekerDetails();
    GetExperiance();
    getEducationList();
  }, []);
  const getVideo = (video, blob) => {
    let videoFile = new File([blob], "video.mp4", { type: "video/mp4" })
    setState({ ...state, intro_video: videoFile })
  }
  const saveVideo = (file) => {
    var data = new FormData()
    data.append('intro_video', file || state.intro_video)
    uploadImtroVideo(
      ApiConfig.VIDEO_INTRO_JOBSEEKER,
      data
    )
      .then((data) => {
        toast.success(data.json.message, { autoClose: 1000 })
        getSeekerDetails()
      })
      .catch((error) => {
        console.log("api response", error);
      });
  }
  return (
    <div>
      <Header />
      <ReactTooltip />
      <section id="profile" className="profile">
        <div className="container">
          <div className="row">
            <div className="">
              <img src={proile_banner} style={{ width: "100%" }} alt="" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="card profile_lfcard">
                <div className="card-body">
                  <div className="profile_cardimg text-center">
                    <img src={state.user_profile_pic} alt="" />
                  </div>
                  <div className="text-center mt-4">
                    <p class="fs-14">
                      {state.about || ""}
                    </p>
                    <h3 className="fw-600 fs-18">{state.full_name}</h3>
                    <h4 className="fs-14">
                      {state.city || ""},{state.country || ""}
                    </h4>
                  </div>
                  <div className="profile_actionbtn text-center py-3">
                    {/* <Link
                      to="/Match_Job"
                      className="btn rounded-circle"
                      data-tip="Matched Jobs"
                    >
                      <img src={matched_jobs} alt="" />
                    </Link> */}
                    <Link
                      to="/Saved_Jobs"
                      state={{ isFrom: "Saved" }}
                      className="btn rounded-circle"
                      data-tip={translation.SAVED_JOBS}
                    >
                      <img src={saved_jobs} alt="" />
                    </Link>

                    <div className="btn-group profilebtn_block">
                      <button
                        style={{ boxShadow: "0px 0px 0px #4190b729" }}
                        data-tip={translation.SET_STATUS}
                        className="btn dropdown-toggle rounded-circle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={set_status} alt="" />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li className="profileaction_bg">
                          <p className="m-0 mb-3 text-white">
                            {translation.ARE_YOU_LOOKING_FOR_A_JOB}
                          </p>
                          <button
                            type="button"
                            className={
                              state.looking_for_job == false
                                ? "btn open_actionbtn active"
                                : "btn open_actionbtn"
                            }
                            onClick={() => activelyLooking("no")}
                          >
                            {translation.NO}
                          </button>
                          <button
                            type="button"
                            className={
                              state.looking_for_job == true
                                ? "btn open_actionbtn active"
                                : "btn open_actionbtn"
                            }
                            // data-bs-toggle="offcanvas"
                            // aria-controls="offcanvasSearchFilter"
                            onClick={() => activelyLooking("yes")}
                          >
                            {translation.YES}
                          </button>
                        </li>
                      </ul>
                    </div>

                    <div className="btn-group profilebtn_block">
                      <button
                        style={{ boxShadow: "0px 0px 0px #4190b729" }}
                        type="button"
                        data-tip={translation.ADD_INTRO_VIDEO}
                        className="btn dropdown-toggle rounded-circle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={add_video} alt="" />
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end">
                        <li className="profileaction_bg outlineaction_btn">
                          <p className="m-0 mb-3 text-white">
                            {translation.UPLOAD_VIDEO}
                          </p>
                          <button
                            type="button"
                            className="btn open_actionbtn"
                            onClick={handleClick}
                          >
                            <img src={upload_from_storage} alt="" />
                          </button>
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            id="profilePicFile"
                            name="myfile"
                            accept="video/*"
                            onChange={handleVideoChange}
                            style={{ display: "none" }}
                          />
                          {/* video  */}
                          <button
                            type="file"
                            className="btn open_actionbtn active"
                            data-bs-toggle="modal"
                            data-bs-target="#HomeUpgrade2"
                          >
                            <img src={take_picture} alt="" />
                          </button>

                          {/* <input
                            type="file"
                            ref={hiddenFileInput}
                            style={{ display: "none" }}
                            accept="mp4/*"
                            id="profilePicFile"
                          /> */}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="modal fade"
                    id="HomeUpgrade2"
                    tabIndex="-1"
                    aria-labelledby="HomeUpgradeLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-body text-center">
                          <div>
                            <ReactMediaRecorder onStop={getVideo}
                              video
                              render={({
                                status,
                                onStop,
                                startRecording,
                                stopRecording,
                                mediaBlobUrl,
                                previewStream,
                              }) => (
                                <div>
                                  <div>
                                    {translation.VIDEO_RECORD_TIME_MSG}
                                  </div>
                                  <p>{status}</p>
                                  <div className="d-flex justify-content-center mb-4">
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      style={{ marginRight: "10px" }}
                                      onClick={() => {
                                        setShowVideo(true);
                                        startRecording();
                                        setVideoBtn(true);
                                        setTimeout(() => {
                                          stopRecording();
                                          setShowVideo(false);
                                          setVideoBtn(false);
                                          setTempVideo(mediaBlobUrl);
                                        }, 47000);
                                      }}
                                    >
                                      {translation.START_RECORDING}
                                    </button>
                                    <button
                                      style={{ marginLeft: "10px" }}
                                      type="button"
                                      class="btn btn-primary"
                                      onClick={() => {
                                        setShowVideo(false);
                                        setVideoBtn(false);
                                        stopRecording();
                                        setTempVideo(mediaBlobUrl);
                                      }}
                                    >
                                      {translation.STOP_RECORDING}
                                    </button>
                                  </div>
                                  {!showVideo ? (
                                    <video
                                      width={400}
                                      height={400}
                                      controls
                                      src={mediaBlobUrl}
                                    />
                                  ) : (
                                    <VideoPreview stream={previewStream} />
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <button
                            disabled={videoBtn}
                            style={{ marginRight: "10px" }}
                            type="button"
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              saveVideo();
                            }}
                          >
                            {translation.SAVE}
                          </button>
                          <button
                            disabled={videoBtn}
                            style={{ marginLeft: "10px" }}
                            type="button"
                            class="btn btn-primary"
                            data-bs-dismiss="modal"
                          >
                            {translation.CANCEL}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {
                    state.looking_for_job && <>
                      <hr className="mt-2 mb-4" />
                      <div className="d-flex justify-content-between hide align-items-center">
                        <div>
                          <h4 className="fs-16 mb-1">{translation.ACTIVELY_LOOKING_FOR}{" "}</h4>
                          <p className="fs-14 m-0">{state.jobList.length ? state.jobList : ""}</p>
                        </div>
                        {/* <div>
                          <a
                            data-bs-toggle="offcanvas"
                            href="#offcanvasPreference"
                            aria-controls="offcanvasSearchFilter"
                          >
                            <img src={edit_cert} alt="" />
                          </a>
                        </div> */}
                      </div>
                    </>
                  }


                  {
                    state.user && state.user.video
                      (state.user && state.user.video || play_video) && (<>
                        <hr className="my-4" />
                        <div className="d-flex justify-content-between">
                          <h4 className="fs-16 m-0">
                            {translation.PLAY_INTRO_VIDEO}{" "}
                            {/* <span className="fs-14 ms-2">(10:30)</span> */}
                          </h4>
                          <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#HomeUpgrade"
                          >
                            <img src={play_video} alt="" />
                          </a>
                        </div>
                      </>)
                  }
                </div >
              </div >
              <div
                class="modal fade"
                id="HomeUpgrade"
                tabIndex="-1"
                aria-labelledby="HomeUpgradeLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body text-center">
                      <video width={"100%"} controls src={state.video_url} />
                    </div>
                    <button
                      type="button"
                      class="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      {translation.CLOSE}
                    </button>
                  </div>
                </div>
              </div>
            </div >

            <div className="col-lg-8">
              <div className="profile_rfcard pt-5 px-4">
                <ul
                  className="profile-tabWrapper nav nav-tabs"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="BasicInfo-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#BasicInfo"
                      type="button"
                      role="tab"
                      aria-controls="BasicInfo"
                      aria-selected="true"
                    >
                      {translation.BASIC_PROFILE}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Education-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Education"
                      type="button"
                      role="tab"
                      aria-controls="Education"
                      aria-selected="false"
                    >
                      {translation.EDUCATION}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Jobs-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Jobs"
                      type="button"
                      role="tab"
                      aria-controls="Jobs"
                      aria-selected="false"
                    >
                      {translation.EXPERIANCE}
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Preferences-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Preferences"
                      type="button"
                      role="tab"
                      aria-controls="Preferences"
                      aria-selected="false"
                    >
                      {translation.PREFERNCES}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="AdvancedSkills-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#AdvancedSkills"
                      type="button"
                      role="tab"
                      aria-controls="AdvancedSkills"
                      aria-selected="false"
                    >
                      {translation.SKILLS}
                    </button>
                  </li>
                </ul>
                <SpinnerCmp loading={state.isProcessing} />

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="BasicInfo"
                    role="tabpanel"
                    aria-labelledby="BasicInfo-tab"
                  >
                    <div className="d-flex justify-content-end mb-4">
                      <div className="profile_actionbtn">
                        <Link to="/Edit_Basic_Info" data-tip={translation.EDIT_BASIC_INFO}>
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px; height: 42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>

                    {/* <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">Job Title</p>
                      <p className="m-0 profile_role">Android Developer</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">Location</p>
                      <p className="m-0 profile_role">Tottenham, England</p>
                    </div> */}
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0"> {translation.EMAIL}</p>
                      <p className="m-0 profile_role">{state.email}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0"> {translation.MOBILE_NUMBER}</p>
                      <p className="m-0 profile_role">{state.phone_number}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0"> {translation.DATE_OF_BIRTH}</p>
                      <p className="m-0 profile_role">{state.dob}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0"> {translation.EXPERIENCE_LEVEL}</p>
                      <p className="m-0 profile_role">
                        {state.user_experience_level}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0"> {translation.LINKEDIN_PROFILE}</p>

                      {/* <Link to={state.linkedin_profile}></Link> */}

                      <a
                        href={state.linkedin_profile}
                        className="profile_role"
                        target="_blank"
                      >
                        {state.linkedin_profile ? state.linkedin_profile : translation.NO_RECORDS_FOUND}
                      </a>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Education"
                    role="tabpanel"
                    aria-labelledby="Education-tab"
                  >
                    <div className="d-flex justify-content-end mb-4">
                      <div className="profile_actionbtn">
                        <Link
                          to="/Edit_Education"
                          data-tip={translation.EDIT_EDUCATION_INFO}
                        >
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px; height: 42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                    {/* PROFILE EDUCATION START */}
                    <div className="edulevels">
                      {getEducation.length > 0
                        ? getEducation.map((edulist) => (
                          <div className="levelcircle active mt-4">
                            <h3 className="fs-16">
                              {edulist.education_type}
                            </h3>
                            <p className="fs-14 m-0">
                              {edulist.institute_name}
                            </p>
                            <p className="fs-14 m-0">
                              {edulist.area_of_study}
                            </p>
                          </div>
                        ))
                        : translation.NO_RECORDS_FOUND}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Jobs"
                    role="tabpanel"
                    aria-labelledby="Jobs-tab"
                  >
                    {/* Exprience */}
                    <div className="d-flex justify-content-end mb-4">
                      <div className="profile_actionbtn">
                        <Link
                          to="/Edit_Experiance"
                          data-tip={translation.EDIT_EXPRIENCE_INFO}
                        >
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px; height: 42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                    {/* PROFILE EXPERIENCE START */}
                    <div className="edulevels">
                      {experiance.item.length ? experiance.item.map((index) => (
                        <div className="levelcircle active mt-4">
                          <h3 className="fs-16">{index.role}</h3>
                          <p className="fs-16 m-0">
                            {index.company_name} . {index.experience_level}
                          </p>
                          <p className="fs-14 m-0">
                            {index.start_year}-{index.end_year}
                          </p>
                        </div>
                      )) : translation.NO_RECORDS_FOUND}
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="AdvancedSkills"
                    role="tabpanel"
                    aria-labelledby="AdvancedSkills-tab"
                  >
                    {/* Advance Skills  */}
                    <div className="d-flex justify-content-end mb-4">
                      <div className="profile_actionbtn">
                        <Link
                          to="/Edit_Advanced_Skills"
                          data-tip={translation.EDIT_ADVANCED_SKILLS}
                        >
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px; height: 42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div class="skills_jobs">
                      <h4 class="mb-4">{translation.SKILLS}</h4>
                      <div class="row mb-5">
                        {state.skill && state.skill.length ?
                          state.skill.map(skl => {
                            return (
                              <div class="col-lg-3 col-md-6  g-5 mt-0 pt-0">

                                <p class="m-0">
                                  {skl.skill} <br />
                                  <span>{skl.level}</span>
                                </p>

                                <div class="row skills_progress">
                                  {skl.level == "Expert" && <>
                                    <div class="col-lg-3 col-3 g-0">
                                      <div class="filled_skill"></div>
                                    </div>
                                    <div class="col-lg-3 col-3 g-0">
                                      <div class="filled_skill"></div>
                                    </div>
                                    <div class="col-lg-3 col-3 g-0">
                                      <div class="filled_skill"></div>
                                    </div>
                                    <div class="col-lg-3 col-3 g-0">
                                      <div class="filled_skill"></div>
                                    </div>
                                  </>
                                  }
                                  {skl.level == "Competent" && <>
                                    <div class="col-lg-3 col-3 g-0">
                                      <div class="filled_skill"></div>
                                    </div>
                                    <div class="col-lg-3 col-3 g-0">
                                      <div class="filled_skill"></div>
                                    </div>
                                    <div class="col-lg-3 col-3 g-0">
                                      <div class="filled_skill"></div>
                                    </div>
                                  </>
                                  }
                                  {skl.level == "Intermediate" && <>
                                    <div class="col-lg-3 col-3 g-0">
                                      <div class="filled_skill"></div>
                                    </div>
                                    <div class="col-lg-3 col-3 g-0">
                                      <div class="filled_skill"></div>
                                    </div>
                                  </>
                                  }
                                  {skl.level == "Beginner" && <>
                                    <div class="col-lg-3 col-3 g-0">
                                      <div class="filled_skill"></div>
                                    </div>
                                  </>
                                  }

                                </div>
                              </div>
                            )
                          })


                          : translation.NO_RECORDS_FOUND}


                      </div>

                      <h4 class="mb-4">{translation.TOOLS_REQUIRED}</h4>

                      <div class="row mb-5">
                        {state.tools && state.tools.length ? state.tools.map(tool => {
                          return (
                            <div class="col-lg-3 col-md-6 g-5 mt-0 pt-0">
                              <p class="m-0">
                                {tool.tool} <span>{tool.level}</span>
                              </p>
                              <div class="row skills_progress">
                                {tool.level == "Expert" && <>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                </>
                                }
                                {tool.level == "Competent" && <>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                </>
                                }
                                {tool.level == "Intermediate" && <>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                </>
                                }
                                {tool.level == "Beginner" && <>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                </>
                                }

                              </div>
                            </div>
                          )
                        }) : translation.NO_RECORDS_FOUND}



                      </div>
                      <h4 class="mb-4">{translation.CERTIFICATES}</h4>
                      <div class="row mb-5">
                        {state.certificate.length ? state.certificate.map(cer => {
                          return (<>
                            <p class="m-0">
                              <span>{cer.user_certificates}</span>
                            </p>
                          </>)
                        }) : translation.NO_RECORDS_FOUND}
                      </div>
                    </div>
                  </div>
                  {/* PROFILE PREFERENCE */}
                  <div
                    className="tab-pane fade"
                    id="Preferences"
                    role="tabpanel"
                    aria-labelledby="Preferences-tab"
                  >
                    <div className="d-flex justify-content-end mb-4">
                      <div className="profile_actionbtn">
                        <Link
                          to="/Edit_preferences"
                          data-tip={translation.EDIT_PREFERENCES}
                        >
                          {" "}
                          <button
                            type="button"
                            className="btn btn-outline-primary rounded-circle"
                            style={{ width: "42px; height: 42px" }}
                          >
                            <img src={edit_profile} alt="" />
                          </button>
                        </Link>
                      </div>
                    </div>
                    {/* Preferences */}
                    <div class="skills_jobs">
                      <h4 class="mb-4">{translation.LANGUAGE}</h4>
                      <div class="row mb-5">
                        {state.languages.length ? state.languages.map((language) => {
                          return (
                            <div
                              class="col-lg-3 col-md-6 g-5 mt-0 pt-0"
                              key={language.id}
                            >
                              <p class="m-0 profile_role">
                                {language.language}
                                <span>
                                  <br />{language.level}
                                </span>
                              </p>
                              <div class="row skills_progress">
                                {language.level == "Expert" && <>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                </>
                                }
                                {language.level == "Competent" && <>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                </>
                                }
                                {language.level == "Intermediate" && <>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                </>
                                }
                                {language.level == "Beginner" && <>
                                  <div class="col-lg-3 col-3 g-0">
                                    <div class="filled_skill"></div>
                                  </div>
                                </>
                                }

                              </div>
                            </div>
                          );
                        }) : ""}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p class="mb-2">{translation.INDUSTRY}</p>
                      <p class="m-0 profile_role">{state.industries.length ? state.industries : translation.NO_RECORDS_FOUND}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.MATCHING_PERCENTAGE.length ? translation.MATCHING_PERCENTAGE : translation.NO_RECORDS_FOUND}</p>
                      <p class="m-0 profile_role">{state.match_percentage}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.JOB_TITLE}</p>
                      <p class="m-0 profile_role">{state.job_role.length ? state.job_role : translation.NO_RECORDS_FOUND}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.MONTHLY_SALARY}</p>
                      <p className="m-0 profile_role">
                        {state.monthly_salary ? state.monthly_salary : translation.NO_RECORDS_FOUND}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">{translation.DESIRED_LOCATION}</p>
                      <p className="m-0 profile_role">{state.city ? state.city : translation.NO_RECORDS_FOUND}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p class="mb-2">{translation.JOB_TYPE}</p>
                      <p class="m-0 profile_role">{state.job_type.length ? state.job_type : translation.NO_RECORDS_FOUND}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p class="mb-2">{translation.WORK_LOCATION}</p>
                      <p class="m-0 profile_role">{state.work_location.length ? state.work_location : translation.NO_RECORDS_FOUND}</p>
                    </div>
                  </div >
                </div >

                <div
                  className="tab-pane fade"
                  id="AdvancedSkills"
                  role="tabpanel"
                  aria-labelledby="AdvancedSkills-tab"
                >
                  {/* Advance Skills  */}
                  <div className="d-flex justify-content-end mb-4">
                    <div className="profile_actionbtn">
                      <Link
                        to="/Edit_Advanced_Skills"
                        data-tip={translation.EDIT_ADVANCED_SKILLS}
                      >
                        <button
                          type="button"
                          className="btn btn-outline-primary rounded-circle"
                          style={{ width: "42px; height: 42px" }}
                        >
                          <img src={edit_profile} alt="" />
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div class="skills_jobs">
                    <h4 class="mb-4">{translation.SKILLS}</h4>

                    <div class="row mb-5">
                      {state.skill &&
                        state.skill.map((item) => {
                          return (
                            <div
                              class="col-lg-3 col-md-6 g-5 mt-0 pt-0"
                              key={item.key}
                            >
                              <p class="m-0">
                                {item.skill}
                                <span>
                                  <br />{item.level}
                                </span>
                              </p>

                              <div class="row skills_progress">
                                {langaugelevel(item.level)}
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    <h4 class="mb-4">{translation.TOOLS_REQUIRED}</h4>

                    <div class="row mb-5">
                      <div class="col-lg-3 col-md-6 g-5 mt-0 pt-0">
                        {state.tools &&
                          state.tools.map((res) => {
                            return (
                              <div key={res.id}>
                                <p class="m-0">
                                  {res.tool} <span>{res.level}</span>
                                </p>
                                <div class="row skills_progress">
                                  {langaugelevel(res.level)}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-4">

                      <p class="mb-2">{translation.CERTIFICATES}</p>
                      <p class="m-0">
                        {state.certificate && state.certificate.length ?
                          state.certificate
                            .map((item) => item.user_certificates)
                            .join(",")
                          : translation.NO_RECORDS_FOUND
                        }
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="m-0">Driving License</p>
                      <p className="m-0 profile_role">
                        {state.driving_license_available ? "True" : "False"}
                      </p>
                    </div>
                  </div>
                </div>
              </div >
            </div >
          </div >
        </div >
      </section >

      {/* <!-- Search Filter Offcanvas Start --> */}
      < Preference />

      {/* <!-- Search Filter Offcanvas End --> */}
    </div >
  );
}

export default Seeker_Profile;
