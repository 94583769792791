import React, { useState } from "react";
import Header from "../../../sharedComponents/Header";
import arrow_next_grey from "../../../assets/img/arrow_next_grey.svg";
import { Link } from "react-router-dom";
import useTranslation from "../../../customHooks/translations";
import { postWithAuthCallWithErrorResponse } from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { toast } from "react-toastify";

function Setting() {
  const translation = useTranslation();
  const [state, setState] = useState({})
  const hideStatusFromRecruiter = (e) => {
    postWithAuthCallWithErrorResponse(ApiConfig.HIDE_PROFILE_STATUS_FROM_RECRUITER)
      .then((data) => {
        toast.success(data.json.message, { autoClose: 1000 })
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  }
  return (
    <div>
      <Header />
      <section class="breadcrumbs_block">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb m-0">
                  <li class="breadcrumb-item">
                    <Link to="/Seeker_Home">{translation.HOME}</Link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {translation.SETTINGS}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="settings" class="settings">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 g-0">
              <div class="card settings_card mt-4">
                <div class="card-body">
                  <label for="" class="fs-14 fw-600 text-accent">
                    {translation.ACCOUNT_SETTINGS}
                  </label>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <p>{translation.HIDE_STATUS_FOR_RECRUITERS}</p>
                    <div class="form-check form-switch">
                      <input
                        onChange={hideStatusFromRecruiter}
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>
                  <hr />
                  {/*   <div class="d-flex justify-content-between">
                    <p>{translation.ENABLE_READ_RECEIPT}</p>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                      />
                    </div>
                  </div>
                  <hr /> */}
                  <Link to="/Setting_BlockUser">
                    <div class="d-flex justify-content-between">
                      <p>{translation.BLOCKING}</p>
                      <img src={arrow_next_grey} alt="" />
                    </div>
                  </Link>
                  <hr />
                  <Link to="/Setting_ChangePass">
                    <div class="d-flex justify-content-between">
                      <p>{translation.CHANGE_PASSWORD}</p>

                      <img src={arrow_next_grey} alt="" />
                    </div>
                  </Link>
                  <hr />
                  <Link to="/Delete_Profile">
                    <div class="d-flex justify-content-between">
                      <p>{translation.DELETE_PROFILE}</p>

                      <img src={arrow_next_grey} alt="" />
                    </div>
                  </Link>
                </div>
              </div>
              {/*  <div class="card settings_card mt-4 mb-4">
                <div class="card-body">
                  <label for="" class="fs-14 fw-600 text-accent">
                    {translation.NOTIFICATIONS}
                  </label>
                  <hr />
                  <div class="d-flex justify-content-between">
                    <p>{translation.ENABLE_NOTIFICATIONS_FOR_MATCHES}</p>
                    <div class="form-check form-switch">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="flexSwitchCheckDefault"
                        checked
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Setting;
