import React, { useState, useEffect } from "react";
import {
  postMultipartWithAuthCallWithErrorResponse,
  getWithAuthCallWithErrorResponse,
} from "../../../api/ApiServices";
import ApiConfig from "../../../api/ApiConfig";
import { Multiselect } from "multiselect-react-dropdown";
import useTranslation from "../../../customHooks/translations";
import { toast } from "react-toastify";

export default function PrerequisiteInfo(props) {
  const translation = useTranslation();
  const [state, setState] = useState({
    //skills: props.jobDetails.skills ? props.jobDetails.skills : [],
    //tools: props.jobDetails.tools ? props.jobDetails.tools : [],
    certificates: props.jobDetails.certificates
      ? props.jobDetails.certificates
      : [],
    selectedSkills: props.jobDetails.selectedSkills
      ? props.jobDetails.selectedSkills
      : [],
    selectedSkillsWithLevel: props.jobDetails.selectedSkillsWithLevel
      ? props.jobDetails.selectedSkillsWithLevel
      : [],
    selectedTools: props.jobDetails.selectedTools
      ? props.jobDetails.selectedTools
      : [],
    selectedToolsWithLevel: props.jobDetails.selectedToolsWithLevel
      ? props.jobDetails.selectedToolsWithLevel
      : [],
    selectedCertificates: props.jobDetails.selectedCertificates
      ? props.jobDetails.selectedCertificates
      : [],
    new_skill: "",
    new_skills_array: [],
    new_tool: "",
    new_tools_array: [],
    new_certificate: "",
    new_certificates_array: [],
    company_name: props.jobDetails.company_name,
    industry: props.jobDetails.industry,
    about_company: props.jobDetails.about_company,
    company_linkedin: props.jobDetails.company_linkedin,
    job_title: props.jobDetails.job_title,
    job_description: props.jobDetails.job_description,
    job_type: props.jobDetails.job_type,
    work_location: props.jobDetails.work_location,
    job_posting_language: props.jobDetails.job_posting_language,
    salary: props.jobDetails.salary,
    ad_duration: props.jobDetails.ad_duration,
    //language_required: props.jobDetails.languages_required,
    selectedLanguagesWithLevel: props.jobDetails.selectedLanguagesWithLevel,
    required_experience: props.jobDetails.required_experience,
    video: props.jobDetails.video,
    photo: props.jobDetails.profile_pic,
  });

  const [skillsState, setSkillsState] = useState();
  const [toolsState, setToolsState] = useState();
  const [certificatesState, setCertificatesState] = useState();

  const handleKeyDown_Certificates = (e) => {
    if (e.key === "Enter") {
      var new_certificates_array = state.new_certificates_array;
      if (state.new_certificate == "" || state.new_certificate == null) {
        // show error message
      } else {
        new_certificates_array.push(state.new_certificate);
      }
      setState({
        ...state,
        new_certificates_array: new_certificates_array,
        new_certificate: "",
      });
    }
  };

  function removeCertificate(certificateToRemove) {
    let filteredCertificateArray = state.new_certificates_array.filter(
      (item) => item !== certificateToRemove
    );
    setState({ ...state, new_certificates_array: filteredCertificateArray });
  }

  //test area
  function skillLevelChange(skill_value) {
    var e = document.getElementById(skill_value);
    var skill_level_value = e.value;
  }

  /* skills section starts */
  const getSkillsList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.SKILLS_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseSkillsList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseSkillsList = (data) => {
    let temp_skills = [];
    data.map((item, index) => {
      temp_skills.push({ name: item.name, id: item.id });
    });

    setSkillsState(temp_skills);
  };

  function onSkillChange(selectedList, selectedItem) {
    console.log(selectedList);
    let temp_skill = [];
    let temp_selectedSkillsWithLevel = [];

    selectedList.map((item, index) => {
      temp_skill.push(item.name);
      temp_selectedSkillsWithLevel.push({
        skill: item.name,
        level: "Beginner",
      });
    });

    setState({
      ...state,
      selectedSkills: selectedList,
      selectedSkillsWithLevel: temp_selectedSkillsWithLevel,
    });
  }

  function onChangeSkillLevel(skill, level) {
    let temp_selectedSkillsWithLevel = [];
    state.selectedSkillsWithLevel.map((item, index) => {
      temp_selectedSkillsWithLevel.push({
        skill: item.skill,
        level: item.skill == skill ? level : item.level,
      });
    });
    setState({
      ...state,
      selectedSkillsWithLevel: temp_selectedSkillsWithLevel,
    });
  }

  function addNewSkill(value) {
    let temp_selectedSkillsWithLevel = skillsState;
    temp_selectedSkillsWithLevel.push({ name: value, id: value });
    setSkillsState(temp_selectedSkillsWithLevel);
  }
  /* skills section ends */

  /* tools section starts */
  const getToolsList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.TOOLS_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseToolsList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseToolsList = (data) => {
    let temp_tools = [];
    data.map((item, index) => {
      temp_tools.push({ name: item.name, id: item.id });
    });

    setToolsState(temp_tools);
  };

  function onToolChange(selectedList, selectedItem) {
    let temp_tool = [];
    let temp_selectedToolsWithLevel = [];

    selectedList.map((item, index) => {
      temp_tool.push(item.name);
      temp_selectedToolsWithLevel.push({ tool: item.name, level: "Beginner" });
    });

    setState({
      ...state,
      selectedTools: selectedList,
      selectedToolsWithLevel: temp_selectedToolsWithLevel,
    });
  }

  function onChangeToolLevel(tool, level) {
    let temp_selectedToolsWithLevel = [];
    state.selectedToolsWithLevel.map((item, index) => {
      temp_selectedToolsWithLevel.push({
        tool: item.tool,
        level: item.tool == tool ? level : item.level,
      });
    });
    setState({ ...state, selectedToolsWithLevel: temp_selectedToolsWithLevel });
  }
  /* tools section ends */

  /* certificates section starts */
  const getCertificatesList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.CERTIFICATES_LISTING_API)
      .then((data) => {
        setState({ ...state, isProcessing: false });
        parseCertificatesList(data.json);
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  const parseCertificatesList = (data) => {
    let temp_tools = [];
    data.map((item, index) => {
      temp_tools.push({ name: item.name, id: item.id });
    });

    setCertificatesState(temp_tools);
  };

  function onCertificateChange(selectedList, selectedItem) {
    let temp_selectedCertificates = [];

    selectedList.map((item, index) => {
      temp_selectedCertificates.push({ job_certificates: item.name });
    });

    setState({
      ...state,
      selectedCertificates: selectedList,
      certificates: temp_selectedCertificates,
    });
  }
  /* certificates section ends */

  const createJobAd = () => {
    let details = JSON.stringify({
      skills: state.selectedSkillsWithLevel,
      tools: state.selectedToolsWithLevel,
      certificates: state.certificates,
      company_name: state.company_name,
      industry: state.industry,
      about_company: state.about_company,
      linkedin_id: state.company_linkedin,
      job_title: state.job_title,
      job_description: state.job_description,
      job_type: state.job_type,
      work_location: state.work_location,
      job_posting_language: state.job_posting_language,
      salary: state.salary,
      ad_duration: state.ad_duration,
      language_required: state.selectedLanguagesWithLevel,
      required_experience: state.required_experience,
    });

    let formData = new FormData();
    formData.append("details", details);
    formData.append("photo", state.photo);
    formData.append("video", state.video);
    postMultipartWithAuthCallWithErrorResponse(
      ApiConfig.CREATE_JOB_AD_API,
      formData
    )
      .then((data) => {

        if (data.error == false) {
          toast.success("Job added successfully", { autoClose: 1000 })
          setTimeout(() => {
            window.location.href = "/recruiter-ad-listing";
          }, 3000);
        } else {
        }
      })
      .catch((error) => {
        console.log("api response", error);
      });
  };

  useEffect(() => {
    getSkillsList();
    getToolsList();
    getCertificatesList();
  }, []);

  return (
    <>
      <div className="col-lg-8 col-md-12">
        <div className="card login settings_card create_job_card">
          <div className="card-body">
            {/* First Skill */}
            <div className="col-md-12 regsiterrf mb-3">
              <label className="skl_block mb-3">{translation.SKILLS}</label>
              <Multiselect
                className="form-select bg-transparent"
                aria-label="Default select example"
                avoidHighlightFirstOption={true}
                onSearch={addNewSkill}
                showCheckbox={true} // To show checkbox in the dropdown
                options={skillsState} // Options to display in the dropdown
                selectedValues={state.selectedSkills} // Preselected value to persist in dropdown
                displayValue="name" // Property name to display in the dropdown options
                onSelect={onSkillChange}
                onRemove={onSkillChange}
              />
              <div className="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                {state.selectedSkillsWithLevel.map((selected_skill, index) => {
                  return (
                    <div className="skill_col me-3" key={index}>
                      <div className="d-flex justify-content-between">
                        <p className="m-0 fs-14">{selected_skill.skill}</p>
                        {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                      </div>

                      <div className="col-md-12 regsiterrf">
                        <select
                          className="form-select fs-14 fw-600 border-primary bg-transparent"
                          value={selected_skill.level}
                          onChange={(e) =>
                            onChangeSkillLevel(
                              selected_skill.skill,
                              e.target.value
                            )
                          }
                          aria-label="Default select example"
                        >
                          <option value="Beginner">
                            {translation.BEGINNER}
                          </option>
                          <option value="Intermediate">
                            {translation.INTERMEDIATE}
                          </option>
                          <option value="Competent">
                            {translation.COMPETENT}
                          </option>
                          <option value="Expert">{translation.EXPERT}</option>
                        </select>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-md-12 regsiterrf mb-3 mt-5">
              <label className="skl_block mb-3">{translation.TOOLS}</label>
              <Multiselect
                className="form-select bg-transparent"
                aria-label="Default select example"
                avoidHighlightFirstOption={true}
                showCheckbox={true} // To show checkbox in the dropdown
                options={toolsState} // Options to display in the dropdown
                selectedValues={state.selectedTools} // Preselected value to persist in dropdown
                displayValue="name" // Property name to display in the dropdown options
                onSelect={onToolChange}
                onRemove={onToolChange}
              />
              <div className="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                {state.selectedToolsWithLevel.map((selected_tool, index) => {
                  return (
                    <div className="skill_col me-3" key={index}>
                      <div className="d-flex justify-content-between">
                        <p className="m-0 fs-14">{selected_tool.tool}</p>
                        {/* <button type="button" className="btn-close" aria-label="Close" ></button> */}
                      </div>

                      <div className="col-md-12 regsiterrf">
                        <select
                          className="form-select fs-14 fw-600 border-primary bg-transparent"
                          value={selected_tool.level}
                          onChange={(e) =>
                            onChangeToolLevel(
                              selected_tool.tool,
                              e.target.value
                            )
                          }
                          aria-label="Default select example"
                        >
                          <option value="Beginner">
                            {translation.BEGINNER}
                          </option>
                          <option value="Intermediate">
                            {translation.INTERMEDIATE}
                          </option>
                          <option value="Competent">
                            {translation.COMPETENT}
                          </option>
                          <option value="Expert">{translation.EXPERT}</option>
                        </select>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="col-md-12 regsiterrf mb-3 mt-5">
              <label className="skl_block mb-3">
                {translation.CERTIFICATES}
              </label>
              {/*<input
                type="text"
                placeholder="Add Certificates  (Type and press Enter)"
                className="form-control border border-primary"
                onKeyDown={handleKeyDown_Certificates}
                value={state.new_certificate}
                onChange={(e) => {
                  setState({
                    ...state,
                    new_certificate: e.target.value,
                  });
                }}
              />*/}
              <Multiselect
                className="form-select bg-transparent"
                aria-label="Default select example"
                avoidHighlightFirstOption={true}
                showCheckbox={true} // To show checkbox in the dropdown
                options={certificatesState} // Options to display in the dropdown
                selectedValues={state.selectedCertificates} // Preselected value to persist in dropdown
                displayValue="name" // Property name to display in the dropdown options
                onSelect={onCertificateChange}
                onRemove={onCertificateChange}
              />
              <div className="AddSkillsBlock d-lg-flex d-md-flex mt-3 pb-0">
                {state.new_certificates_array.map(
                  (new_certificates_data, index) => {
                    return (
                      <div className="skill_col me-3">
                        <div className="d-flex justify-content-between">
                          <p className="m-0 fs-14">{new_certificates_data}</p>
                          &nbsp;&nbsp;&nbsp;
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={() =>
                              removeCertificate(new_certificates_data)
                            }
                          ></button>
                        </div>
                        <div className="col-md-12 regsiterrf"></div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>

            <hr className="mt-4" />
            <div className="card_footer_btn d-flex justify-content-between pt-0 mt-0">
              <a
                href="#"
                className="fw-600 fs-18"
                onClick={() => {
                  props.handlePrevious();
                }}
              >
                {translation.PREV}
              </a>
              <a href="#" className="fw-600 fs-18" onClick={createJobAd}>
                {translation.NEXT}
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
