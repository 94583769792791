import moment from 'moment';
import React, { useContext } from 'react';
import { Menu } from 'react-feather';
import { NotificationContext } from '../../contexts/NotificationContext';
import useTranslation from '../../customHooks/translations';
import notification from "../../assets/img/notification.svg";

function NotificationComponent(props) {
    const translation = useTranslation();
    const { notificationList } = useContext(NotificationContext)
    return (
        <div class="col-lg-12 col-md-12 chat_message">
            {notificationList.length ? notificationList.map((notif, index) => {
                return (
                    <div class="card notification_card settings_card mt-4" key={index}>
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div class="itemside">
                                    <div class="aside">
                                        <img src={notification} class="img-sm" />
                                    </div>
                                    <div class="info align-self-center">
                                        <p class="title m-0 mb-1">
                                            <span class="fw-600">{notif.title}</span>
                                            <span class="fw-600">{notif.text}</span>
                                        </p>
                                        <div class="time-wrap">
                                            <span class="time_notification fs-14 fw-600">
                                                {moment(notif.time).fromNow()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="linco_dropdown btn-group dropstart">
                                    <button
                                        type="button"
                                        class="btn dropdown-toggle"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <img src={Menu} alt="" />
                                    </button>
                                    <ul class="dropdown-menu">
                                        {/* <li>
                        <Link to="/Chat">
                          <button class="dropdown-item" type="button">
                            {translation.CHAT}
                          </button>
                        </Link>
                      </li> */}
                                        <li>
                                            <button class="dropdown-item" type="button">
                                                {translation.NOT_INTERESTED}
                                            </button>
                                        </li>
                                        <li>
                                            <button class="dropdown-item" type="button">
                                                {translation.BLOCK_COMPANY}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }) : (
                <div style={{ padding: "1rem" }}>
                    {translation.NO_RECORDS_FOUND}</div>
            )}
        </div>
    );
}

export default NotificationComponent;