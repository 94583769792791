import React, { useState, useEffect } from "react";
import ExploreImage from "../../assets/img/explore_image.svg";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import { Link } from "react-router-dom";
import ApiConfig from "../../api/ApiConfig";
import { getWithAuthCallWithErrorResponse } from "../../api/ApiServices";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";
import { Buffer } from "buffer";
import useTranslation from "../../customHooks/translations";

function RecruiterExplore() {
  const [state, setState] = useState({
    isProcessing: false,
    jobRoleList: [],
  });
  const translation = useTranslation();
  const getExploreList = () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.RECRUITER_EXPLORE_PAGE_API)
      .then((data) => {
        setState({ ...state, isProcessing: false, jobRoleList: data.json });
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
        //console.log('error parse', error);
      });
  };

  useEffect(() => {
    getExploreList();
  }, []);
  return (
    <>
      <RecruiterHeader />
      <section id="explore" className="explore">
        <div className="container">
          <div className="row explore_card">
            <div className="col-lg-8 pt-5">
              <div className="explore_banner">
                <h2 className="text-white fs-24 fw-600">
                  {translation.LOOKING_TO_HIRE_PEOPLE}
                </h2>
                <p className="text-white">
                  {translation.SELECT_YOUR_PREFERRED_ROLE}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="explore_img">
                <img src={ExploreImage} alt="" />
              </div>
            </div>
          </div>

          <SpinnerCmp loading={state.isProcessing} />
          <div
            className="row"
            style={{ display: state.isProcessing ? "none" : "" }}
          >
            <div className="col-lg-12">
              <div className="job_cards mt-5">
                {state.jobRoleList.map((jobrole, index) => {
                  // var id_string = jobrole.name + "--" + jobrole.id;
                  // const id = Buffer.from(id_string).toString('base64');
                  return (
                    <div className="card explorejob_card" key={index}>
                      <div className="card-body">
                        <p>{jobrole.name}</p>
                        <Link
                          to={
                            "/recruiter-explore-jobs?designation=" +
                            jobrole.name
                          }
                          className="stretched-link"
                        ></Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RecruiterExplore;
