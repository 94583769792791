export default {
  FULLNAME: "पूर्ण नाव",
  EMAIL: "ईमेल",
  MOBILE_NUMBER: "मोबाइल नंबर",
  COUNTRY: "देश",
  CITY: "शहर",
  DATE_OF_BIRTH: "जन्मतारीख",
  LINKEDIN_PROFILE: "लिंकडिन प्रोफाइल",
  SHORT_DESCRIPTION: "स्वतःबद्दलचे संक्षिप्त वर्णन",
  UPDATE_BASIC_DETAILS: "मूलभूत तपशील अद्यतनित करा",
  UPDATE: "अपडेट",
  CONFIRMATION_FOR_DELETE: "तुम्हाला नक्की हटवायचे आहे का?",
  MATCHED_JOBS: "जुळलेल्या नोकऱ्या",
  SAVED_JOBS: "जतन केलेली नोकरी",
  SET_STATUS: "स्थिती सेट करा",
  ADD_INTRO_VIDEO: "परिचय व्हिडिओ जोडा",
  BASIC_PROFILE: "मूलभूत प्रोफाइल",
  EDUCATION: "शिक्षण",
  EXPERIANCE: "अनुभव",
  PREFERNCES: "प्राधान्ये",
  LANGUAGE: "भाषा",
  INDUSTRY: "उद्योग",
  MATCHING_PERCENTAGE_PREFERENCE: "जुळणारे टक्केवारी प्राधान्य",
  JOB_TITLE: "नोकरी शीर्षक",
  MONTHLY_SALARY: "मासिक पगार",
  DESIRED_LOCATION: "इच्छित स्थान",
  WORK_LOCATION: "कामाचे ठिकाण",
  SKILLS: "कौशल्य",
  TOOLS: "साधने",
  Certificates: "प्रमाणपत्रे",
  CURRENT_EXPERIANCE_LEVEL: "वर्तमान अनुभव पातळी",
  CURRENT_ROLE: "वर्तमान भूमिका",
  TUTORIAL: "ट्यूटोरियल",
  CHANGE_LANGAUAGE: "भाषा बदला",
  SUBSCRIPTION_PLANS: "सदस्यता योजना",
  FAQ: "FAQ",
  FEEDBACK_SUGGESTION: "अभिप्राय आणि सूचना",
  CONTACT_US: "आमच्याशी संपर्क साधा",
  SETTINGS: "सेटिंग्ज",
  LEGAL: "कायदेशीर",
  LOGOUT: "लॉगआउट",
  SELECT_LANGUAGE: "भाषा निवडा",
  SELECT_YOUR_PREFERRED_LANGUAGE_TO_CONTINUE:
    "सुरू ठेवण्यासाठी तुमची पसंतीची भाषा निवडा",
  CONTINUE: "सुरू ठेवा",
  UPGRADE: "अपग्रेड",
  GIVE_US_FEEDBACK_ABOUT_A_FEATURE_LET_US_KNOW_HOW_WE_CAN_IMPROVE_YOUR_EXPERIANCE:
    "आम्हाला एका वैशिष्ट्याबद्दल अभिप्राय द्या. आम्ही तुमचा अनुभव कसा सुधारू शकतो ते आम्हाला कळवा.",
  SELECT_FEATURE: "वैशिष्ट्य निवडा",
  ENTER_YOUR_FEEDBACK_BELOW: "खाली तुमचा अभिप्राय प्रविष्ट करा",
  GET_IN_TOUCH_WITH_US: "आमच्याशी संपर्क साधा",
  MESSAGE_US: "US MESSAGE",
  SUBJECT: "विषय",
  PLEASE_SELECT_SUBJECT: "कृपया विषय निवडा",
  MESSAGE: "संदेश",
  ACCOUNT_SETTINGS: "खाते सेटिंग्ज",
  HIDE_STATUS_FOR_EMPLOYERS: "नियोक्त्यासाठी स्थिती लपवा",
  ENABLE_READ_RECEIPT: "वाचन पावती सक्षम करा",
  BLOCKING: "ब्लॉक करणे",
  CHANGE_PASSWORD: "पासवर्ड बदला",
  DELETE_PROFILE: "प्रोफाइल हटवा",
  NOTIFICATIONS_SETTINGS: "NOTIFICATIONS सेटिंग्ज",
  ENABLE_NOTIFICATIONS_FOR_MATCHES: "सामन्यांसाठी सूचना सक्षम करा",
  WANT_TO_TRY_OUR_PLATFORM: "आमचे प्लॅटफॉर्म वापरून पहायचे आहे?",
  CLICK_HERE_FOR_3_DAYS_FREE_TRIAL:
    "3 दिवसांच्या विनामूल्य चाचणीसाठी येथे क्लिक करा!",
  MAXIMUM_JOBS_TO_APPLY: "अर्ज करण्यासाठी जास्तीत जास्त नोकऱ्या",
  NUMBER_OF_JOBS_SAVES: "नोकरी वाचवण्याची संख्या",
  VALIDITY: "वैधता",
  PRICE: "किंमत",
  PRIVACY_POLICY: "गोपनीयता धोरण",
  TERMS_CONDITIONS: "अटी आणि नियम",
  PRIVACY_PREFERENCES: "गोपनीयता प्राधान्ये",
  NO_NEW_NOTIFICATION: "कोणतीही नवीन सूचना नाही!",
  ADD_EXPERIENCE: "अनुभव जोडा",
  COMPANY_NAME: "कंपनीचे नाव",
  JOB_TYPE: "नोकरीचा प्रकार",
  PLEASE_SELECT_JOB_TYPE: "कृपया नोकरीचा प्रकार निवडा",
  PLEASE_SELECT_JOB_TITLE: "कृपया नोकरीचे शीर्षक निवडा",
  PLEASE_SELECT_JOB_EXPERIENCE: "कृपया नोकरीचा अनुभव निवडा",
  START_YEAR: "प्रारंभ वर्ष",
  SELECT_DATE: "तारीख निवडा",
  END_YEAR: "शेवटचे वर्ष",
  I_AM_CURRENTLY_WORK_HERE: "मी सध्या येथे काम करत आहे",
  SAVE: "जतन करा",
  EDUCATION_TYPE: "शिक्षण प्रकार",
  PLEASE_SELECT_EDUCATION_TYPE: "कृपया शिक्षण प्रकार निवडा",
  INSTITUTE_NAME: "संस्थेचे नाव",
  AREA_OF_STUDY: "अभ्यासाचे क्षेत्र",
  YEAR_OF_GRADUATION: "पदवीचे वर्ष",
  PLEASE_ENTER_EDUCATION_TYPE: "कृपया शिक्षण प्रकार प्रविष्ट करा",
  PLEASE_ENTER_INSTITUTE_NAME: "कृपया संस्थेचे नाव प्रविष्ट करा",
  PLEASE_ENTER_AREA_OF_STUDY: "कृपया अभ्यासाचे क्षेत्र प्रविष्ट करा",
  PLEASE_ENTER_GRADUATION_YEAR: "कृपया पदवी वर्ष प्रविष्ट करा",
  PLEASE_ENTER_VALID_GRADUATION_YEAR: "कृपया वैध पदवी वर्ष प्रविष्ट करा",
  PLEASE_ENTER_COMPANY_NAME: "कृपया कंपनीचे नाव प्रविष्ट करा!",
  PLEASE_CHOOSE_ROLE: "कृपया भूमिका निवडा!",
  PLEASE_CHOOSE_JOB_TYPE: "कृपया नोकरीचा प्रकार निवडा!",
  PLEASE_ADD_EXPERIENCE: "कृपया अनुभव जोडा!",
  PLEASE_ADD_CITY: "कृपया शहर जोडा!",
  PLEASE_ADD_COUNTRY: "कृपया देश जोडा!",
  PLEASE_CHOOSE_START_YEAR: "कृपया प्रारंभ वर्ष निवडा!",
  BEGINNER: "नवशिक्या",
  COMPETENT: "सक्षम",
  PROFICIENT: "प्रवीण",
  EXPERT: "तज्ञ",
  ADD_CERTIFICATE: "प्रमाणपत्र जोडा",
  ADD_LINKEDIN_PROFILE: "लिंक्डइन प्रोफाइल जोडा",
  PLEASE_SELECT_LANGUAGE: "कृपया भाषा निवडा",
  PLEASE_SELECT_INDUSTRIES: "कृपया उद्योग निवडा",
  DESIRED_COUNTRY: "इच्छित देश",
  DESIRED_CITY: "इच्छित शहर",
  PLEASE_SELECT_YOUR_SALARY_RANGE: "कृपया तुमची वेतन श्रेणी निवडा",
  MATCH_PRECENTAGE: "मिळण्याची पूर्वस्थिती",
  PLEASE_SELECT_MATCH_PRECENTTAG: "कृपया जुळणी पूर्वस्थिती निवडा",
  ONSITE: "ऑनसाइट",
  HYBRID: "हायब्रिड",
  REMOTE: "रिमोट",
  UPGRADE_TO_FULL_ACCESS_MSG:
    "आणखी नोकर्‍या जतन/अर्ज करण्‍यासाठी तुम्हाला एकतर पूर्ण प्रवेशावर अपग्रेड करावे लागेल किंवा तुमचा विनामूल्य प्रवेश रीलोड होईपर्यंत प्रतीक्षा करावी लागेल.",
  CANCEL: "रद्द करा",
  RECENT_SEARCHES: "अलीकडील शोध",
  PLEASE_ENTER_YOUR_EMAIL_PHONE_NUMBER:
    "कृपया तुमचा ईमेल/फोन नंबर प्रविष्ट करा!",
  PLEASE_ENTER_YOUR_PASSWORD: "कृपया तुमचा पासवर्ड प्रविष्ट करा!",
  PLEASE_SELECT_USER_TYPE: "कृपया वापरकर्ता प्रकार निवडा",
  JOB_SEEKER: "नोकरी शोधणारा",
  RECRUITER: "रिक्रूटर",
  LOGIN: "लॉगिन",
  LOGIN_INSTRUCTION_LABEL_MSG:
    "तुमच्या खात्यात लॉग इन करण्यासाठी तुमचा सत्यापित ईमेल/फोन नंबर प्रविष्ट करा",
  FORGOT_PASSWORD: "पासवर्ड विसरलात?",
  LOGIN_AGREE_LABEL_MSG: "'लॉगिन' वर क्लिक करून, तुम्ही आमच्या ",
  TERMS: "अटी..",
  LOGIN_PRIVACY_COOKIES_POLICY_LABEL_MSG:
    "आम्ही आपल्या डेटावर प्रक्रिया कशी करतो ते जाणून घ्या",
  PRIVACY_POLICY: "गोपनीयता धोरण.",
  LOGIN_WITH: "यासह लॉगिन करा",
  OR_LOGIN_WITH: "किंवा लॉग इन करा",
  DONT_HAVE_AN_ACCOUNT: "खाते नाही?",
  REGISTER: "नोंदणी करा",
  PLEASE_ENTER_CORRECT_OTP: "कृपया योग्य OTP प्रविष्ट करा!",
  DIDNT_RECEIVE_CODE: "कोड प्राप्त झाला नाही?",
  RESEND: "पुन्हा पाठवा",
  PLEASE_ENTER_YOUR_NAME: "कृपया तुमचे नाव प्रविष्ट करा.",
  PLEASE_ENTER_YOUR_EMAIL: "कृपया तुमचा ईमेल प्रविष्ट करा.",
  PLEASE_ENTER_VALID_EMAIL_ADDRESS: "कृपया वैध ईमेल पत्ता प्रविष्ट करा!",
  PLEASE_ENTER_YOUR_MOBILE_NUMBER: "कृपया तुमचा मोबाईल नंबर प्रविष्ट करा.",
  PLEASE_ENTER_YOUR_CITY: "कृपया तुमचे शहर प्रविष्ट करा.",
  PLEASE_ENTER_YOUR_COUNTRY: "कृपया तुमचा देश प्रविष्ट करा.",
  PLEASE_SELECT_YOUR_DATE_OF_BIRTH: "कृपया तुमची जन्मतारीख निवडा!",
  PLEASE_DESCRIBE_YOUR_SELF: "कृपया स्वतःचे वर्णन करा!",
  PLEASE_ENTER_YOUR_PASSWORD: "कृपया तुमचा पासवर्ड प्रविष्ट करा.",
  VALID_PASSWORD_MSG:
    "पासवर्ड किमान एक अप्परकेस, लोअरकेस, अंकीय आणि विशेष वर्णांचे मिश्रण असावे.",
  PLEASE_ENTER_YOUR_CONFIRM_PASSWORD:
    "कृपया तुमचा पुष्टी संकेतशब्द प्रविष्ट करा.",
  PASSWORD_DO_NOT_MATCH: "पासवर्ड जुळत नाही!",
  UPLOAD_PROFILE_PICTURE: "प्रोफाइल चित्र अपलोड करा",
  UPLOAD_FROM_STORAGE: "स्टोरेजमधून अपलोड करा",
  CAMERA: "कॅमेरा",
  CONFIRM: "पुष्टी करा",
  PLEASE_ENTER_YOUR_DEPARTMENT: "कृपया तुमचा विभाग प्रविष्ट करा!",
  PLEASE_SELECT_YOUR_ROLE: "कृपया तुमची भूमिका निवडा!",
  PLEASE_ENTER_YOUR_COMPANY_NAME: "कृपया आपल्या कंपनीचे नाव प्रविष्ट करा!",
  PLEASE_SELECT_YOUR_COMPANY_SIZE: "कृपया तुमच्या कंपनीचा आकार निवडा!",
  PLEASE_ENTER_YOUR_VAT_COMPANY_REGISTRATION_NUMBER:
    "कृपया तुमचा VAT/कंपनी नोंदणी क्रमांक प्रविष्ट करा!",
  PLEASE_ADD_COMPANY_LINKEDIN_PROFILE: "कृपया कंपनी लिंक्डइन प्रोफाइल जोडा!",
  SUCCESSFULLY_REGISTER: "यशस्वीपणे नोंदणी करा.",
  PLEASE_SELECT_INDUSTRY: "कृपया उद्योग निवडा",
  DEPARTMENT: "विभाग",
  PLEASE_SELECT_SIZE: "कृपया आकार निवडा",
  VAT_COMPANY_REGISTRATION_NUMBER: "VAT/कंपनी नोंदणी क्रमांक",
  ADD_COMPANY_LINKEDIN_PROFILE: "कंपनी लिंक्डइन प्रोफाइल जोडा",
  YOUR_PASSWORD_HAS_BEEN_CHANGED_Successfully:
    "तुमचा पासवर्ड यशस्वीरित्या बदलला गेला आहे!",
  PLEASE_ENTER_CURRENT_PASSWORD: "कृपया वर्तमान पासवर्ड प्रविष्ट करा.",
  PLEASE_ENTER_NEW_PASSWORD: "कृपया नवीन पासवर्ड प्रविष्ट करा.",
  PLEASE_ENTER_CONFIRM_NEW_PASSWORD: "कृपया नवीन पासवर्डची पुष्टी करा.",
  CURRENT_PASSWORD_AND_NEW_PASSWORD_SAME:
    "वर्तमान पासवर्ड आणि नवीन पासवर्ड समान!",
  PASSWORD_DOES_NOT_MATCH: "पासवर्ड जुळत नाही!",
  ENTER_CURRENT_PASSWORD: "चालू पासवर्ड प्रविष्ट करा",
  ENTER_NEW_PASSWORD: "नवीन पासवर्ड प्रविष्ट करा",
  CONFIRM_NEW_PASSWORD: "नवीन पासवर्डची पुष्टी करा",
  SUBMIT: "सबमिट करा",
  PLEASE_ADD_YOUR__PHOTO: "कृपया आपला फोटो जोडा!",
  PLEASE_ENTER_ABOUT_COMPANY: "कृपया कंपनीबद्दल प्रविष्ट करा!",
  PLEASE_ENTER_INDUSTRY_NAME: "कृपया उद्योगाचे नाव प्रविष्ट करा!",
  COPY_JOB_AD: "नोकरी जाहिरात कॉपी करा",
  COMPANY_INFO: "कंपनी माहिती",
  ABOUT_THE_COMPANY: "कंपनीबद्दल",
  VIDEO_LENGTH_SHOULD_NOT_BE_MORE_THAN_45_SEC:
    "व्हिडिओची लांबी ४५ सेकंदांपेक्षा जास्त नसावी.!",
  PLEASE_ENTER_JOB_DESCRIPTION: "कृपया नोकरीचे वर्णन प्रविष्ट करा!",
  PLEASE_SELECT_JOB_TYPE: "कृपया नोकरीचा प्रकार निवडा!",
  PLEASE_SELECT_REQUIRE_EXPERIENCE: "कृपया अनुभव आवश्यक निवडा!",
  PLEASE_SELECT_WORK_LOCATION: "कृपया कार्य स्थान निवडा!",
  PLEASE_SELECT_LANGUAGE_REQUIRE: "कृपया आवश्यक भाषा निवडा!",
  PLEASE_SELECT_LANGUAGE_FOR_JOB_POSTING: "कृपया जॉब पोस्टिंगसाठी भाषा निवडा!",
  PLEASE_ADD_DURATION_OF_ADV: "कृपया adv चा कालावधी जोडा!",
  JOB_INFO: "नोकरी माहिती",
  ADD_VIDEO: "व्हिडिओ जोडा",
  PART_TIME: "पार्ट टाइम",
  FULL_TIME: "पूर्ण वेळ",
  EXPERIENCE_LEVEL: "अनुभव पातळी",
  LIST_OF_LANGUAGES: "भाषांची सूची",
  JOB_POSTING_LANGUAGE: "नोकरी पोस्टिंग भाषा",
  AD_DURATION: "जाहिरात कालावधी",
  JOB_COPIED_SUCCESSFULLY: "नोकरी यशस्वीरित्या कॉपी केली.",
  PREREQUISITES: "आवश्यकता",
  ADD_SKILLS: "कौशल्य जोडा",
  ADD_TOOLS: "साधने जोडा",
  CREATE_JOB_AD: "नोकरी जाहिरात तयार करा",
  JOB_CREATED_SUCCESSFULLY: "नोकरी यशस्वीरित्या तयार झाली",
  OPPS_NO_JOBS_FOUND: "OPPS! नोकऱ्या सापडल्या नाहीत!",
  OPPS_NO_PROFILES_FOUND: "OPPS! कोणतेही प्रोफाइल आढळले नाहीत!",
  LOADING: "लोड करत आहे...",
  THIS_IS_TEMPORARY_I_WILL_BE_BACK: "हे तात्पुरते आहे, मी परत येईन.",
  I_GET_TOO_MANY_NOTIFICATIONS: "मला बर्‍याच सूचना मिळतात.",
  I_SPEND_TOO_MUCH_TIME_IN_PROW: "मी ProW मध्ये खूप वेळ घालवतो.",
  MY_ACCOUNT_WAS_HACKED: "माझे खाते हॅक झाले आहे.",
  I_DIDNT_FIND_PROW_USEFUL: "मला ProW उपयुक्त वाटले नाही.",
  I_HAVE_A_PRIVATE_CONCERN: "मला एक खाजगी चिंता आहे.",
  DELETE_ACCOUNT: "खाते हटवा",
  SELECT_A_REASON_FOR_DELETING_THE_ACCOUNT: "खाते हटवण्याचे कारण निवडा",
  OTHER: "इतर",
  DELETE_YOUR_ACCOUNT_DO_YOU_WISH_TO_CONTINUE:
    "एकदा तुम्ही तुमचे खाते हटवले की, तुमचा सर्व डेटा नष्ट होईल आणि तुम्ही यापुढे तुमच्या खात्यात प्रवेश करू शकणार नाही. तुम्ही पुढे चालू ठेवू इच्छिता?",
  NO: "नाही",
  YES: "होय",
  YOUR_ACCOUNT_HAS_BEEN_DELETED_SUCCESSFULLY_CLICK_CONTINUE_TO_LOGOUT:
    "तुमचे खाते यशस्वीरित्या हटविले गेले आहे! लॉगआउट करण्यासाठी सुरू ठेवा क्लिक करा.",
  EDIT_JOB_AD: "नोकरी जाहिरात संपादित करा",
  JOB_UPDATED_SUCCESSFULLY: "नोकरी यशस्वीरित्या अद्यतनित केली",
  SEARCH: "शोध...",
  SAVED_PROFILES: "सेव्ह केलेले प्रोफाइल",
  MATCHED_PROFILES: "जुळलेल्या प्रोफाइल",
  OPEN: "उघडा",
  PAUSED: "विराम दिला",
  PAUSE: "विराम द्या",
  CLOSED: "बंद",
  CLOSE: "बंद करा",
  RESUME: "पुन्हा सुरू करा",
  JOB_ADS: "नोकरी जाहिराती",
  REQUIRED_LANGUAGE: "आवश्यक भाषा",
  NO_VIDEO_FOUND: "कोणताही व्हिडिओ सापडला नाही!",
  ARE_You_SURE_WANT_TO_MAKE_DUPLICATE_JOB:
    "तुम्हाला खात्री आहे की डुप्लिकेट बनवायचे आहे का?, कृपया लक्षात घ्या की ही क्रिया या नोकरीची डुप्लिकेट तयार करेल.",
  NO_PROFILES_SAVED: "कोणतीही प्रोफाइल जतन केलेली नाही.",
  ABOUT_THE_JOB: "नोकरीबद्दल",
  AD_DETAILS: "AD तपशील",
  ARE_YOU_SURE_WANT_TO_LOGOUT: "तुम्हाला नक्की लॉगआउट करायचे आहे का?",
  SELECT_YEAR: "वर्ष निवडा",
  PLEASE_ENTER_INDUSTRY_PREFERENCE: "कृपया उद्योग प्राधान्य प्रविष्ट करा!",
  PLEASE_ENTER_ROLE_PREFERENCE: "कृपया भूमिका प्राधान्य प्रविष्ट करा!",
  PLEASE_ENTER_JOB_TYPE_PREFERENCE: "कृपया जॉब प्रकार प्राधान्य प्रविष्ट करा!",
  PLEASE_SELECT_YOUR_PREFERENCE_COUNTRY: "कृपया तुमचा प्राधान्य देश निवडा!",
  PLEASE_SELECT_YOUR_PREFERENCE_CITY: "कृपया तुमचे प्राधान्य शहर निवडा!",
  PLEASE_SELECT_MONTHLY_SALARY_PREFERENCE: "कृपया मासिक वेतन प्राधान्य निवडा!",
  INDUSTRIES: "इंडस्ट्रीज",
  PERCENTAGE: "टक्केवारी",
  PLEASE_SELECT_PERCENTAGE: "कृपया टक्केवारी निवडा",
  PLEASE_ADD_YOUR_CURRENT_EXPERIENCE_LEVEL:
    "कृपया तुमचा वर्तमान अनुभव स्तर जोडा!",
  PLEASE_ADD_YOUR_CUURENT_DESIGNATION: "कृपया तुमचे क्यूरेंट पद जोडा!",
  PLEASE_SELECT_YOUR_SKILLS: "कृपया तुमची कौशल्ये निवडा",
  PLEASE_SELECT_YOUR_TOOLS: "कृपया तुमची साधने निवडा",
  DO_YOU_HAVE_DRIVING_LICENSE: "तुमच्याकडे ड्रायव्हिंग लायसन्स आहे का?",
  CURRENT_EXPERIENCE_LEVEL: "वर्तमान अनुभव पातळी",
  PHONE_NUMBER_VERIFIED_SUCESSFULLY: "फोन नंबरची यशस्वीपणे पडताळणी झाली",
  EMAIL_VERIFIED_SUCESSFULLY: "ईमेल यशस्वीरित्या सत्यापित झाले",
  PLEASE_ENTER_PHONE_NUMBER: "कृपया फोन नंबर प्रविष्ट करा!",
  OTP_VERIFICATION: "Otp सत्यापन",
  PLEASE_ENTER_THE_4_DIGIT_CODE_SENT_TO:
    "कृपया पाठवलेला ४ अंकी कोड प्रविष्ट करा",
  YOU_NEED_TO_VERIFIY_YOUR_EMAIL_PLEASE_VERIFIY:
    "तुम्हाला तुमचा ईमेल सत्यापित करणे आवश्यक आहे, कृपया सत्यापित करा",
  OK: "ठीक आहे",
  YOU_ARE_ALREADY_USING_THIS_EMAIL_NO_NEED_OF_VERIFICATION:
    "तुम्ही आधीच हा ईमेल वापरत आहात, पडताळणीची गरज नाही",
  YOU_NEED_TO_VERIFIY_YOUR_PHONE_NUMBER_PLEASE_VERIFIY:
    "तुम्हाला तुमचा फोन नंबर सत्यापित करणे आवश्यक आहे, कृपया सत्यापित करा",
  YOU_ARE_ALREADY_USING_THIS_PHONE_NUMBER_NO_NEED_OF_VERIFICATION:
    "तुम्ही हा फोन नंबर आधीच वापरत आहात, पडताळणीची गरज नाही",
  PLEASE_ENTER_INDUSTRY: "कृपया उद्योगात प्रवेश करा!",
  UPDATE_COMPANY_INFO: "कंपनी माहिती अपडेट करा",
  PRESENT: "वर्तमान",
  ARE_YOU_ACTIVELY_HIRING: "तुम्ही सक्रियपणे कामावर घेत आहात?",
  ACTIVELY_HIRING_FOR: "सक्रियपणे कामावर घेणे",
  ACTIVELY_LOOKING_FOR: "सक्रियपणे नोकरी शोधत आहे",
  MATCHES: "सामने",
  CREATE_NEW_JOB_AD: "नवीन नोकरी जाहिरात तयार करा",
  SEE_ALL_ADS: "सर्व जाहिराती पहा",
  BASIC_INFO: "मूलभूत माहिती",
  ARE_YOU_LOOKING_FOR_A_JOB: "तुम्ही नोकरी शोधत आहात?",
  TUTORIALS: "ट्यूटोरियल",
  SKIP: "skip",
  TOOLBAR: "टूलबार",
  TYPE_HERE: "येथे टाइप करा",
  GET_STARTED: "प्रारंभ करा",
  PLEASE_ADD_YOUR_LINKEDIN_PROFILE: "कृपया तुमचे लिंक्डइन प्रोफाइल जोडा",
  PLEASE_SELECT_DID_You_HAVE_DRIVING_LICENSE:
    "कृपया तुमच्याकडे ड्रायव्हिंग लायसन्स आहे का ते निवडा!",
  SOMTHING_WENT_TO_WRONG: "काहीतरी चूक झाली!",
  MATCH_WITH_JOB: "नोकरीशी जुळवा",
  TOOLS_REQUIRED: "आवश्यक साधने",
  DRIVING_LICENSE: "ड्रायव्हिंग लायसन्स",
  PROFILE: "प्रोफाइल",
  ADVANCED_SKILLS: "प्रगत कौशल्ये",
  UPLOAD_VIDEO: "व्हिडिओ अपलोड करा",
  YOUR_PREFERRED_INDUSTRY: "तुमचा पसंतीचा उद्योग निवडा",
  LOOKING_FOR_A_JOB: "नोकरी शोधत आहात?",
  FILTERS: "फिल्टर्स",
  PLAY_INTRO_VIDEO: "परिचय व्हिडिओ प्ले करा",
  WORKPLACE: "कार्यस्थळ",
  APPLY: "अर्ज करा",
  MENU: "मेनू",
  NOTIFICATIONS: "सूचना",
  SEE_ALL: "सर्व पहा",
  SEND: "पाठवा",
  HIDE_STATUS_FOR_RECRUITERS: "नियुक्तीसाठी स्थिती लपवा",
  HOME: "घर",
  CHAT: "चॅट",
  NOT_INTERESTED: "स्वारस्य नाही",
  BLOCK_COMPANY: "ब्लॉक कंपनी",
  EXPLORE: "एक्सप्लोर करा",
  MATCHING_JOBS: "जुळणाऱ्या नोकऱ्या",
  MATCHING_PERCENTAGE: "जुळणारी टक्केवारी",
  MATCH: "सामना",
  LOOKING_TO_HIRE_PEOPLE: "लोकांना कामावर ठेवायचे आहे?",
  SELECT_YOUR_PREFERRED_ROLE: "तुमची पसंतीची भूमिका निवडा.",
  ACTIVELY_HIRING: "सक्रियपणे कामावर घेणे",
  COMPANY: "कंपनी",
  LOCATION: "स्थान",
  COMPANY_SIZE: "कंपनीचा आकार",
  COMPANY_LINKEDIN_PROFILE: "कंपनी लिंक्डइन प्रोफाइल",
  JOBS: "नोकरी",
  EDIT_BASIC_INFO: "मूलभूत माहिती संपादित करा",
  EDIT_COMPANY_INFO: "कंपनी माहिती संपादित करा",
  BLOCK: "ब्लॉक",
  NO_OF_JOB_SAVES: "नोकरी बचतीची संख्या",
  OUR_PLANS: "आमच्या योजना",
  FEEDBACK_AND_SUGGESTIONS: "फीडबॅक आणि सूचना",
  ACCOUNT: "खाते",
  SEARCH_HERE: "येथे शोधा",
  TOP_MATCHES: "टॉप मॅचेस",
  RECENT_CHATS: "अलीकडील गप्पा",
  BLOCK_COMPANY_LABEL_MSG:
    "एकदा तुम्ही एखाद्या कंपनीला ब्लॉक केल्यानंतर, तुम्ही त्यांच्याद्वारे पोस्ट केलेली नोकरीची जाहिरात पाहू शकणार नाही.",
  BLOCKED_COMPANY_LIST: "ब्लॉक केलेली कंपनी सूची",
  ACTION: "कृती",
  UNBLOCK: "अनब्लॉक",
  RECRUITER_INFO: "रिक्रूटर माहिती",
  TYPE_NAME_HERE: "येथे नाव टाइप करा",
  COMPANY_BLOCKED_SUCESSFULLY: "कंपनी यशस्वीरित्या अवरोधित केली",
  PLEASE_ENTER_YOUR_MESSAGE: "कृपया तुमचा संदेश प्रविष्ट करा!",
  MEASSAGE_SENT_SUCCESSFULLY: "संदेश यशस्वीरित्या पाठवला",
  PLEASE_CREATE_JOB_FIRST: "कृपया आधी नोकरी तयार करा!",
  MATCHED_JOBS_NOT_FOUND: "जुळलेल्या नोकऱ्या सापडल्या नाहीत!",
  MATCHED_PROFILES_NOT_FOUND: "जुळलेल्या प्रोफाइल आढळल्या नाहीत!",
  NO_NEW_RECORDS_FOUND: "कोणतेही नवीन रेकॉर्ड सापडले नाहीत",
  PLEASE_SELECT_MATCH_FOR: "कृपया साठी जुळणी निवडा",
  PLEASE_ENTER_YOUR_MOBILE_NUMBER_OR_EMAIL:
    "कृपया तुमचा मोबाईल नंबर किंवा ईमेल प्रविष्ट करा",
  ENTER_YOUR_MOBILE_NUMBER_OR_EMAIL_ID_TO_CONTINUE:
    "सुरू ठेवण्यासाठी तुमचा मोबाईल नंबर किंवा ईमेल आयडी टाका",
  MOBILE_NUMBER_OR_EMAIL_ID: "मोबाइल नंबर किंवा ईमेल आयडी",
  EMAIL_OR_PHONE: "ईमेल किंवा फोन",
  ENTER_THE_CODE_SENT_YOUR_REGISTERED_MOBILE_NUMBER_EMAIL_ID_TO_CONTINUE:
    "सुरू ठेवण्यासाठी तुमचा नोंदणीकृत मोबाईल नंबर/ईमेल आयडी पाठवलेला कोड एंटर करा",
  AD_ID: "जाहिरात आयडी",
  VALID_UNTIL: "पर्यंत वैध",
  COMPANY_INFO: "कंपनी माहिती",
  ARE_YOU_SURE_YOU_WANT_TO: "तुम्हाला नक्की करायचे आहे का",
  THE_JOB: "नोकरी?",
  SELECT_JOB: "नोकरी निवडा",
  NO_RECORDS_FOUND: "कोणतेही रेकॉर्ड सापडले नाही",
  NO_RECENT_SEARCHES: "कोणतेही अलीकडील शोध नाहीत",
  REJECT: "नकार द्या",
  ACCEPT: "स्वीकारा",
  EDIT_EDUCATION_INFO: "शिक्षण माहिती संपादित करा",
  EDIT_EXPRIENCE_INFO: "अनुभव माहिती संपादित करा",
  EDIT_ADVANCED_SKILLS: "संपादित_प्रगत_कौशल्य",
  EDIT_PREFERENCES: "प्राधान्य संपादित करा",
  PREV: "मागील",
  NEXT: "पुढील",
  ADD_JOB_INFO: "नोकरी माहिती जोडा",
  WELCOME_LABEL: "स्वागत आहे",
  PRODUCT_INTRO:
    "नोकरी शोधणार्‍यांसाठी आणि भर्ती करणार्‍यांसाठी डिझाइन केलेले. तुमचे नियुक्तीचे निर्णय जलद, सोपे घ्या आम्ही तुम्हाला नोकरी आणि प्रोफाइलची टक्केवारी अचूक जुळणारी सांगतो.",
  PH_EMAIL_PHONE_NUMBER: "ईमेल/फोन नंबर",
  PASSWORD: "पासवर्ड",
  ERROR_MSG_INVALID_CREDENTIALS: "अवैध क्रेडेन्शियल",
  REGISTER_AS: "म्हणून नोंदणी करा",
  CONFIRM_PASSWORD: "पासवर्डची पुष्टी करा",
  REGISTER_INTO: "प्लॅटफॉर्मवर नोंदणी करण्यासाठी तपशील प्रविष्ट करा",
  ALERT_OTP_NOT_VERIFIED: "OTP सत्यापित नाही",
  ALREADY_HAVE_A_ACCOUNT_LABLE: "आधीपासूनच खाते आहे?",
  SETUP_YOUR_PROFILE: "तुमचे प्रोफाइल सेट करण्यासाठी तपशील प्रविष्ट करा",
  ROLE: "भूमिका",
  ADD_YOUR_COMPANY_LINKEDIN_PROFILE: "तुमची कंपनी लिंक्डइन प्रोफाइल जोडा",
  FORGOT_PASSWORD_HEADING: "पासवर्ड विसरलात",
  DATA_UPDATE_SUCCESSFULLY: "डेटा अपडेट यशस्वीरित्या",
  PLEASE_SELECT_JOB_TITLE: "कृपया नोकरीचे शीर्षक निवडा",
  SELECT_EXPERIENCE: "अनुभव निवडा",
  SELECT_COUNTRY: "देश निवडा",
  SELECT_CITY: "शहर निवडा",
  SELECT_START_YEAR: "प्रारंभ वर्ष निवडा",
  SELECT_END_YEAR: "अंतिम वर्ष निवडा",
  DELETE: "हटवा",
  EDIT: "संपादित करा",
  EDIT_EXPERIENCE: "अनुभव संपादित करा",
  COMPANY_UNBLOCK_SUCESSFULLY: "कंपनी यशस्वीरित्या अनब्लॉक करा",
  ADD_EXPERIENCE: "अनुभव जोडा",
  SETUP_YOUR_PROFILE: "तुमचे प्रोफाइल सेट करा",
  INTERMEDIATE: "मध्यवर्ती",
  DESIGNATION: "पदनाम",
  ROLES: "भूमिका",
  ENTER_FULL_NAME: "पूर्ण नाव प्रविष्ट करा",
  ENTER_EMAIL: "ईमेल प्रविष्ट करा",
  ENTER_DATE_OF_BIRTH: "जन्मतारीख प्रविष्ट करा",
  ENTER_PHONE_NUMBER: "फोन नंबर प्रविष्ट करा",
  SELECT_COUNTRY_CODE: "देश कोड निवडा",
  ENTER_CITY: "शहर प्रविष्ट करा",
  ENTER_ABOUT_YOU: "आपल्याबद्दल प्रविष्ट करा",
  ENTER_COUNTRY: "देश प्रविष्ट करा",
  JOB_DESCRIPTION: "नोकरीचे वर्णन",
  REQUIRED_EXPERIENCE: "आवश्यक अनुभव",
  ADD_PREFERENCES: "प्राधान्ये जोडा",
  LANGAUGES: "भाषा",
  SELECT_SALARY: "पगार निवडा",
  SELECT: "निवडा",
  ENTER_COMPANY_COUNTRY: "कंपनी देश प्रविष्ट करा",
  ENTER_YOUR_JOB_TITLE: "तुमचे नोकरीचे शीर्षक प्रविष्ट करा",
  ENTER_COMPANY_SIZE: "कंपनी आकार प्रविष्ट करा",
  ENTER_COMPANY_CITY: "कंपनी शहर प्रविष्ट करा",
  ENTER_DEPARTMENT: "विभागात प्रवेश करा",
  ENTER_INDUSTRY: "उद्योगात प्रवेश करा",
  SELECT_JOB_TITLE: "नोकरीचे शीर्षक निवडा",
  SELECT_EDUCATION_TYPE: "शिक्षण प्रकार निवडा",
  ENTER_INTITUTE_NAME: "इंटिट्युटचे नाव प्रविष्ट करा",
  ENTER_AREA_OF_STUDY: "अभ्यासाचे क्षेत्र प्रविष्ट करा",
  ENTER_YEAR_OF_GRADUATION: "पदवीचे वर्ष प्रविष्ट करा",
  EDIT_EDUCATION: "शिक्षण संपादित करा",
  INTERESTED: "रुची आहे",
  SKILLS_REQUIRED: "कौशल्य आवश्यक",
  MATCH_PERCENTAGE: "मॅच टक्केवारी",
  VIDEO_RECORD_TIME_MSG: "व्हिडिओ फक्त 45 सेकंदांसाठी रेकॉर्ड केला जाईल",
  START_RECORDING: "रेकॉर्डिंग सुरू करा",
  STOP_RECORDING: "रेकॉर्डिंग थांबवा",
  ENTER_SUBJECT: "विषय प्रविष्ट करा",
  ENTER_MESSAGE: "संदेश प्रविष्ट करा",
  MESSAGE_SENT_SUCCESSFULLY: "संदेश यशस्वीरीत्या पाठवला",
  JOB_SAVED_SUCCESSFULLY: "नोकरी यशस्वीरित्या जतन झाली",
  JOB_NOT_SAVED_SUCCESSFULLY: "नोकरी यशस्वीरित्या जतन झाली नाही",
  SELECT_AN_ITEM: "एक आयटम निवडा",
  ERROR_TRY_AFTER_SOMETIME: "काही त्रुटी आली. कृपया काही वेळानंतर प्रयत्न करा",
  REASON_NEEDED_PROCEED: "पुढे जाण्यासाठी कारण आवश्यक आहे",
  S_No: "S.No",
  PERSONAL_BANKED_TO_LOGIN: "लॉगिन करण्यासाठी तुमची वैयक्तिक बँक प्रविष्ट करा.",
  VALID_DIGIT_OTP: "कृपया वैध 4 अंकी OTP प्रविष्ट करा",
  GO_BACK: "परत जा",
  SETUP_CAREER_PROFILE: "तुमचा अनुभव जोडून तुमचे करिअर प्रोफाइल सेट करा",
  ADD_EDUCATION_LEVELS: "शिक्षण पातळी जोडा",
  SELECT_EDUCATION_TYPE: "शिक्षण प्रकार निवडा",
  ENTER_EMAIL_IN_CURRECT_FORMAT: "ईमेल योग्य स्वरूपात प्रविष्ट करा",
  ENTER_PASSWORD: "पासवर्ड प्रविष्ट करा",
  PASSWORDS_DOES_NOT_MATCH: "संकेतशब्द जुळत नाहीत",
  COOKIES_POLICY: "कुकीज धोरण",
  PERSONAL_ID: "वैयक्तिक आयडी",
  UPLOAD_PICTURE: "चित्र अपलोड करा",
  ADD_EDUCATION: "शिक्षण जोडा",
  CHECKOUT_MORE_PROFILES:"एक्सप्लोर विभागावर अधिक प्रोफाइल तपासा",
};
