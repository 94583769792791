import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";
import chevron_right from "../../assets/img/chevron_right_colored.svg";
import camera from "../../assets/img/camera.svg";
import default_profile_pic from "../../assets/img/profile_pic.svg";
import {
  getWithAuthCallWithErrorResponse,
  putMultipartWithAuthCallWithErrorResponse,
} from "../../api/ApiServices";
import ApiConfig from "../../api/ApiConfig";
import ReactTooltip from "react-tooltip";
import SpinnerCmp from "../../sharedComponents/SpinnerCmp";
import moment from "moment";
import Select from "react-select";
import Country from "../../assets/CountrtiesData/Country";
import listContryCity from "../../assets/CountrtiesData/CoutrycityList";
import { toast } from "react-toastify";
import useTranslation from "../../customHooks/translations";

function EditBasicProfileInfo() {
  const translation = useTranslation();
  const [state, setState] = useState({
    isProcessing: false,
    errMsg: {},
    full_name: "",
    profile_pic: "",
    city: "",
    country: "",
    email: "",
    phone_number: "",
    country_code: "",
    dob: "",
    selected_dob: "",
    about: "",
    user_profile_pic: default_profile_pic,
    profile_pic_name: "",
    TodayDate_yyyy_mm_dd: moment().format("YYYY-MM-DD"),
    selected_Country: {},
    selected_City: {},
  });

  const [countriesState, setCountriesState] = useState();
  const [citiesState, setCitiesState] = useState();

  const getRecruiterDetails = async () => {
    setState({ ...state, isProcessing: true });
    getWithAuthCallWithErrorResponse(ApiConfig.RECRUITER_DETAILS_API)
      .then((data) => {
        if (data.error == true) {
          setState({ ...state, isProcessing: false });
        } else {
          var full_name = data.json.user.full_name;
          var profile_pic = data.json.user.profile_pic;
          var city = data.json.user.city;
          var country = data.json.user.country;
          var email = data.json.user.email;
          var phone_number = data.json.user.phone_number;
          var dob = data.json.user.dob;
          var about = data.json.user.about;
          var country_code = data.json.user.country_code;
          var selected_dob = dob.split("-").reverse().join("-");

          setState({
            ...state,
            full_name: full_name,
            city: city,
            country: country,
            selected_Country: { label: country, value: country },
            selected_City: { label: city, value: city },
            email: email,
            phone_number: phone_number,
            dob: dob,
            selected_dob: selected_dob,
            about: about,
            user_profile_pic: profile_pic ? profile_pic : default_profile_pic,
            country_code: country_code,
          });
        }
      })
      .catch((error) => {
        setState({ ...state, isProcessing: false });
      });
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleImageChange = async (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      /*this.setState({
          image: file,
          imagePreviewUrl: reader.result
      });*/
      setState({
        ...state,
        profile_pic: file,
        profile_pic_name: file.name,
        user_profile_pic: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  const updateProfile = async () => {
    // debugger
    if (
      state.phone_number == "" ||
      state.phone_number == null ||
      state.full_name == "" ||
      state.full_name == null ||
      state.city == "" ||
      state.city == null ||
      state.country == "" ||
      state.country == null ||
      state.email == "" ||
      state.email == null ||
      state.dob == "" ||
      state.dob == null ||
      state.about == "" ||
      state.about == null ||
      state.country_code == "" ||
      state.country_code == null
    ) {
      if (state.full_name == "" || state.full_name == null) {
        setState({ ...state, errMsg: { full_name: translation.ENTER_FULL_NAME } });
      } else if (state.email == "" || state.email == null) {
        setState({ ...state, errMsg: { email: translation.ENTER_EMAIL } });
      } else if (state.dob == "" || state.dob == null) {
        setState({ ...state, errMsg: { dob: translation.ENTER_DATE_OF_BIRTH } });
      } else if (state.phone_number == "" || state.phone_number == null) {
        setState({ ...state, errMsg: { phone_number: translation.ENTER_PHONE_NUMBER } });
      } else if (state.country_code == "" || state.country_code == null) {
        setState({
          ...state,
          errMsg: { phone_number: translation.SELECT_COUNTRY_CODE },
        });
      } else if (state.city == "" || state.city == null) {
        setState({ ...state, errMsg: { city: translation.ENTER_CITY } });
      } else if (state.country == "" || state.country == null) {
        setState({ ...state, errMsg: { country: translation.ENTER_COUNTRY } });
      } else if (state.about == "" || state.about == null) {
        setState({ ...state, errMsg: { about: translation.ENTER_ABOUT_YOU } });
      }
    } else {
      let formData = new FormData();
      formData.append("phone_number", state.phone_number);
      formData.append("full_name", state.full_name);
      formData.append("city", state.city);
      formData.append("country", state.country);
      formData.append("email", state.email);
      formData.append("dob", state.dob);
      formData.append("about", state.about);
      formData.append("profile_pic", state.profile_pic);
      formData.append("country_code", state.country_code);

      putMultipartWithAuthCallWithErrorResponse(
        ApiConfig.UPDATE_PROFILE_API,
        formData
      )
        .then((data) => {
          if (data.error == false) {
            window.location.href = "/recruiter-profile";
          } else {
            setState({ ...state, errorMsg: data });
            toast.error(data.json.message, { autoClose: 1000 });
          }
        })
        .catch((error) => {
          console.log("api response", error);
        });
    }
  };

  /* city section starts */
  function getCitiesList(selected_country) {
    Object.keys(listContryCity.listContryCity).forEach(function (key, index) {
      var newJson = listContryCity.listContryCity[key];
      if (newJson.country == selected_country) {
        updateCity(newJson.city);
      }
    });
  }

  function updateCity(data) {
    let temp_cities = [];
    data.map((item, index) => {
      temp_cities.push({ label: item, value: item });
    });
    setCitiesState(temp_cities);
  }

  function onCityChange(value) {
    setState({ ...state, selected_City: value, city: value.label });
  }
  /* city section ends */

  /* country section starts */
  const selectStyles = {
    control: (provided) => ({ ...provided, backgroundColor: 'transparent', borderRadius: "8px", border: "1px solid #b3c2c9", fontSize: "13px" }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)", backgroundColor: 'transparent' }),
  };

  function getCountriesList() {
    setCountriesState(Country.Country);
  }

  function onCountryChange(value) {
    setState({ ...state, selected_Country: value, country: value.label });
    getCitiesList(value.label);
  }
  /* country section ends */

  useEffect(() => {
    getCountriesList();
    getRecruiterDetails();
  }, []);

  return (
    <div>
      <RecruiterHeader />
      <ReactTooltip />

      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="/recruiter-profile">{translation.PROFILE}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.EDIT_BASIC_INFO}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <SpinnerCmp loading={state.isProcessing} />
      <section
        id="settings"
        className="settings"
        style={{ display: state.isProcessing ? "none" : "" }}
      >
        <div className="container">
          <div className="row position-relative">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div
                className="card login settings_card"
                style={{ marginTop: "7em" }}
              >
                <div className="card-body">
                  <div className="form_block w-100 pt-0">
                    <form
                      className="row g-3 text-start"
                      encType="multipart/form-data"
                    >
                      <div className="col-md-12">
                        <div className="upload_profileimg mb-4">
                          <div className="profle_circle">
                            <div className="profle_upload">
                              <img
                                src={state.user_profile_pic}
                                alt=""
                                style={{
                                  borderRadius: "50%",
                                  width: "94px",
                                  height: "94px",
                                }}
                              />
                              <a
                                href="#"
                                onClick={handleClick}
                                data-tip={translation.UPLOAD_PROFILE_PICTURE}
                              >
                                <img
                                  src={camera}
                                  className="upload-button"
                                  alt=""
                                />
                              </a>
                              <input
                                type="file"
                                ref={hiddenFileInput}
                                style={{ display: "none" }}
                                accept="image/*"
                                id="profilePicFile"
                                onChange={(e) => handleImageChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label htmlFor="full_name" className="form-label fs-14">
                          {translation.FULLNAME}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="full_name"
                          value={state.full_name}
                          onChange={(e) => {
                            setState({ ...state, full_name: e.target.value });
                          }}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.full_name ? state.errMsg.full_name : ""}
                        </span>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label htmlFor="email" className="form-label fs-14">
                          {translation.EMAIL}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          value={state.email}
                          onChange={(e) => {
                            setState({ ...state, email: e.target.value });
                          }}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.email ? state.errMsg.email : ""}
                        </span>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label htmlFor="DOB" className="form-label fs-14">
                          {translation.DATE_OF_BIRTH}
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="DOB"
                          max={state.TodayDate_yyyy_mm_dd}
                          value={state.selected_dob}
                          onChange={(e) => {
                            setState({
                              ...state,
                              dob: e.target.value,
                              selected_dob: e.target.value,
                            });
                          }}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.dob ? state.errMsg.dob : ""}
                        </span>
                      </div>
                      <div className="col-md-6 regsiterrf">
                        <label
                          htmlFor="phone_number"
                          className="form-label fs-14"
                        >
                          {translation.MOBILE_NUMBER}
                        </label>
                        <div className="input-group country-code-select">
                          <select
                            className="form-select bg-transparent"
                            aria-label="Default select example"
                            onChange={(e) => {
                              setState({
                                ...state,
                                country_code: e.target.value,
                              });
                            }}
                            value={state.country_code}
                          >
                            <option></option>
                            <option value="+91">+91</option>
                            <option value="+45">+45</option>
                            <option value="+14">+14</option>
                          </select>
                          <input
                            type="text"
                            className="form-control"
                            id="phone_number"
                            value={state.phone_number}
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (
                                e.target.value === "" ||
                                re.test(e.target.value)
                              ) {
                                setState({
                                  ...state,
                                  phone_number: e.target.value,
                                });
                              }
                            }}
                          />
                        </div>
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.phone_number
                            ? state.errMsg.phone_number
                            : ""}
                        </span>
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label htmlFor="country" className="form-label fs-14">
                          {translation.COUNTRY}
                        </label>

                        <Select
                          isClearable={false}
                          onChange={onCountryChange}
                          options={countriesState}
                          placeholder={translation.SEARCH}
                          styles={selectStyles}
                          //tabSelectsValue={false}
                          value={state.selected_Country}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.country ? state.errMsg.country : ""}
                        </span>
                      </div>

                      <div className="col-md-6 regsiterrf">
                        <label htmlFor="city" className="form-label fs-14">
                          {translation.CITY}
                        </label>
                        <Select
                          isClearable={false}
                          onChange={onCityChange}
                          options={citiesState}
                          placeholder={translation.SEARCH}
                          styles={selectStyles}
                          //tabSelectsValue={false}
                          value={state.selected_City}
                        />
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.city ? state.errMsg.city : ""}
                        </span>
                      </div>

                      <div className="col-md-12 regsiterrf">
                        <label
                          htmlFor="inputPassword4"
                          className="form-label fs-14"
                        >
                          {translation.SHORT_DESCRIPTION}
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          rows="5"
                          style={{ height: "69px" }}
                          value={state.about}
                          onChange={(e) => {
                            setState({ ...state, about: e.target.value });
                          }}
                        ></textarea>
                        <span className="fs-14" style={{ color: "red" }}>
                          {state.errMsg.about ? state.errMsg.about : ""}
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="loginwithlinks editprofile_block">
                <div className="d-lg-flex d-md-flex justify-content-end">
                  <div className="loginwithbtn">
                    <p className="fs-22 fw-600 text-primary">
                      {translation.UPDATE}{" "}
                      <button
                        onClick={updateProfile}
                        type="button"
                        className="btn btn-outline-primary rounded-circle ms-2"
                      >
                        <img src={chevron_right} alt="" />
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default EditBasicProfileInfo;
