import React from "react";
import useTranslation from "../../customHooks/translations";
import RecruiterHeader from "../../sharedComponents/RecruiterHeader";

function RecruiterFaq() {
  const translation = useTranslation();
  return (
    <div>
      <RecruiterHeader />
      <section className="breadcrumbs_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active" aria-current="page">
                    {translation.FAQ}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section id="faq" className="faq">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="accordion mt-5"
                id="accordionPanelsStayOpenExample"
              >
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingOne"
                  >
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne"
                      aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne"
                    >
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseOne"
                    className="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingTwo"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseTwo"
                    >
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingTwo"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="panelsStayOpen-headingThree"
                  >
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree"
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseThree"
                    >
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et?
                    </button>
                  </h2>
                  <div
                    id="panelsStayOpen-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="panelsStayOpen-headingThree"
                  >
                    <div className="accordion-body">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- <div className="col-lg-6 col-md-12 offset-lg-3">
                <div className="card delete_profile login settings_card" style="margin-top: 4em;">
                  <div className="card-body">
                      <p className="text-muted mb-4">Select a reason for deleting the account</p>

                      <div className="d-flex justify-content-between">
                        <p>This is temporary, I will be back.</p>
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                        </div>
                      </div>
                      <hr>
                      <div className="d-flex justify-content-between">
                          <p>I get too many notifications.</p>
                          <div className="form-check">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                          </div>
                      </div>
                      <hr>
                      <div className="d-flex justify-content-between">
                          <p>I spend too much time in Linco.</p>
                          <div className="form-check">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                          </div>
                      </div>
                      <hr>
                      <div className="d-flex justify-content-between">
                          <p>My account was hacked.</p>
                          <div className="form-check">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                          </div>
                      </div>
                      <hr>
                      <div className="d-flex justify-content-between">
                          <p>I didn’t find Linco useful.</p>
                          <div className="form-check">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                          </div>
                      </div>
                      <hr>
                      <div className="d-flex justify-content-between">
                          <p>I have a privacy concern.</p>
                          <div className="form-check">
                              <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                          </div>
                      </div>
                      <hr>

                      <div className="form_block w-100 pt-0">
                          <form className="row g-3 text-start">
                              <div className="col-md-12 regsiterrf">
                                  <label for="inputPassword4" className="form-label fs-14">Other</label>
                                  <textarea className="form-control" id="exampleFormControlTextarea1" rows="5" style="height: 69px;"></textarea>
                              </div>
                            </form>
                        </div>
                    </div>
                  </div>

                  <div className="loginwithlinks editprofile_block">
                      <div className="d-lg-flex d-md-flex justify-content-end">
                        <div className="loginwithbtn">
                          <p className="fs-22 fw-600 text-primary">Continue  <button type="button" className="btn btn-outline-primary rounded-circle ms-2" data-bs-toggle="modal" data-bs-target="#HomeUpgrade"><img src="img/chevron_right_colored.svg" alt=""></button>
                          </p>
                        </div>
                      </div>
                    </div>

                </div> --> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default RecruiterFaq;
